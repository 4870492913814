import React from 'react'
import Report from "./module/Report";
import {toDollars} from "../../util/Util";
import {CorkTable} from "corky";
import {PLATFORMS, TRANSACTION_TYPES} from "../../util/Constants";
import {bindActionCreators} from "redux";
import Actions from "../../actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ReportCard from "./module/ReportCard";
import {Reporting} from "drip-drop"

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class TaxesReport extends Report {
    componentDidMount() {
        let {start, end, locations} = this.props.partner.reporting;

        super.updateData(start, end, locations)
    }

    getColumns(data) {
        let {taxes, net} = Reporting.Helpers.calculateSummary(data);

        return {
            TAXABLE: "$" + toDollars(net),
            TAXES: "$" + toDollars(taxes)
        };
    }

    render() {
        let {orders: data} = this.props.partner.reporting.data;
        let {net, taxes} = Reporting.Helpers.calculateSummary(data);
        let {otherPayments} = this.props.partner;

        let cash = data.filter((item) => item.TRANSACTION_TYPE === TRANSACTION_TYPES.CASH);
        let web = data.filter((item) => item.PLATFORM === PLATFORMS.WEB);
        let terminal = data.filter((item) => item.PLATFORM === PLATFORMS.REGISTER && item.TRANSACTION_TYPE === TRANSACTION_TYPES.CARD);
        let mobile = data.filter((item) => item.PLATFORM === PLATFORMS.MOBILE);
        let gifts = data.filter((item) => item.TRANSACTION_TYPE === TRANSACTION_TYPES.GIFT_CARD);
        let other = data.filter((item) => item.TRANSACTION_TYPE === TRANSACTION_TYPES.OTHER);

        let leftOther = {ID: 7, METHOD: "Other", ...this.getColumns(other)};

        return (
            <div>
                {super.render()}

                <div className="row g-pa-20">
                    <ReportCard content={"$" + toDollars(net)} label="Taxable Sales" description="Net Sales"/>

                    <ReportCard label="Tax Collected" description="The tax we collected"
                                content={"$" + toDollars(taxes)}/>
                </div>

                <div className="row">
                    <CorkTable key={data} headerMode="download" downloadText="Download" header={{
                        TAXABLE: {
                            NAME: "Total Collected",
                            WIDTH: 50
                        },
                        TAXES: {
                            NAME: "Fees",
                            WIDTH: 50
                        }
                    }} sort="METHOD" data={[
                        {ID: 1, METHOD: "Cash", ...this.getColumns(cash)},
                        {ID: 2, METHOD: "Card in Store", ...this.getColumns(terminal)},
                        {ID: 3, METHOD: "Card on Web", ...this.getColumns(web)},
                        {ID: 4, METHOD: "Mobile", ...this.getColumns(mobile)},
                        {ID: 5, METHOD: "Gift Card", ...this.getColumns(gifts)},
                        ...(otherPayments.length > 0 ? otherPayments.map((item) => ({
                            ID: 10 + item.ID,
                            METHOD: item.NAME, ...this.getColumns(other.filter((other) => other.OTHER_ID === item.ID))
                        })) : [leftOther]),
                        {ID: 100000000, METHOD: "Total", ...this.getColumns(data)},
                    ]} desc={false} title="Sales Breakdown by Method"  ignoreRowParams={true} hidePaging={true}/>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaxesReport));