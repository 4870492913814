import React, {Component} from "react"
import {Chart} from "react-charts";
import PropTypes from "prop-types";
import moment from "moment";

const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

class DayChart extends Component {
    render() {
        let {
            data, title = "Unnamed Title", label = "Unnamed", calc = (item) => 1
        } = this.props;

        let {start, end} = this.props.partner.reporting;
        let dateData = data.reduce((dict, item) => {
            let day = moment(new Date(item.DATE_SENT)).format("MM/DD")

            if (dict[day]) {
                dict[day].push(item);
            } else {
                dict[day] = [item]
            }

            return dict;
        }, {});


        if (true === true) {
            let futureData = data.reduce((arr, item) => {
                arr[new Date(item.DATE_SENT).getDay()] += calc(item);
                return arr;
            }, [0, 0, 0, 0, 0, 0, 0]);

            return (
                <div className="col-xl-12">
                    <div className="card g-brd-gray-light-v7 g-pa-15 g-pa-25-30--md g-mb-30">
                        <header className="media g-mb-30">
                            <h3 className="d-flex align-self-center text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                {title}
                            </h3>
                        </header>

                        <section>
                            <Chart series={{type: 'bar'}} style={{width: "400px", height: "300px"}} data={[{
                                label, secondaryAxisID: label, data: futureData.map((item, i) => [DAYS_OF_WEEK[i], item])
                            }]} axes={[
                                {primary: true, type: "ordinal", position: "bottom"},
                                {type: "linear", hardMin: 0, id: label, position: "left"},
                            ]} primaryCursor tooltip/>
                        </section>
                    </div>
                </div>
            )
        }


        return (
            <div className="col-xl-12">
                <div className="card g-brd-gray-light-v7 g-pa-15 g-pa-25-30--md g-mb-30">
                    <header className="media g-mb-30">
                        <h3 className="d-flex align-self-center text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            {label}
                        </h3>
                    </header>

                    <section>
                        <Chart style={{
                            width: "400px",
                            height: "200px",
                        }} data={[{label, data: dateData}]} axes={[
                            {primary: true, type: "ordinal", position: "bottom"},
                            {type: "linear", position: "left"}
                        ]} primaryCursor tooltip/>
                    </section>
                </div>
            </div>
        )
    }
}

DayChart.propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    calc: PropTypes.func.isRequired
}

export default DayChart;