import React, {Component} from 'react';
import Fancy from "../../modules/fancy/Fancy";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {COLORS, formatDate, request} from "../../util/Util";
import moment from 'moment';
import News from "./modules/Feed";

class FeedScreen extends Component {
    state = {
        id: -1,
        name: "",
        price: "",
        category: null
    };

    componentDidMount() {
        let audio = null;

        this.interval = setInterval(() => {
            request('partner/valid', 'GET', null).then((_payload) => {
                let payload = _payload.find((item) => {
                    return item.location.ID === parseInt(localStorage.getItem("CURRENT_LOCATION"))
                });

                if (payload.orders.filter((item) => {
                    return item.DATE_COMPLETED === null
                }).length > 0) {
                    if (audio === null) {
                        audio = new Audio('assets/audio/alert_2.wav');
                    }

                    audio.play();
                }

                if (typeof payload.orders !== "undefined") {
                    this.props.updateOrders(payload.orders);
                }
            });
        }, 1000 * 60);

        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);

            let Util = {};
            Util.base64 = function (mimeType, base64) {
                return 'data:' + mimeType + ';base64,' + base64;
            };

            let video = document.createElement('video');
            video.setAttribute('loop', '');

            function addSourceToVideo(element, type, dataURI) {
                var source = document.createElement('source');
                source.src = dataURI;
                source.type = 'video/' + type;
                element.appendChild(source);
            }

            addSourceToVideo(video, 'webm', Util.base64('video/webm', 'GkXfo0AgQoaBAUL3gQFC8oEEQvOBCEKCQAR3ZWJtQoeBAkKFgQIYU4BnQI0VSalmQCgq17FAAw9CQE2AQAZ3aGFtbXlXQUAGd2hhbW15RIlACECPQAAAAAAAFlSua0AxrkAu14EBY8WBAZyBACK1nEADdW5khkAFVl9WUDglhohAA1ZQOIOBAeBABrCBCLqBCB9DtnVAIueBAKNAHIEAAIAwAQCdASoIAAgAAUAmJaQAA3AA/vz0AAA='));
            addSourceToVideo(video, 'mp4', Util.base64('video/mp4', 'AAAAHGZ0eXBpc29tAAACAGlzb21pc28ybXA0MQAAAAhmcmVlAAAAG21kYXQAAAGzABAHAAABthADAowdbb9/AAAC6W1vb3YAAABsbXZoZAAAAAB8JbCAfCWwgAAAA+gAAAAAAAEAAAEAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIAAAIVdHJhawAAAFx0a2hkAAAAD3wlsIB8JbCAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAQAAAAAAIAAAACAAAAAABsW1kaWEAAAAgbWRoZAAAAAB8JbCAfCWwgAAAA+gAAAAAVcQAAAAAAC1oZGxyAAAAAAAAAAB2aWRlAAAAAAAAAAAAAAAAVmlkZW9IYW5kbGVyAAAAAVxtaW5mAAAAFHZtaGQAAAABAAAAAAAAAAAAAAAkZGluZgAAABxkcmVmAAAAAAAAAAEAAAAMdXJsIAAAAAEAAAEcc3RibAAAALhzdHNkAAAAAAAAAAEAAACobXA0dgAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAAAIAAgASAAAAEgAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABj//wAAAFJlc2RzAAAAAANEAAEABDwgEQAAAAADDUAAAAAABS0AAAGwAQAAAbWJEwAAAQAAAAEgAMSNiB9FAEQBFGMAAAGyTGF2YzUyLjg3LjQGAQIAAAAYc3R0cwAAAAAAAAABAAAAAQAAAAAAAAAcc3RzYwAAAAAAAAABAAAAAQAAAAEAAAABAAAAFHN0c3oAAAAAAAAAEwAAAAEAAAAUc3RjbwAAAAAAAAABAAAALAAAAGB1ZHRhAAAAWG1ldGEAAAAAAAAAIWhkbHIAAAAAAAAAAG1kaXJhcHBsAAAAAAAAAAAAAAAAK2lsc3QAAAAjqXRvbwAAABtkYXRhAAAAAQAAAABMYXZmNTIuNzguMw=='));
            video.play();
        })
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    createProduct() {
        let {name, price, category} = this.state;
        request("products", "POST", {NAME: name, PRICE: price, CATEGORY: category}, (err, id) => {
            if (err) {
                alert("ERROR");
                return;
            }

            this.props.updateProducts([...this.props.partner.products, {
                NAME: name,
                PRICE: price,
                CATEGORY_ID: category,
                ID: id
            }]);

            this.setState({name: "", price: "", category: null});
            this.props.history.push("/product/" + id);
        });
    }

    push() {
        request("partner/push/settings", "POST", {}).then((status) => {
            if (status) {
                alert("Pushes have been enabled on device");
            } else {
                alert("Pushes have been disabled");
            }
        });
    }

    render() {
        let {name, price, category} = this.state;
        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        let Orders = [];

        this.props.partner.orders.filter((item) => {
            return item.DATE_COMPLETED === null
        }).forEach((item, i) => {
            Orders.push(<News key={i} item={item} {...this.props} />);
        });

        let Toggle = (
            <div style={{float: "right", margin: 15}}>
                <a style={{color: COLORS.DRIP_GOLD, cursor: "pointer", fontWeight: "bold"}}
                   onClick={this.push.bind(this)}>
                    Toggle Pushes on Device
                </a>
            </div>
        );


        return (
            <div>
                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">Drink Feed</h1>
                        </div>

                        <div style={{float: "right"}}>
                            {Toggle}
                        </div>
                    </div>

                    <h5>Click an order to complete it!</h5>

                    <hr />

                    <div style={{display: "flex", flexDirection: "row"}}>
                        <div style={{flexDirection: "column"}}>
                            {Orders}
                        </div>

                        <div style={{flex: 1}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default FeedScreen;
