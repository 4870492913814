import React, {Component} from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {CorkTable} from 'corky'
import CorkButton from "../../../modules/CorkButton";
import PromotionModal from "../modals/PromotionModal";
import 'react-day-picker/lib/style.css';
import {request, toDollars} from "../../../util/Util";

class PromotionsScreen extends Component {
    async remove(item) {
        if (!window.confirm("Are you sure you want to delete this promotion")) return
        let {promotions, location} = this.props.partner;

        await request("promotions/" + item.ID, "DELETE", {}, undefined, location.ID);
        let index = promotions.findIndex((promotion) => promotion.ID === item.ID);
        promotions.splice(index, 1);

        this.props.updatePromotions(promotions);
    }

    render() {
        let {promotions} = this.props.partner;

        return (
            <div>
                <PromotionModal ref={(e) => this.promotionModal = e} {...this.props} />

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                                Promotions
                            </h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton onClick={() => this.promotionModal.open()}>
                                Create Promotion
                            </CorkButton>
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        <CorkTable header={{
                            LOGO: {
                                NAME: "",
                                WIDTH: 5,
                                SORT: "ID"
                            },
                            NAME: {
                                NAME: "Name",
                                WIDTH: 30
                            },
                            TYPE: {
                                NAME: "Discount Type",
                                WIDTH: 20
                            },
                            CONTENT: {
                                NAME: "Discount Type",
                                WIDTH: 20
                            }
                        }} sort="LOGO" data={promotions.map((item) => ({
                            onDelete: this.remove.bind(this, item),
                            ...item, TYPE: item.DISCOUNT.FLAT_FEE ? "Flat Fee" : "Percentage",
                            CONTENT: item.DISCOUNT.FLAT_FEE ?
                                "$" + toDollars(item.DISCOUNT.FLAT_FEE) :
                                item.DISCOUNT.PERCENTAGE + "%",
                        }))} delete={true} desc={true} title=""/>
                    </div>
                </div>
            </div>
        )
    }
}

export default PromotionsScreen;
