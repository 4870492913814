import React, {Component} from "react"
import {request} from "../../../util/Util";

class BankSection extends Component {
    componentDidMount() {
        this.canContinue();
    }

    canContinue() {
        this.props.update(true);
    }

    tryStep() {
        return Promise.resolve();

        return new Promise((resolve, reject) => {
            request("employees/stripe", "POST", {REDIRECT: window.location.href}).then((data) => {
                window.location.href = data;
            });
        });
    }

    render() {
        return (
            <div style={{margin: "auto", maxWidth: 500}}>
                Click continue to finish your signup and link your billing account. This has to be done before you start
                your shift
            </div>
        );
    }
}

export default BankSection;