import React, {Component} from 'react'
import {toDollars} from "../../util/Util";
import {CorkTable} from "corky"

let moment = require("moment");

const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
class OrdersScreen extends Component {
    render() {
        let memberDict = {}, drinksDict = {};
        let drinks = this.props.partner.orders;
        let members = this.props.partner.members;

        for (let i = 0; i < members.length; i++) {
            memberDict[members[i]['ID']] = members[i];
        }

        for (let i = 0; i < drinks.length; i++) {
            let drink = drinks[i];

            if (!drinksDict.hasOwnProperty(drink['ACCOUNT_ID'])) {
                drinksDict[drink['ACCOUNT_ID']] = [];
            }

            drinksDict[drink['ACCOUNT_ID']].push(drink);
        }

        let tips = this.props.partner.tips.reduce((dict, item) => {
            dict[item.ORDER_ID] = item;
            return dict;
        }, {});

        let Rows = drinks.map((item, i) => {
            let user = memberDict[item.ACCOUNT_ID];

            let Re = moment(new Date(item.DATE_SENT));
            let tip = tips[item.ID] ? tips[item.ID].AMOUNT : 0;

            let completed = "?";
            if (item.DATE_COMPLETED !== null) {
                completed = moment(new Date(item.DATE_COMPLETED)).format("h:mm a");
            }

            return {
                ID: item.ID,
                LINK_ID: item.ACCOUNT_ID,
                NAME: user ? user.FULL_NAME : "Register Order",
                SUB: toDollars(item.SUBTOTAL),
                TAXES: toDollars(item.TAXES),
                TIP: toDollars(tip),
                TOTAL: toDollars(item.TOTAL + tip),
                TIME: Re.format('h:mm a'),
                DATE: Re.format('ddd MM/DD/YYYY'),
                FULL: completed,
                TIMESTAMP: item.DATE_SENT,
                ACCOUNT_ID: item.ACCOUNT_ID
            };
        });

        return (
            <div>
                <CorkTable header={{
                    ID: {
                        NAME: "Identifier",
                        WIDTH: 3,
                        LINK: "/order/",
                        LINK_ID: "ID"
                    },
                    NAME: {
                        NAME: "Patron",
                        WIDTH: 10,
                        LINK: "/patron/",
                        LINK_ID: "ACCOUNT_ID"
                    },
                    SUB: {
                        NAME: "Subtotal",
                        WIDTH: 5
                    },
                    TAXES: {
                        NAME: "Tax",
                        WIDTH: 5
                    },
                    TIP: {
                        NAME: "Tip",
                        WIDTH: 5,
                        IGNORE: "None"
                    },
                    TOTAL: {
                        NAME: "Total",
                        WIDTH: 5
                    },
                    TIME: {
                        NAME: "Ordered",
                        WIDTH: 5
                    },
                    FULL: {
                        NAME: "Fulfilled",
                        WIDTH: 5
                    },
                    DATE: {
                        NAME: "Date",
                        WIDTH: 10,
                        SORT: "TIMESTAMP"
                    }
                }} sort="DATE" data={Rows} desc={true} title="Tickets"/>
                <div style={{marginBottom: 25, height: 25}}>
                    <br/>
                </div>
            </div>
        );
    }

    static toDate(utc) {
        let jsDate = new Date(utc);
        let minutes = jsDate.getMinutes();

        if (minutes < 10) {
            minutes = "0" + minutes;
        }

        let time = '';
        if (jsDate.getHours() > 12) {
            time = jsDate.getHours() % 12 + ':' + minutes + ' PM on ';
        } else {
            if (jsDate.getHours() === 0) {
                time = '12:' + jsDate.getMinutes() + ' AM on ';
            } else {
                time = jsDate.getHours() + ':' + minutes + ' AM on ';
            }
        }

        return time + jsDate.toDateString()
    }

    static getHotDay(array) {
        let most = array[0], value = [0];
        for (let i = 1; i < array.length; i++) {
            if (array[i] > most) {
                value = [i];
                most = array[i];
            } else if (array[i] === most) {
                value.push(i);
            }
        }

        if (most === 0) {
            return "NaN";
        }

        return value.map((item) => {
            return DAYS[item];
        }).join("/");
    }
}

export default OrdersScreen;
