import React, {Component} from "react"
import moment from "moment"
import {getObjectImage, request} from "../../../util/Util";

const TODO = (
    <span
        className="u-tags-v1 text-center g-width-130 g-brd-around g-brd-primary g-bg-primary g-color-white g-rounded-50 g-py-4 g-px-15">To Do</span>
);

const DONE = (
    <span
        className="u-tags-v1 text-center g-width-130 g-brd-around g-brd-teal-v2 g-bg-teal-v2 g-color-white g-rounded-50 g-py-4 g-px-15">Done</span>
);

const PROGRESS = (
    <span
        className="u-tags-v1 text-center g-width-130 g-brd-around g-brd-lightblue-v3 g-bg-lightblue-v3 g-color-white g-rounded-50 g-py-4 g-px-15">In Progress</span>
);

class Checklist extends Component {
    state = {show: false};

    constructor(props) {
        super(props);

        this.random = Math.floor(Math.random() * 10000)
    }

    componentWillMount() {
        window.addEventListener("click", this.closeChecklist.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.closeChecklist.bind(this));
    }

    closeChecklist(e) {
        let {show} = this.state;

        if (!show) {
            return;
        }

        if (e.target && e.target.classList && e.target.classList.contains("no-close")) {
            return;
        }

        this.setState({show: false})
    }

    completeInstance() {
        let {checklistInstances} = this.props.partner;
        let {instance} = this.props;
        if (!window.confirm("Are you sure you want to mark this checklist complete")) {
            return;
        }

        request("checklists/instance/" + instance.ID, "POST", {}).then(() => {
            let index = checklistInstances.findIndex((item) => item.ID === instance.ID);
            checklistInstances.splice(index, 1, {...instance, DATE_COMPLETED: new Date().getTime()});

            this.props.updateChecklistInstances(checklistInstances);
        });
    }

    deleteInstance() {
        let {checklistInstances} = this.props.partner;
        let {instance} = this.props;
        if (!window.confirm("Are you sure you want to delete this instance of the checklist")) {
            return;
        }

        request("checklists/instance/" + instance.ID, "DELETE", {}).then(() => {
            let index = checklistInstances.findIndex((item) => item.ID === instance.ID);
            checklistInstances.splice(index, 1);

            this.props.updateChecklistInstances(checklistInstances);
        });
    }

    renderEditLine() {
        let {instance, checklist} = this.props;

        if (instance.DATE_STARTED === null) {
            return "Created by Drip servers";
        }


        let tasks = instance.TICKS;
        tasks.sort((a, b) => a.DATE_COMPLETED - b.DATE_COMPLETED);

        if (instance.DATE_COMPLETED !== null) {
            if (tasks.length === 0 || tasks.length !== checklist.LINES.length) {
                return "Marked complete by a partner"
            }

            return tasks[tasks.length - 1].EMPLOYEE.FULL_NAME + " completed the checklist"
        }

        if (tasks.length === 1) {
            return tasks[0].EMPLOYEE.FULL_NAME + " started the checklist"
        }

        return tasks[tasks.length - 1].EMPLOYEE.FULL_NAME + " completed a task"
    }

    renderEmployees() {
        let {instance} = this.props;

        if (instance.TICKS.length === 0) {
            return (
                <div/>
            )
        }

        let employees = {};
        for (let tick of instance.TICKS) {
            if (!employees[tick.EMPLOYEE.ID]) {
                employees[tick.EMPLOYEE.ID] = tick;
            }
        }

        return (
            <ul className="list-inline mb-0">
                {Object.values(employees).map((item) => {
                    return (
                        <li className="list-inline-item g-mb-10 g-mb-0--sm">
                            <a href={"/employee/" + item.EMPLOYEE_ID} target="_blank">
                                <img className="g-width-40 g-height-40 rounded-circle" title={item.EMPLOYEE.FULL_NAME}
                                     src={getObjectImage(item.EMPLOYEE, "LOGO", "appicon.png")}
                                     alt={item.EMPLOYEE.FULL_NAME}/>
                            </a>
                        </li>
                    );
                })}
            </ul>
        );
    }

    renderProgress() {
        let {instance} = this.props;

        if (instance.DATE_COMPLETED !== null) {
            return DONE;
        }

        if (instance.DATE_STARTED !== null) {
            return PROGRESS;
        }

        return TODO;
    }

    renderDropdown() {
        let {instance} = this.props;
        let {show} = this.state;

        if (!show) {
            return;
        }

        return (
            <div id={"dropDown_" + instance.ID + this.random}
                 className="u-shadow-v31 g-pos-abs g-right-0 g-bg-white u-dropdown--jquery-slide"
                 aria-labelledby={"dropDownInvoker_" + instance.ID + this.random}>
                <ul className="list-unstyled g-nowrap mb-0">
                    <li>
                        <a onClick={() => this.completeInstance()}
                           className="d-flex align-items-center u-link-v5 g-bg-gray-light-v8--hover g-font-size-12 g-font-size-default--md g-color-gray-dark-v6 g-px-25 g-py-14">
                            <i className="hs-admin-check g-font-size-18 g-color-gray-light-v6 g-mr-10 g-mr-15--md"/>
                            Mark as Done
                        </a>
                    </li>
                    <li>
                        <a onClick={() => this.deleteInstance()}
                           className="d-flex align-items-center u-link-v5 g-bg-gray-light-v8--hover g-font-size-12 g-font-size-default--md g-color-gray-dark-v6 g-px-25 g-py-14 show-cursor">
                            <i className="hs-admin-trash g-font-size-18 g-color-gray-light-v6 g-mr-10 g-mr-15--md"/>
                            Delete
                        </a>
                    </li>
                </ul>
            </div>
        )
    }

    render() {
        let {checklist, instance} = this.props;
        let {location} = this.props.partner;

        return (
            <div className="col-md-6 col-lg-4 g-mb-30">
                <div className="card h-100 g-brd-gray-light-v7 rounded">
                    <header className="card-header g-bg-transparent g-brd-bottom-none g-pa-20 g-pa-30--sm">
                        <div className="media g-mb-15">
                            <div className="d-flex align-self-center">
                                {location.NAME}
                            </div>
                            <div className="media-body d-flex justify-content-end">
                                <div className="g-pos-rel g-z-index-2">
                                    <div onClick={() => this.setState({show: true})}
                                       className="u-link-v5 g-line-height-0 g-font-size-24 g-color-gray-light-v6 g-color-secondary--hover"
                                       >
                                        <i className="hs-admin-more-alt g-ml-20 no-close"/>
                                    </div>

                                    {this.renderDropdown()}
                                </div>
                            </div>
                        </div>
                        <h3 className="g-font-weight-300 g-font-size-20 g-color-black g-mb-15">
                            {checklist.NAME} ({moment(instance.DATE_START_BY).format("hh:mma")})
                        </h3>
                        {this.renderProgress()}
                    </header>
                    <hr className="d-flex g-brd-gray-light-v7 g-mx-20 g-mx-30--sm my-0"/>
                    <div className="card-block d-flex justify-content-between g-px-20 g-px-30--sm g-py-15 g-py-20--sm">
                        <div>
                            <h4 className="g-line-height-1_2 g-font-weight-300 g-font-size-28 g-color-black">
                                {checklist.LINES.length}
                            </h4>
                            <em className="g-font-style-normal g-font-weight-300 g-font-size-16 g-color-gray-dark-v6">
                                Tasks
                            </em>
                        </div>
                        <div>
                            <h4 className="g-line-height-1_2 g-font-weight-300 g-font-size-28 g-color-black">
                                {instance.TICKS.length}
                            </h4>
                            <em className="g-font-style-normal g-font-weight-300 g-font-size-16 g-color-gray-dark-v6">
                                Done
                            </em>
                        </div>
                        <div>
                            <h4 className="g-line-height-1_2 g-font-weight-300 g-font-size-28 g-color-black">
                                {checklist.LINES.length - instance.TICKS.length}
                            </h4>
                            <em className="g-font-style-normal g-font-weight-300 g-font-size-16 g-color-gray-dark-v6">
                                Pending
                            </em>
                        </div>
                    </div>
                    <hr className="d-flex g-brd-gray-light-v7 g-mx-20 g-mx-30--sm my-0"/>
                    <div className="card-block g-px-20 g-px-30--sm g-py-15 g-py-20--sm">
                        <div className="row g-mb-25">
                            <div className="col-md-6 g-mb-25 g-mb-0--md">
                                <h5 className="g-font-size-default g-color-gray-dark-v6 g-mb-5">
                                    Date Started
                                </h5>
                                <p className="g-color-black mb-0">
                                    {instance.DATE_STARTED === null ? "Pending" : moment(instance.DATE_STARTED).format("hh:mm A")}
                                </p>
                            </div>
                            <div className="col-md-6">
                                <h5 className="g-font-size-default g-color-gray-dark-v6 g-mb-5">
                                    Date Completed
                                </h5>
                                <p className="g-color-black mb-0">
                                    {instance.DATE_COMPLETED === null ? "Pending" : moment(instance.DATE_COMPLETED).format("hh:mm A")}
                                </p>
                            </div>
                        </div>
                        <div className="g-mb-25">
                            <h5 className="g-font-size-default g-color-gray-dark-v6 g-mb-5">Last edited</h5>
                            <p className="g-color-black mb-0">{this.renderEditLine()}</p>
                        </div>
                        {this.renderEmployees()}
                    </div>
                </div>
            </div>
        );
    }
}

export default Checklist;