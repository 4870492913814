import React, {Component} from "react"
import {Fancy, FancyDay, FancySelect, FancyText} from "corky";
import {decimalToDollars, request} from "../../../util/Util";
import {DISCOUNT_TYPE, PROMOTION_TYPE} from "../../../util/Constants";
import PropTypes from "prop-types"

class PromotionModal extends Component {
    state = {
        name: "", code: "", redeems: "", start: null, end: null, discount: DISCOUNT_TYPE[0], content: "", products: [],
        categories: [], type: PROMOTION_TYPE[0], product: null, category: null
    };

    open() {
        let {products, categories} = this.props.partner;

        this.setState({
            products: products.map((item) => ({label: item.NAME, value: item.ID})),
            categories: categories.map((item) => ({label: item.NAME, value: item.ID}))
        }, () => this.modal.open());
    }

    async createPromotion() {
        let {name, code, end, redeems, start, content, discount, product, category} = this.state;
        let {coupons, promotions} = this.props.partner;
        let {promotion = true} = this.props;

        let isValid = true;
        for (let field of this.fields) {
            if (!field || !field.isValid) {
                continue;
            }

            if (!field.isValid()) {
                isValid = false;
            }
        }

        if (!isValid) {
            return false;
        }

        this.modal.getCreateButton().startLoading();

        let promotionPayload = {
            NAME: name, DATE_STARTED: start, DATE_EXPIRES: end, DISCOUNT: {
                FLAT_FEE: discount.value === 0 ? decimalToDollars(content) : undefined,
                PERCENTAGE: discount.value === 1 ? parseInt(content) : undefined,
            }, PRODUCT_ID: product && product.value, CATEGORY_ID: category && category.value
        };

        if (promotion) {
            let serverPromotion = await request("promotions/", "POST", promotionPayload);

            this.props.updatePromotions([...promotions, serverPromotion]);
        } else {
            let serverCoupon = await request("promotions/coupons", "POST", {
                CODE: code, MAX_REDEEMS: redeems, PROMOTION: promotionPayload
            });

            this.props.updateCoupons([...coupons, serverCoupon]);
        }
    }

    renderPromotionType() {
        let {type, categories, products} = this.state;

        switch (type.value) {
            default:
                return <div/>
            case 1:
                return (
                    <FancySelect label="Product" options={products} name="product" parentState={this.state}
                                 setParent={(state, callback) => this.setState(state, callback)} half={true}/>
                )
            case 2:
                return (
                    <FancySelect label="Category" options={categories} name="category" parentState={this.state}
                                 setParent={(state, callback) => this.setState(state, callback)} half={true}/>
                )
        }
    }

    render() {
        this.fields = [];
        let {discount} = this.state;
        let {promotion = true} = this.props;

        return (
            <Fancy name={promotion ? "Create Promotion" : "Create Coupon"} ref={(e) => this.modal = e}
                   buttonText="Create" onClick={this.createPromotion.bind(this)}>
                <div className="row g-mb-20">
                    <FancyText name="name" label="Name" tri={true}
                               placeholder="Example: Spring 2020 Rush"
                               ref={(e) => this.fields.push(e)} required={true}
                               parentState={this.state} setParent={(state) => this.setState(state)}
                    />

                    <FancySelect label="Discount Type" options={DISCOUNT_TYPE} name="discount" parentState={this.state}
                                 setParent={(state, callback) => this.setState(state, callback)} tri={true}/>

                    <FancyText name="content" label={discount.value === 0 ? "Dollars Off" : "Percentage Off"} tri={true}
                               placeholder={discount.value === 0 ? "Example: $1.50" : "Example: 50"}
                               ref={(e) => this.fields.push(e)} required={true}
                               parentState={this.state} setParent={(state) => this.setState(state)}
                    />
                </div>

                {promotion ? <div/> : (
                    <div className="row g-mb-20">
                        <FancyText name="code" label="Code" half={true}
                                   placeholder="Example: SPRING2020"
                                   ref={(e) => this.fields.push(e)} required={true}
                                   parentState={this.state} setParent={(state) => this.setState(state)}
                        />

                        {/*<FancyText name="redeems" label="Max Redeems" half={true}*/}
                        {/*           placeholder="Leave blank if none"*/}
                        {/*           ref={(e) => this.fields.push(e)} required={true}*/}
                        {/*           parentState={this.state} setParent={(state) => this.setState(state)}*/}
                        {/*/>*/}
                    </div>
                )}

                <div className="row g-mb-20">
                    <FancyDay label="Date Start" half={true} parentState={this.state} name="start"
                              placeholder="Leave blank if there is no time"
                              ref={(e) => this.fields.push(e)} required={true}
                              setParent={(state, callback) => this.setState(state, callback)}/>

                    <FancyDay label="Date End" half={true} parentState={this.state} name="end"
                              placeholder="Leave blank if there is no time"
                              ref={(e) => this.fields.push(e)} required={true}
                              setParent={(state, callback) => this.setState(state, callback)}/>
                </div>

                <div className="row g-mb-20">
                    <FancySelect label="Promotion Type" name="type" setParent={(state, callback) => this.setState({
                        ...state, product: null, category: null
                    }, callback)} half={true} options={PROMOTION_TYPE} parentState={this.state}/>

                    {this.renderPromotionType()}
                </div>
            </Fancy>
        )
    }
}

PromotionModal.propTypes = {
    promotion: PropTypes.bool
}

export default PromotionModal;