import React, {Component} from 'react'
import Report from "./module/Report";
import {request, toDollars} from "../../util/Util";
import {CorkTable} from "corky";
import {PLATFORMS, TRANSACTION_TYPES} from "../../util/Constants";
import {Chart} from "react-charts";
import moment from "moment";
import DailyChart from "./module/DailyChart";
import {bindActionCreators} from "redux";
import Actions from "../../actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ReportCard from "./module/ReportCard";
import DayChart from "./module/DayChart";
import HourChart from "./module/HourChart";
import {Reporting} from "drip-drop"
import {BarLoader} from "react-spinners";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class DrawerSummary extends Report {
    state = {drawer: null};

    async componentDidMount() {
        const {ID} = this.props.match.params;

        let drawer = await request("cashbox/" + ID, "GET", null);

        this.setState({drawer});
    }

    renderLoading() {
        return (
            <div style={{
                flex: 1,
                height: "calc(100vh - 65px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <BarLoader sizeUnit={"px"} size={200} color={'#123abc'} loading={true}/>
            </div>
        )
    }

    renderOrders() {
        // let {drawer} = this.state;
        //
        // return (
        //     <div className="row">
        //         <CorkTable key={data} headerMode="download" header={{
        //             DATE_OPEN: {
        //                 NAME: "Date Open",
        //                 FORMAT: (item) => moment(item).format("MMM, DD h:mma"),
        //                 WIDTH: 20
        //             },
        //             DATE_CLOSE: {
        //                 NAME: "Date Close",
        //                 FORMAT: (item) => moment(item).format("MMM, DD h:mma"),
        //                 WIDTH: 20
        //             },
        //             DEVICE_NAME: {
        //                 NAME: "Device Name",
        //                 FORMAT: (item) => item ? item : "-",
        //                 WIDTH: 20
        //             },
        //             AMOUNT_EXPECTED: {
        //                 NAME: "Expected Amount",
        //                 FORMAT: (item) => "$" + toDollars(item),
        //                 WIDTH: 15
        //             },
        //             AMOUNT_CLOSE: {
        //                 NAME: "Difference",
        //                 FORMAT: (item, row) => item ? "$" + toDollars(item - row.AMOUNT_EXPECTED) : "-",
        //                 WIDTH: 15
        //             },
        //             STATUS: {
        //                 NAME: "Status",
        //                 FORMAT: (item, row) => row.AMOUNT_CLOSE ? "Closed" : "Open",
        //                 WIDTH: 10
        //             }
        //         }} sort="DATE_OPEN" downloadText="Download" data={drawers}
        //                    desc={true} title="Sales Breakdown" ignoreRowParams={true} hidePaging={true}/>
        //     </div>
        // )
    }

    render() {
        let {drawers, orders: data} = this.props.partner.reporting.data;
        let {gross, tips, collected: total, discounts, net, refunds, taxes, processing} = Reporting.Helpers.calculateSummary(data);
        let {drawer} = this.state;

        if (drawer === null) {
            return this.renderLoading();
        }

        return (
            <div>
                {super.render()}

                <div className="row g-pa-20">
                    <ReportCard
                        content={drawer.DEVICE_NAME}
                        label="Device Name" description="Device name the drawer opened from"/>

                    <ReportCard content={drawer.AMOUNT_CLOSE ? "Closed" : "Open"}
                        label="Status" description="Drawer Status"/>

                    <ReportCard label="Date Open" description="Date and time drawer was opened"
                                content={moment(drawer.DATE_OPEN).format("MMM, DD h:mma")}/>

                    <ReportCard label="Date Close" description="Date and time drawer was closed"
                                content={drawer.DATE_CLOSE === null ? "In Progress" : moment(drawer.DATE_CLOSE).format("MMM, DD h:mma")}/>

                    <ReportCard label="Open Amount"
                                content={"$" + toDollars(drawer.AMOUNT_OPEN)}
                                description="Starting amount in drawer"/>

                    <ReportCard label="Close Amount"
                                content={drawer.AMOUNT_CLOSE === null ? "In Progress" : "$" + toDollars(drawer.AMOUNT_CLOSE)}
                                description="Closing amount in drawer"/>

                    <ReportCard label="Expected Amount"
                                content={drawer.AMOUNT_EXPECTED ? "$" + toDollars(drawer.AMOUNT_EXPECTED) : "-"}
                                description="Amount that's excepted to be in the drawer"/>

                    <ReportCard label="Closing Difference"
                                content={drawer.AMOUNT_CLOSE ? "$" + toDollars(drawer.AMOUNT_CLOSE - drawer.AMOUNT_EXPECTED) : "-"}
                                description="Drawer Close - Amount Expected"/>
                </div>

                <div className="row">
                    <CorkTable key={data} headerMode="download" header={{
                        TYPE: {
                            NAME: "Event Type",
                            FORMAT: (item) => moment(item).format("MMM, DD h:mma"),
                            WIDTH: 20
                        },
                        AMOUNT: {
                            NAME: "Amount",
                            FORMAT: (item) => "$" + toDollars(item),
                            WIDTH: 20
                        },
                        DATE_SENT: {
                            NAME: "Date Sent",
                            FORMAT: (item) => moment(item).format("MMM, DD h:mma"),
                            WIDTH: 15
                        },
                        NOTES: {
                            NAME: "Event Notes",
                            FORMAT: (item) => !item || item.length === 0 ? "None" : item,
                            WIDTH: 20
                        },
                    }} sort="DATE_OPEN" downloadText="Download" data={drawer.PAYINS}
                               desc={true} title="Drawer Events" ignoreRowParams={true} hidePaging={true}/>
                </div>

                {this.renderOrders()}
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DrawerSummary));