import moment from "moment";

const TO_DAY = {
    0: "Monday",
    1: "Tuesday",
    2: "Wednesday",
    3: "Thursday",
    4: "Friday",
    5: "Saturday",
    6: "Sunday"
};

export function getOperatingHours() {
    let {hours} = this.props.partner;

    let earliest = 1000 * 60 * 60 * 24, latest = 0;
    for (let day of Object.values(TO_DAY)) {
        let upDay = day.toUpperCase();
        if (hours[upDay + "_OPEN"] === "CLOSED" || hours[upDay + "_CLOSE"] === "CLOSED") {
            continue;
        }

        let startMoment = moment(hours[upDay + "_OPEN"]);
        let closeMoment = moment(hours[upDay + "_CLOSE"]);
        let start = startMoment.toDate().getTime() - startMoment.startOf("day").toDate().getTime();
        let close = closeMoment.toDate().getTime() - closeMoment.startOf("day").toDate().getTime();

        if (start < earliest) {
            earliest = start;
        }

        if (close > latest) {
            latest = close;
        }
    }

    let start = moment().startOf("day");
    let ourStart = moment(start).add(earliest / 1000, "seconds").startOf("hour").toDate().getTime();
    let ourEnd = moment(start).add(latest / 1000, "seconds").startOf("hour").toDate().getTime();

    let items = [], startSeconds = start.toDate().getTime();
    while (ourStart <= ourEnd) {
        items.push({label: moment(ourStart).format("ha"), value: ourStart - startSeconds});

        ourStart += 1000 * 60 * 60;
    }

    return items;
}

export function getOperatingHour(day) {
    let {hours} = this.props.partner;

    let startMoment = moment(hours[day.toUpperCase() + "_OPEN"]), closeMoment = moment(hours[day.toUpperCase() + "_CLOSE"]);
    let starting = startMoment.toDate().getTime() - startMoment.startOf("day").toDate().getTime();
    let closing = closeMoment.toDate().getTime() - closeMoment.startOf("day").toDate().getTime()

    let upDay = day.toUpperCase();
    if (hours[upDay + "_OPEN"] === "CLOSED" || hours[upDay + "_CLOSE"] === "CLOSED") {
        return [];
    }

    let start = moment().startOf("day");
    let ourStart = moment(start).add(starting / 1000, "seconds").startOf("hour").toDate().getTime();
    let ourEnd = moment(start).add(closing / 1000, "seconds").startOf("hour").toDate().getTime();

    let items = [], startSeconds = start.toDate().getTime();
    while (ourStart <= ourEnd) {
        items.push({label: moment(ourStart).format("ha"), value: ourStart - startSeconds});

        ourStart += 1000 * 60 * 60;
    }

    items.pop();
    return items;
}