import React, {Component} from 'react';
import Fancy from "../../modules/fancy/Fancy";
import 'bootstrap-daterangepicker/daterangepicker.css';
import {asyncDelay, parseIdDict, request, toDollars} from "../../util/Util";
import {CorkTable} from 'corky'
import {Link} from 'react-router-dom'
import ReorderModal from "./modules/ReorderModal";
import CorkButton from "../../modules/CorkButton";
import {sendSuccess} from "../../helpers/NotificationHelper";
import CreateProductModal from "./modals/CreateProductModal";

const ENABLED = <span
    className="u-tags-v1 text-center g-width-150--md g-brd-around g-bg-darkblue-v2 g-font-size-12 g-font-size-default--md g-color-white g-rounded-50 g-py-4 g-px-15">Enabled</span>
const DISABLED = <span
    className="u-tags-v1 text-center g-width-150--md g-brd-around g-bg-darkred g-font-size-12 g-font-size-default--md g-color-white g-rounded-50 g-py-4 g-px-15">Disabled</span>

class ProductsScreen extends Component {
    state = {
        id: -1,
        name: "",
        price: "",
        category: null,
        settings: null,
        data: null,
        location: null
    };

    componentWillMount() {
        this.setState({data: this.props.partner.products});
    }

    deleteProduct(ID) {
        if (!window.confirm("Are you sure you want to delete this product? There is no going back!")) {
            return;
        }

        request("product/" + ID, "DELETE", {}, (err) => {
            if (err) {
                alert("Internal Error");
                return;
            }

            let products = this.props.partner.products;
            products.some((item, i) => {
                if (item.ID === ID) {
                    products.splice(i, 1);
                    return true;
                }
            });

            this.setState({data: products, settings: {reload: Math.floor(Math.random() * 1000000)}});
        });
    }

    toggleEnabled(ID) {
        request("product/" + ID + "/toggle", "POST", {}, (err) => {
            if (err) {
                alert("Internal Error");
                return;
            }

            let page = this.table.state.page;
            let products = this.props.partner.products;
            products.some((item, i) => {
                if (item.ID === ID) {
                    products.splice(i, 1, {...item, ENABLED: item.ENABLED ? 0 : 1});
                    return true;
                }
            });

            this.setState({data: products, settings: {reload: Math.floor(Math.random() * 1000000)}});
        });
    }

    sync() {
        let startTime = new Date().getTime();
        this.syncButton.startLoading();

        request("partner/sync", "POST", {}).then(async () => {
            await asyncDelay(startTime);

            this.syncButton.stopLoading();
            sendSuccess("Menu Synced", "Menu synced to mobile app and register");
        });
    }

    render() {
        let {category, data} = this.state;

        let Label = "Please select category";
        let Categories = this.props.partner.categories.map((item) => {
            if (category === item.ID) {
                Label = item.NAME;
            }

            return (
                <li data-original-index={1}><a tabIndex={0} className data-tokens="null"
                                               onClick={() => this.setState({category: item.ID})}
                                               role="option" aria-disabled="false"
                                               aria-selected="false"><span className="text">{item.NAME}</span><span
                    className="glyphicon glyphicon-ok check-mark"/></a></li>
            )
        });

        let categoryDict = parseIdDict(this.props.partner.categories);
        let Data = data.map((item) => {
            return {
                onDelete: () => this.deleteProduct(item.ID),
                onEdit: () => this.props.history.push("/product/" + item.ID),
                ID: item.ID,
                NAME: (
                    <Link
                        className="d-flex align-items-center u-link-v5 g-color-black g-color-secondary--hover g-color-secondary--opened"
                        to={"/product/" + item.ID}>
                        <img
                            style={{width: 50, height: 50, objectFit: "cover", borderRadius: 10}}
                            className="g-mr-20--sm"
                            src={item.LOGO === null ?
                                "https://s3.amazonaws.com/drip-drinks/appicon.png" :
                                "https://s3.amazonaws.com/drip-drinks/" + item.LOGO} alt="Product Image"/>
                        <span className="g-hidden-sm-down">{item.NAME}</span>
                    </Link>
                ),
                _NAME: item.NAME,
                PRICE: "$" + parseFloat(toDollars(item.PRICE)).toFixed(2),
                CATEGORY: item.CATEGORY_ID,
                CATEGORY_NAME: categoryDict[item.CATEGORY_ID].NAME,
                STATUS: item.ENABLED ?
                    <div onClick={() => this.toggleEnabled(item.ID)}>{ENABLED}</div> :
                    <div onClick={() => this.toggleEnabled(item.ID)}>{DISABLED}</div>,
                _STATUS: item.ENABLED
            }
        });

        let categories = this.props.partner.categories.map((item) => ({
            label: item.NAME,
            value: item.ID
        }));

        categories.unshift({
            label: "All Products",
            value: null
        });

        return (
            <div>
                <ReorderModal ref={(e) => this.reorder = e} {...this.props}/>
                <CreateProductModal ref={(e) => this.createModal = e} {...this.props} />

                <Fancy name="Reorder Category" ref={(e) => this.orderSelector = e} buttonText="Select"
                       onClick={() => this.orderSelector.close().then(() => this.reorder.open(category))}
                >
                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Select Category to Reorder</label>

                        <div className="form-group u-select--v2 g-pos-rel g-brd-gray-light-v7 g-rounded-4 mb-0">
                            <span
                                className="g-pos-abs g-top-0 g-right-0 d-block g-width-40 h-100 opacity-0 g-opacity-1--success">
                                <i className="hs-admin-check g-absolute-centered g-font-size-default g-color-secondary"/>
                            </span>

                            <div className="btn-group bootstrap-select js-select u-select--v2-select w-100">
                                <button type="button"
                                        className={"btn dropdown-toggle btn-default " + (category === null ? "bs-placeholder" : "")}
                                        data-toggle="dropdown" role="button" title={Label}>
                                    <span className="filter-option pull-left">
                                        {Label}
                                    </span>&nbsp;
                                    <span className="bs-caret"><span className="caret"/></span>
                                </button>
                                <div className="dropdown-menu open" role="combobox">
                                    <ul className="dropdown-menu inner" role="listbox" aria-expanded="false">
                                        {Categories}
                                    </ul>
                                </div>
                            </div>

                            <i className="hs-admin-angle-down g-absolute-centered--y g-right-0 g-color-gray-light-v6 ml-auto g-mr-15"/>
                        </div>
                    </div>
                </Fancy>

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">Products</h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton type={CorkButton.TYPES.OUTLINE} onClick={() => this.setState({
                                category: null
                            }, () => {
                                this.orderSelector.open();
                            })}>
                                Order Products
                            </CorkButton>

                            <CorkButton type={CorkButton.TYPES.OUTLINE} marginLeft onClick={this.sync.bind(this)} ref={(e) => this.syncButton = e}>
                                Sync Products
                            </CorkButton>

                            <CorkButton marginLeft onClick={() => this.createModal.open()}>
                                New Product
                            </CorkButton>
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <CorkTable headerMode="simple" settings={this.state.settings} ref={(e) => this.table = e}
                                   filters={[{
                                       label: "Status:",
                                       column: "STATUS",
                                       items: [
                                           {label: "All", badge: "g-bg-black", value: null},
                                           {label: "Enabled", badge: "g-bg-darkblue-v2", value: 1},
                                           {label: "Disabled", badge: "g-bg-darkred", value: 0}
                                       ]
                                   }, {
                                       label: "Categories:",
                                       placeholder: "Select Category",
                                       column: "CATEGORY",
                                       items: categories
                                   }]} edit={true} delete={true} header={{
                            NAME: {
                                NAME: "Name",
                                WIDTH: 25,
                                TYPE: "String",
                                SORT: "_NAME"
                            },
                            CATEGORY_NAME: {
                                NAME: "Category",
                                TYPE: "String",
                                WIDTH: 25,
                            },
                            PRICE: {
                                NAME: "Price",
                                WIDTH: 15,
                                TYPE: "Number"
                            },
                            STATUS: {
                                NAME: "Mobile Status",
                                WIDTH: 10,
                                SORT: "_STATUS"
                            }
                        }} sort="ID" data={Data} desc={true} title="" sortItems={["_TITLE"]}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductsScreen;
