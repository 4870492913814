import React, {Component} from "react"
import PropTypes from "prop-types"

class ButtonRow extends Component {
    render() {
        return (
            <div className="media-row" style={{display: "flex", flexDirection: "row"}}>
                {this.props.children}
            </div>
        )
    }
}

ButtonRow.propTypes = {
    children: PropTypes.array
};

export default ButtonRow;