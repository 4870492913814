import React, {Component} from "react"
import Fancy from "../../../modules/fancy/Fancy";

import {FancySelect} from "corky"
import {request} from "../../../util/Util";
import {Products} from "drip-drop";
import {
    ISO_WEEK_DAYS,
    PRODUCT_AVAILABILITY_COMPARISONS,
    PRODUCT_AVAILABILITY_TYPES,
    QUARTER_HOURS
} from "../../../util/Constants";

const FIELDS = {
    TYPE: "availability-type",
    CONTENT: "availability-content",
    COMPARE: "availability-compare",
    EXTRA: "availability-extra"
};

class AvailabilityModal extends Component {
    state = {setup: false};

    constructor(props) {
        super(props);

        this.form = {
            [FIELDS.COMPARE]: PRODUCT_AVAILABILITY_COMPARISONS[0],
            [FIELDS.TYPE]: PRODUCT_AVAILABILITY_TYPES[0],
            [FIELDS.CONTENT]: ISO_WEEK_DAYS[0],
            [FIELDS.CONTENT]: QUARTER_HOURS[40]
        };
    }

    addAvailability() {
        let form = this.form;
        const {ID} = this.props.match.params;
        let {productAvailability} = this.props.partner;

        let isValid = true;
        for (let field of this.fields) {
            if (field === null) {
                continue;
            }

            if (!field.isValid()) {
                isValid = false;
            }
        }

        if (!isValid) {
            return;
        }

        let type = form[FIELDS.TYPE].value;
        let content = form[FIELDS.CONTENT].value;
        let compare = form[FIELDS.COMPARE].value;
        let extra = type === Products.Constants.AVAILABILITY_TYPES.DAY_OF_WEEK ? form[FIELDS.EXTRA].value : null
        request(`products/${ID}/availability`, "POST", {
            TYPE: type,
            CONTENT: content,
            EXTRA: extra,
            COMPARE: compare
        }).then(async (payload) => {
            await this.fancy.close();

            productAvailability.push({
                ID: payload, PRODUCT_ID: parseInt(ID), TYPE: type, CONTENT: content, COMPARE: compare, EXTRA: extra
            });

            this.props.updateProductAvailability(productAvailability);
        })
    }

    open() {
        this.form = {
            [FIELDS.COMPARE]: PRODUCT_AVAILABILITY_COMPARISONS[0],
            [FIELDS.TYPE]: PRODUCT_AVAILABILITY_TYPES[0],
            [FIELDS.EXTRA]: ISO_WEEK_DAYS[0],
            [FIELDS.CONTENT]: QUARTER_HOURS[40]
        };

        this.forceUpdate();
        this.fancy.open()
    }

    renderExtra() {
        if (!this.form[FIELDS.TYPE]) {
            return (
                <div className="col-md-6" />
            );
        }

        switch (this.form[FIELDS.TYPE].value) {
            default:
            case Products.Constants.AVAILABILITY_TYPES.WEEKDAYS:
            case Products.Constants.AVAILABILITY_TYPES.WEEKENDS:
            case Products.Constants.AVAILABILITY_TYPES.EVERYDAY:
                return (
                    <div className="col-md-6" />
                );
            case Products.Constants.AVAILABILITY_TYPES.DAY_OF_WEEK:
                return (
                    <FancySelect form={this.form} id={FIELDS.EXTRA} label="Day of Week" half={true}
                                 defaultValue={ISO_WEEK_DAYS[0]}
                                 options={ISO_WEEK_DAYS} ref={(e) => this.fields.push(e)} required={true}/>
                )
        }
    }

    render() {
        this.fields = [];

        return (
            <Fancy name="Add Unavailability" onClick={this.addAvailability.bind(this)} ref={(e) => this.fancy = e}
                   buttonText="Add">
                <div className="g-mb-20 row">
                    <FancySelect form={this.form} id={FIELDS.TYPE} label="Type" half={true}
                                 defaultValue={PRODUCT_AVAILABILITY_TYPES[0]} onChange={() => this.forceUpdate()}
                                 options={PRODUCT_AVAILABILITY_TYPES} ref={(e) => this.fields.push(e)} required={true}/>

                    <FancySelect form={this.form} id={FIELDS.COMPARE} ref={(e) => this.fields.push(e)}
                                 label="Comparison" onChange={() => this.forceUpdate()}
                                 options={PRODUCT_AVAILABILITY_COMPARISONS} required={true} half={true}
                                 defaultValue={PRODUCT_AVAILABILITY_COMPARISONS[0]}/>
                </div>

                <div className="g-mb-20 row">
                    {this.renderExtra()}

                    {this.form[FIELDS.COMPARE] && this.form[FIELDS.COMPARE].value === 2 ? <div/> : (
                        <FancySelect form={this.form} id={FIELDS.CONTENT} label="Time" defaultValue={QUARTER_HOURS[40]}
                                     options={QUARTER_HOURS} ref={(e) => this.fields.push(e)} required={true}
                                     half={true}/>
                    )}
                </div>
            </Fancy>
        )
    }
}

export default AvailabilityModal;