import React, {Component} from 'react'
import {Switch, Route, Link} from 'react-router-dom'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import Actions from '../../actions/index'

import {
    withRouter
} from 'react-router-dom'
import GeneralTab from "./tabs/GeneralTab";
import SecondaryNavItem from "../../modules/SecondaryNavItem";
import TimesheetsTab from "./tabs/TimesheetsTab";
import PayoutsTab from "./tabs/PayoutsTab";
import PayoutTab from "./tabs/PayoutTab";
import {getObjectImage} from "../../util/Util";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class EmployeeScreen extends Component {
    state = {
        active: 0,
        user: {FIRST_NAME: "Unknown", LAST_NAME: "User"}
    };

    render() {
        const {ID} = this.props.match.params;
        let employee = this.props.partner.employees.find((item) => item.ID === parseInt(ID));

        if (!employee) {
            return (
                <div>
                    Employee not found
                </div>
            );
        }

        return (
            <div>
                <div className="g-hidden-sm-down g-bg-gray-light-v8 g-pa-20">
                    <ul className="u-list-inline g-color-gray-dark-v6">
                        <li className="list-inline-item g-mr-10">
                            <Link className="u-link-v5 g-color-gray-dark-v6 g-color-secondary--hover g-valign-middle"
                                  to="/employees">
                                Employees
                            </Link>
                            <i className="hs-admin-angle-right g-font-size-12 g-color-gray-light-v6 g-valign-middle g-ml-10"/>
                        </li>
                        <li className="list-inline-item g-mr-10">
                            <Link className="u-link-v5 g-color-gray-dark-v6 g-color-secondary--hover g-valign-middle"
                                  to={`/employee/${ID}`}>
                                {employee.FULL_NAME}
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="g-pa-20">
                    <div className="row">
                        <div className="col-md-3 g-mb-30 g-mb-0--md">
                            <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                                <section className="text-center g-mb-30 g-mb-50--md">
                                    <div className="d-inline-block g-pos-rel g-mb-20">
                                        <img className="img-fluid rounded-circle"
                                             src={getObjectImage(employee, "ACCOUNT_LOGO", "appicon.png")}
                                             alt=""/>
                                    </div>

                                    <h3 className="g-font-weight-300 g-font-size-20 g-color-black mb-0">
                                        {employee.FULL_NAME}
                                    </h3>
                                </section>

                                <section>
                                    <ul className="list-unstyled mb-0">
                                        <SecondaryNavItem icon="hs-admin-user" {...this.props}
                                                          url={"/employee/" + ID} name="General Information"/>
                                        <SecondaryNavItem icon="fa fa-hourglass-half" {...this.props}
                                                          url={"/employee/" + ID + "/timesheet"} name="Timesheet"/>
                                        {/*<SecondaryNavItem icon="fa fa-money-check-alt" {...this.props}*/}
                                        {/*                  url={"/employee/" + ID + "/payouts"} name="Payouts"/>*/}
                                    </ul>
                                </section>
                            </div>
                        </div>

                        <Switch>
                            <Route exact path='/employee/:ID'
                                   component={() => <GeneralTab employee={employee} {...this.props} />}/>
                            <Route exact path='/employee/:ID/timesheet'
                                   component={() => <TimesheetsTab employee={employee} {...this.props} />}/>
                            <Route exact path='/employee/:ID/payouts'
                                   component={() => <PayoutsTab employee={employee} {...this.props} />}/>
                            <Route exact path='/employee/:ID/payout/:PAYOUT'
                                   component={PayoutTab}/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmployeeScreen));