import React, {Component} from "react";
import PropTypes from "prop-types";

class ReportCard extends Component {
    render() {
        let {label, content, description} = this.props;

        return (
            <div className="col-sm-6 col-lg-6 col-xl-3 g-mb-30">
                <div className="card h-80 g-brd-gray-light-v7 rounded">
                    <div className="card-block g-pa-20">
                        <h4 className="media g-font-weight-300 g-font-size-16 g-mb-10 g-mb-20--md">
                            <span className="d-flex align-self-center g-color-gray-dark-v6 g-font-weight-400">
                                {label}
                            </span>

                            <span className="media-body align-self-center text-right g-font-size-18--md g-color-black">
                                {content}
                            </span>
                        </h4>

                        <p className="g-font-weight-300 g-color-gray-dark-v6 mb-0">
                            {description}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

ReportCard.propTypes = {
    label: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
}

export default ReportCard;