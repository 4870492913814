import React, {Component} from 'react'
import Report from "./module/Report";
import {toDollars} from "../../util/Util";
import {CorkTable} from "corky";
import {PLATFORMS, TRANSACTION_TYPES} from "../../util/Constants";
import {Chart} from "react-charts";
import moment from "moment";
import DailyChart from "./module/DailyChart";
import {bindActionCreators} from "redux";
import Actions from "../../actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ReportCard from "./module/ReportCard";
import DayChart from "./module/DayChart";
import HourChart from "./module/HourChart";
import {Reporting} from "drip-drop"

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class ProductsReport extends Report {
    componentDidMount() {
        let {start, end, locations} = this.props.partner.reporting;

        super.updateData(start, end, locations)
    }

    render() {
        let {products: data, orders} = this.props.partner.reporting.data;
        let {net, discounts} = Reporting.Helpers.calculateSummary(orders);

        let Data = data.map((item) => ({
            ...item,
            SALES_SUM: "$" + toDollars(item.SALES_SUM),
            SUM_REFUNDS: "$" + toDollars(item.SUM_REFUNDS),
            NET: "$" + toDollars(item.SALES_SUM - item.SUM_REFUNDS)
        }));

        let custom = (net + discounts) - (data.reduce((accum, item) => accum + item.SALES_SUM - item.SUM_REFUNDS, 0));
        if (custom > 0) {
            Data.push({
                ID: 10000000, NAME: "Custom Product", CATEGORY_NAME: "-", ITEMS_SOLD: "-", NUMBER_REFUNDS: "-",
                SALES_SUM: "$" + toDollars(custom), SUM_REFUNDS: "$" + toDollars(0), NET: "$" + toDollars(custom)
            });
        }

        return (
            <div>
                {super.render()}

                <div className="row g-pa-20">
                    <ReportCard
                        content={data.reduce((accum, item) => accum + item.ITEMS_SOLD, 0)}
                        label="Products Sold" description="Number of products sold"/>

                    <ReportCard
                        content={data.reduce((accum, item) => accum + item.NUMBER_REFUNDS, 0)}
                        label="Products Refunded" description="Number of products refunded"/>
                </div>

                <CorkTable key={data} headerMode="download" header={{
                    NAME: {
                        NAME: "Item",
                        WIDTH: 40,
                        SORT: "ID"
                    },
                    CATEGORY_NAME: {
                        NAME: "Category",
                        WIDTH: 20
                    },
                    ITEMS_SOLD: {
                        NAME: "Items Sold",
                        WIDTH: 10
                    },
                    NUMBER_REFUNDS: {
                        NAME: "Items Refunded",
                        WIDTH: 10
                    },
                    SALES_SUM: {
                        NAME: "Sales Sum",
                        WIDTH: 20
                    }
                }} sort="KEY" downloadText="Download" ignoreRowParams={true} desc={false} title="Sales Breakdown"
                           data={Data}/>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsReport));