import React, {Component} from "react"
import {createPortal} from "react-dom"
import PropTypes from "prop-types"
import Fancy from "./Fancy";
import Select from "react-select";
import FancyComponent from "./FancyComponent"

class FancySelect extends FancyComponent {
    state = {value: null, error: null};

    componentWillMount(): void {
        let {value, form, id, defaultValue} = this.props;

        // If we have a form and id field, and it holds content we want to set the content as our value
        if (form && id && form[id]) {
            return this.setState({value: form[id]});
        }

        console.log(id, defaultValue);

        if (value) {
            if (form && id) {
                form[id] = value;
            }

            this.setState({value});
        } else if (defaultValue) {
            if (form && id) {
                form[id] = defaultValue;
            }

            this.setState({value: defaultValue});
        }
    }

    getValue() {
        let {value} = this.state;

        return value;
    }

    renderSubtext() {
        let {error} = this.state;

        if (!error) {
            return <div/>
        }

        return (
            <small className="g-font-weight-300 g-font-size-12 g-color-lightred-v2 g-pt-5">
                {error}
            </small>
        )
    }

    renderQuad() {
        let {label, options} = this.props;
        let {value} = this.state;

        return (
            <div className="col-md-3">
                <label className="g-mb-10" htmlFor="#bio">{label}</label>

                <Select styles={{menuList: () => ({maxHeight: 150, overflowY: "scroll"})}} {...this.props.select}
                        options={options} value={value}
                        onChange={(value) => this.setState({value})}
                />
            </div>
        )
    }

    renderHalf() {
        let {label, options} = this.props;
        let {value} = this.state;

        return (
            <div className="col-md-6">
                <label className="g-mb-10" htmlFor="#bio">{label}</label>

                <Select styles={{menuList: () => ({maxHeight: 150, overflowY: "scroll"})}} {...this.props.select}
                        options={options} value={value}
                        onChange={(value) => this.setState({value})}
                />
            </div>
        )
    }

    render() {
        let {half, quad, label, options, form, id, defaultValue} = this.props;
        let {value} = this.state;

        let wrapperClass = "g-mb-20";
        if (half) {
            wrapperClass = "col-md-6";
        } else if (quad) {
            wrapperClass = "col-md-3";
        }

        return (
            <div className={wrapperClass}>
                <label className="g-mb-10" htmlFor="#bio">
                    {label}
                </label>

                <div className={wrapperClass === "g-mb-20" ? "form-group mb-0" : ""} style={{marginLeft: 0}}>
                    <Select {...this.props.select} menuPortalTarget={document.body}
                            options={options} value={value}
                            styles={{
                                menuPortal: base => ({...base, zIndex: 99999}),
                                menuList: () => ({maxHeight: 150, overflowY: "scroll"}),
                                ...this.props.customStyles,
                            }}
                            onChange={(value) => {
                                if (form && id) {
                                    form[id] = value;
                                }

                                this.setState({value});
                            }}
                    />
                </div>

                {this.renderSubtext()}
            </div>
        )
    }
}

FancySelect.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    defaultValue: PropTypes.object,
    customStyles: PropTypes.object,
    value: PropTypes.string,
    half: PropTypes.bool,
    quad: PropTypes.bool,
    form: PropTypes.object,
    id: PropTypes.string
};

export default FancySelect;