import React, {Component} from 'react'
import {COLORS, getURL, request, toDollars} from "../../../util/Util";
import CorkButton from "../../../modules/CorkButton";
import CardModal from "./modals/CardModal";
import BankModal from "./modals/BankModal";
import {CorkTable} from "corky";

let moment = require("moment");
let chrono = require('chrono-node');

class BillingTab extends Component {
    state = {requirements: [], sources: [], subscriptions: [], charges: []};

    open() {
        if (this.props.partner.location.STRIPE_ID === null) {
            request("stripe/connect", "GET").then((data) => {
                window.open(data);
            });
        } else {
            request("stripe/dashboard", "GET").then((data) => {
                window.open(data);
            });
        }
    }

    componentDidMount() {
        let {location} = this.props.partner;
        request("stripe/status", "GET", null, undefined, location.ID).then((requirements) => {
            this.setState({requirements})
        });

        request("partner/billing", "GET", null, undefined, location.ID).then((data) => {
            if (data === null) {
                return;
            }

            this.setState({...data});
        })
    }

    redirect() {
        let {location} = this.props.partner;

        request("stripe/connect/partner", "POST", {}, undefined, location.ID).then((data) => {
            window.open(data);
        });
    }

    redirectGet() {
        let {location} = this.props.partner;

        request("stripe/connect/partner", "GET", null, undefined, location.ID).then((data) => {
            window.open(data);
        });
    }

    renderRequired() {
        let {requirements} = this.state;

        if (!requirements.currently_due) {
            return (
                <div>
                    Loading
                </div>
            );
        }

        if (requirements.currently_due.length === 0) {
            return (
                <div>
                    You are all set
                </div>
            )
        }

        let currentlyRequired = requirements.currently_due[0];

        if (currentlyRequired.startsWith("business_profile") || currentlyRequired.startsWith("company")) {
            return (
                <CorkButton onClick={this.redirectGet.bind(this)}>
                    Update Info
                </CorkButton>
            )
        }

        if (currentlyRequired.startsWith("external_account")) {

        }

        return (
            <div>
                None
            </div>
        )
    }

    renderConnect() {
        let {location} = this.props.partner;

        if (location.CONNECT_ID) {
            return (
                <div>
                    Done
                </div>
            );
        }

        return (
            <CorkButton onClick={this.redirect.bind(this)}>
                Add Connect
            </CorkButton>
        );
    }

    renderInfo() {
        return (
            <div>
                <header>
                    <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                        Payouts
                    </h2>
                </header>

                <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                {this.renderConnect()}

                {this.renderRequired()}
            </div>
        )
    }

    render() {
        let {sources, charges} = this.state;

        let Data = charges.map((item, i) => ({
            ID: i,
            DATE: moment(parseInt(item.created + "000")).format("MMMM DD, YYYY"),
            AMOUNT: toDollars(item.amount),
            SOURCE: (item.source.object === "card" ? item.source.brand + " Card" : "Bank") + " " + item.source.last4
        }));

        return (
            <div className="col-md-9">
                <CardModal ref={(e) => this.cardModal = e}
                           add={(card) => {
                               if (sources.length === 0) {
                                   sources[0].default = true;
                               }

                               this.setState({sources: [...sources, card]})
                           }}/>
                <BankModal ref={(e) => this.bankModal = e}
                           add={(bank) => {
                               if (sources.length === 0) {
                                   sources[0].default = true;
                               }

                               this.setState({sources: [...sources, bank]})
                           }}/>

                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <header>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            Plans
                        </h2>
                    </header>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-25--md"/>

                    <div className="row g-mx-minus-10 g-mb-60">
                        <div className="col-md-6 g-px-10 g-mb-20 g-mb-0--md">
                            <article
                                className="g-bg-white g-overflow-hidden g-brd-around g-brd-gray-light-v7 g-rounded-4">
                                <header className="text-center g-bg-gray-light-v8 g-pa-20">
                                    <h4 className="h6 text-uppercase g-font-weight-400 g-font-size-16 g-color-black mb-0">Drip
                                        POS</h4>
                                </header>
                                <div className="g-px-20 g-py-30">
                                    <strong
                                        className="d-block text-center g-color-primary g-font-weight-400 g-font-size-30 g-line-height-0_8 g-mb-25">
                                        <sup className="g-font-size-18">$</sup>149
                                        <span className="g-font-size-default g-color-gray-light-v11">/mo</span>
                                    </strong>

                                    <ul className="list-unstyled g-color-gray-dark-v12 g-min-height-200 g-px-10 g-mb-30">
                                        <li className="g-mb-20">
                                            <i className="hs-admin-check g-color-secondary g-mr-14"
                                               style={{marginRight: 16}}/>
                                            Unlimited products
                                        </li>
                                        <li className="g-mb-20">
                                            <i className="hs-admin-check g-color-secondary g-mr-14"
                                               style={{marginRight: 16}}/>
                                            Unlimited employees
                                        </li>
                                        <li className="g-mb-20">
                                            <i className="hs-admin-check g-color-secondary g-mr-14"
                                               style={{marginRight: 16}}/>
                                            All team features
                                        </li>
                                        <li className="g-mb-20">
                                            <i className="hs-admin-check g-color-secondary g-mr-14"
                                               style={{marginRight: 16}}/>
                                            All marketing features
                                        </li>
                                        <li className="g-mb-20">
                                            <i className="hs-admin-check g-color-secondary g-mr-14"
                                               style={{marginRight: 16}}/>
                                            All operational features
                                        </li>
                                        <li className="g-mb-20">
                                            <i className="hs-admin-check g-color-secondary g-mr-14"
                                               style={{marginRight: 16}}/>
                                            Analytics & reporting
                                        </li>
                                        <li className="mb-0">
                                            <i className="hs-admin-check g-color-secondary g-mr-14"
                                               style={{marginRight: 16}}/>
                                            Accounting dashboard
                                        </li>
                                    </ul>
                                    <a className="btn btn-md btn-xl--md btn-block u-btn-darkgray g-font-size-default--md"
                                       href="#!">Activate</a>
                                </div>
                            </article>
                        </div>
                        <div className="col-md-6 g-px-10 g-mb-20 g-mb-0--md">
                            <article
                                className="g-bg-white g-overflow-hidden g-brd-around g-brd-gray-light-v7 g-rounded-4">
                                <header className="text-center g-bg-gray-light-v8 g-pa-20">
                                    <h4 className="h6 text-uppercase g-font-weight-400 g-font-size-16 g-color-black mb-0">Standart</h4>
                                </header>
                                <div className="g-px-20 g-py-30">
                                    <strong
                                        className="d-block text-center g-color-primary g-font-weight-400 g-font-size-30 g-line-height-0_8">
                                        <sup className="g-font-size-18">$</sup>200
                                        <span className="g-font-size-default g-color-gray-light-v11">/mo</span>
                                    </strong>

                                    <strong
                                        className="d-block text-center g-color-primary g-font-weight-400 g-font-size-30 g-line-height-0_8 g-mb-25">
                                        <span className="g-font-size-default g-color-gray-light-v11">per location</span>
                                    </strong>

                                    <ul className="list-unstyled g-color-gray-dark-v12 g-min-height-200 g-px-10 g-mb-30">
                                        <li className="g-mb-20">
                                            <i className="hs-admin-check g-color-secondary g-mr-14"
                                               style={{marginRight: 16}}/>
                                            Branded Mobile App
                                        </li>
                                        <li className="g-mb-20">
                                            <i className="hs-admin-check g-color-secondary g-mr-14"
                                               style={{marginRight: 16}}/>
                                            iOS app listing
                                        </li>
                                        <li className="g-mb-20">
                                            <i className="hs-admin-check g-color-secondary g-mr-14"
                                               style={{marginRight: 16}}/>
                                            Android app listing
                                        </li>
                                        <li className="g-mb-0">
                                            <i className="hs-admin-check g-color-secondary g-mr-14"
                                               style={{marginRight: 16}}/>
                                            Continuous updates
                                        </li>
                                    </ul>
                                    <a className="btn btn-md btn-xl--md btn-block u-btn-primary g-font-size-default--md"
                                       href="#!">Activate</a>
                                </div>
                            </article>
                        </div>
                    </div>

                    <header>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            Past Payments
                        </h2>
                    </header>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-25--md"/>

                    <CorkTable noHeader={true} settings={Data} noPadding={true} header={{
                        DATE: {
                            NAME: "Date",
                            WIDTH: 40,
                            SORT: "ID"
                        },
                        AMOUNT: {
                            NAME: "Amount Charged",
                            WIDTH: 20,
                        },
                        SOURCE: {
                            NAME: "Payment",
                            WIDTH: 20,
                        }
                    }} sort="DATE" data={Data} desc={true}/>

                    <header>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            Payment Methods
                        </h2>
                    </header>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-25--md"/>

                    {sources.map((item) => (
                        <PaymentMethod item={item} parentState={this.state}
                                       setParent={(state) => this.setState(state)} {...this.props} />
                    ))}

                    <div className="row">
                        <div className="col-md-6">
                            <a className="js-check u-link-v5 g-pos-rel d-block g-color-gray-dark-v12 g-color-secondary--hover g-mb-60 show-cursor"
                               onClick={() => this.cardModal.open()}>
                                <div
                                    className="media align-items-center g-parent g-brd-around g-brd-style-dashed g-brd-gray-light-v7 g-rounded-4 g-px-20 g-py-15">
                                    <div className="d-flex">
                                        <div
                                            className="d-inline-block u-icon-v3 u-icon-size--lg g-bg-gray-light-v8 g-font-size-24 g-color-secondary rounded-circle">
                                            <i className="hs-admin-credit-card"/>
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex g-width-220 g-font-size-12 g-font-size-default--md g-ml-20">Add
                                        Debit or Credit Card
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-md-6">
                            <a className="js-check u-link-v5 g-pos-rel d-block g-color-gray-dark-v12 g-color-secondary--hover g-mb-60 show-cursor"
                               onClick={() => this.bankModal.open()}>
                                <div
                                    className="media align-items-center g-parent g-brd-around g-brd-style-dashed g-brd-gray-light-v7 g-rounded-4 g-px-20 g-py-15">
                                    <div className="d-flex">
                                        <div
                                            className="d-inline-block u-icon-v3 u-icon-size--lg g-bg-gray-light-v8 g-font-size-24 g-color-secondary rounded-circle">
                                            <i className="fa fa-university"/>
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex g-width-220 g-font-size-12 g-font-size-default--md g-ml-20">Add
                                        Bank Account
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class PaymentMethod extends Component {
    state = {show: false};

    clickClose(e) {
        if (!this.state.show) {
            return;
        }

        if (e.target && e.target.classList && e.target.classList.contains("no-close")) {
            return;
        }

        this.setState({show: false})
    }

    componentDidMount() {
        window.addEventListener("click", this.clickClose.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.clickClose.bind(this));
    }

    deleteSource() {
        let {item, parentState} = this.props;
        let {location} = this.props.partner;

        let {sources} = parentState;
        request("partner/source/" + item.id, "DELETE", {}, undefined, location.ID).then(() => {
            let index = sources.findIndex((_item) => item.id === _item.id);

            sources.splice(index, 1);
            this.props.setParent({sources});
        }).catch((err) => {
            alert("Error deleting source");
        });
    }

    renderName() {
        let {item} = this.props;

        if (item.object === "bank_account") {
            return "Bank xxxx " + item.last4;
        }

        return "Credit Card xxxx " + item.last4;
    }

    render() {
        let {show} = this.state;
        let {item} = this.props;

        return (
            <label className="g-pos-rel d-block g-mb-20">
                <input className="g-pos-abs opacity-0 no-close" name="radGroup1" type="radio"
                       checked={item.default}/>
                <div
                    className="media-md align-items-center g-parent g-bg-gray-light-v8--sibling-checked g-brd-around g-brd-gray-light-v7 g-rounded-4 g-px-20 g-py-15">
                    <div className="d-flex-md text-center g-mb-20 g-mb-0--md">
                        {/*<div*/}
                        {/*    className="d-inline-block u-icon-v3 u-icon-size--lg g-bg-gray-light-v8 g-bg-gray-light-v7--parent-sibling-checked g-font-size-24 g-color-secondary rounded-circle">*/}
                        {/*    <i className="g-font-size-0">*/}
                        {/*        <img className="img-fluid g-width-40" src="../assets/img/icons/visa.svg"*/}
                        {/*             alt=""/>*/}
                        {/*    </i>*/}
                        {/*</div>*/}
                    </div>
                    <div
                        className="d-flex align-items-center g-font-size-12 g-font-size-default--md g-mb-10 g-mb-0--md g-mx-10--md">
                        <div className="g-width-220--md">
                            {this.renderName()}
                        </div>
                        <div className="text-right g-width-40--md g-color-gray-dark-v12 ml-auto">06/21</div>
                    </div>
                    <div className="d-flex align-items-center ml-auto">
                        <em className="d-flex align-items-center g-font-style-normal g-color-gray-dark-v6 opacity-0 g-opacity-1--parent-sibling-checked">
                            <span
                                className="g-pos-rel g-width-20 g-height-20 g-bg-lightblue-v3 g-brd-around g-brd-lightblue-v3 rounded-circle">
                              <i className="hs-admin-check g-absolute-centered g-font-weight-800 g-font-size-12 g-color-white"
                                 title="Default"/>
                            </span>
                            <span
                                className="g-hidden-lg-down g-font-weight-300 g-font-size-default g-ml-10">Default</span>
                        </em>
                        <div className="g-pos-rel g-top-2 ml-auto g-ml-25--md">
                            <a onClick={() => this.setState({show: !show})}
                               className="u-link-v5 g-line-height-0 g-font-size-24 g-color-gray-light-v6 g-color-secondary--hover show-cursor"
                            >
                                <i className="hs-admin-more-alt no-close"/>
                            </a>
                            <div
                                className="u-shadow-v31 g-pos-abs g-top-100x g-right-0 g-bg-white u-dropdown--jquery-slide slideInDms"
                                style={{height: show ? "auto" : "0", overflow: "hidden", zIndex: 200}}>
                                <ul className="list-unstyled g-nowrap mb-0">
                                    <li>
                                        <a className="d-flex align-items-center u-link-v5 g-bg-gray-light-v8--hover g-font-size-12 g-font-size-default--md g-color-gray-dark-v6 g-px-25 g-py-14"
                                           href="#!">
                                            <i className="hs-admin-check g-font-size-18 g-color-gray-light-v6 g-mr-10 g-mr-15--md"/>
                                            Make Default
                                        </a>
                                    </li>
                                    <li>
                                        <a className="d-flex align-items-center u-link-v5 g-bg-gray-light-v8--hover g-font-size-12 g-font-size-default--md g-color-gray-dark-v6 g-px-25 g-py-14 show-cursor"
                                           onClick={this.deleteSource.bind(this)}>
                                            <i className="hs-admin-trash g-font-size-18 g-color-gray-light-v6 g-mr-10 g-mr-15--md"/>
                                            Delete
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </label>
        );
    }
}

export default BillingTab;
