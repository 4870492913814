import React, {Component} from 'react'
import FormItem from "../../../../modules/form/FormItem";
import FormTextBox from "../../../../modules/form/FormTextBox";
import {request} from "../../../../util/Util";
import CorkButton from "../../../../modules/CorkButton";

class GeneralTab extends Component {
    state = {invoice: {}, lines: []};

    componentWillMount() {
        let {invoice} = this.props;

        console.log("WILL HE OMG");
        this.setState({invoice: JSON.parse(JSON.stringify(invoice)), lines: JSON.parse(JSON.stringify(invoice.LINES))});
    }

    updateInfo() {
        let {invoice, lines} = this.state;

        request("accounting/invoice/" + invoice.ID, "PATCH", {...invoice, LINES: lines}).then((lRows) => {
            let invoices = this.props.partner.invoices;
            invoices.splice(invoices.findIndex((item) => item.ID === invoice.ID), 1, {...invoice, LINES: lRows});
            this.props.updateInvoices(invoices);
        }).catch((err) => {
            alert("Problem saving invoice");
        });
    }

    render() {
        let {invoice, lines} = this.state;

        return (
            <div className="col">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <form className="js-validate" onSubmit={(e) => e.preventDefault()}>
                        <header>
                            <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                Invoice Info
                            </h2>

                            <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                            <FormItem name="Number" value={invoice.INVOICE_NUMBER}/>
                            <FormItem name="Name" value={invoice.NAME}/>
                            <FormTextBox name="Notes" value={invoice.NOTES}/>

                            <header style={{marginTop: 40}}>
                                <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                    Invoice Lines
                                </h2>

                            </header>

                            <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                            {lines.map((line, i) => (
                                <Line line={line} updateLine={(line) => {
                                    lines.splice(i, 1, line);

                                    this.setState({lines});
                                }} removeLine={(line) => {
                                    lines.splice(i, 1);

                                    this.setState({lines});
                                }}/>
                            ))}

                            {/* eslint-disable-next-line */}
                            <a className="u-link-v5 d-flex align-items-center g-color-secondary g-ml-30 show-cursor"
                               onClick={() => this.setState({
                                   lines: [...lines, {
                                       DESCRIPTION: "",
                                       RATE: "",
                                       QUANTITY: ""
                                   }]
                               })}>
                                <span
                                    className="u-badge-v2--xl g-pos-rel g-transform-origin--top-left g-bg-lightblue-v3 g-font-size-10 g-color-white">
                                    <i className="hs-admin-plus g-absolute-centered"/>
                                </span>
                                <span className="g-ml-15">Add Line</span>
                            </a>

                            <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                            <div>
                                <CorkButton onClick={() => this.updateInfo()}>
                                    Save Changes
                                </CorkButton>
                            </div>
                        </header>
                    </form>
                </div>
            </div>
        )
    }
}

class Line extends Component {
    render() {
        let {line} = this.props;

        return (
            <div className="row g-mb-20">
                <div className="col-md-6">
                    <div className="form-group g-pos-rel mb-0">
                        <input
                            className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                            type="text" placeholder="Description"
                            onChange={(e) => this.props.updateLine({...line, DESCRIPTION: e.target.value})}
                            value={line.DESCRIPTION}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group g-pos-rel mb-0">
                        <input
                            className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                            type="text" placeholder="Rate (in cents)" value={line.RATE}
                            onChange={(e) => this.props.updateLine({...line, RATE: e.target.value})}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group g-pos-rel mb-0">
                        <input
                            className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                            type="text" placeholder="Quantity" value={line.QUANTITY}
                            onChange={(e) => this.props.updateLine({...line, QUANTITY: e.target.value})}
                        />
                    </div>
                </div>
                <div className="col-md-1" style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                     onClick={() => this.props.removeLine()}>
                    <i style={{color: "red", fontSize: 26}} className="fa fa-times show-cursor"/>
                </div>
            </div>
        )
    }
}


export default GeneralTab;
