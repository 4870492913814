import React, {Component} from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {COLORS, formatDate, request, toDollars} from "../../util/Util";
import {CorkTable, FancySelect} from 'corky'
import CorkButton from "../../modules/CorkButton";
import {Fancy, FancyText} from "corky";
import {Team} from "drip-drop"
import {bindActionCreators} from "redux";
import Actions from "../../actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import OtherPaymentsModal from "./modals/OtherPaymentsModal";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class OtherPaymentsScreen extends Component {
    render() {
        let {otherPayments} = this.props.partner;

        let Data = otherPayments.map((item) => {
            return {
                onEdit: () => this.otherModal.open(item),
                ID: item.ID,
                NAME: item.NAME
            }
        });

        return (
            <div>
                <OtherPaymentsModal ref={(e) => this.otherModal = e} {...this.props} />

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                                Other Payment Types
                            </h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton onClick={() => this.otherModal.open()}>
                                Create Other Payments
                            </CorkButton>
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        <CorkTable header={{
                            NAME: {
                                NAME: "Name",
                                WIDTH: 45,
                                TYPE: "String"
                            },
                        }} edit={true} sort="ID" data={Data} desc={true} title=""/>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OtherPaymentsScreen));
