import React, {Component} from 'react'
import FormItem from "../../../modules/form/FormItem";
import {request} from "../../../util/Util";
import CorkButton from "../../../modules/CorkButton";
import FormDropdown from "../../../modules/form/FormDropdown";
import {EMPLOYEE_NEW_HIRE, EMPLOYEE_STATUS, EMPLOYEE_TYPES} from "../../../util/Constants";
import moment from "moment";

class GeneralTab extends Component {
    state = {employee: null};

    connect() {
        let {employee} = this.props;
        request("employees/connect/" + employee.ID, "POST", {}).then((data) => {
            window.open(data);
        });
    }

    componentWillMount() {
        let {employee} = this.props;

        let employeeClone = JSON.parse(JSON.stringify(employee));
        employeeClone.ROLE_ID_DICT = {label: employee.ROLE_NAME, value: employee.ROLE_ID};
        employeeClone.TYPE_DICT = EMPLOYEE_TYPES.find((item) => item.value === employeeClone.TYPE);
        employeeClone.STATUS_DICT = EMPLOYEE_STATUS.find((item) => item.value === employeeClone.STATUS);
        employeeClone.PAYROLL_DICT = EMPLOYEE_NEW_HIRE.find((item) => item.value === employeeClone.PAYROLL);

        this.setState({employee: employeeClone});
    }

    save() {
        let {employee} = this.state;
        let {employees} = this.props.partner;

        request("employees/" + employee.ID, "PUT", {
            ROLE_ID: employee.ROLE_ID,
            PIN: employee.PIN,
            TYPE: employee.TYPE,
            STATUS: employee.STATUS,
            RATE: employee.RATE,
            PAYROLL: employee.PAYROLL
        }).then(async () => {
            let index = employees.findIndex((item) => item.ID === employee.ID);
            employees.splice(index, 1, employee);

            this.props.updateEmployees(employees);
            alert("Employee Updated");
        })

    }

    renderBilling() {
        let {employee} = this.state;
        if (employee.STRIPE_ID !== null) {
            return <div/>
        }

        return (
            <button style={{backgroundColor: "#4ACE9C"}} onClick={this.connect.bind(this)}
                    className="btn btn-md btn-xl--md u-btn-secondary g-width-160--md g-font-size-12 g-font-size-default--md g-mb-10">
                Connect Billing
            </button>
        )
    }

    renderEmployee() {
        let {employee} = this.state;

        if (!employee.PAYROLL) {
            return
        }

        return (
            <div>
                <FormDropdown name="Pay Type" data={EMPLOYEE_TYPES} value={employee.TYPE_DICT}
                              onChange={(val) => {
                                  this.setState(({
                                      employee: {
                                          ...employee,
                                          TYPE: val.value,
                                          TYPE_DICT: val
                                      }
                                  }));
                              }}
                />

                <FormItem name="Rate/Salary" value={employee.RATE}
                          onChange={(txt) => this.setState({employee: {...employee, RATE: txt}})} />

                <FormDropdown name="Status" data={EMPLOYEE_STATUS} value={employee.STATUS_DICT}
                              onChange={(val) => {
                                  this.setState(({
                                      employee: {
                                          ...employee,
                                          STATUS: val.value,
                                          STATUS_DICT: val
                                      }
                                  }));
                              }}
                />

                <FormItem name="Start Date" value={moment(employee.DATE_STARTED).format("MM/DD/YYYY")} disabled={true}/>
            </div>
        )
    }

    render() {
        let {employee} = this.state;
        let {roles} = this.props.partner;

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <form className="js-validate" onSubmit={(e) => e.preventDefault()}>
                        <header>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                    Personal information
                                </h2>

                                <CorkButton onClick={this.save.bind(this)}>
                                    Save Changes
                                </CorkButton>
                            </div>

                            <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                            <FormItem disabled={true} name="Name" value={employee.FULL_NAME}/>
                            <FormItem disabled={true} name="Phone" value={employee.PHONE}/>
                            <FormItem disabled={true} name="Email" value={employee.EMAIL}/>

                            <header style={{marginTop: 40}}>
                                <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                    Employee information
                                </h2>
                            </header>

                            <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                            <FormDropdown name="Role" data={roles.map((item) => ({label: item.NAME, value: item.ID}))}
                                          value={employee.ROLE_ID_DICT}
                                          onChange={(val) => {
                                              this.setState(({
                                                  employee: {
                                                      ...employee,
                                                      ROLE_ID: val.value,
                                                      ROLE_ID_DICT: val
                                                  }
                                              }));
                                          }}
                            />

                            <FormItem name="Pin" value={employee.PIN}
                                      onChange={(txt) => this.setState({employee: {...employee, PIN: txt}})}
                            />

                            <FormDropdown name="Payroll Employee" data={EMPLOYEE_NEW_HIRE}
                                          value={employee.PAYROLL_DICT}
                                          onChange={(val) => {
                                              this.setState(({
                                                  employee: {
                                                      ...employee,
                                                      PAYROLL: val.value,
                                                      PAYROLL_DICT: val
                                                  }
                                              }));
                                          }}
                            />

                            {this.renderEmployee()}
                        </header>
                    </form>
                </div>
            </div>
        )
    }
}

export default GeneralTab;
