import React, {Component} from 'react'
import FormItem from "../../../modules/form/FormItem";
import FormTextBox from "../../../modules/form/FormTextBox";
import {inverseString, request} from "../../../util/Util";
import Select from "react-select";
import FormCheckbox from "../../../modules/form/FormCheckbox";

class CheckoutTab extends Component {
    state = {settings: {}};

    componentWillMount() {
        this.setState({settings: JSON.parse(JSON.stringify(this.props.partner.settings))});
    }

    save() {
        let {settings} = this.state;

        if (isNaN(settings.MOBILE_REWARD_MAX_REDEEM)) {
            return alert("Reward Max has to be a number");
        }

        request("settings", "PATCH", {SETTINGS: settings}).then(() => {
            this.props.updateSettings(settings);

            alert("Settings Saved");
        }).catch(() => {
            alert("ERROR");
        })
    }

    render() {
        let {settings} = this.state;

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <header>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            General information
                        </h2>
                    </header>

                    <FormCheckbox label="Ask for customer name at checkout"
                                  onChange={() => {
                                      this.setState({
                                          settings: {
                                              ...settings,
                                              REGISTER_ASK_CUSTOMER: inverseString(settings.REGISTER_ASK_CUSTOMER)
                                          }
                                      })
                                  }} value={settings.REGISTER_ASK_CUSTOMER === "1"}/>
                    <FormCheckbox label="Ask for loyalty account at checkout"
                                  onChange={() => {
                                      this.setState({
                                          settings: {
                                              ...settings,
                                              REGISTER_ASK_LOYALTY: inverseString(settings.REGISTER_ASK_LOYALTY)
                                          }
                                      })
                                  }} value={settings.REGISTER_ASK_LOYALTY === "1"}/>
                    <FormCheckbox label="Ask for order type at checkout"
                                  onChange={() => {
                                      this.setState({
                                          settings: {
                                              ...settings,
                                              REGISTER_ASK_ORDER_TYPE: inverseString(settings.REGISTER_ASK_ORDER_TYPE)
                                          }
                                      })
                                  }} value={settings.REGISTER_ASK_ORDER_TYPE === "1"}/>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    <FormCheckbox label="Pass on processing fee to user on mobile"
                                  onChange={() => {
                                      this.setState({
                                          settings: {
                                              ...settings,
                                              MOBILE_PASS_PROCESSING_FEE: inverseString(settings.MOBILE_PASS_PROCESSING_FEE)
                                          }
                                      })
                                  }} value={settings.MOBILE_PASS_PROCESSING_FEE === "1"}/>
                    <FormCheckbox label="Pass on processing fee to user on website"
                                  onChange={() => {
                                      this.setState({
                                          settings: {
                                              ...settings,
                                              WEB_PASS_PROCESSING_FEE: inverseString(settings.WEB_PASS_PROCESSING_FEE)
                                          }
                                      })
                                  }} value={settings.WEB_PASS_PROCESSING_FEE === "1"}/>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    <FormCheckbox label="Delivery Enabled"
                                  onChange={() => {
                                      this.setState({
                                          settings: {
                                              ...settings,
                                              DELIVERY_ENABLED: inverseString(settings.DELIVERY_ENABLED)
                                          }
                                      })
                                  }} value={settings.DELIVERY_ENABLED === "1"}/>

                    <FormItem name="Delivery Radius (in miles)"
                                  onChange={(val) => {
                                      this.setState({
                                          settings: {
                                              ...settings,
                                              DELIVERY_RADIUS: val
                                          }
                                      })
                                  }} value={settings.DELIVERY_RADIUS}/>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    <FormItem name="Max Checkout Redeem (in cents)"
                                  onChange={(val) => {
                                      this.setState({
                                          settings: {
                                              ...settings,
                                              MOBILE_REWARD_MAX_REDEEM: val
                                          }
                                      })
                                  }} value={settings.MOBILE_REWARD_MAX_REDEEM}/>
                    <div>
                        <button style={{backgroundColor: "#4ACE9C", color: "white"}} onClick={this.save.bind(this)}
                                className="btn btn-md btn-xl--md u-btn-secondary g-width-160--md g-font-size-12 g-font-size-default--md g-mb-10">
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckoutTab;
