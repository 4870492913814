import React, {Component} from 'react'
import {Switch, Route} from 'react-router-dom'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import Actions from '../../actions/index'

import {
    withRouter
} from 'react-router-dom'
import GeneralTab from "./tabs/GeneralTab";
import SecondaryNavItem from "../../modules/SecondaryNavItem";
import HoursTab from "./tabs/HoursTab";
import PayoutTab from "./tabs/PayoutTab";
import PresetsTab from "./tabs/PresetsTab";
import BillingTab from "./tabs/BillingTab";
import WebsiteTab from "./tabs/WebsiteTab";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class ShopScreen extends Component {
    state = {
        active: 0,
    };

    render() {
        return (
            <div className="col g-ml-45 g-ml-0--lg g-pb-65--md">
                <div className="g-pa-20">
                    <div className="row">
                        <div className="col-md-3 g-mb-30 g-mb-0--md">
                            <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                                <section className="text-center g-mb-30 g-mb-50--md">
                                    <div className="d-inline-block g-pos-rel g-mb-20">
                                        <img className="img-fluid"
                                             style={{width: 200, height: 200, objectFit: "cover", borderRadius: 20}}
                                             src={this.props.partner.location.LOGO}
                                             alt=""/>
                                    </div>

                                    <h3 className="g-font-weight-300 g-font-size-20 g-color-black mb-0">
                                        {this.props.partner.location.NAME}
                                    </h3>
                                </section>

                                <section>
                                    <ul className="list-unstyled mb-0">
                                        <SecondaryNavItem icon="hs-admin-user" {...this.props}
                                                          url={"/shop"} name="Information"/>
                                        {/*<SecondaryNavItem icon="fa fa-wallet" {...this.props}*/}
                                        {/*                  url={"/shop/billing"} name="Billing"/>*/}
                                        <SecondaryNavItem icon="fa fa-hourglass-start" {...this.props}
                                                          url={"/shop/hours"} name="Hours"/>
                                        <SecondaryNavItem icon="fa fa-browser" {...this.props}
                                                          url={"/shop/website"} name="Websites"/>
                                    </ul>
                                </section>
                            </div>
                        </div>

                        <Switch>
                            <Route exact path='/shop/' component={() =>
                                <GeneralTab {...this.props} />
                            }/>

                            <Route exact path='/shop/hours' component={() =>
                                <HoursTab {...this.props} />
                            }/>

                            <Route exact path='/shop/website' component={() =>
                                <WebsiteTab {...this.props} />
                            }/>

                            <Route exact path='/shop/billing' component={() =>
                                <BillingTab {...this.props} />
                            }/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShopScreen));
