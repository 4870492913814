import React, {Component} from 'react'

class Double extends Component {
    render() {
        return (
            <div className="row g-mb-20">
                <div className="col-md-3 align-self-center g-mb-5 g-mb-0--md">
                    <label className="mb-0">{this.props.label}</label>
                </div>

                <div className="col-md-4">
                    <div className="form-group g-pos-rel mb-0">
                        <input
                            className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                            type="text" value={this.props.end}
                            onChange={(e) => this.props.onStartChange(e.target.value)}
                            value={this.props.start} placeholder={this.props.startPlaceholder}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group g-pos-rel mb-0">
                        <input
                            className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                            type="text" value={this.props.end} onChange={(e) => this.props.onEndChange(e.target.value)}
                            placeholder={this.props.endPlaceholder}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Double;