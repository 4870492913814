import React, {Component} from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {COLORS, formatDate, parseIdDict, request, toDollars} from "../../../util/Util";
import {CorkTable} from 'corky'
import moment from "moment"
import Select from "react-select"
import CorkButton from "../../../modules/CorkButton";
import ShiftModal from "./../modals/ShiftModal";
import {bindActionCreators} from "redux";
import Actions from "../../../actions";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {FancySelect} from "corky";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class WeekScheduling extends Component {
    state = {
        id: -1,
        employeeId: null,
        start: new Date().getTime(),
        end: new Date().getTime() + 1000 * 60 * 60,
        startStart: null,
        shiftEnd: null,
        notes: "",
        filterRoles: [], filterEmployees: [],

        window: moment().startOf("isoWeek").add(1, "weeks").toDate().getTime(),
        windowDict: {
            value: moment().startOf("isoWeek").add(1, "weeks").toDate().getTime(),
            label: `${moment().startOf("isoWeek").add(1, "weeks").format("MMM DD")} - ${moment().endOf("isoWeek").add(1, "weeks").format("MMM DD")}`
        }
    };

    componentDidMount() {
    }

    componentWillMount() {
        this.setState({data: this.props.partner.products});
    }

    createShift(employeeId, day) {
        let {window} = this.state;

        this.shiftModal.open(employeeId, day, window);
    }

    editShift(shift, day) {
        let {window} = this.state;

        this.shiftModal.open(shift.EMPLOYEE_ID, day, window, shift);
    }

    getWeeks() {
        let {shifts} = this.props.partner;

        let curWindow = moment().startOf("isoWeek").add(1, "week");
        let weeks = [{
            label: `${curWindow.format("MMM DD")} - ${moment(curWindow).endOf("isoWeek").format("MMM DD")}`,
            value: curWindow
        }];

        if (shifts.length === 0) {
            return weeks;
        }

        while (shifts[0].DATE_START < curWindow.toDate().getTime()) {
            curWindow = curWindow.subtract(7, "days");

            weeks.push({
                label: `${curWindow.format("MMM DD")} - ${moment(curWindow).endOf("isoWeek").format("MMM DD")}`,
                value: curWindow.toDate().getTime()
            })
        }

        return weeks;
    }

    getEmployees() {
        let {employees} = this.props.partner;

        return employees.map((item) => ({
            label: item.FULL_NAME,
            value: item.ID
        }))
    }

    getRoles() {
        let {roles} = this.props.partner;

        return roles.map((item) => ({
            label: item.NAME,
            value: item.ID
        }))
    }

    renderShifts(employee, days, shifts) {
        let {window} = this.state;
        let {employeePreferences} = this.props.partner;

        let windowStart = moment(window).add(days, "days").toDate().getTime();
        let windowEnd = moment(windowStart).add(1, "days").toDate().getTime();

        shifts = shifts.filter((item) => item.DATE_START > windowStart && windowEnd > item.DATE_START);
        employeePreferences = employeePreferences.filter((item) => item.EMPLOYEE_ID === employee.ID && item.ISO_DAY === days);

        return (
            <div>
                {employeePreferences.map((item) => {
                    let start = moment().startOf("day").add(item.OFFSET, "minutes");
                    let end = moment(start).add(item.DURATION, "minutes");

                    return (
                        <div>
                            <div className="show-not-allowed" style={{
                                backgroundColor: "#E1E1E1", padding: 8, borderRadius: 10, display: "flex",
                                justifyContent: "center", alignItems: "center", flexDirection: "column"
                            }}>
                                <div style={{fontSize: 12, fontWeight: "bold", color: "#808080"}}>
                                    Unavailable
                                </div>
                                <div style={{textAlign: "center", fontSize: 12, fontWeight: "bold", color: "#808080"}}>
                                    {item.ALL_DAY ? "All Day" : start.format("h:mm a") + " - " + end.format("h:mm a")}
                                </div>
                            </div>
                        </div>
                    )
                })}

                {shifts.map((item) => (
                    <Shift shift={item} edit={this.editShift.bind(this)} day={days} {...this.props} />
                ))}
                <AddShift create={this.createShift.bind(this)} day={days} employee={employee.ID}/>
            </div>
        );
    }

    render() {
        let {employees, shifts} = this.props.partner;
        let {window, windowDict, filterEmployees, filterRoles} = this.state;
        let windowEnd = moment(window).add(1, "weeks").toDate().getTime();

        let windowShifts = shifts.filter((item) => {
            return item.DATE_START > window && item.DATE_START < windowEnd
        });

        let employeesDict = parseIdDict(employees);
        let totalHours = windowShifts.reduce((accum, item) => accum + moment(item.DATE_END).diff(moment(item.DATE_START), "hours"), 0);
        let totalPrice = windowShifts.reduce((accum, item) => accum + (moment(item.DATE_END).diff(moment(item.DATE_START), "hours") * employeesDict[item.EMPLOYEE_ID].RATE), 0);

        let Data = employees.filter((item) => {
            if (filterEmployees.length > 0) {
                let employee = filterEmployees.find((_item) => _item.value === item.ID)

                if (!employee) {
                    return false;
                }
            }

            if (filterRoles.length > 0) {
                let role = filterRoles.find((_item) => _item.value === item.ID)

                if (!role) {
                    return false;
                }
            }

            return true
        }).map((item) => {
            let employeeShifts = windowShifts.filter((_item) => _item.EMPLOYEE_ID === item.ID);
            let hours = employeeShifts.reduce((accum, item) => {
                return accum + moment(item.DATE_END).diff(moment(item.DATE_START), "hours")
            }, 0);

            return {
                ID: item.ID,
                EMPLOYEE: (
                    <div>
                        <div style={{fontWeight: "bold"}}>
                            <Link to={"/employee/" + item.ID}>
                                {item.FULL_NAME}
                            </Link>
                        </div>

                        <div style={{fontSize: 10, fontColor: "#808080"}}>
                            {hours} hrs - ${toDollars(hours * item.RATE)}
                        </div>
                    </div>
                ),
                MONDAY: this.renderShifts(item, 0, employeeShifts),
                TUESDAY: this.renderShifts(item, 1, employeeShifts),
                WEDNESDAY: this.renderShifts(item, 2, employeeShifts),
                THURSDAY: this.renderShifts(item, 3, employeeShifts),
                FRIDAY: this.renderShifts(item, 4, employeeShifts),
                SATURDAY: this.renderShifts(item, 5, employeeShifts),
                SUNDAY: this.renderShifts(item, 6, employeeShifts)
            }
        });

        let disableRight = window === moment().startOf("isoWeek").add(1, "week").toDate().getTime();

        return (
            <div>
                <ShiftModal ref={(e) => this.shiftModal = e} {...this.props} />

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">Schedule</h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            {/*<CorkButton onClick={() => {*/}
                            {/*}} type={CorkButton.TYPES.DISABLED}>*/}
                            {/*    Auto Schedule*/}
                            {/*</CorkButton>*/}

                            <CorkButton
                                type={windowShifts.filter((item) => item.DRAFT === 1).length === 0 ? CorkButton.TYPES.DISABLED : CorkButton.TYPES.GENERAL}
                                onClick={() =>
                                    windowShifts.filter((item) => item.DRAFT === 1).length > 0 ? this.props.history.push("/publish/" + window) : {}
                                } marginLeft>
                                Publish
                            </CorkButton>
                        </div>
                    </div>

                    <hr/>

                    <div className="row" style={{margin: "0 20px", marginBottom: -20, alignItems: "center"}}>
                        <div style={{
                            border: "1px solid gray",
                            borderRadius: 5,
                            alignItems: "center",
                            display: "flex",
                            overflow: "hidden",
                            marginTop: -20,
                        }}>
                            <a style={{padding: "10px 15px", borderRight: "1px solid gray"}} className="show-cursor"
                               onClick={() => {
                                   let newWindow = moment(window).subtract(1, "week");
                                   this.setState({
                                       window: newWindow.toDate().getTime(),
                                       windowDict: {
                                           value: newWindow.toDate().getTime(),
                                           label: `${newWindow.format("MMM DD")} - ${moment(newWindow).endOf("isoWeek").format("MMM DD")}`
                                       }
                                   })
                               }}>
                                <i className="fa fa-chevron-left"/>
                            </a>

                            <div style={{padding: "0 10px", border: "0px solid black", minWidth: 200}}
                                 className="show-cursor">
                                <Select menuPortalTarget={document.body} placeholder="Quick Select"
                                        menuPortalTarget={document.body} styles={{
                                    menuList: () => ({maxHeight: 150, overflowY: "scroll"}),
                                    menuPortal: base => ({...base, zIndex: 99999}),
                                    control: (base) => ({
                                        ...base,
                                        border: 0,
                                        boxShadow: 0,
                                        '&:hover': {
                                            border: 0
                                        }
                                    })
                                }} options={this.getWeeks()} value={windowDict}
                                        onChange={(val) => this.setState({
                                            windowDict: val,
                                            window: val.value
                                        })}/>
                            </div>

                            <a style={{
                                padding: "10px 15px", overflow: "hidden",
                                borderLeft: "1px solid gray", backgroundColor: "white"
                            }}
                               className="show-cursor"
                               onClick={() => {
                                   let newWindow = moment(window).add(1, "week");
                                   this.setState({
                                       window: newWindow.toDate().getTime(),
                                       windowDict: {
                                           value: newWindow.toDate().getTime(),
                                           label: `${newWindow.format("MMM DD")} - ${moment(newWindow).endOf("isoWeek").format("MMM DD")}`
                                       }
                                   })
                               }}>
                                <i className="fa fa-chevron-right"/>
                            </a>
                        </div>

                        <div style={{width: 125, marginLeft: 12}}>
                            <FancySelect onChange={({value}) => {
                                if (value === 0) {
                                    this.props.history.push("/scheduling/day")
                                }
                            }} value={{label: "Week", value: 1}} options={[
                                {label: "Week", value: 1},
                                {label: "Day", value: 0}
                            ]} placeholder="Test"/>
                        </div>

                        <div style={{flex: 1, marginLeft: 12}}>
                            <FancySelect options={this.getEmployees()} value={filterEmployees}
                                         onChange={(filterEmployees) => this.setState({filterEmployees})}
                                         select={{isMulti: true, placeholder: "Filter by Employee"}}/>
                        </div>

                        <div style={{flex: 1, marginLeft: 12}}>
                            <FancySelect options={this.getRoles()} value={filterRoles}
                                         onChange={(filterRoles) => this.setState({filterRoles})}
                                         select={{isMulti: true, placeholder: "Filter by Role"}}/>
                        </div>
                    </div>


                    <div className="row">
                        <CorkTable noHeader={true} settings={this.state.window} ref={(e) => this.table = e}
                                   showAll={true} ignoreRowParams={true} filters={[{
                            label: "Status:",
                            column: "STATUS",
                            items: [
                                {label: "All", badge: "g-bg-black", value: null},
                                {label: "Enabled", badge: "g-bg-darkblue-v2", value: 1},
                                {label: "Disabled", badge: "g-bg-darkred", value: 0}
                            ]
                        }]} header={{
                            EMPLOYEE: {
                                NAME: (
                                    <div style={{fontSize: 18, fontWeight: "bold"}}>
                                        Employee
                                    </div>
                                ),
                                WIDTH: 12,
                                TYPE: "String"
                            },
                            MONDAY: {
                                NAME: <HourHeader day="Monday" {...this.props} />,
                                WIDTH: 11,
                                TYPE: "String"
                            },
                            TUESDAY: {
                                NAME: <HourHeader day="Tuesday" {...this.props} />,
                                WIDTH: 11
                            },
                            WEDNESDAY: {
                                NAME: <HourHeader day="Wednesday" {...this.props} />,
                                WIDTH: 11
                            },
                            THURSDAY: {
                                NAME: <HourHeader day="Thursday" {...this.props} />,
                                WIDTH: 11
                            },
                            FRIDAY: {
                                NAME: <HourHeader day="Friday" {...this.props} />,
                                WIDTH: 11
                            },
                            SATURDAY: {
                                NAME: <HourHeader day="Saturday" {...this.props} />,
                                WIDTH: 11
                            },
                            SUNDAY: {
                                NAME: <HourHeader day="Sunday" {...this.props} />,
                                WIDTH: 11
                            }
                        }} sort="EMPLOYEE" data={Data} desc={true} title=""/>
                    </div>

                    <div className="row">
                        <div className="col g-ml-50 g-ml-0--lg g-overflow-hidden">
                            <div className="g-pa-20">
                                <table className="table u-table--v3 g-color-black">
                                    <colgroup>
                                        <col key={1} width={"12%"}/>
                                        <col key={1} width={"11%"}/>
                                        <col key={1} width={"11%"}/>
                                        <col key={1} width={"11%"}/>
                                        <col key={1} width={"11%"}/>
                                        <col key={1} width={"11%"}/>
                                        <col key={1} width={"11%"}/>
                                        <col key={1} width={"11%"}/>
                                    </colgroup>

                                    <tbody>
                                    <tr>
                                        <td>
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <div>
                                                    Wages
                                                    <br/>
                                                    Hours
                                                </div>

                                                <div style={{marginLeft: 5}}>
                                                    ${toDollars(totalPrice)}
                                                    <br/>
                                                    {totalHours}
                                                </div>
                                            </div>
                                        </td>

                                        <DayWageTotal employees={employeesDict} shifts={windowShifts} day={0}
                                                      window={window}/>
                                        <DayWageTotal employees={employeesDict} shifts={windowShifts} day={1}
                                                      window={window}/>
                                        <DayWageTotal employees={employeesDict} shifts={windowShifts} day={2}
                                                      window={window}/>
                                        <DayWageTotal employees={employeesDict} shifts={windowShifts} day={3}
                                                      window={window}/>
                                        <DayWageTotal employees={employeesDict} shifts={windowShifts} day={4}
                                                      window={window}/>
                                        <DayWageTotal employees={employeesDict} shifts={windowShifts} day={5}
                                                      window={window}/>
                                        <DayWageTotal employees={employeesDict} shifts={windowShifts} day={6}
                                                      window={window}/>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class HourHeader extends Component {
    render() {
        let hours = this.props.partner.hours;

        return (
            <div>
                <span style={{fontSize: 18, fontWeight: "bold"}}>{this.props.day}</span>

                <br/>
                <span style={{fontSize: 14}}>
                    {moment(hours[this.props.day.toUpperCase() + "_OPEN"]).format("hh:mma") + "-" + moment(hours[this.props.day.toUpperCase() + "_CLOSE"]).format("hh:mma")}
                </span>
            </div>
        )
    }
}

class Shift extends Component {
    state = {show: false};

    render() {
        let {day, shift} = this.props;
        let {hours} = this.props.partner;

        return (
            <div>
                <div
                    onClick={() => this.props.edit(shift, TO_DAY[day])}
                    className="show-cursor" style={{
                    backgroundColor: "#" + shift.COLOR,
                    padding: 8,
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                }}>
                    <div style={{fontSize: 10, fontWeight: "bold"}}>
                        {shift.DRAFT === 1 ? "Draft" : ""}
                    </div>
                    <div style={{
                        textAlign: "center",
                        fontSize: 12,
                        fontWeight: "bold"
                    }}>
                        {moment(shift.DATE_START).format("hh:mmA") + "-" + moment(shift.DATE_END).format("hh:mmA")}
                    </div>
                </div>
            </div>
        )
    }
}

class DayWageTotal extends Component {
    render() {
        let {shifts, employees, window, day} = this.props;

        let windowStart = moment(window).add(day, "days").toDate().getTime();
        let windowEnd = moment(windowStart).add(1, "days").toDate().getTime();

        shifts = shifts.filter((item) => item.DATE_START > windowStart && windowEnd > item.DATE_START);

        let totalHours = shifts.reduce((accum, item) => accum + moment(item.DATE_END).diff(moment(item.DATE_START), "hours"), 0);
        let totalPrice = shifts.reduce((accum, item) => accum + (moment(item.DATE_END).diff(moment(item.DATE_START), "hours") * employees[item.EMPLOYEE_ID].RATE), 0);

        return (
            <td>
                <div style={{textAlign: "right", paddingRight: 20}}>
                    <div style={{fontSize: 12, color: "#808080"}}>
                        ${toDollars(totalPrice)}
                    </div>

                    <div style={{fontSize: 12, color: "#808080"}}>
                        {totalHours}
                    </div>
                </div>
            </td>
        )
    }
}

class AddShift extends Component {
    state = {show: false};

    render() {
        let {show} = this.state;
        let {employee, day} = this.props;

        let add = show ?
            <div className="show-cursor" style={{color: "#4ACE9C", fontWeight: "bold", textAlign: "center"}}
                 onClick={() => this.props.create(employee, TO_DAY[day])}>
                ADD
            </div> :
            <span>&nbsp;</span>;


        return (
            <div onMouseEnter={() => this.setState({show: true})} onMouseLeave={() => this.setState({show: false})}>
                {add}
            </div>
        )
    }
}


const TO_DAY = {
    6: "Sunday",
    0: "Monday",
    1: "Tuesday",
    2: "Wednesday",
    3: "Thursday",
    4: "Friday",
    5: "Saturday"
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WeekScheduling));