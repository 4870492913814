import React, {Component} from 'react'
import Report from "./module/Report";

class OrderSummary extends Report {
    constructor(props) {
        super(props);

        this.cards = [
            {
                LABEL: "Orders",
                DESCRIPTION: "Number of Orders",
                CALC: (items) => items.length
            },
            {
                LABEL: "Unique Orders",
                DESCRIPTION: "One order per user",
                CALC: (items) => new Set(items.map((item) => item.ACCOUNT_ID)).size
            }
        ];

        this.sort = "DATE_SENT";
        this.name = "Orders";
        this.downloadUrl = "report/summary/orders";
        this.downloadName = "Drip_Sales_Summary.csv";
        this.valCal = (item) => 1;
        this.perCalc = (item) => item.length;
    }

    render() {
        return super.render();
    }

    componentWillMount() {
        let data = this.props.partner.orders.sort((a, b) => a.DATE_SENT - b.DATE_SENT);
        this.setState({data});
    }
}

export default OrderSummary;