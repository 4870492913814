import React, {Component} from 'react'
import {Switch, Route} from 'react-router-dom'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import Actions from '../../actions/index'

import {
    withRouter
} from 'react-router-dom'
import SecondaryNavItem from "../../modules/SecondaryNavItem";
import EditTab from "./tabs/EditTab";
import ViewTab from "./tabs/ViewTab";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class LetterScreen extends Component {
    state = {
        html: ""
    };

    render() {
        const {ID} = this.props.match.params;

        return (
            <div className="col g-ml-45 g-ml-0--lg g-pb-65--md">
                <div className="g-pa-20">
                    <div className="row">
                        <div className="col-md-3 g-mb-30 g-mb-0--md">
                            <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                                <section className="text-center g-mb-30 g-mb-50--md">
                                    <h3 className="g-font-weight-300 g-font-size-20 g-color-black mb-0">
                                        News Letter Post
                                    </h3>
                                </section>

                                <section>
                                    <ul className="list-unstyled mb-0">
                                        <SecondaryNavItem icon="hs-admin-user" {...this.props}
                                                          url={"/letter/" + ID} name="Edit Section"/>
                                        <SecondaryNavItem icon="hs-admin-user" {...this.props}
                                                          url={"/letter/" + ID + "/view"} name="Preview Letter"/>

                                    </ul>
                                </section>
                            </div>
                        </div>

                        <Switch>
                            <Route exact path='/letter/:ID'
                                   component={() => <EditTab {...this.props} />}/>
                            <Route exact path='/letter/:ID/view'
                                   component={() => <ViewTab {...this.props} />}/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LetterScreen));