import React, {Component} from 'react'
import {CorkTable} from "corky";
import {request, toDollars} from "../../../util/Util";
import moment from "moment";
import Card from "../../dashboard/modules/Card";
import CorkButton from "../../../modules/CorkButton";
import PayoutModal from "../modal/PayoutModal";
import {Banking} from "drip-drop";

class ActivityTab extends Component {
    state = {pending: null};

    async componentDidMount() {
        let pending = await request("banking/balance/pending", "GET", null);

        this.setState({pending});
    }

    render() {
        let {payouts, balance, account} = this.props.parentState;
        let {orders} = this.props.partner;
        let {pending} = this.state;

        let withholding = account.settings.payouts.schedule.interval === "manual"
            ? orders.filter((item) => item.PAYOUT_ID === null).reduce((accum, item) => accum + item.TAXES, 0) : 0;
        let Data = payouts.map((item) => {
            return {
                DATE: moment(item.created * 1000).format("MMM D"),
                TIMESTAMP: item.created,
                STATUS: item.status,
                DESCRIPTOR: item.statement_descriptor,
                AMOUNT: "$" + toDollars(item.amount)
            };
        });

        return (
            <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                <PayoutModal parentState={this.props.parentState} ref={(e) => this.payoutModal = e}
                             setParent={(state) => this.setState(state)} {...this.props} />

                <div className="g-pa-0">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                                Activity
                            </h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            {/* <CorkButton onClick={() => this.payoutModal.open()} marginRight>
                             Topup Funds
                             </CorkButton>*/}

                            <CorkButton onClick={() => this.payoutModal.open()}>
                                Manual Payout
                            </CorkButton>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <Card name="Available Balance"
                          value={"$" + toDollars(Math.max(0, balance.available[0].amount - withholding))}/>
                    <Card name="Pending Balance" value={"$" + toDollars(balance.pending[0].amount)}/>

                    {account.settings.payouts.schedule.interval === "manual" ? (
                        <Card name="Tax Withheld"
                              value={"$" + toDollars(withholding)}/>
                    ) : <div/>}
                </div>

                <CorkTable header={{
                    DATE: {
                        NAME: "Date",
                        WIDTH: 20,
                        SORT: "TIMESTAMP"
                    },
                    DESCRIPTOR: {
                        NAME: "Description",
                        WIDTH: 40,
                    },
                    STATUS: {
                        NAME: "Status",
                        WIDTH: 20,
                    },
                    AMOUNT: {
                        NAME: "Amount",
                        WIDTH: 20
                    }
                }} sort="DATE" data={Data} noPadding={true} desc={true}/>


                <header style={{marginTop: 40}}>
                    <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                        Pending Transactions
                    </h2>
                </header>

                <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-25--md"/>

                <CorkTable data={pending === null ? [] : pending} loading={pending === null} header={{
                    DATE_AVAILABLE: {
                        NAME: "Date Available for Payout",
                        WIDTH: 30,
                        FORMAT: (data) => moment(data).format("ddd MMMM Do"),
                        SORT: "ID"
                    },
                    TRANSACTIONS: {
                        NAME: "Transactions",
                        WIDTH: 20,
                        TYPE: "String"
                    },
                    NET: {
                        NAME: "Amount",
                        WIDTH: 20,
                        FORMAT: (data) => "$" + toDollars(data),
                        TYPE: "String"
                    },
                    PAYOUT: {
                        NAME: "Estimated Payout Arrival",
                        WIDTH: 30,
                        TYPE: "String",
                        FORMAT: (_, row) => Banking.Helpers.getPayoutEstimate(new Date(row.DATE_AVAILABLE).getISODay())
                    }
                }} noHeader noPadding hidePaging/>
            </div>
        );
    }
}


export default ActivityTab;