import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {Team} from "drip-drop"

class Item extends Component {
    render() {
        let history = this.props.history.location.pathname;
        let className = "media u-side-nav--top-level-menu-link u-side-nav--hide-on-hidden g-px-15 g-py-12";

        if (history === this.props.to) {
            className += " active active";
        }

        return (
            <li className="u-sidebar-navigation-v1-menu-item u-side-nav--top-level-menu-item">
                <Link className={className}
                      to={this.props.to}>
                        <span className="d-flex align-self-center g-pos-rel g-font-size-18 g-mr-18" style={{width: 25}}>
                            <i className={this.props.icon}/>
                        </span>
                    <span className="media-body align-self-center">{this.props.name}</span>
                </Link>
            </li>
        )
    }
}

class Dropdown extends Component {
    render() {
        let {PERMISSIONS: permissions} = this.props.partner.account;
        let {permission} = this.props;

        if (typeof this.props.kids === "undefined") {
            console.log("No kids but says there is:");
            console.dir(this.props);
            return <div/>
        }

        if (permission && permissions.indexOf(permission) === -1 && permissions.indexOf(Team.Constants.ROLE_PERMISSIONS.ADMIN_FULL) === -1) {
            return <div />
        }

        let Kids = this.props.kids.map((item) => {
            let history = this.props.history.location.pathname;
            let className = "media u-side-nav--second-level-menu-link g-px-15 g-py-12";

            if (history === item.to) {
                className += " active active";
            }

            return (
                <li className="u-sidebar-navigation-v1-menu-item u-side-nav--second-level-menu-item">
                    <Link className={className}
                          to={item.to}>
                            <span className="d-flex align-self-center g-mr-15 g-mt-minus-1" style={{width: 25}}>
                              <i style={{minWidth: 20, textAlign: "center"}} className={item.icon}/>
                            </span>
                        <span className="media-body align-self-center">{item.name}</span>
                    </Link>
                </li>
            );
        });

        return (
            <li className="u-sidebar-navigation-v1-menu-item u-side-nav--has-sub-menu u-side-nav--top-level-menu-item">
                <a className="media u-side-nav--top-level-menu-link u-side-nav--hide-on-hidden g-px-15 g-py-12"
                   href="#!" data-hssm-target={"#" + this.props.id}>
                    <span className="d-flex align-self-center g-pos-rel g-font-size-18 g-mr-18" style={{width: 25}}>
                      <i style={{minWidth: 20, textAlign: "center"}} className={this.props.icon}/>
                    </span>
                    <span className="media-body align-self-center">{this.props.name}</span>
                    <span className="d-flex align-self-center u-side-nav--control-icon">
                      <i className="hs-admin-angle-right"/>
                    </span>
                    <span className="u-side-nav--has-sub-menu__indicator"/>
                </a>
                <ul id={this.props.id} className="u-sidebar-navigation-v1-menu u-side-nav--second-level-menu mb-0">
                    {Kids}
                </ul>
            </li>
        )
    }
}

const Elements = [
    {
        name: "Dashboard",
        icon: "hs-admin-layout-media-center-alt",
        to: "/"
    },
    {
        id: "shop_menu",
        name: "My Shop",
        icon: "fa fa-street-view",
        permission: Team.Constants.ROLE_PERMISSIONS.ADMIN_FULL,
        kids: [
            {
                name: "Information",
                icon: "fa fa-list",
                to: "/shop"
            },
            {
                name: "Hours",
                icon: "fa fa-hourglass-start",
                to: "/shop/hours"
            },
            {
                name: "Website",
                icon: "fa fa-browser",
                to: "/shop/website"
            },
            {
                name: "Devices",
                icon: "fa fa-phone-laptop",
                to: "/devices"
            }
        ]
    },
    {
        id: "banking_menu",
        name: "Banking",
        icon: "fa fa-piggy-bank",
        permission: Team.Constants.ROLE_PERMISSIONS.BANKING_FULL,
        kids: [
            {
                name: "Activity",
                icon: "far fa-chart-line",
                to: "/banking"
            },
            /*{
                name: "Expense Cards",
                icon: "fa fa-credit-card",
                to: "/banking/cards"
            },*/
            {
                name: "Settings",
                icon: "fa fa-cogs",
                to: "/banking/settings"
            }
        ]
    },
    {
        id: "products_menu",
        name: "Menu",
        icon: "fa fa-utensils",
        permission: Team.Constants.ROLE_PERMISSIONS.MENU_FULL,
        kids: [
            {
                name: "Products",
                icon: "far fa-coffee-togo",
                to: "/products"
            },
            {
                name: "Categories",
                icon: "fa fa-puzzle-piece",
                to: "/categories"
            },
            {
                name: "Discounts",
                icon: "fa fa-tags",
                to: "/discounts"
            },
            {
                name: "Global Modifiers",
                icon: "fa fa-fill",
                to: "/presets"
            },
            {
                name: "Global Variants",
                icon: "fa fa-text-size",
                to: "/variants"
            },
            {
                name: "Other Payments",
                icon: "fa fa-money-bill-wave-alt",
                to: "/other"
            }
        ]
    },
    {
        id: "team_menu",
        name: "Team",
        icon: "fa fa-people-carry",
        permission: Team.Constants.ROLE_PERMISSIONS.TEAM_FULL,
        kids: [
            {
                name: "Employees",
                icon: "fa fa-users",
                to: "/employees"
            },
            {
                name: "Roles",
                icon: "fa fa-user-tag",
                to: "/roles"
            },
            {
                name: "Scheduling",
                icon: "fa fa-user-clock",
                to: "/scheduling"
            },
            {
                name: "Timesheets",
                icon: "fa fa-hourglass-start",
                to: "/timesheets"
            },
            /*{
                name: "Payroll",
                icon: "fa fa-money-check-edit",
                to: "/payroll"
            },
            {
                name: "Employment Forms",
                icon: "fa fa-paste",
                to: "/forms"
            },*/
            {
                name: "Communication",
                icon: "fa fa-walkie-talkie",
                to: "/soon/communication"
            },
        ]
    },
    {
        id: "operations_menu",
        name: "Operations",
        icon: "fa fa-business-time",
        permission: Team.Constants.ROLE_PERMISSIONS.OPERATIONS_FULL,
        kids: [
            {
                name: "Checklists",
                icon: "fa fa-tasks",
                to: "/checklists"
            },
            {
                name: "Inventory",
                icon: "fa fa-inventory",
                to: "/soon/inventory"
            },
            {
                name: "Messages",
                icon: "fa fa-inbox",
                to: "/soon/messaging"
            }
        ]
    },
    {
        id: "marketing_menu",
        name: "Marketing",
        icon: "fa fa-bullhorn",
        permission: Team.Constants.ROLE_PERMISSIONS.MARKETING_FULL,
        kids: [
            {
                name: "Email Campaign",
                icon: "fa fa-envelope-open-text",
                to: "/marketing/emails"
            },
            {
                name: "Text Campaign",
                icon: "fa fa-phone",
                to: "/marketing/texts"
            },
            {
                name: "Promotions",
                icon: "fa fa-tags",
                to: "/promotions"
            },
            {
                name: "Coupons",
                icon: "fa fa-user-tag",
                to: "/coupons"
            },
            {
                name: "News Posts",
                icon: "fa fa-newspaper",
                to: "/news"
            },
            /*{
                name: "News Letter",
                icon: "fa fa-mail-bulk",
                to: "/letters"
            },*/
            {
                name: "Loyalty",
                icon: "fa fa-hand-holding-heart",
                to: "/loyalty/activity"
            }
            /*{
                name: "Retention",
                icon: "fa fa-concierge-bell",
                to: "/retention"
            }*/
        ]
    },
    {
        id: "stats_menu",
        name: "Orders",
        icon: "fa fa-clipboard-list",
        permission: Team.Constants.ROLE_PERMISSIONS.REPORTING_FULL,
        kids: [
            {
                name: "Patrons",
                icon: "fa fa-users",
                to: "/patrons"
            },
            {
                name: "Tickets",
                icon: "fa fa-ticket-alt",
                to: "/orders"
            }
        ]
    },
    {
        id: "reporting_menu",
        name: "Reports",
        icon: "fa fa-chart-bar",
        permission: Team.Constants.ROLE_PERMISSIONS.REPORTING_FULL,
        kids: [
            {
                name: "Sales",
                icon: "fa fa-wallet",
                to: "/report/sales"
            },
            {
                name: "Products",
                icon: "fa fa-coffee",
                to: "/report/products"
            },
            {
                name: "Categories",
                icon: "fa fa-puzzle-piece",
                to: "/report/categories"
            },
            {
                name: "Taxes",
                icon: "fa fa-tag",
                to: "/report/taxes"
            },
            {
                name: "Cash Drawer",
                icon: "fa fa-cash-register",
                to: "/report/drawer"
            }
        ]
    },
    {
        id: "finance_menu",
        name: "Invoices",
        icon: "fa fa-file-invoice-dollar",
        permission: Team.Constants.ROLE_PERMISSIONS.INVOICES_FULL,
        kids: [
            /*{
                name: "Overview",
                icon: "fa fa-list-alt",
                to: "/soon/overview"
            },
            {
                name: "Sales",
                icon: "fa fa-wallet",
                to: "/soon/sales"
            },
            {
                name: "Expenses",
                icon: "fa fa-file-chart-line",
                to: "/soon/expenses"
            },*/
            {
                name: "Invoices",
                icon: "fa fa-file-invoice",
                to: "/invoices"
            },
            {
                name: "Customers",
                icon: "fa fa-store",
                to: "/vendors"
            },
        ]
    },
    {
        id: "settings_menu",
        name: "Settings",
        icon: "fa fa-cog",
        permission: Team.Constants.ROLE_PERMISSIONS.ADMIN_FULL,
        kids: [
            {
                name: "Checkout",
                icon: "fa fa-shopping-basket",
                to: "/settings/checkout"
            },
            {
                name: "Tickets",
                icon: "fa fa-ticket-alt",
                to: "/settings/ticket"
            },
            {
                name: "Reports",
                icon: "fa fa-ticket-alt",
                to: "/settings/reports"
            }
        ]
    }
];

class Navbar extends Component {
    componentDidMount() {
        eval(`$(document).on('mouseover', '.u-pagination-v1__item', function (ev, el) {
                ev.currentTarget.removeAttribute("href");
            });`)
    }

    render() {
        let Items = Elements.map((item, i) => {
            if (typeof item.id !== "undefined") {
                return <Dropdown key={i} {...item} {...this.props} />
            }

            return <Item key={i} {...item} {...this.props} />
        });

        return (
            <div id="sideNav"
                 className="col-auto u-sidebar-navigation-v1 u-sidebar-navigation--light">
                <ul id="sideNavMenu"
                    className="u-sidebar-navigation-v1-menu u-side-nav--top-level-menu g-min-height-100vh mb-0">
                    {Items}
                </ul>
            </div>
        );
    }
}

export default Navbar;
