import React, {Component} from "react"
import Fancy from "../../../../modules/fancy/Fancy";

import {FancyText, FancySelect} from "corky"
import {request} from "../../../../util/Util";
import {BANK_TYPE} from "../../../../util/Constants";

const FIELDS = {
    HOLDER: "bank-holder",
    TYPE: "bank-type",
    ACCOUNT: "bank-account",
    ACCOUNT_CONFIRM: "bank-account-confirm",
    ROUTING: "bank-routing",
};

class BankModal extends Component {
    state = {setup: false};

    constructor(props) {
        super(props);

        this.form = {};
    }

    validForm() {
        let form = this.form;

        let isValid = true;
        for (let field of this.fields) {
            if (field === null) {
                continue;
            }

            if (!field.isValid()) {
                isValid = false;
            }
        }

        if (!isValid) {
            return false;
        }

        if (form[FIELDS.ACCOUNT] !== form[FIELDS.ACCOUNT_CONFIRM]) {
            this.confirm.setState({error: "Account numbers don't match"});

            return false;
        }

        return true;
    }

    createBank() {
        let form = this.form;
        if (!this.validForm()) {
            return;
        }

        request("partner/billing/bank", "POST", {
            NAME: form[FIELDS.HOLDER],
            ACCOUNT: form[FIELDS.ACCOUNT],
            ROUTING: form[FIELDS.ROUTING],
            TYPE: form[FIELDS.TYPE].value,
        }).then(async (payload) => {
            await this.fancy.close();

            this.props.add(payload);
        })
    }

    setupBank() {
        let form = this.form;
        if (!this.validForm()) {
            return;
        }

        request("partner/billing/external", "POST", {
            NAME: form[FIELDS.HOLDER],
            ACCOUNT: form[FIELDS.ACCOUNT],
            ROUTING: form[FIELDS.ROUTING],
            TYPE: form[FIELDS.TYPE].value,
        }).then(async (payload) => {
            await this.fancy.close();

            this.props.add(payload);
        })
    }

    open(setup) {
        this.setState({setup}, () => this.fancy.open());
    }

    render() {
        this.fields = [];
        let {setup} = this.state;

        return (
            <Fancy name={setup ? "Setup Bank" : "Add Bank"}
                   onClick={setup ? this.setupBank.bind(this) : this.createBank.bind(this)}
                   ref={(e) => this.fancy = e}
                   buttonText={"Add Bank"}>
                <div className="g-mb-20">
                    <FancyText form={this.form} id={FIELDS.HOLDER} label="Account Holder Name"
                               ref={(e) => this.fields.push(e)} required={true}/>
                </div>

                <div className="row g-mb-20">
                    <FancySelect options={BANK_TYPE} defaultValue={BANK_TYPE[0]} form={this.form} id={FIELDS.TYPE}
                                 label="Account Type" ref={(e) => this.fields.push(e)} half={true} required={true}/>
                    <FancyText form={this.form} id={FIELDS.ROUTING} label="Routing Number" max={9} required={true}
                               ref={(e) => this.fields.push(e)} half={true} placeholder="110000000"/>
                </div>

                <div className="row g-mb-20">
                    <FancyText form={this.form} id={FIELDS.ACCOUNT} label="Account Number" required={true} max={17}
                               ref={(e) => this.fields.push(e)} half={true} placeholder="000123456789"/>
                    <FancyText form={this.form} id={FIELDS.ACCOUNT_CONFIRM} label="Confirm Account Number" ref={(e) => {
                        this.fields.push(e);
                        this.confirm = e;
                    }} required={true} half={true} max={17} placeholder="000123456789"/>
                </div>
            </Fancy>
        )
    }
}

export default BankModal;