import React, {Component} from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DayScheduling from "./types/DayScheduling";
import WeekScheduling from "./types/WeekScheduling";

const STEPS = {
    DAY: 0,
    WEEK: 1,
    MONTH: 2
};

class SchedulingScreen extends Component {
    state = {type: STEPS.WEEK};

    change(type) {
        this.setState({type});
    }

    render() {
        let {type} = this.state;

        if (type === STEPS.DAY) {
            return (
                <DayScheduling change={this.change.bind(this)} {...this.props} />
            )
        } else if(type === STEPS.WEEK) {
            return (
                <WeekScheduling change={this.change.bind(this)} {...this.props}  />
            )
        }
    }
}

export default SchedulingScreen;
