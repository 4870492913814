import React, {Component} from "react"
import {Chart} from "react-charts";
import PropTypes from "prop-types";
import moment from "moment";

class DailyChart extends Component {
    render() {
        let {
            data, title = "Unnamed Title", label = "Unnamed", fields = [{
                label: "Unnamed Label", calc: (item) => item.length
            }]
        } = this.props;
        let {start, end} = this.props.partner.reporting;

        let dateData = data.reduce((dict, item) => {
            let day = moment(new Date(item.DATE_SENT)).format("MM/DD")

            if (dict[day]) {
                dict[day].push(item);
            } else {
                dict[day] = [item]
            }

            return dict;
        }, {});

        // for (let key of Object.keys(dateData)) {
        //     dateData[key] = calc(dateData[key]);
        // }

        if (true === true) {
            let futureData = fields.map(() => []);
            while (start < end) {
                let day = moment(start).format("MM/DD");

                fields.forEach(({calc, label}, i) => {
                    let da = dateData[day] ? dateData[day] : [];

                    futureData[i].push([day, calc(da)])
                });

                start += (1000 * 60 * 60 * 24);
            }

            return (
                <div className="col-xl-12">
                    <div className="card g-brd-gray-light-v7 g-pa-15 g-pa-25-30--md g-mb-30">
                        <header className="media g-mb-30">
                            <h3 className="d-flex align-self-center text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                {title}
                            </h3>
                        </header>

                        <section>
                            <Chart style={{width: "400px", height: "300px"}} data={fields.map(({label}, i) => {
                                return {label, secondaryAxisID: label, data: futureData[i]}
                            })} axes={[
                                {primary: true, type: "ordinal", position: "bottom"},
                                {
                                    type: "linear", hardMin: 0, id: fields[0].label, position: "left",
                                    format: (e) => {
                                        if (e - Math.floor(e) !== 0) {
                                            return "$" + e
                                        }
                                        return e
                                    }
                                },
                            ]} primaryCursor tooltip/>
                        </section>
                    </div>
                </div>
            )
        }


        return (
            <div className="col-xl-12">
                {/* Statistic Card */}
                <div className="card g-brd-gray-light-v7 g-pa-15 g-pa-25-30--md g-mb-30">
                    <header className="media g-mb-30">
                        <h3 className="d-flex align-self-center text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            {label}
                        </h3>
                    </header>

                    <section>
                        <Chart style={{
                            width: "400px",
                            height: "300px",
                        }} data={[{label, data: dateData}]} axes={[
                            {primary: true, type: "ordinal", position: "bottom"},
                            {
                                type: "linear",
                                hardMin: 0,
                                position: "left",
                                color: "purple",
                                format: this.axeTick ? e => this.axeTick(e) : undefined
                            }
                        ]} primaryCursor tooltip/>
                    </section>
                </div>
            </div>
        )
    }
}

DailyChart.propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    fields: PropTypes.array.isRequired
}

export default DailyChart;