import React, {Component} from "react"

class AdditionalDropZone extends Component {
    state = {over: false, files: []};

    getFiles() {
        return this.state.files;
    }

    renderFiles() {
        let {files} = this.state;
        let {update} = this.props;

        if (files.length === 0) {
            return (
                <div/>
            )
        }

        return (
            <div style={{
                backgroundColor: "white",
                padding: "8px 12px",
                display: "flex",
                overflowX: "auto",
                overflowY: "hidden"
            }}>
                {files.map((file, i) => (
                    <div
                        style={{
                            backgroundColor: "#F4F4F4",
                            padding: "0px 8px",
                            display: "flex",
                            alignItems: "center",
                            marginRight: 8,
                            maxWidth: 300
                        }}>
                        <div style={{fontSize: 12, textOverflow: "ellipsis", overflow: "hidden"}}>
                            {file.name}
                        </div>

                        <div style={{marginLeft: 8}} className="show-cursor"
                             onClick={() => {
                                 files.splice(i, 1);

                                 this.setState({files}, () => update())
                             }}>
                            <i className="fa fa-times" style={{fontSize: 12}}/>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    render() {
        let {over, files} = this.state;
        let {update} = this.props;

        return (
            <div style={{
                flex: 1,
                border: "1px solid #C6C6C6",
                borderRadius: 5,
                paddingTop: 18,
                backgroundColor: over ? "#b5b5b5" : "#F4F4F4",
                alignItems: "center",
                textAlign: "center",
                overflow: "hidden"
            }} onDragLeave={() => {
                this.setState({over: false})
            }} onDragOver={(ev) => {
                if (!over) {
                    this.setState({over: true})
                }

                ev.preventDefault();
            }} onDropCapture={(ev) => {
                ev.preventDefault();

                this.setState({over: false});
                if (ev.dataTransfer.items) {
                    // Use DataTransferItemList interface to access the file(s)
                    let rawFiles = [];
                    for (let item of ev.dataTransfer.items) {
                        if (item.kind === 'file') {
                            rawFiles.push(item.getAsFile());

                            if (item.getAsFile().name.split(".")[1] !== "pdf") {
                                return alert("Please upload a pdf file type.")
                            }
                        }
                    }

                    this.setState({over: false, files: [...files, ...rawFiles]}, () => update());
                } else {
                    let rawFiles = [];
                    for (let file of ev.dataTransfer.files) {
                        rawFiles.push(file);

                        if (file.getAsFile().name.split(".")[1] !== "pdf") {
                            return alert("Please upload a pdf file type.")
                        }
                    }

                    this.setState({over: false, files: [...files, ...rawFiles]}, () => update());
                }
            }}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div style={{fontSize: 18, fontWeight: "bold"}}>
                        Additional Fields
                    </div>

                    <i className="fa fa-map-marker-question" style={{fontSize: 18, marginLeft: 12}}/>
                </div>

                <div style={{marginTop: 8, marginBottom: 20}}>
                    Drop files here or <span className="show-cursor" style={{color: "#4ACE9C", fontWeight: "bold"}}
                                             onClick={() => {
                                                 this.click.click();
                                             }}>
                    Browse
                </span>
                </div>

                <input id="uploadLogo" type="file" style={{display: "none"}} ref={(e) => this.click = e}
                       onChange={() => {
                           let rawFiles = this.click.files;

                           for (let file of rawFiles) {
                               if (file.getAsFile().name.split(".")[1] !== "pdf") {
                                   return alert("Please upload a pdf file type.")
                               }
                           }

                           this.setState({files: [...files, ...rawFiles]}, () => update());
                       }}/>

                {this.renderFiles()}
            </div>
        );
    }
}

export default AdditionalDropZone;