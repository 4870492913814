import React, {Component} from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {formatDate, formatPhone, getObjectImage, parseIdDict, request, toDollars} from "../../util/Util";
import {CorkTable} from 'corky'
import CorkButton from "../../modules/CorkButton";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Actions from "../../actions";
import moment from "moment"
import Card from "../dashboard/modules/Card";
import PublishModal from "./modals/PublishModal";

const ACTIVE = <span
    className="u-tags-v1 text-center g-width-150--md g-brd-around g-bg-darkblue-v2 g-font-size-12 g-font-size-default--md g-color-white g-rounded-50 g-py-4 g-px-15">Active</span>
const INACTIVE = <span
    className="u-tags-v1 text-center g-width-150--md g-brd-around g-bg-darkred g-font-size-12 g-font-size-default--md g-color-white g-rounded-50 g-py-4 g-px-15">Inactive</span>

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class PublishScreen extends Component {
    render() {
        let {shifts} = this.props.partner;
        let {WINDOW} = this.props.match.params;

        WINDOW = parseInt(WINDOW);
        let endOfWeek = moment(WINDOW).endOf("isoWeek");
        shifts = shifts.filter((item) => item.DRAFT === 1 && item.DATE_START > WINDOW && item.DATE_START < endOfWeek.valueOf());
        let employees = new Set();

        let Data = shifts.map((item) => {
            let startMoment = moment(item.DATE_START);
            let endMoment = moment(item.DATE_END);
            employees.add(item.EMPLOYEE_ID);

            return {
                onDelete: () => this.deleteProduct(item.ID),
                onEdit: () => this.props.history.push("/product/" + item.ID),
                DATE: startMoment.format("MMMM DD, YYYY"),
                HOURS: endMoment.diff(startMoment, "hours", true),
                SHIFT: (
                    <div>
                        {startMoment.format("hh:mm a") + " - " + endMoment.format("hh:mm a")}
                    </div>
                ),
                ...item,
            }
        });

        return (
            <div>
                <PublishModal window={WINDOW} ref={(e) => this.publishModal = e} {...this.props} />

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                                Publish Schedule
                            </h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton
                                type={shifts.length === 0 ? CorkButton.TYPES.DISABLED : CorkButton.TYPES.GENERAL}
                                onClick={() => shifts.length === 0 ? {} : this.publishModal.open()}>
                                Continue
                            </CorkButton>
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        <Card name="Shifts" value={shifts.length}/>
                        <Card name="Employees Affected" value={employees.size}/>
                    </div>

                    <CorkTable header={{
                        DATE: {
                            NAME: "Date",
                            SORT: "DATE_START",
                            WIDTH: 20
                        },
                        FULL_NAME: {
                            NAME: "Name",
                            WIDTH: 20,
                            TYPE: "String",
                            LINK: "/employee/",
                            LINK_ID: "EMPLOYEE_ID"
                        },
                        ROLE_NAME: {
                            NAME: "Role",
                            WIDTH: 20
                        },
                        SHIFT: {
                            NAME: "Shift",
                            WIDTH: 30
                        },
                        HOURS: {
                            NAME: "Shift Length",
                            WIDTH: 10
                        },
                    }} filters={[]} sort="DATE" noPadding={true} data={Data} headerMode="simple" desc={false}
                               title=""/>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublishScreen));
