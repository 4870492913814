import React, {Component} from "react"
import {request} from "../../../util/Util";
import {FancyText} from "corky"

const FIELDS = {
    ADDRESS: "tax-address",
    CITY: "tax-city",
    STATE: "tax-state",
    ZIPCODE: "tax-zipcode",
    APT_NUMBER: "tax-apt-number",
}

class TaxSection extends Component {
    constructor(props) {
        super(props);

        this.form = {};
    }

    componentDidMount() {
        this.canContinue();
    }

    canContinue() {
        this.props.update(true);

        // TODO: Steve - Do validation here
    }

    tryStep() {
        let form = this.form;

        return Promise.resolve();
    }

    render() {
        let form = this.form;

        return (
            <div style={{margin: "auto", maxWidth: 750}}>
                <div style={{marginTop: 64}}>
                    <FancyText form={form} id={FIELDS.ADDRESS} label="Address"/>

                    <div className="row g-mb-20">
                        <FancyText form={form} id={FIELDS.CITY} label="City" quad={true}/>
                        <FancyText form={form} id={FIELDS.APT_NUMBER} label="Apt Number" quad={true}/>
                        <FancyText form={form} id={FIELDS.STATE} label="State" quad={true}/>
                        <FancyText form={form} id={FIELDS.ZIPCODE} label="Zipcode" quad={true}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default TaxSection;