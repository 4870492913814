import React, {Component} from "react"
import {Fancy, FancyDay, FancySelect, FancyText} from "corky";
import {CAMPAIGN_TYPE, QUARTER_HOURS} from "../../../../util/Constants";
import moment from "moment"
import {request} from "../../../../util/Util";

class TextModal extends Component {
    state = {
        name: "", date: Date.now(), time: QUARTER_HOURS[36], type: CAMPAIGN_TYPE[0]
    };

    open() {
        this.setState({}, () => this.modal.open());
    }

    async createCampaign() {
        let {name, date, time, type} = this.state;
        let {textCampaigns} = this.props.partner;

        let isValid = true;
        for (let field of this.fields) {
            if (!field || !field.isValid) {
                continue;
            }

            if (!field.isValid()) {
                isValid = false;
            }
        }

        if (!isValid) {
            return false;
        }

        this.modal.getCreateButton().startLoading();

        let promotionPayload = {
            NAME: name, DATE_SCHEDULED: moment(date).startOf("day").add(time.value, "milliseconds").valueOf(),
            TYPE: type.value
        };

        if (moment(promotionPayload.DATE_SCHEDULED).isBefore(moment())) {
            this.modal.getCreateButton().stopLoading();

            return alert("Can't be before now");
        }

        let serverCampaign = await request("marketing/text_campaigns", "POST", promotionPayload);

        this.props.updateTextCampaigns([...textCampaigns, serverCampaign]);
    }

    render() {
        this.fields = [];
        let {time} = this.state;

        return (
            <Fancy name="Create Text Campaign" ref={(e) => this.modal = e}
                   buttonText="Create" onClick={this.createCampaign.bind(this)}>
                <div className="row g-mb-20">
                    <div className="col-md-8">
                        <FancyText name="name" label="Name" placeholder="Example: Spring 2020 Rush"
                                   ref={(e) => this.fields.push(e)} required={true}
                                   parentState={this.state} setParent={(state) => this.setState(state)}
                        />
                    </div>

                    <FancySelect label="Type" name="type" options={CAMPAIGN_TYPE} parentState={this.state}
                                 ref={(e) => this.fields.push(e)} required={true} tri={true}
                                 setParent={(state, callback) => this.setState(state, callback)}/>
                </div>

                <div className="row g-mb-20">
                    <FancySelect label="Time" name="time" options={QUARTER_HOURS} parentState={this.state}
                                 ref={(e) => this.fields.push(e)} required={true} half={true}
                                 setParent={(state, callback) => this.setState(state, callback)}/>

                    <FancyDay label="Date" half={true} parentState={this.state} name="date"
                              ref={(e) => this.fields.push(e)} required={true}
                              setParent={(state, callback) => this.setState(state, callback)}/>
                </div>
            </Fancy>
        )
    }
}

TextModal.propTypes = {}

export default TextModal;
