import React, {Component} from 'react'
import PropTypes from "prop-types"
import CorkButton from "./CorkButton";

class DescriptionScreen extends Component {
    render() {
        let {image, header, content} = this.props;

        return (
            <div>
                <div className="g-pa-50--lg">
                    <div style={{display: "flex", margin: 30, flexDirection: "row", justifyContent: "space-between"}}>
                        <div style={{flex: 1}}>
                            <div style={{fontSize: 24, fontWeight: "bold"}}>
                                {header}
                            </div>
                            <div style={{marginTop: 28, fontSize: 18, marginRight: 80}}>
                                {content}
                            </div>
                        </div>
                        <div style={{width: 350}}>
                            <img src={require("./../assets/" + image)}
                                 style={{maxWidth: "100%", maxHeight: "100%"}} alt="Drip"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

DescriptionScreen.propTypes = {
    type: PropTypes.string,
    header: PropTypes.string,
    content: PropTypes.string,
    image: PropTypes.string
};

export default DescriptionScreen;