import React, {Component} from "react"
import Fancy from "../../../modules/fancy/Fancy";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {request} from "../../../util/Util";

const grid = 5;
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "lightgray",

    // styles we need to apply on draggables
    ...draggableStyle
});

class TaskModal extends Component {
    state = {lines: []};

    open(lines) {
        this.setState({lines});
        this.modal.open();
    }

    saveOrder() {
        let {lines} = this.state;
        let {checklist} = this.props;

        request(`checklists/${checklist.ID}/seq`, "POST", {
            ITEMS: lines.map((item, i) => ({
                ID: item.ID,
                SEQ: i + 1
            }))
        }).then(() => {
            this.modal.close().then(() => {
                let checklists = this.props.partner.checklists;
                let items = checklists.find((item) => item.ID === checklist.ID).LINES;

                lines.forEach((item, i) => {
                    items.find((_item) => item.ID === _item.ID).SEQ = i + 1;
                });

                this.props.updateChecklists(checklists);
            });
        });
    }

    reorderFunc(info) {
        let {lines} = this.state;

        if (!info.destination) {
            return lines;
        }

        const [removed] = lines.splice(info.source.index, 1);
        lines.splice(info.destination.index, 0, removed);

        this.setState({lines});
        return lines;
    };

    render() {
        let {lines} = this.state;

        return (
            <Fancy name="Reorder Tasks" ref={(e) => this.modal = e} onClick={this.saveOrder.bind(this)}
                   buttonText="Save">
                <div className="g-mb-20" style={{height: 300, overflowY: "scroll"}}>
                    <DragDropContext onDragEnd={this.reorderFunc.bind(this)}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {
                                        lines.map((item, index) => (
                                            <Draggable key={item.ID} draggableId={item.ID} index={index}>
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef}
                                                         {...provided.draggableProps}
                                                         {...provided.dragHandleProps}
                                                         style={getItemStyle(
                                                             snapshot.isDragging,
                                                             provided.draggableProps.style
                                                         )}
                                                    >
                                                        {item.NAME}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))
                                    }
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </Fancy>
        )
    }
}

export default TaskModal;