import React, {Component} from 'react'
import {request} from '../../util/Util'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import Actions from '../../actions/index'

import {
    withRouter
} from 'react-router-dom'

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class LoginScreen extends Component {
    state = {loginStatus: null, token: null};

    componentDidMount() {
        document.title = "Drip Partner Login";
    }

    componentWillMount() {
        request('dashboard/valid', 'GET', null, (err, payload) => {
            if (err) {
                return;
            }

            window.location.href = '/';
        });
    }

    login() {
        let element = document.getElementById("token");
        let _token = element.value.trim();
        let {token} = this.state;

        if (token === null) {
            if (_token[0] !== "1") {
                _token = "1" + _token;
            }

            request('login/initiate', 'POST', {PHONE: _token}, (err, payload) => {
                if (err) {
                    alert("ERROR LOGGING IN");
                    return;
                }

                element.value = "";
                this.setState({token: payload});
            });

            return;
        }

        request('login/complete', 'POST', {
            UNIQUE: token, TOKEN: _token, CLIENT: {
                NAME: "Admin",
                INFO: "Website",
                TYPE: 2
            }
        }, (err, payload) => {
            if (err) {
                alert("ERROR LOGGING IN");
                return;
            }

            localStorage.setItem("TOKEN", payload);
            window.location.href = '/';
        });
    }

    render() {
        let {token} = this.state;
        let loginStatus = this.state.loginStatus === null ? <span/> :
            <span style={{color: "darkred"}}>{this.state.loginStatus}</span>;

        return (
            <body style={{backgroundColor: "#F1F1F1"}}>
            <div style={{paddingTop: "10%", paddingBottom: "15%"}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div
                            className="col-8 col-md-6 col-lg-5 col-xl-4 text-center g-mb-70 card h-100 g-brd-gray-light-v3 g-px-30 g-py-25">
                            <div className="text-center g-py-5 g-pb-15">
                                <img style={{maxWidth: "45%", maxHeight: "45%"}}
                                     src={require("./../../assets/drip.png")}
                                     alt="Drip"/>
                            </div>
                            <h2 className="h5 mb-4">Partner Dashboard</h2>
                            {/* Login Form */}
                            {loginStatus}
                            <div className="g-pb-30">
                                <div className="g-mb-20">
                                    <input onKeyUp={(e) => {
                                        if (e.key === "Enter") this.login()
                                    }} type="text" id="token"
                                           placeholder={token === null ? "Input Phone Number" : "Input Code from Phone"}
                                           className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded-20 g-py-13 g-px-15"/>
                                </div>
                                <button
                                    className="btn btn-block u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-900 g-font-size-14 rounded-20 g-px-25 g-py-15"
                                    onClick={(e) =>
                                        this.login()
                                    }>Sign In
                                </button>
                            </div>
                            <p>Don't have a drip account? <a href="https://startdrip.com">Sign up</a></p>
                            {/* End Login Form */}
                        </div>
                    </div>
                </div>
            </div>
            </body>

        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginScreen));