import React, {Component} from 'react'
import {formatDate, request} from "../../../util/Util";

class Feed extends Component {
    complete() {
        let {item} = this.props;

        if (item.DATE_COMPLETED !== null) {
            return;
        }

        if (!window.confirm("Would you like to mark this order complete?")) {
            return;
        }

        request("order/" + item.ID, "POST", {}, (err) => {
            if (err) {
                alert("ERROR");
                return;
            }

            let orders = this.props.partner.orders;
            let index = orders.findIndex((_item) => _item.ID === item.ID);

            orders.splice(index, 1, {...orders[index], DATE_COMPLETED: new Date().getTime()});
            this.props.updateOrders(orders);
        });
    }

    render() {
        let {item} = this.props;

        return (
            <div style={{backgroundColor: "#ebebeb", borderRadius: 5, marginBottom: 10}} className="show-cursor"
                 onClick={this.complete.bind(this)}>
                <div style={{padding: 25}}>
                    <span style={{fontSize: 24}}>
                        #{item.ID} - <span style={{fontWeight: "bold"}}>{item.FULL_NAME}</span>
                    </span>

                    <div style={{color: "gray"}}>
                        Date Sent: {formatDate(item.DATE_SENT)}
                    </div>

                    <span style={{color: "gray", overflow: "hidden"}}>
                    {item.ITEMS.map((item) => {
                        return (
                            <div style={{padding: 25, borderRadius: 5, backgroundColor: "white", marginTop: 10}}>
                                <span style={{fontWeight: "bold", fontSize: 16}}>{item.NAME}</span>
                                <div>
                                    {item.CUSTOMIZATIONS.map((item) => {
                                        return <div>{item.KEY.NAME + ": " + item.VALUE.NAME}</div>
                                    })}
                                </div>
                            </div>
                        )
                    })}
                    </span>
                </div>
            </div>
        );
    }
}

export default Feed;