import React, {Component} from 'react'
import TutorialCheckbox from "./TutorialCheckbox";
import PropTypes from 'prop-types';

class TutorialContainer extends Component {
    render() {
        return (
            <div className="g-pos-rel u-timeline-v1 g-pa-15 g-pa-20--md" style={{marginBottom: 20}}>
                <header className="media g-mb-30">
                    <div className="d-flex align-self-center">
                        <div className="media">
                            <a className="d-flex align-self-center g-mr-10 g-mr-15--md" href="#!">
                                <img
                                    className="g-width-40 g-width-50--md g-height-40 g-height-50--md rounded-circle"
                                    src="../../assets/img-temp/100x100/img7.jpg" alt=""/>
                            </a>
                            <div className="media-body align-self-center">
                                <h3 className="g-font-weight-300 g-font-size-16 g-color-black g-mb-5">
                                    {this.props.title}
                                </h3>
                                <em className="d-block g-font-style-normal g-font-weight-300 g-color-gray-dark-v6">
                                    {this.props.subtitle}
                                </em>
                            </div>
                        </div>
                    </div>
                </header>

                <form>
                    {this.props.children}
                </form>
            </div>
        );
    }
}

export default TutorialContainer;