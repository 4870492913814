import React, {Component} from 'react'
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import DayPicker from "react-day-picker/DayPicker";
import moment from "moment"

class FormDate extends Component {
    state = {show: false};

    constructor(props) {
        super(props);

        this.componentId = "dripCalendar_" + Math.floor(Math.random() * 100000)
        this.wrapperId = "dripCalendarWrapper_" + Math.floor(Math.random() * 100000)
        this.calendarId = "dripCalendarPicker_" + Math.floor(Math.random() * 100000)
    }

    onClick(e) {
        let {show} = this.state
        if (!show) {
            return;
        }

        let component = document.getElementById(this.componentId);
        let calendar = document.getElementById(this.calendarId);
        if (e.target && ((component && component.contains(e.target))) || (calendar && calendar.contains(e.target))
            || (e.target.classList && e.target.classList.contains("fancy-icon"))) {
            return;
        }

        this.setState({show: false})
    }

    componentWillMount() {
        window.addEventListener("click", this.onClick.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.onClick.bind(this));
    }

    render() {
        let {value, onChange, placeholder = "Select a date", disabled} = this.props;
        let {show} = this.state;

        let Tooltip = <div/>;
        if (this.props.tooltip) {
            Tooltip = (
                <i style={{marginLeft: 5}} className="fa fa-info-circle" data-tip={this.props.tooltip}/>
            );
        }

        return (
            <div className="row g-mb-20">
                <div className="col-md-3 align-self-center g-mb-5 g-mb-0--md">
                    <label className="mb-0">{this.props.name}</label>

                    {Tooltip}
                    <ReactTooltip/>
                </div>

                <div className="col-md-9 align-self-center">
                    <div className="form-group g-pos-rel mb-0 has-success" id={this.componentId}>
                        <input
                            className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-primary--focus g-brd-red--error g-rounded-4 g-px-20 g-py-12"
                            type="text" value={moment(value).format("MM/DD/YYYY")} disabled={disabled}
                            onFocus={() => !disabled && this.setState({show: true})} aria-invalid="false"
                            placeholder={placeholder}/>

                        <div id={this.wrapperId}>
                            {show ? (
                                <DayPicker
                                    ref={(e) => this.date = e}
                                    containerProps={{
                                        style: {
                                            zIndex: 1000000, position: "absolute", backgroundColor: "#f3f3f3", top: 50
                                        }, id: this.calendarId
                                    }}
                                    month={value ? new Date(value) : new Date()}
                                    value={new Date(value)}
                                    onDayClick={(value) => {
                                        this.setState({show: false}, () => onChange(value.getTime()));
                                    }}
                                    selectedDays={value ? new Date(value) : null}
                                />
                            ) : <div/>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

FormDate.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,

    customStyles: PropTypes.object,
    disabled: PropTypes.bool,
    multi: PropTypes.bool
};

export default FormDate;
