import React, {Component} from 'react'
import Report from "./module/Report";
import {toDollars} from "../../util/Util";
import {CorkTable} from "corky";
import {PLATFORMS, TRANSACTION_TYPES} from "../../util/Constants";
import {Chart} from "react-charts";
import moment from "moment";
import DailyChart from "./module/DailyChart";
import {bindActionCreators} from "redux";
import Actions from "../../actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ReportCard from "./module/ReportCard";
import DayChart from "./module/DayChart";
import HourChart from "./module/HourChart";
import {Reporting} from "drip-drop"

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class DrawerSummary extends Report {
    componentDidMount() {
        let {start, end, locations} = this.props.partner.reporting;

        super.updateData(start, end, locations)
    }

    render() {
        let {drawers, orders: data} = this.props.partner.reporting.data;

        return (
            <div>
                {super.render()}

                <div className="row">
                    <CorkTable key={data} headerMode="download" header={{
                        DATE_OPEN: {
                            NAME: "Date Open",
                            LINK: "/report/drawer/",
                            LINK_ID: "ID",
                            FORMAT: (item) => moment(item).format("MMM, DD h:mma"),
                            WIDTH: 20
                        },
                        DATE_CLOSE: {
                            NAME: "Date Close",
                            FORMAT: (item) => item === null ? "In Progress" : moment(item).format("MMM, DD h:mma"),
                            WIDTH: 20
                        },
                        DEVICE_NAME: {
                            NAME: "Device Name",
                            FORMAT: (item) => item ? item : "-",
                            WIDTH: 20
                        },
                        AMOUNT_EXPECTED: {
                            NAME: "Expected Amount",
                            FORMAT: (item) => item ? "$" + toDollars(item) : "-",
                            WIDTH: 15
                        },
                        AMOUNT_CLOSE: {
                            NAME: "Difference",
                            FORMAT: (item, row) => item ? "$" + toDollars(item - row.AMOUNT_EXPECTED) : "-",
                            WIDTH: 15
                        },
                        STATUS: {
                            NAME: "Status",
                            FORMAT: (item, row) => row.AMOUNT_CLOSE ? "Closed" : "Open",
                            WIDTH: 10
                        }
                    }} sort="DATE_OPEN" downloadText="Download" data={drawers}
                               desc={true} title="Cash Drawers" ignoreRowParams={true} hidePaging={true}/>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DrawerSummary));