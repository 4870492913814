import React, {Component} from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {CorkTable} from 'corky'
import moment from "moment"
import CorkButton from "../../../modules/CorkButton";
import ShiftModal from "./../modals/ShiftModal";
import {getOperatingHour} from "../../../helpers/SchedulingHelper";
import Select from "react-select";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {bindActionCreators} from "redux";
import Actions from "../../../actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class DayScheduling extends Component {
    state = {
        id: -1,
        employeeId: null,
        start: new Date().getTime(),
        end: new Date().getTime() + 1000 * 60 * 60,
        startStart: null,
        shiftEnd: null,
        notes: "",

        window: moment().startOf("day").toDate().getTime(),
    };

    createShift(employeeId, day, hour) {
        let {window} = this.state;

        this.shiftModal.openDay(employeeId, day, moment(window).toDate().getTime(), hour);
    }

    editShift(shift, day) {
        let {window} = this.state;

        this.shiftModal.open(shift.EMPLOYEE_ID, day, moment(window).toDate().getTime(), shift, true);
    }

    render() {
        let {window} = this.state;
        let windowEnd = moment(window).add(1, "days").toDate().getTime();
        let {employees, shifts, employeePreferences} = this.props.partner;

        let windowShifts = shifts.filter((item) => {
            return item.DATE_START > window && item.DATE_START < windowEnd
        });

        let hours = getOperatingHour.call(this, TO_DAY[new Date(window).getDay()]);
        let header = {};

        for (let item of hours) {
            header[item.label.toUpperCase()] = {
                NAME: (
                    <div style={{fontSize: 18, fontWeight: "bold"}}>
                        {item.label}
                    </div>
                ),
                WIDTH_PX: 100
            }
        }

        header = {
            DAY: {
                NAME: (
                    <div style={{fontSize: 18, fontWeight: "bold"}}>
                        Day
                    </div>
                ),
                WIDTH_PX: 250,
                TYPE: "String",
                SORT: "ID"
            }, ...header
        };

        let currentItem = moment(window);
        let Data = employees.map((employee, i) => {
            let todaysShifts = windowShifts.filter((item) => item.EMPLOYEE_ID === employee.ID);
            let todayPreferences = employeePreferences.filter((item) => {
                return item.EMPLOYEE_ID === employee.ID && item.ISO_DAY === moment(window).toDate().getISODay()
            })

            let nextShift = null, nextPreference = null;

            return {
                ID: employee.ID,
                DAY: (
                    <div style={{minWidth: 200}}>
                        <div style={{fontSize: 14, fontWeight: "bold"}}>
                            {employee.FULL_NAME}
                        </div>
                        <div style={{fontSize: 10, color: "#808080", marginTop: 4}}>
                            {moment(window).format("MMM D")}
                        </div>
                    </div>
                ),
                ...hours.reduce((dict, item, i) => {
                    let hasDiv = null

                    let hourStart = item.value + window;
                    let hourEnd = item.value + window + (1000 * 60 * 60);

                    let shift = todaysShifts.find((_item) => {
                        return (_item.DATE_START >= hourStart) && (_item.DATE_START < hourEnd)
                    });

                    let preference = todayPreferences.find((_item) => {
                        let start = moment(window).startOf("day").add(_item.OFFSET, "minutes").valueOf();

                        return (_item.ALL_DAY && i === 0) || ((start >= hourStart) && (start < hourEnd))
                    })

                    if (nextShift !== null) {
                        let shiftStartPer = (nextShift.DATE_END - nextShift.DATE_START) / (1000 * 60 * 15) * 50 / 1.20;
                        let diff = nextShift.DATE_START - moment(nextShift.DATE_START).startOf("hour").toDate().getTime();
                        let myShift = nextShift;

                        hasDiv = (
                            <div style={{
                                minWidth: 50,
                                display: "flex",
                                width: "" + shiftStartPer + "%",
                                backgroundColor: "#" + nextShift.COLOR,
                                marginLeft: -((4 - (diff / (1000 * 60 * 15))) * 25),
                                height: 50,
                                borderRadius: 5
                            }}>
                                <div className="show-cursor"
                                     onClick={() => this.editShift(myShift, TO_DAY[currentItem.toDate().getDay()])}
                                     style={{
                                         width: "" + shiftStartPer + "%",
                                         height: 50,
                                         display: "flex",
                                         justifyContent: "center",
                                         alignItems: "center",
                                         flexDirection: "column"
                                     }}>
                                    <div style={{fontSize: 10, fontWeight: "bold"}}>
                                        {nextShift.DRAFT === 1 ? "Draft" : ""}
                                    </div>
                                    <div style={{textAlign: "center", fontSize: 12, fontWeight: "bold"}}>
                                        {moment(nextShift.DATE_START).format("h:mm a") + "-" + moment(nextShift.DATE_END).format("h:mm a")}
                                    </div>
                                </div>
                            </div>
                        );

                        nextShift = null;
                    } else if (nextPreference) {
                        let start = moment(window).startOf("day").add(nextPreference.OFFSET, "minutes").valueOf();
                        let end = moment(start).add(nextPreference.DURATION, "minutes").valueOf();

                        let shiftStartPer = (start - end) / (1000 * 60 * 15) * 50 / 1.20;
                        let diff = start - moment(start).startOf("hour").toDate().getTime();

                        hasDiv = (
                            <div style={{
                                minWidth: 50,
                                display: "flex",
                                width: "" + shiftStartPer + "%",
                                backgroundColor: "#E1E1E1",
                                marginLeft: -((4 - (diff / (1000 * 60 * 15))) * 25),
                                height: 50,
                                borderRadius: 5
                            }}>
                                <div className="show-cursor"
                                     style={{
                                         width: "" + shiftStartPer + "%",
                                         height: 50,
                                         display: "flex",
                                         justifyContent: "center",
                                         alignItems: "center",
                                         flexDirection: "column"
                                     }}>
                                    <div style={{fontSize: 10, fontWeight: "bold"}}>
                                        Test
                                    </div>
                                    <div style={{textAlign: "center", fontSize: 12, fontWeight: "bold"}}>
                                        Test 2
                                    </div>
                                </div>
                            </div>
                        );

                        nextPreference = null;
                    }

                    if (shift) {
                        if (shift.DATE_START - moment(shift.DATE_START).startOf("hour").toDate().getTime() !== 0) {
                            nextShift = shift;
                        } else {
                            let shiftStartPer = (shift.DATE_END - shift.DATE_START) / (1000 * 60 * 15) * 50 / 1.20;

                            hasDiv = (
                                <div style={{
                                    display: "flex",
                                    minWidth: 50,
                                    width: "" + shiftStartPer + "%",
                                    backgroundColor: "#" + shift.COLOR,
                                    height: 50,
                                    borderRadius: 5
                                }}>
                                    <div className="show-cursor"
                                         onClick={() => this.editShift(shift, TO_DAY[currentItem.toDate().getDay()])}
                                         style={{
                                             width: "" + shiftStartPer + "%",
                                             height: 50,
                                             display: "flex",
                                             justifyContent: "center",
                                             alignItems: "center",
                                             flexDirection: "column"
                                         }}>
                                        <div style={{fontSize: 10, fontWeight: "bold"}}>
                                            {shift.DRAFT === 1 ? "Draft" : ""}
                                        </div>
                                        <div style={{
                                            textAlign: "center",
                                            fontSize: 12,
                                            fontWeight: "bold"
                                        }}>
                                            {moment(shift.DATE_START).format("h:mm a") + "-" + moment(shift.DATE_END).format("h:mm a")}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    } else if (preference) {
                        let start = preference.ALL_DAY ? moment(window).add(hours[0].value, "milliseconds") : moment(window).startOf("day").add(preference.OFFSET, "minutes").valueOf();
                        let end = moment(start).add(preference.DURATION + (preference.ALL_DAY ? (hours.length - 0.5) * 60 : 0), "minutes").valueOf();

                        if (start - moment(start).startOf("hour").toDate().getTime() !== 0) {
                            nextPreference = preference;
                        } else {
                            let shiftStartPer = (end - start) / (1000 * 60 * 15) * 50 / 1.20;

                            console.log("CURRENT", shiftStartPer);
                            hasDiv = (
                                <div style={{
                                    display: "flex", minWidth: 50, width: "" + shiftStartPer + "%",
                                    backgroundColor: "#E1E1E1", height: 50, borderRadius: 5
                                }}>
                                    <div className="show-cursor"
                                         style={{
                                             width: "" + shiftStartPer + "%",
                                             height: 50,
                                             display: "flex",
                                             justifyContent: "center",
                                             alignItems: "center",
                                             flexDirection: "column"
                                         }}>
                                        <div style={{fontSize: 10, fontWeight: "bold"}}>
                                            Yo
                                        </div>
                                        <div style={{
                                            textAlign: "center",
                                            fontSize: 12,
                                            fontWeight: "bold"
                                        }}>
                                            Dog
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    }

                    let block = todaysShifts.find((item) => item.DATE_START <= hourStart && item.DATE_END > hourStart);
                    if (!block && hasDiv === null) {
                        hasDiv = (
                            <AddShift create={this.createShift.bind(this)} employee={employee}
                                      day={currentItem.toDate().getDay()} hour={hourStart}/>
                        )
                    }

                    dict[item.label.toUpperCase()] = hasDiv;

                    return dict;
                }, {})
            }
        });

        return (
            <div>
                <ShiftModal ref={(e) => this.shiftModal = e} {...this.props} />

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">Schedule</h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            {/*<CorkButton onClick={() => {*/}
                            {/*}} type={CorkButton.TYPES.DISABLED}>*/}
                            {/*    Auto Schedule*/}
                            {/*</CorkButton>*/}

                            <CorkButton onClick={() => {
                                this.props.history.push("/publish/" + moment(window).startOf("isoWeek").valueOf());
                            }} marginLeft>
                                Publish
                            </CorkButton>
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        <div className="row g-ml-50 g-pa-20 g-ml-0--lg" style={{marginLeft: 0}}>
                            <div style={{
                                border: "1px solid gray",
                                borderRadius: 5,
                                marginLeft: 2,
                                alignItems: "center",
                                display: "flex"
                            }}>
                                <a style={{padding: "10px 15px", borderRight: "1px solid gray"}} className="show-cursor"
                                   onClick={() => {
                                       let newWindow = moment(window).subtract(1, "day");
                                       this.setState({
                                           window: newWindow.toDate().getTime(),
                                       })
                                   }}>
                                    <i className="fa fa-chevron-left"/>
                                </a>

                                <div style={{padding: "0 10px", border: "0px solid black"}}
                                     className="show-cursor">
                                    <DayPickerInput value={new Date(window)} onDayChange={(value) => {
                                        this.setState({window: moment(value).startOf("day").toDate().getTime()});
                                    }} dayPickerProps={{
                                        selectedDays: new Date(window)
                                    }} inputProps={{
                                        style: {
                                            borderWidth: 0,
                                            textAlign: "center"
                                        }
                                    }} formatDate={(date) => moment(date).format("MM/DD/YYYY")}/>
                                </div>

                                <a style={{
                                    padding: "10px 15px", overflow: "hidden", marginRight: 2,
                                    borderLeft: "1px solid gray", backgroundColor: "white"
                                }}
                                   className={"show-cursor"}
                                   onClick={() => {
                                       let newWindow = moment(window).add(1, "days");
                                       this.setState({
                                           window: newWindow.toDate().getTime()
                                       })
                                   }}>
                                    <i className="fa fa-chevron-right"/>
                                </a>
                            </div>

                            <div style={{width: 200, marginLeft: 12}}>
                                <Select menuPortalTarget={document.body} placeholder="View" styles={{
                                    menuPortal: base => ({...base, zIndex: 99999}),
                                    menuList: () => ({maxHeight: 150, overflowY: "scroll"})
                                }} options={[
                                    {label: "Week", value: 1},
                                    {label: "Day", value: 0}
                                ]} value={{label: "Day", value: 0}}
                                        onChange={() => this.props.history.push("/scheduling")}/>
                            </div>
                        </div>
                    </div>


                    <div className="row" style={{marginTop: 15}}>
                        <CorkTable noHeader={true} ref={(e) => this.table = e} ignoreRowParams={true} showAll={true}
                                   filters={[{
                                       label: "Status:",
                                       column: "STATUS",
                                       items: [
                                           {label: "All", badge: "g-bg-black", value: null},
                                           {label: "Enabled", badge: "g-bg-darkblue-v2", value: 1},
                                           {label: "Disabled", badge: "g-bg-darkred", value: 0}
                                       ]
                                   }]} header={header} sort="DAY" data={Data} desc={false} title=""
                                   tableStyle={{tableLayout: "fixed"}}/>
                    </div>
                </div>
            </div>
        )
    }
}

const TO_DAY = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
};

class AddShift extends Component {
    state = {show: false};

    render() {
        let {show} = this.state;
        let {employee, day, hour} = this.props;

        let add = show ?
            <div className="show-cursor"
                 style={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     color: "#4ACE9C",
                     width: 60,
                     height: 50,
                     fontWeight: "bold",
                     textAlign: "center"
                 }}
                 onClick={() => this.props.create(employee.ID, TO_DAY[day], hour)}>
                ADD
            </div> :
            <span>&nbsp;</span>;


        return (
            <div style={{color: "#4ACE9C", width: 60, height: 50}} onMouseEnter={() => this.setState({show: true})}
                 onMouseLeave={() => this.setState({show: false})}>
                {add}
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DayScheduling));
