import React, {Component} from 'react'
import {Link} from 'react-router-dom'

class SecondaryNavItem extends Component {
    render() {
        return (
            <li className="g-brd-top g-brd-gray-light-v7 mb-0">
                <Link
                    className={"d-flex align-items-center u-link-v5 g-parent g-py-15 " + (this.props.history.location.pathname === this.props.url ? " active" : "")}
                    to={this.props.url}>
                    <span
                        className="g-font-size-18 g-color-gray-light-v6 g-color-primary--parent-hover g-color-primary--parent-active g-mr-15">
                        <i className={this.props.icon}/>
                    </span>
                    <span
                        className="g-color-gray-dark-v6 g-color-primary--parent-hover g-color-primary--parent-active">{this.props.name}</span>
                </Link>
            </li>
        );
    }
}

export default SecondaryNavItem;