import {ADD_NOTIFICATION, REMOVE_NOTIFICATION} from "../actions/notification";

let cloneObject = function (obj) {
    return JSON.parse(JSON.stringify(obj))
};

const defaultState = {
    notifications: []
};

let newState = cloneObject(defaultState);

export default function (state = newState, action) {
    switch (action.type) {
        case "TT":
            return newState;
        case ADD_NOTIFICATION:
            return {notifications: [...state.notifications, action.payload]};
        case REMOVE_NOTIFICATION:
            let {notifications} = state;

            let index = notifications.findIndex((item) => item.ID === action.payload);

            if (index !== -1) {
                notifications.splice(index, 1);
            }

            return {notifications};
        default:
            return state
    }
}