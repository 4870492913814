import React, {Component} from 'react'
import {request} from "../../../util/Util";
import {addNotification} from "../../../util/NotificationUtil";
import {Link} from "react-router-dom";

const Months = ['Jan', 'Fed', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

class News extends Component {
    state = {
        show: false
    };

    test(e) {
        if (!this.state.show) {
            return;
        }

        if (e.target && e.target.classList && e.target.classList.contains("no-close")) {
            return;
        }

        this.setState({show: false})
    }

    componentWillMount() {
        window.addEventListener("click", this.test.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.test.bind(this));
    }

    deleteNews() {
        let {ID} = this.props.item;
        if (!window.confirm("Are you sure you want to delete this news?")) {
            return;
        }

        request("partner/news/" + ID, "DELETE", {}, (err) => {
            if (err) {
                addNotification.call(this, "Post Error", "The news post could not be deleted", 3);
                return;
            }

            this.props.removePost(ID);
            addNotification.call(this, "Post Delete", "The news post has been deleted", 1);
        });
    }

    render() {
        let {item} = this.props;

        let datePosted = new Date(item.DATE_POSTED), dateReleased = new Date(item.DATE_POSTED);
        return (
            <div className="col-md-6 col-lg-4 g-mb-30">
                <div className="card h-100 g-brd-gray-light-v7 rounded">
                    <header className="card-header g-bg-transparent g-brd-bottom-none g-pa-20 g-pa-30--sm">
                        <div className="media g-mb-15">
                            <div className="d-flex align-self-center" style={{flexDirection: "column"}}>
                                <div style={{fontWeight: "bold", fontSize: 24}}>{item.TITLE}</div>
                                <div style={{fontSize: 14}}>{item.SUBTITLE}</div>
                            </div>

                            <div className="media-body d-flex justify-content-end">
                                <div className="g-pos-rel g-z-index-2">
                                    <a
                                        className="u-link-v5 g-line-height-0 g-font-size-24 g-color-gray-light-v6 g-color-secondary--hover show-cursor"
                                        onClick={() => this.setState({show: !this.state.show})}
                                    >
                                        <i className="hs-admin-more-alt g-ml-20 no-close"/>
                                    </a>

                                    <div
                                        className="u-shadow-v31 g-pos-abs g-right-0 g-bg-white u-dropdown--jquery-slide slideInDms"
                                        style={{height: this.state.show ? "auto" : "0", overflow: "hidden"}}>
                                        <ul className="list-unstyled g-nowrap mb-0">
                                            <li className="show-cursor">
                                                <a className="d-flex align-items-center u-link-v5 g-bg-gray-light-v8--hover g-font-size-12 g-font-size-default--md g-color-gray-dark-v6 g-px-25 g-py-14"
                                                   onClick={() => {
                                                       this.props.onClick();
                                                       this.setState({show: false});
                                                   }}>
                                                    <i className="hs-admin-pencil g-font-size-18 g-color-gray-light-v6 g-mr-10 g-mr-15--md"/>
                                                    Edit
                                                </a>
                                            </li>
                                            <li className="show-cursor">
                                                <a className="d-flex align-items-center u-link-v5 g-bg-gray-light-v8--hover g-font-size-12 g-font-size-default--md g-color-gray-dark-v6 g-px-25 g-py-14"
                                                   onClick={() => {
                                                       this.deleteNews();
                                                       this.setState({show: false});
                                                   }}>
                                                    <i className="hs-admin-trash g-font-size-18 g-color-gray-light-v6 g-mr-10 g-mr-15--md"/>
                                                    Delete
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    <hr className="d-flex g-brd-gray-light-v7 g-mx-20 g-mx-30--sm my-0"/>

                    {/*<div
                        className="card-block d-flex justify-content-between g-px-20 g-px-30--sm g-py-15 g-py-20--sm">
                        <div>
                            <h4 className="g-line-height-1_2 g-font-weight-300 g-font-size-28 g-color-black">
                                {this.props.partner.views.filter((_item) => {
                                    return _item.NEWS_ID === parseInt(item.ID)
                                }).length}
                                ?
                            </h4>
                            <em className="g-font-style-normal g-font-weight-300 g-font-size-16 g-color-gray-dark-v6">Views</em>
                        </div>

                        <div>
                            <h4 className="g-line-height-1_2 g-font-weight-300 g-font-size-28 g-color-black">
                                {this.props.partner.clicks.filter((_item) => {
                                    return _item.NEWS_ID === parseInt(item.ID)
                                }).length}
                                ?
                            </h4>
                            <em className="g-font-style-normal g-font-weight-300 g-font-size-16 g-color-gray-dark-v6">Clicks</em>
                        </div>

                        <div>
                            <h4 className="g-line-height-1_2 g-font-weight-300 g-font-size-28 g-color-black">
                                ?
                            </h4>
                            <em className="g-font-style-normal g-font-weight-300 g-font-size-16 g-color-gray-dark-v6">Likes</em>
                        </div>
                    </div>*/}

                    <hr className="d-flex g-brd-gray-light-v7 g-mx-20 g-mx-30--sm my-0"/>

                    <div className="card-block g-px-20 g-px-30--sm g-py-15 g-py-20--sm">
                        <div className="row g-mb-25">
                            <div className="col-md-6 g-mb-25 g-mb-0--md">
                                <h5 className="g-font-size-default g-color-gray-dark-v6 g-mb-5">
                                    Date Created
                                </h5>
                                <p className="g-color-black mb-0">
                                    {datePosted.getDate()} {Months[datePosted.getMonth()]} {datePosted.getFullYear()}
                                </p>
                            </div>

                            <div className="col-md-6 g-mb-25 g-mb-0--md">
                                <h5 className="g-font-size-default g-color-gray-dark-v6 g-mb-5">Date
                                    Published</h5>
                                <p className="g-color-black mb-0">
                                    {dateReleased.getDate()} {Months[dateReleased.getMonth()]} {dateReleased.getFullYear()}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default News;