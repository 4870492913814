import React, {Component} from 'react'
import {Switch, Route, Link} from 'react-router-dom'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import Actions from '../../../actions/index'

import {
    withRouter
} from 'react-router-dom'
import SecondaryNavItem from "../../../modules/SecondaryNavItem";
import ActivityTab from "./tabs/ActivityTab";
import PatronsTab from "./tabs/PatronsTab";
import TasksTab from "./tabs/TasksTab";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class LoyaltyScreen extends Component {
    render() {
        return (
            <div>
                <div className="g-pa-20">
                    <div className="row">
                        <div className="col-md-3 g-mb-30 g-mb-0--md">
                            <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                                <section className="text-center g-mb-30 g-mb-50--md">
                                    <div className="d-inline-block g-pos-rel g-mb-20">
                                        <img className="img-fluid rounded-circle"
                                             src={"../../assets/img/loyalty.png"}
                                             alt=""/>
                                    </div>

                                    <h3 className="g-font-weight-300 g-font-size-20 g-color-black mb-0">
                                        Loyalty
                                    </h3>
                                </section>

                                <section>
                                    <ul className="list-unstyled mb-0">
                                        <SecondaryNavItem icon="far fa-chart-line" {...this.props}
                                                          url="/loyalty/activity" name="Activity"/>
                                        <SecondaryNavItem icon="fas fa-user-friends" {...this.props}
                                                          url={"/loyalty/patrons"} name="Patrons"/>
                                       {/* <SecondaryNavItem icon="fas fa-thumbtack" {...this.props}
                                                          url={"/loyalty/tasks"} name="Challenges & Tasks"/>*/}
                                    </ul>
                                </section>
                            </div>
                        </div>

                        <Switch>
                            <Route exact path='/loyalty/activity'
                                   component={() => <ActivityTab {...this.props} />}/>
                            <Route exact path='/loyalty/patrons'
                                   component={() => <PatronsTab {...this.props} />}/>
                           {/* <Route exact path='/loyalty/tasks'
                                   component={() => <TasksTab {...this.props} />}/>*/}
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoyaltyScreen));