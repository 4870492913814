import React, {Component} from "react"
import {bindActionCreators} from "redux";
import Actions from "../../actions";
import {Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {request} from "../../util/Util";
import CorkButton from "../../modules/CorkButton";
import BankModal from "../shop/tabs/modals/BankModal";
import SecondaryNavItem from "../../modules/SecondaryNavItem";
import ActivityTab from "./tabs/ActivityTab";
import {CircleLoader} from "react-spinners";
import CardsTab from "./tabs/CardsTab";
import SettingsTab from "./tabs/SettingsTab";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class BankingContainer extends Component {
    state = {requirements: null, payouts: [], balance: null, account: null, banks: [], holders: []};

    componentDidMount() {
        let {location} = this.props.partner;
        request("stripe/status", "GET", null, undefined, location.ID).then((requirements) => {
            this.setState({requirements});

            if (requirements.currently_due.length === 0) {
                request("partner/banking", "GET", null, undefined, location.ID).then((dat) => {
                    this.setState(dat);
                });
            }
        });
    }

    redirect(get) {
        let {location} = this.props.partner;

        this.loading.startLoading();
        request("stripe/connect/partner", get ? "GET" : "POST", null, undefined, location.ID).then((data) => {
            window.location.href = data;
        });
    }

    renderRequireButton() {
        let {location} = this.props.partner;
        let {requirements} = this.state;

        if (location.CONNECT_ID === null) {
            return (
                <div style={{marginTop: 38}}>
                    <CorkButton bold onClick={this.redirect.bind(this, false)} ref={(e) => this.loading = e}>
                        Setup Account
                    </CorkButton>
                </div>
            )
        }

        let currentlyRequired = requirements.currently_due[0];
        if (currentlyRequired.startsWith("external_account")) {
            return (
                <div style={{marginTop: 38}}>
                    <CorkButton bold onClick={() => this.bankModal.open(true)}>
                        Setup Bank Details
                    </CorkButton>
                </div>
            )
        }

        if (currentlyRequired.startsWith("business_profile") || currentlyRequired.startsWith("company") || currentlyRequired.startsWith("person") || currentlyRequired.startsWith("individual")) {
            return (
                <div style={{marginTop: 38}}>
                    <CorkButton bold onClick={this.redirect.bind(this, true)} ref={(e) => this.loading = e}>
                        Continue Setup
                    </CorkButton>
                </div>
            )
        }

        return (
            <div style={{marginTop: 38}}>
                Contact Drip Support
            </div>
        )
    }

    renderRequirements() {
        return (
            <div className="col g-ml-45--lg g-ml-50--lg g-pb-65--md">
                <BankModal ref={(e) => this.bankModal = e} {...this.props} add={() => window.location.reload()}/>

                <div className="g-pa-50--lg">
                    <div style={{display: "flex", margin: 50, flexDirection: "row", justifyContent: "space-between"}}>
                        <div style={{flex: 1}}>
                            <div style={{fontSize: 24, fontWeight: "bold"}}>
                                Integrated Account
                            </div>

                            <div style={{marginTop: 28, fontSize: 18, marginRight: 80}}>
                                Access daily sales when they occur, manage a fleet of expense cards, setup automatic
                                transfers to any account, and much more.
                            </div>

                            {this.renderRequireButton()}
                        </div>

                        <div style={{width: 350}}>
                            <img src={require("./../../assets/banking.png")}
                                 style={{maxWidth: "100%", maxHeight: "100%"}} alt="Drip"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderLoading() {
        return (
            <div style={{
                flex: 1,
                height: "calc(100vh - 65px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <CircleLoader sizeUnit={"px"} size={200} color={'#123abc'} loading={true}/>
            </div>
        )
    }

    render() {
        let {requirements, balance} = this.state;
        let {location} = this.props.partner;

        if (location.CONNECT_ID === null) {
            return this.renderRequirements();
        }

        if (requirements === null) {
            return this.renderLoading();
        }

        if (requirements.currently_due.length > 0) {
            return this.renderRequirements();
        }

        if (balance === null) {
            return this.renderLoading();
        }

        return (
            <div className="col g-ml-45 g-ml-0--lg g-pb-65--md">
                <div className="g-pa-20">
                    <div className="row">
                        <div className="col-md-3 g-mb-30 g-mb-0--md">
                            <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                                <section className="text-center g-mb-30">
                                    <div className="d-inline-block g-pos-rel g-mb-20">
                                        <img className="img-fluid"
                                             src={require("./../../assets/banking.png")}
                                             alt="Banking Logo"/>
                                    </div>

                                    <h3 className="g-font-weight-300 g-font-size-20 g-color-black mb-0">
                                        Banking
                                    </h3>
                                </section>

                                <section>
                                    <ul className="list-unstyled mb-0">
                                        <SecondaryNavItem icon="far fa-chart-line" {...this.props}
                                                          url={"/banking"} name="Activity"/>
                                       {/* <SecondaryNavItem icon="fa fa-credit-card" {...this.props}
                                                          url={"/banking/cards"} name="Expense Cards"/>*/}
                                        <SecondaryNavItem icon="fa fa-cog" {...this.props}
                                                          url={"/banking/settings"} name="Settings"/>
                                    </ul>
                                </section>
                            </div>
                        </div>

                        <div className="col-md-9">
                                <Switch>
                                    <Route exact path='/banking' component={() =>
                                        <ActivityTab parentState={this.state}
                                                     setParent={(state) => this.setState({state})}  {...this.props} />
                                    }/>
                                    <Route exact path='/banking/cards' component={() =>
                                        <CardsTab parentState={this.state}
                                                  setParent={(state) => this.setState({state})} {...this.props} />
                                    }/>
                                    <Route exact path='/banking/settings' component={() =>
                                        <SettingsTab parentState={this.state}
                                                     setParent={(state) => this.setState({state})} {...this.props} />
                                    }/>
                                </Switch>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BankingContainer));