import React, {Component} from "react"
import Fancy from "../../../modules/fancy/Fancy";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {request} from "../../../util/Util";

const grid = 5;
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "lightgray",

    // styles we need to apply on draggables
    ...draggableStyle
});

class OptionsModal extends Component {
    state = {options: []};

    open(options) {
        this.setState({options});
        this.modal.open();
    }

    saveOrder() {
        let {options} = this.state;
        let {product, customization} = this.props;

        request(`product/${product.ID}/customization/${customization.ID}/seq`, "POST", {
            ITEMS: options.map((item, i) => ({
                OPTION_ID: item.ID,
                SEQ: i + 1
            }))
        }).then(() => {
            this.modal.close().then(() => {
                if (product.ID === -1) {
                    let presets = this.props.partner.presets;
                    let customizations = presets.find((item) => item.ID === customization.ID);

                    options.forEach((item, i) => {
                        customizations.OPTIONS.find((_item) => item.ID === _item.ID).SEQ = i + 1;
                    });

                    this.props.updatePresets(presets);
                } else {
                    let products = this.props.partner.products;
                    let customizations = products.find((item) => item.ID === product.ID).CUSTOMIZATIONS;
                    let index = customizations.findIndex((item) => item.ID === customization.ID);
                    let actual = customizations[index];

                    options.forEach((item, i) => {
                        actual.OPTIONS.find((_item) => item.ID === _item.ID).SEQ = i + 1;
                    });

                    this.props.updateProducts(products);
                    alert("Ordering saved");
                }
            });
        });
    }

    reorderFunc(info) {
        let {options} = this.state;

        if (!info.destination) {
            return options;
        }

        const [removed] = options.splice(info.source.index, 1);
        options.splice(info.destination.index, 0, removed);

        this.setState({options});
        return options;
    };

    render() {
        let {options} = this.state;

        return (
            <Fancy name="Order Options" ref={(e) => this.modal = e} onClick={this.saveOrder.bind(this)}
                   buttonText="Save">
                <div className="g-mb-20" style={{height: 300, overflowY: "scroll"}}>
                    <DragDropContext onDragEnd={this.reorderFunc.bind(this)}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {
                                        options.map((item, index) => (
                                            <Draggable key={item.ID} draggableId={item.ID} index={index}>
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef}
                                                         {...provided.draggableProps}
                                                         {...provided.dragHandleProps}
                                                         style={getItemStyle(
                                                             snapshot.isDragging,
                                                             provided.draggableProps.style
                                                         )}
                                                    >
                                                        {item.NAME}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))
                                    }
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </Fancy>
        )
    }
}

export default OptionsModal;