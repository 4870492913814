import React, {Component} from "react"
import {Calendar, momentLocalizer} from "react-big-calendar";
import 'react-big-calendar/lib/css/react-big-calendar.css';

let moment = require("moment");
const localizer = momentLocalizer(moment);

class HoursScreen extends Component {
    render() {
        return (
            <div className="g-pa-20">
                <Calendar style={{minHeight: 500}}
                          localizer={localizer}
                          events={[]}
                          selectable={true}
                          onSelectEvent={event => alert(event.title)}
                          onSelectSlot={() => {}}

                />
            </div>
        )
    }
}

export default HoursScreen;