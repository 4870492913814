import {Products} from "drip-drop"
import moment from "moment"

export const TRANSACTION_TYPES = {
    MOBILE: 0,
    CARD: 1,
    CASH: 2,
    TAB: 3,
    GIFT_CARD: 4,
    OTHER: 5,
    INVOICE: 6,
    REWARD: 7,
    SPLIT: 8
};

export const PLATFORMS = {
    MOBILE: 0,
    REGISTER: 1,
    KIOSK: 2,
    WEB: 3
};

export const EMPLOYEE_TYPES = [
    {label: "Salaried", value: "SAL"},
    {label: "Hourly", value: "HOUR"}
];

export const EMPLOYEE_STATUS = [
    {label: "Full Time (30+ Hours Per Week)", value: "FT"},
    {label: "Part Time (20-29 Hours Per Week)", value: "PT1"},
    {label: "Part Time (0-19 Hours Per Week)", value: "PT2"},
    {label: "Variable (Hours Vary Each Week)", value: "VAR"},
];

export const EMPLOYEE_NEW_HIRE = [
    {label: "Yes", value: 1},
    {label: "No", value: 0}
];

export const FORM_TYPES = {
    "I9": "Federal",
    "W4": "Federal",
    "I9-VER": "Federal",
    "CUSTOM": "Internal"
};

export const FORM_DISPLAY_NAMES = {
    "W4": "Federal Tax - Form W4",
    "I9": "Employment Eligibility - Form I9",
    "I9-VER": "Employment Eligibility - Verification File",
    "CUSTOM": "Custom Form"
};

export const DISCOUNT_TYPES = [
    {label: "Flat Amount", value: 0},
    {label: "Percentage", value: 1},
];

export const MOBILE_OPEN_OFFSETS = [
    {label: "None", value: 0},
    {label: "15 Minutes After Opened", value: 1000 * 60 * 15},
    {label: "30 Minutes After Opened", value: 1000 * 60 * 30},
    {label: "1 Hour After Opened", value: 1000 * 60 * 60},
    {label: "1 Hour 30 Minutes After Opened", value: 1000 * 60 * 90},
    {label: "2 Hours After Opened", value: 1000 * 60 * 120},
];

export const MOBILE_CLOSE_OFFSETS = [
    {label: "None", value: 0},
    {label: "15 Minutes Before Closed", value: 1000 * 60 * 15},
    {label: "30 Minutes Before Closed", value: 1000 * 60 * 30},
    {label: "1 Hour Before Closed", value: 1000 * 60 * 60},
    {label: "1 Hour 30 Minutes Before Closed", value: 1000 * 60 * 90},
    {label: "2 Hours Before Closed", value: 1000 * 60 * 120},
];

export const BANK_TYPE = [
    {label: "Individual", value: "individual"},
    {label: "Company", value: "company"}
];

export const CARDHOLDER_TYPES = [
    {label: "Individual", value: "individual"},
    {label: "Business", value: "business_entity"}
];

export const MCC_TYPES = [
    {label: "Eating Places, Restaurants", value: "5812"},
    {label: "Bakeries", value: "5462"},
    {label: "Drinking Places", value: "5813"},
    {label: "Dairy Products Stores", value: "5451"},
    {label: "Candy, Nut, and Confectionery Stores", value: "5441"},
    {label: "Miscellaneous Food Stores - Convenience Stores and Specialty Markets", value: "5499"},
];

export const PRODUCT_AVAILABILITY_TYPES = [
    {label: "Everyday", value: Products.Constants.AVAILABILITY_TYPES.EVERYDAY},
    {label: "Weekdays", value: Products.Constants.AVAILABILITY_TYPES.WEEKDAYS},
    {label: "Weekends", value: Products.Constants.AVAILABILITY_TYPES.WEEKENDS},
    {label: "Day of Week", value: Products.Constants.AVAILABILITY_TYPES.DAY_OF_WEEK},
];

export const PRODUCT_AVAILABILITY_COMPARISONS = [
    {label: "All Day", value: 2},
    {label: "Before", value: 0},
    {label: "After", value: 1}
];

const _QUARTER_HOURS = () => {
    let start = 0;
    let values = [];

    while (start <= 1000 * 60 * 60 * 24) {
        values.push({label: moment().startOf("day").add(start, "milliseconds").format("hh:mm a"), value: start});

        start += 1000 * 60 * 15;
    }

    return values;
};

export const QUARTER_HOURS = _QUARTER_HOURS();

export const ISO_WEEK_DAYS = [
    {label: "Monday", value: 0},
    {label: "Tuesday", value: 1},
    {label: "Wednesday", value: 2},
    {label: "Thursday", value: 3},
    {label: "Friday", value: 4},
    {label: "Saturday", value: 5},
    {label: "Sunday", value: 6},
];

export const PAYOUT_TRANSFER_SCHEDULE = [
    {label: "Daily", value: "daily"},
    {label: "Weekly", value: "weekly"},
    {label: "Custom", value: "manual"}
];

export const PAYOUT_TRANSFER_TIME = [
    {label: "12AM EST", value: 0},
    {label: "1AM EST", value: 60},
    {label: "2AM EST", value: 60 * 2},
    {label: "3AM EST", value: 60 * 3},
    {label: "4AM EST", value: 60 * 4},
    {label: "5AM EST", value: 60 * 5},
    {label: "6AM EST", value: 60 * 6},
    {label: "7AM EST", value: 60 * 7},
    {label: "8AM EST", value: 60 * 8},
    {label: "9AM EST", value: 60 * 9},
    {label: "10AM EST", value: 60 * 10},
    {label: "11AM EST", value: 60 * 11},
    {label: "12PM EST", value: 60 * 12},
    {label: "1PM EST", value: 60 * 13},
    {label: "2PM EST", value: 60 * 14},
    {label: "3PM EST", value: 60 * 15},
    {label: "4PM EST", value: 60 * 16},
    {label: "5PM EST", value: 60 * 17},
    {label: "6PM EST", value: 60 * 18},
    {label: "7PM EST", value: 60 * 19},
    {label: "8PM EST", value: 60 * 20},
    {label: "9PM EST", value: 60 * 21},
    {label: "10PM EST", value: 60 * 22},
    {label: "11PM EST", value: 60 * 23},
];

export const PAYOUT_TRANSFER_SPEED = [
    {label: "1-2 Business Days", value: 0},
    {label: "Instant", value: 1},
];

export const DISCOUNT_TYPE = [
    {label: "Flat Rate", value: 0},
    {label: "Percentage", value: 1}
];

export const PROMOTION_TYPE = [
    {label: "Full Order", value: 0},
    {label: "Specific Product", value: 1},
    {label: "Whole Category", value: 2},
];

export const CAMPAIGN_TYPE = [
    {label: "Location Specific", value: 0},
    {label: "Cross Locations", value: 1}
];
