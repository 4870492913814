import React, {Component} from 'react'
import Table from "../../modules/Table";
import Fancy from "../../modules/fancy/Fancy";
import 'bootstrap-daterangepicker/daterangepicker.css';
import {decimalToDollars, formatDate, request, toDollars} from "../../util/Util";
import {addNotification, ALERT_INFO} from "../../util/NotificationUtil";
import ReorderModal from "./modules/ReorderModal";
import CategoriesModal from "./modules/CategoriesModal";
import CorkButton from "../../modules/CorkButton";
import {FancyText} from "corky";
import FancySelect from "../../modules/fancy/FancySelect";
import {Products} from "drip-drop";
import {DISCOUNT_TYPES} from "../../util/Constants";

const FIELDS = {
    NAME: "discount-name",
    LOGO: "discount-logo",
    TYPE: "discount-type",
    CONTENT: "discount-content"
};

class DiscountsScreen extends Component {
    state = {
        id: -1,
        name: "",
        appEnabled: true,
        products: []
    };

    constructor(props) {
        super(props);

        this.form = {};
    }

    edit(item) {
        this.setState({
            id: item.ID
        });

        this.form = {
            [FIELDS.NAME]: item.NAME,
            [FIELDS.CONTENT]: item.TYPE === 0 ? toDollars(item.CONTENT) : item.CONTENT,
            [FIELDS.LOGO]: item.LOGO,
            [FIELDS.TYPE]: item.TYPE,
        };

        this.fancy.open();
    }

    remove(item) {
        if (!window.confirm("Are you sure you want to delete this category: " + item.NAME + "?")) {
            return;
        }

        let ID = Math.floor(Math.random() * 1000000);
        request(`category/${item.ID}`, "DELETE", {}).then(() => {
            this.props.removeCategory(item.ID);

            addNotification.call(this, "Category Delete", "You have successfully deleted the category " + item.NAME, ALERT_INFO);
        }).catch(() => {
            addNotification.call(this, "Category Deletion Error", "We were not able to delete your category. Try again in a little.", ALERT_INFO);
        });
    }

    validFields() {
        let isValid = true;
        for (let field of this.fields) {
            if (field === null || !field.isValid) {
                continue;
            }

            if (!field.isValid()) {
                isValid = false;
            }
        }

        return isValid;
    }

    createDiscount() {
        if (!this.validFields()) {
            return
        }

        let {discounts} = this.props.partner;
        let params = {
            NAME: this.form[FIELDS.NAME],
            TYPE: this.form[FIELDS.TYPE].value,
            CONTENT: this.form[FIELDS.TYPE].value === 0 ? decimalToDollars(this.form[FIELDS.CONTENT]) : this.form[FIELDS.CONTENT],
            LOGO: "appicon.png"
        };

        request("partner/discount", "POST", params).then((dRow) => {
            params.ID = dRow;
            discounts.push(params);

            this.fancy.close().then(() => {
                this.props.updateDiscounts(discounts);
            })
        })
    }

    editDiscount() {
        if (!this.validFields()) {
            return
        }

        let {id} = this.state;
        let {discounts} = this.props.partner;
        let params = {
            NAME: this.form[FIELDS.NAME],
            TYPE: this.form[FIELDS.TYPE].value,
            CONTENT: this.form[FIELDS.TYPE].value === 0 ? decimalToDollars(this.form[FIELDS.CONTENT]) : this.form[FIELDS.CONTENT],
            LOGO: "appicon.png"
        };

        request("partner/discount/" + id, "PUT", params).then(() => {
            params.ID = id;

            let index = discounts.findIndex((item) => item.ID === id);
            discounts.splice(index, 1, params);

            this.fancy.close().then(() => {
                this.props.updateDiscounts(discounts);
            });
        })
    }

    deleteDiscount() {
        if (!window.confirm("Are you sure you want to delete this discount?")) {
            return;
        }

        let {id} = this.state;
        let {discounts} = this.props.partner;

        request("partner/discount/" + id, "DELETE", {}).then(() => {
            let index = discounts.findIndex((item) => item.ID === id);
            discounts.splice(index, 1);

            this.fancy.close().then(() => {
                this.props.updateDiscounts(discounts);
            });
        })
    }

    render() {
        let {id} = this.state;
        let {discounts} = this.props.partner;

        this.fields = [];
        let data = discounts.map((item) => {
            return {
                ID: item.ID,
                NAME: (
                    <span style={{color: "#4ACE9C", fontWeight: "bold"}} className="show-cursor"
                          onClick={this.edit.bind(this, item)}>
                        {item.NAME}
                    </span>
                ),
                TYPE: item.TYPE === 0 ? DISCOUNT_TYPES[0].label : DISCOUNT_TYPES[1].label,
                CONTENT: item.TYPE === 0 ? "$" + toDollars(item.CONTENT) : item.CONTENT + "%",
                onDelete: () => this.setState({id: item.ID}, () => this.deleteDiscount()),
                onEdit: this.edit.bind(this, item)
            }
        });

        return (
            <div>
                <Fancy name={id === -1 ? "Add Discount" : "Edit Discount"} ref={(e) => this.fancy = e}
                       buttonText={id === -1 ? "Create" : "Save"}
                       onDelete={id === -1 ? undefined : this.deleteDiscount.bind(this)}
                       onClick={() => id === -1 ? this.createDiscount() : this.editDiscount()}>

                    <div className="g-mb-20">

                        <FancyText label="Name" form={this.form} id={FIELDS.NAME} required={true}
                                   ref={(e) => this.fields.push(e)}/>
                    </div>

                    <div className="g-mb-20 row">
                        <FancySelect label="Type" options={DISCOUNT_TYPES} form={this.form} id={FIELDS.TYPE}
                                     ref={(e) => this.fields.push(e)} value={DISCOUNT_TYPES[0]} half={true}/>

                        <FancyText label="Value (Dollars/Percent of 100)" form={this.form} id={FIELDS.CONTENT}
                                   required={true}
                                   ref={(e) => this.fields.push(e)} half={true}/>
                    </div>
                </Fancy>

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                                Discounts
                            </h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton onClick={() => this.fancy.open()}>
                                New Discount
                            </CorkButton>
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <Table header={{
                            NAME: {
                                NAME: "Name",
                                WIDTH: 50,
                                SORT: "ID"
                            },
                            TYPE: {
                                NAME: "Type",
                                WIDTH: 25,
                            },
                            CONTENT: {
                                NAME: "Value",
                                WIDTH: 25
                            }
                        }} sort="NAME" delete={true} edit={true} data={data} desc={false}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default DiscountsScreen;
