import React, {Component} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {formatDate, request} from "../../util/Util";
import {addNotification, ALERT_INFO} from "../../util/NotificationUtil";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import ReorderModal from "./modules/ReorderModal";
import CategoriesModal from "./modules/CategoriesModal";
import CorkButton from "../../modules/CorkButton";
import {CorkTable, Fancy} from "corky"

class CategoriesScreen extends Component {
    state = {
        id: -1,
        name: "",
        appEnabled: true,
        autoFill: false,
        products: []
    };

    constructor(props) {
        super(props);

        this.lastClick = new Date().getTime();
    }

    edit(item) {
        this.setState({
            id: item.ID,
            name: item.NAME,
            appEnabled: item.APP_ENABLED === 1,
            autoFill: item.AUTO_COMPLETE === 1
        });

        this.fancy.open();
    }

    remove(item) {
        if (!window.confirm("Are you sure you want to delete this category: " + item.NAME + "?")) {
            return;
        }

        request(`category/${item.ID}`, "DELETE", {}).then(() => {
            this.props.removeCategory(item.ID);

            addNotification.call(this, "Category Deleted", "You have successfully deleted the category " + item.NAME, ALERT_INFO);
        }).catch(() => {
            addNotification.call(this, "Category Deletion Error", "We were not able to delete your category. Try again in a little.", ALERT_INFO);
        });
    }

    create() {
        let {name, appEnabled, autoFill} = this.state;
        let {categories} = this.props.partner;

        request("category", "POST", {
            NAME: name,
            APP_ENABLED: appEnabled,
            AUTO_COMPLETE: autoFill
        }, (err, payload) => {
            if (err) {
                alert("Something went wrong");
                return;
            }

            this.fancy.close().then(() => {
                this.props.updateCategories([...categories, payload]);

                this.setState({name: "", appEnabled: true, AUTO_COMPLETE: false});
            });
        });
    }

    putPush() {
        let {id, name, appEnabled, autoFill} = this.state;
        let {categories} = this.props.partner;

        request("category/" + id, "PUT", {
            NAME: name,
            APP_ENABLED: appEnabled,
            AUTO_COMPLETE: autoFill
        }, (err) => {
            if (err) {
                alert("Something went wrong");
                return;
            }

            categories.some((item, i) => {
                if (item.ID === id) {
                    categories.splice(i, 1, {
                        ID: id,
                        NAME: name,
                        APP_ENABLED: appEnabled,
                        AUTO_COMPLETE: autoFill
                    });

                    return true;
                }
            });

            this.props.updateCategories(categories);
            this.setState({name: "", appEnabled: true, autoFill: false});
        })
    }

    render() {
        let {categories, products} = this.props.partner;
        let {id, name, appEnabled, autoFill} = this.state;

        let data = categories.map((item) => {
            return {
                ID: item.ID,
                SEQ: item.SEQ,
                ENABLED: item.APP_ENABLED ? "Shown" : "Hidden",
                AUTO_FILL: item.AUTO_COMPLETE ? "Auto Completing" : "Manual",
                NAME: (
                    <span style={{color: "#4ACE9C", fontWeight: "bold"}} className="show-cursor" onClick={this.edit.bind(this, item)}>
                        {item.NAME}
                    </span>
                ),
                PRODUCTS: products.filter((_item) => _item.CATEGORY_ID === item.ID).length,
                onDelete: this.remove.bind(this, item)
            }
        });

        return (
            <div>
                <CategoriesModal ref={(e) => this.categories = e} {...this.props}/>
                <ReorderModal ref={(e) => this.reorder = e} {...this.props}/>
                <Fancy name="Create Category" ref={(e) => this.fancy = e} buttonText={id === -1 ? "Create" : "Edit"}
                       onClick={() => id === -1 ? this.create() : this.putPush()} buttons={id === -1 ? <div /> : (
                    <button onClick={() => {
                        this.fancy.close().then(() => {
                            this.reorder.open(id)
                        })
                    }}
                            className="btn btn-xl u-btn-secondary g-brd-around g-brd-lightbrown g-bg-lightbrown g-width-160--md g-color-white g-font-size-14 g-mr-15"
                            type="submit">
                        Edit Ordering
                    </button>
                )}
                >
                    <div className="g-mb-20">
                        <label className="g-mb-10" for="#bio">Name</label>

                        <input
                            className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-primary--focus g-brd-red--error g-rounded-4 g-px-20 g-py-12"
                            type="text" value={name} onChange={(e) => this.setState({name: e.target.value})}
                            aria-invalid="false"/>
                    </div>

                    <div onClick={() => {
                        if (this.lastClick + 100 > new Date().getTime()) {
                            return;
                        }

                        this.lastClick = new Date().getTime();
                        this.setState({appEnabled: !appEnabled})
                    }}
                         className="form-group d-flex align-items-center justify-content-between g-mb-20 show-cursor">
                        <span>Enabled on customer app</span>
                        <label className="u-check mb-0 g-ml-10">
                            <input className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0"
                                   type="checkbox" checked={appEnabled} onChange={() => {}}
                            />
                            <div
                                className="u-check-icon-radio-v8 u-check-icon--md u-check-icon--lightblue-v3">
                                <i className="fa"/>
                            </div>
                        </label>
                    </div>

                    <div onClick={() => {
                        if (this.lastClick + 100 > new Date().getTime()) {
                            return;
                        }

                        this.lastClick = new Date().getTime();
                        this.setState({autoFill: !autoFill})
                    }}
                         className="form-group d-flex align-items-center justify-content-between g-mb-20 show-cursor">
                        <span>Auto complete items in this category</span>
                        <label className="u-check mb-0 g-ml-10">
                            <input className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0"
                                   type="checkbox" checked={autoFill} onChange={() => {}}
                            />
                            <div
                                className="u-check-icon-radio-v8 u-check-icon--md u-check-icon--lightblue-v3">
                                <i className="fa"/>
                            </div>
                        </label>
                    </div>
                </Fancy>

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">Categories</h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton type={CorkButton.TYPES.OUTLINE} marginLeft onClick={() => this.setState({id: -1}, () => this.categories.open())}>
                                Order Categories
                            </CorkButton>
                            <CorkButton marginLeft onClick={() => this.setState({id: -1}, () => this.setState({
                                name: "",
                                appEnabled: true,
                                autoFill: false
                            }, () => this.fancy.open()))}>
                                New Category
                            </CorkButton>
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <CorkTable header={{
                            NAME: {
                                NAME: "Name",
                                WIDTH: 40
                            },
                            ENABLED: {
                                NAME: "Shown on App",
                                WIDTH: 15,
                            },
                            AUTO_FILL: {
                                NAME: "Auto Complete Tickets",
                                WIDTH: 15,
                            },
                            PRODUCTS: {
                                NAME: "Number of Products",
                                WIDTH: 15
                            }
                        }} sort="ID" delete={true} data={data} desc={false}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default CategoriesScreen;
