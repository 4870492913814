export const UPDATE_REDEEM = 'UPDATE_REDEEM';


// Partner Stuff
export const UPDATE_PARTNER = 'UPDATE_PARTNER';
export const UPDATE_OVERSEER = 'UPDATE_OVERSEER';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const UPDATE_BEVERAGES = 'UPDATE_BEVERAGES';
export const UPDATE_NEWS = 'UPDATE_NEWS';
export const UPDATE_PUSHES = 'UPDATE_PUSHES';
export const UPDATE_BAR_BEVERAGES = 'UPDATE_BAR_BEVERAGES';
export const UPDATE_SECTIONS = 'UPDATE_SECTIONS';
export const UPDATE_ANNOUNCEMENTS = 'UPDATE_ANNOUNCEMENTS';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const UPDATE_ORDERS = 'UPDATE_ORDERS';
export const UPDATE_HOURS = 'UPDATE_HOURS';
export const UPDATE_PRESETS = 'UPDATE_PRESETS';
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY';
export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const UPDATE_POST = 'UPDATE_POST';
export const REMOVE_POST = 'REMOVE_POST';
export const UPDATE_EMPLOYEES = 'UPDATE_EMPLOYEES';
export const UPDATE_SHIFT = 'UPDATE_SHIFT';
export const UPDATE_VENDORS = 'UPDATE_VENDORS';
export const UPDATE_INVOICES = 'UPDATE_INVOICES';
export const UPDATE_TIMESHEETS = 'UPDATE_TIMESHEETS';
export const UPDATE_PAYOUTS = 'UPDATE_PAYOUTS';
export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_INFO = 'UPDATE_INFO';
export const UPDATE_RAW_PRESETS = 'UPDATE_RAW_PRESETS';
export const UPDATE_CHECKLISTS = 'UPDATE_CHECKLISTS';
export const UPDATE_REPORTING = 'UPDATE_REPORTING';
export const UPDATE_CHECKLIST_INSTANCES = 'UPDATE_CHECKLIST_INSTANCES';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_ROLES = 'UPDATE_ROLES';
export const UPDATE_SETUP_FORMS = 'UPDATE_SETUP_FORMS';
export const UPDATE_DISCOUNTS = 'UPDATE_DISCOUNTS';
export const UPDATE_CLOSING = 'UPDATE_CLOSING';
export const UPDATE_PRODUCT_AVAILABILITY = 'UPDATE_PRODUCT_AVAILABILITY';
export const UPDATE_PROMOTIONS = 'UPDATE_PROMOTIONS';
export const UPDATE_COUPONS = 'UPDATE_COUPONS';
export const UPDATE_DEVICES = 'UPDATE_DEVICES';
export const UPDATE_WEBSITE = 'UPDATE_WEBSITE';
export const UPDATE_TEXT_CAMPAIGNS = 'UPDATE_TEXT_CAMPAIGNS';
export const UPDATE_EMAIL_CAMPAIGNS = 'UPDATE_EMAIL_CAMPAIGNS';

export function updateRedeem(credentials) {
    return {
        type: UPDATE_REDEEM,
        payload: credentials
    }
}

export function updatePromotions(credentials) {
    return {
        type: UPDATE_PROMOTIONS,
        payload: credentials
    }
}

export function updateCoupons(credentials) {
    return {
        type: UPDATE_COUPONS,
        payload: credentials
    }
}

export function updatePartner(credentials) {
    return {
        type: UPDATE_PARTNER,
        payload: credentials
    }
}

export function updateOverseer(credentials) {
    return {
        type: UPDATE_OVERSEER,
        payload: credentials
    }
}

export function updateLocation(credentials) {
    return {
        type: UPDATE_LOCATION,
        payload: credentials
    }
}

export function updateBeverages(credentials) {
    return {
        type: UPDATE_BEVERAGES,
        payload: credentials
    }
}

export function updateNews(credentials) {
    return {
        type: UPDATE_NEWS,
        payload: credentials
    }
}

export function updatePushes(credentials) {
    return {
        type: UPDATE_PUSHES,
        payload: credentials
    }
}

export function updateBarBeverages(credentials) {
    return {
        type: UPDATE_BAR_BEVERAGES,
        payload: credentials
    }
}

export function updateSections(credentials) {
    return {
        type: UPDATE_SECTIONS,
        payload: credentials
    }
}

export function updateAnnouncements(credentials) {
    return {
        type: UPDATE_ANNOUNCEMENTS,
        payload: credentials
    }
}

export function updateProducts(credentials) {
    return {
        type: UPDATE_PRODUCTS,
        payload: credentials
    }
}

export function updateCategories(credentials) {
    return {
        type: UPDATE_CATEGORIES,
        payload: credentials
    }
}

export function updateOrders(credentials) {
    return {
        type: UPDATE_ORDERS,
        payload: credentials
    }
}

export function updateHours(credentials) {
    return {
        type: UPDATE_HOURS,
        payload: credentials
    }
}

export function updatePresets(credentials) {
    return {
        type: UPDATE_PRESETS,
        payload: credentials
    }
}

export function addError(credentials) {
    return {
        type: ADD_ERROR,
        payload: credentials
    }
}

export function removeError(credentials) {
    return {
        type: REMOVE_ERROR,
        payload: credentials
    }
}


export function removeCategory(credentials) {
    return {
        type: REMOVE_CATEGORY,
        payload: credentials
    }
}

export function updatePost(credentials) {
    return {
        type: UPDATE_POST,
        payload: credentials
    }
}

export function removePost(credentials) {
    return {
        type: REMOVE_POST,
        payload: credentials
    }
}

export function updateEmployees(credentials) {
    return {
        type: UPDATE_EMPLOYEES,
        payload: credentials
    }
}

export function addEmployee(employee) {
    return (dispatch, getState) => {
        let {employees} = getState().partner;

        dispatch(updateEmployees([employee, ...employees]));
    }
}

export function findEmployee(id) {
    return (dispatch, getState) => {
        let {employees} = getState().partner;

        return employees.find((item) => item.ID === id);
    }
}

export function updateShifts(credentials) {
    return {
        type: UPDATE_SHIFT,
        payload: credentials
    }
}

export function addShifts(newShifts) {
    return (dispatch, getState) => {
        let {shifts} = getState().partner;

        dispatch(updateShifts([...newShifts, ...shifts]))
    }
}

export function editShift(shift) {
    return (dispatch, getState) => {
        let {shifts} = getState().partner;

        let index = shifts.findIndex((item) => item.ID === shift.ID);
        shifts.splice(index, 1, shift);

        dispatch(updateShifts(shifts))
    }
}

export function updateInvoices(credentials) {
    return {
        type: UPDATE_INVOICES,
        payload: credentials
    }
}

export function updateVendors(credentials) {
    return {
        type: UPDATE_VENDORS,
        payload: credentials
    }
}

export function updateTimesheets(credentials) {
    return {
        type: UPDATE_TIMESHEETS,
        payload: credentials
    }
}

export function addTimeCard(card) {
    return (dispatch, getState) => {
        let {timesheets} = getState().partner;

        dispatch(updateTimesheets([...timesheets, card]))
    }
}

export function updatePayouts(credentials) {
    return {
        type: UPDATE_PAYOUTS,
        payload: credentials
    }
}

export function updateLoading(credentials) {
    return {
        type: UPDATE_LOADING,
        payload: credentials
    }
}

export function updateInfo(credentials) {
    return {
        type: UPDATE_INFO,
        payload: credentials
    }
}

export function updateRawPresets(credentials) {
    return {
        type: UPDATE_RAW_PRESETS,
        payload: credentials
    }
}

export function updateChecklists(credentials) {
    return {
        type: UPDATE_CHECKLISTS,
        payload: credentials
    }
}

export function updateReporting(credentials) {
    return {
        type: UPDATE_REPORTING,
        payload: credentials
    }
}


export function updateChecklistInstances(credentials) {
    return {
        type: UPDATE_CHECKLIST_INSTANCES,
        payload: credentials
    }
}

export function updateSettings(credentials) {
    return {
        type: UPDATE_SETTINGS,
        payload: credentials
    }
}

export function updateRoles(credentials) {
    return {
        type: UPDATE_ROLES,
        payload: credentials
    }
}

export function addRole(role) {
    return (dispatch, getState) => {
        let {roles} = getState().partner;

        dispatch(updateRoles([role, ...roles]));
    }
}

export function editRole(role) {
    return (dispatch, getState) => {
        let {roles} = getState().partner;

        let index = roles.findIndex((item) => item.ID === role.ID);
        roles.splice(index, 1, role);

        dispatch(updateRoles(roles));
    }
}

export function updateSetupForms(credentials) {
    return {
        type: UPDATE_SETUP_FORMS,
        payload: credentials
    }
}

export function updateDiscounts(credentials) {
    return {
        type: UPDATE_SETUP_FORMS,
        payload: credentials
    }
}

export function updateDevices(credentials) {
    return {
        type: UPDATE_DEVICES,
        payload: credentials
    }
}

export function updateClosings(credentials) {
    return {
        type: UPDATE_CLOSING,
        payload: credentials
    }
}

export function updateWebsite(credentials) {
    return {
        type: UPDATE_WEBSITE,
        payload: credentials
    }
}

export function updateProductAvailability(credentials) {
    return {
        type: UPDATE_PRODUCT_AVAILABILITY,
        payload: credentials
    }
}

export function updateEmailCampaigns(credentials) {
    return {
        type: UPDATE_EMAIL_CAMPAIGNS,
        payload: credentials
    }
}

export function updateTextCampaigns(credentials) {
    return {
        type: UPDATE_TEXT_CAMPAIGNS,
        payload: credentials
    }
}

export const UPDATE_OTHER_PAYMENTS = 'UPDATE_OTHER_PAYMENTS';
export function updateOtherPayments(credentials) {
    return {
        type: UPDATE_OTHER_PAYMENTS,
        payload: credentials
    }
}

export function addOtherPayments(other) {
    return (dispatch, getState) => {
        let {otherPayments} = getState().partner;

        dispatch(updateOtherPayments([other, ...otherPayments]));
    }
}

export function editOtherPayments(other) {
    return (dispatch, getState) => {
        let {otherPayments} = getState().partner;

        let index = otherPayments.findIndex((item) => item.ID === other.ID);
        otherPayments.splice(index, 1, other);

        dispatch(updateOtherPayments(otherPayments));
    }
}

export function updateTextCampaign(campaign) {
    return (dispatch, getState) => {
        let {textCampaigns} = getState().partner;

        let index = textCampaigns.findIndex((item) => item.ID === campaign.ID);
        textCampaigns.splice(index, 1, campaign);

        dispatch(updateTextCampaigns(textCampaigns));
    }
}

export function updateEmailCampaign(campaign) {
    return (dispatch, getState) => {
        let {emailCampaigns} = getState().partner;

        let index = emailCampaigns.findIndex((item) => item.ID === campaign.ID);
        emailCampaigns.splice(index, 1, campaign);

        dispatch(updateEmailCampaigns(emailCampaigns));
    }
}
