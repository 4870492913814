import React, {Component} from 'react'

class CorkDrop extends Component {
    constructor(props) {
        super(props);

        this.test =0;
    }

    render() {
        let Divs = this.props.items.map((item) => {
            return (
                <li data-original-index={0} className="selected">
                    <a tabIndex={0} className onClick={() => {
                        this.props.onChange(item.VALUE);
                    }}
                       data-tokens="null"
                       role="option"
                       aria-disabled="false"
                       aria-selected="true">
                        <span className="text">{item.NAME}</span>
                        <span className="glyphicon glyphicon-ok check-mark"/>
                    </a>
                </li>
            )
        });

        let Options = this.props.items.map((item) => {
            return (
                <option value={item.VALUE} selected="selected">{item.NAME}</option>
            );
        });

        let Button = (
            <button type="button" className="btn dropdown-toggle btn-default" data-toggle="dropdown"
                    role="button" title="Male" aria-expanded="false">
                        <span className="filter-option pull-left">
                                {this.props.value === null ? this.props.label : this.props.items.find((item) => this.props.value === item.VALUE).NAME}
                            </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span
                    className="bs-caret"><span className="caret"/></span></button>
        );

        return (
            <div className="form-group g-pos-rel g-brd-gray-light-v7 g-rounded-4 mb-0">
                <span className="g-pos-abs g-top-0 g-right-0 d-block g-width-40 h-100 opacity-0 g-opacity-1--success">
                    <i className="hs-admin-check g-absolute-centered g-font-size-default g-color-secondary"/>
                </span>

                <div className="btn-group bootstrap-select js-select u-select--v2-select w-100">
                    {Button}

                    <div className="dropdown-menu open" role="combobox" x-placement="bottom-start" style={{
                        maxHeight: '312.125px',
                        overflow: 'hidden',
                        minHeight: '0px',
                        position: 'absolute',
                        transform: 'translate3d(1px, 37px, 0px)',
                        top: '0px',
                        left: '0px',
                        willChange: 'transform'
                    }}>
                        <ul className="dropdown-menu inner" role="listbox" aria-expanded="false"
                            style={{maxHeight: '310.125px', overflowY: 'auto', minHeight: '0px'}}>
                            {Divs}
                        </ul>
                    </div>
                    <select className="w-100" required="required" onChange={(e) => {
                        this.props.onChange(e.target.value);
                    }} style={{display: 'none'}} value={this.props.value} tabIndex={-98} aria-required="true">
                        {Options}
                    </select></div>
                <i className="hs-admin-angle-down g-absolute-centered--y g-right-0 g-color-gray-light-v6 ml-auto g-mr-15"/>
            </div>
        );
    }
}

export default CorkDrop;