import React, {Component} from 'react'
import ReactTooltip from "./FormItem";
import PropTypes from "prop-types";

class FormTextBox extends Component {
    render() {
        let Tooltip = <div/>;
        let {placeholder} = this.props;

        if (this.props.tooltip) {
            Tooltip = (
                <i style={{marginLeft: 5}} className="fa fa-info-circle" data-tip={this.props.tooltip}/>
            );
        }

        return (
            <div className="row g-mb-20">
                <div className="col-md-3 align-self-center g-mb-5 g-mb-0--md">
                    <label className="mb-0">{this.props.name}</label>

                    {Tooltip}
                </div>

                <div className="col-md-9 align-self-center">
                    <div className="form-group g-pos-rel mb-0 has-success">
                        <textarea name="firstName"
                                  className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-primary--focus g-brd-red--error g-rounded-4 g-px-20 g-py-12"
                                  rows={this.props.rows ? this.props.rows : 2} value={this.props.value}
                                  disabled={this.props.disabled ? "disabled" : false}
                                  onChange={(e) => {
                                      if (typeof this.props.onChange !== "undefined") {
                                          this.props.onChange(e.target.value);
                                      }
                                  }} aria-invalid="false" placeholder={placeholder}/>
                    </div>
                </div>
            </div>
        )
    }
}
FormTextBox.propTypes = {
    placeholder: PropTypes.string,
};
export default FormTextBox;
