import React, {Component} from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {formatDate, request} from "../../util/Util";
import Letter from "./modules/Letter";
import CorkButton from "../../modules/CorkButton";

class LettersScreen extends Component {
    render() {
        let {letters} = this.props.partner;

        let Letters = letters.map((item, i) => {
            return (
                <Letter key={i} item={item} {...this.props} onClick={() => {
                }}/>
            )
        });

        return (
            <div>
                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">News Letter</h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton onClick={() => {
                            }}>
                                Create Letter
                            </CorkButton>
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        {Letters}
                    </div>
                </div>
            </div>
        )
    }
}

export default LettersScreen;
