import React, {Component} from 'react'
import {CorkTable, FancySelect, FancyText} from "corky";
import {COLORS, request, toDollars} from "../../../util/Util";
import moment from "moment";
import CorkButton from "../../../modules/CorkButton";
import {
    MCC_TYPES,
    PAYOUT_TRANSFER_SCHEDULE,
    PAYOUT_TRANSFER_SPEED,
    PAYOUT_TRANSFER_TIME
} from "../../../util/Constants";
import {sendError, sendSuccess} from "../../../helpers/NotificationHelper";
import BankModal from "../../shop/tabs/modals/BankModal";
import Select from "react-select";

class SettingsTab extends Component {
    state = {schedule: null, schedules: [], pending: null, speed: true, speedValue: PAYOUT_TRANSFER_SPEED[0]};

    componentDidMount() {
        let {account} = this.props.parentState;

        this.setState({
            schedule: PAYOUT_TRANSFER_SCHEDULE.find(({value}) => value === account.settings.payouts.schedule.interval),
            schedules: account.schedules, bank: account.settings.payments.statement_descriptor
        });
    }

    check({value}) {
        let {location} = this.props.partner;
        let schedules = [];

        if (value === "manual") {
            schedules = [
                {LOCATION_ID: location.ID, ISO_DAY: 0, TIME: 60 * 20, SPEED: 0, DESTINATION: null},
                {LOCATION_ID: location.ID, ISO_DAY: 1, TIME: 60 * 20, SPEED: 0, DESTINATION: null},
                {LOCATION_ID: location.ID, ISO_DAY: 2, TIME: 60 * 20, SPEED: 0, DESTINATION: null},
                {LOCATION_ID: location.ID, ISO_DAY: 3, TIME: 60 * 20, SPEED: 0, DESTINATION: null},
                {LOCATION_ID: location.ID, ISO_DAY: 4, TIME: 60 * 20, SPEED: 0, DESTINATION: null},
                {LOCATION_ID: location.ID, ISO_DAY: 5, TIME: 60 * 20, SPEED: 0, DESTINATION: null},
                {LOCATION_ID: location.ID, ISO_DAY: 6, TIME: 60 * 20, SPEED: 0, DESTINATION: null},
            ]
        } else {
            schedules = [];
        }

        this.setState({schedules})
    }

    save() {
        let {schedules, schedule, bank} = this.state;

        if (!/^(?=[^a-z]*[a-z])(?:(?![<>\\'"])[\x00-\x7F]){5,22}$/i.test(bank)) {
            return this.bank.setState({error: "Bank descriptor cannot contain the following special characters < > \\ ' \" *"})
        }

        this.saveButton.startLoading();
        request("partner/connect/account", "PATCH", {
            MCC: this.mcc.getValue().value,
            DESCRIPTOR: bank,
            PAYOUTS: schedule.value,
            SCHEDULES: schedules
        }).then(() => {
            sendSuccess("Information Updated", "Your banking information has been updated!");
        }).catch(() => {
            sendError("Internal Error", "There was an internal error trying to update your banking information.");
        }).finally(() => {
            this.saveButton.stopLoading();
        })
    }

    renderBanks() {
        let {banks} = this.props.parentState;

        return banks.map((item, i) => (
            <div className="row">
                <div className="col-md-5">
                    <FancyText disabled={true} value={item.bank_name} label={i === 0 ? "Bank Name" : undefined}
                               customIcon={(
                                   <span style={{color: COLORS.DRIP_GREEN, fontWeight: "bold"}}
                                         className="g-pos-abs g-top-0 g-right-0 d-block g-width-50 h-100 show-cursor forceCenterNav">
                            Edit
                        </span>
                               )} labelStyle={{fontWeight: "bold"}}/>
                </div>

                <div className="col-md-3">
                    <FancyText disabled={true} value={item.routing_number} labelStyle={{fontWeight: "bold"}}
                               label={i === 0 ? "Routing Number" : undefined}/>
                </div>

                <div className="col-md-3">
                    <FancyText disabled={true} value={"**** " + item.last4} labelStyle={{fontWeight: "bold"}}
                               label={i === 0 ? "Account Number" : undefined}/>
                </div>
            </div>
        ))
    }

    render() {
        let {sources, account} = this.props.parentState;
        let {speedValue, schedule, schedules, bank} = this.state;

        return (
            <div>
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <BankModal ref={(e) => this.bankModal = e}
                               add={(bank) => {
                                   if (sources.length === 0) {
                                       sources[0].default = true;
                                   }

                                   this.setState({sources: [...sources, bank]})
                               }}/>

                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                                Settings
                            </h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton onClick={() => this.save()} ref={(e) => this.saveButton = e}>
                                Save Changes
                            </CorkButton>
                        </div>
                    </div>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    {this.renderBanks()}

                    <a className="btn btn-link d-flex align-self-center align-items-center u-link-v5 g-color-primary g-color-primary--hover  g-brd-none p-0"
                       style={{fontWeight: "bold"}} onClick={() => this.bankModal.open()}>
                        <i style={{fontWeight: "bold"}} className="hs-admin-plus g-font-size-14"/>

                        <span style={{fontWeight: "bold"}} className="g-ml-10">Add Account</span>
                    </a>

                    <header style={{marginTop: 60}}>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            Bank Statements
                        </h2>
                    </header>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-25--md"/>

                    <div className="row">
                        <div className="col-md-5">
                            <FancySelect label="Bank Statement Category" options={MCC_TYPES} ref={(e) => this.mcc = e}
                                         labelStyle={{fontWeight: "bold"}}
                                         tooltip="The category that will show up on customer's bank statement"
                                         defaultValue={MCC_TYPES.find((item) => item.value === account.business_profile.mcc)}/>
                        </div>

                        <div className="col-md-7">
                            <FancyText label="Bank Statement Descriptor" labelStyle={{fontWeight: "bold"}}
                                       ref={(e) => this.bank = e}
                                       value={bank} required={true} min={5} max={22} onChange={(bank) => this.setState({bank})}
                                       tooltip="The descriptor that will show up on a customer's bank statement"
                                       />
                        </div>
                    </div>

                    <header style={{marginTop: 40}}>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            Transfer Schedule
                        </h2>
                    </header>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-25--md"/>

                    <div className="row">
                        <div className="col-md-4">
                            <FancySelect label="Transfer Schedule" options={PAYOUT_TRANSFER_SCHEDULE}
                                         labelStyle={{fontWeight: "bold"}} ref={(e) => this.payouts = e}
                                         parentState={this.state} name="schedule" onChange={(val) => this.check(val)}
                                         setParent={(state, callback) => this.setState(state, callback)}
                            />
                        </div>

                        <div className="col-md-4">
                            <FancySelect label="Transfer Speed" options={PAYOUT_TRANSFER_SPEED} disabled
                                         labelStyle={{fontWeight: "bold"}} ref={(e) => this.speed = e}
                                         onChange={(val) => this.setState({speedValue: val})}
                                         defaultValue={PAYOUT_TRANSFER_SPEED[0]}/>
                        </div>
                    </div>

                    <CorkTable noHeader noPadding hidePaging ref={(e) => this.table = e} settings={this.state} header={{
                        NAME: {
                            NAME: "Day",
                            WIDTH: 20,
                            SORT: "ID"
                        },
                        TIME: {
                            NAME: "Transfer Time",
                            WIDTH: 30,
                            TYPE: "String"
                        },
                        SPEED: {
                            NAME: "Transfer Speed",
                            WIDTH: 30,
                            TYPE: "String"
                        },
                        ARRIVE: {
                            NAME: "Arrives in Bank",
                            WIDTH: 20,
                            TYPE: "String"
                        }
                    }} sort="NAME" data={[
                        {
                            ID: 0,
                            NAME: "Monday",
                            TIME: (
                                <Select isDisabled={!schedule || schedule.value !== "manual"} styles={{
                                    container: () => ({maxWidth: 200}),
                                }} options={PAYOUT_TRANSFER_TIME}
                                        onChange={({value}) => {
                                            schedules[0].TIME = value

                                            this.setState({schedules})
                                        }}
                                        value={schedules.length === 0 ? null : PAYOUT_TRANSFER_TIME.find(({value}) => schedules[0].TIME === value)}
                                        menuPortalTarget={document.body}/>
                            ),
                            SPEED: (
                                <Select options={PAYOUT_TRANSFER_SPEED} value={speedValue} styles={{
                                    menuPortal: base => ({...base, zIndex: 99999}),
                                    menuList: () => ({maxHeight: 150, overflowY: "scroll"}),
                                }} menuPortalTarget={document.body} isDisabled={true}/>
                            ),
                            ARRIVE: "Tuesday/Wednesday"
                        },
                        {
                            ID: 1,
                            NAME: "Tuesday",
                            TIME: (
                                <Select isDisabled={!schedule || schedule.value !== "manual"} styles={{
                                    container: () => ({maxWidth: 200}),
                                }} options={PAYOUT_TRANSFER_TIME}
                                        onChange={({value}) => {
                                            schedules[1].TIME = value

                                            this.setState({schedules})
                                        }}
                                        value={schedules.length === 0 ? null : PAYOUT_TRANSFER_TIME.find(({value}) => schedules[1].TIME === value)}
                                        menuPortalTarget={document.body}/>
                            ),
                            SPEED: (
                                <Select options={PAYOUT_TRANSFER_SPEED} value={speedValue} styles={{
                                    menuPortal: base => ({...base, zIndex: 99999}),
                                    menuList: () => ({maxHeight: 150, overflowY: "scroll"}),
                                }} menuPortalTarget={document.body} isDisabled={true}/>
                            ),
                            ARRIVE: "Tuesday/Wednesday"
                        },
                        {
                            ID: 2, NAME: "Wednesday", TIME: (
                                <Select isDisabled={!schedule || schedule.value !== "manual"} styles={{
                                    container: () => ({maxWidth: 200}),
                                }} options={PAYOUT_TRANSFER_TIME}
                                        onChange={({value}) => {
                                            schedules[2].TIME = value

                                            this.setState({schedules})
                                        }}
                                        value={schedules.length === 0 ? null : PAYOUT_TRANSFER_TIME.find(({value}) => schedules[2].TIME === value)}
                                        menuPortalTarget={document.body}/>
                            ), SPEED: (
                                <Select options={PAYOUT_TRANSFER_SPEED} value={speedValue} styles={{
                                    menuPortal: base => ({...base, zIndex: 99999}),
                                    menuList: () => ({maxHeight: 150, overflowY: "scroll"}),
                                }} menuPortalTarget={document.body} isDisabled={true}/>
                            ), ARRIVE: "Thursday/Friday"
                        },
                        {
                            ID: 3, NAME: "Thursday", TIME: (
                                <Select isDisabled={!schedule || schedule.value !== "manual"} styles={{
                                    container: () => ({maxWidth: 200}),
                                }} options={PAYOUT_TRANSFER_TIME}
                                        onChange={({value}) => {
                                            schedules[3].TIME = value

                                            this.setState({schedules})
                                        }}
                                        value={schedules.length === 0 ? null : PAYOUT_TRANSFER_TIME.find(({value}) => schedules[3].TIME === value)}
                                        menuPortalTarget={document.body}/>
                            ), SPEED: (
                                <Select options={PAYOUT_TRANSFER_SPEED} value={speedValue} styles={{
                                    menuPortal: base => ({...base, zIndex: 99999}),
                                    menuList: () => ({maxHeight: 150, overflowY: "scroll"}),
                                }} menuPortalTarget={document.body} isDisabled={true}/>
                            ), ARRIVE: "Friday/Monday"
                        },
                        {
                            ID: 4, NAME: "Friday", TIME: (
                                <Select isDisabled={!schedule || schedule.value !== "manual"} styles={{
                                    container: () => ({maxWidth: 200}),
                                }} options={PAYOUT_TRANSFER_TIME}
                                        onChange={({value}) => {
                                            schedules[4].TIME = value

                                            this.setState({schedules})
                                        }}
                                        value={schedules.length === 0 ? null : PAYOUT_TRANSFER_TIME.find(({value}) => schedules[4].TIME === value)}
                                        menuPortalTarget={document.body}/>
                            ), SPEED: (
                                <Select options={PAYOUT_TRANSFER_SPEED} value={speedValue} styles={{
                                    menuPortal: base => ({...base, zIndex: 99999}),
                                    menuList: () => ({maxHeight: 150, overflowY: "scroll"}),
                                }} menuPortalTarget={document.body} isDisabled={true}/>
                            ), ARRIVE: "Monday/Tuesday"
                        },
                        {
                            ID: 5, NAME: "Saturday", TIME: (
                                <Select isDisabled={!schedule || schedule.value !== "manual"} styles={{
                                    container: () => ({maxWidth: 200}),
                                }} options={PAYOUT_TRANSFER_TIME}
                                        onChange={({value}) => {
                                            schedules[5].TIME = value

                                            this.setState({schedules})
                                        }}
                                        value={schedules.length === 0 ? null : PAYOUT_TRANSFER_TIME.find(({value}) => schedules[5].TIME === value)}
                                        menuPortalTarget={document.body}/>
                            ), SPEED: (
                                <Select options={PAYOUT_TRANSFER_SPEED} value={speedValue} styles={{
                                    menuPortal: base => ({...base, zIndex: 99999}),
                                    menuList: () => ({maxHeight: 150, overflowY: "scroll"}),
                                }} menuPortalTarget={document.body} isDisabled={true}/>
                            ), ARRIVE: "Monday/Tuesday"
                        },
                        {
                            ID: 6, NAME: "Sunday", TIME: (
                                <Select isDisabled={!schedule || schedule.value !== "manual"} styles={{
                                    container: () => ({maxWidth: 200}),
                                }} options={PAYOUT_TRANSFER_TIME}
                                        onChange={({value}) => {
                                            schedules[6].TIME = value

                                            this.setState({schedules})
                                        }}
                                        value={schedules.length === 0 ? null : PAYOUT_TRANSFER_TIME.find(({value}) => schedules[6].TIME === value)}
                                        menuPortalTarget={document.body}/>
                            ), SPEED: (
                                <Select options={PAYOUT_TRANSFER_SPEED} value={speedValue} styles={{
                                    menuPortal: base => ({...base, zIndex: 99999}),
                                    menuList: () => ({maxHeight: 150, overflowY: "scroll"}),
                                }} menuPortalTarget={document.body} isDisabled={true}/>
                            ), ARRIVE: "Monday/Tuesday"
                        },
                    ]} desc={false}/>
                </div>
            </div>
        );
    }
}


export default SettingsTab;
