export const ALERT_INFO = 1;
export const ALERT_WARNING = 2;
export const ALERT_ERROR = 3;

/**
 *
 * @param title
 * @param message
 * @param type
 * @param [timeout]
 */
export function addNotification(title, message, type, timeout) {
    let ID = Math.floor(Math.random() * 1000000);

    this.props.addError({
        ID: ID,
        SAVE: false,
        TITLE: title,
        MESSAGE: message,
        TYPE: type
    });

    setTimeout(() => {
        this.props.removeError(ID);
    }, timeout || 3500);
}