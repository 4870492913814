import React, {Component} from 'react'
import FormItem from "../../../../modules/form/FormItem";
import CorkButton from "../../../../modules/CorkButton";
import FormDropdown from "../../../../modules/form/FormDropdown";
import {CAMPAIGN_TYPE, QUARTER_HOURS} from "../../../../util/Constants";
import moment from "moment"
import FormTextBox from "../../../../modules/form/FormTextBox";
import FormDate from "../../../../modules/form/FormDate";
import {request} from "../../../../util/Util";

class InformationTab extends Component {
    state = {name: "", subject: "", type: null, date: null, time: null};

    componentDidMount() {
        let {campaign: {NAME: name, SUBJECT: subject, TYPE: type, DATE_SCHEDULED: dateTime}} = this.props;

        let date = moment(dateTime).startOf("day").valueOf();
        this.setState({
            name, date, subject, time: QUARTER_HOURS.find(({value}) => value === (dateTime - date)),
            type: CAMPAIGN_TYPE.find(({value}) => value === type)
        });
    }

    async save() {
        let {name, date, subject, time, type} = this.state;
        let {campaign} = this.props;

        let promotionPayload = {
            NAME: name, DATE_SCHEDULED: moment(date).startOf("day").add(time.value, "milliseconds").valueOf(),
            TYPE: type.value, SUBJECT: subject
        };

        this.saveButton.startLoading();
        let serverCampaign = await request("marketing/email_campaigns/" + campaign.ID, "PATCH", promotionPayload);

        this.props.updateEmailCampaign(serverCampaign);
        // this.saveButton.stopLoading();
    }

    render() {
        let {date, name, time, type, subject} = this.state;
        let {campaign} = this.props;

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <form className="js-validate" onSubmit={(e) => e.preventDefault()}>
                        <header>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                    Personal information
                                </h2>

                                <CorkButton ref={(e) => this.saveButton = e} onClick={this.save.bind(this)}>
                                    Save Changes
                                </CorkButton>
                            </div>
                        </header>

                        <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                        <FormItem name="Campaign Name" value={name} onChange={(name) => this.setState({name})}
                                  disabled={campaign.DATE_EXECUTED !== null}
                        />

                        <FormDropdown name="Campaign Type" value={type} data={CAMPAIGN_TYPE}
                                      disabled={campaign.DATE_EXECUTED !== null}
                                      onChange={(type) => this.setState({type})}/>

                        <FormItem name="Email Subject" value={subject} onChange={(subject) => this.setState({subject})}
                                  disabled={campaign.DATE_EXECUTED !== null}
                        />

                        <FormDropdown name="Send Time" value={time} data={QUARTER_HOURS}
                                      disabled={campaign.DATE_EXECUTED !== null}
                                      onChange={(time) => this.setState({time})}/>

                        <FormDate name="Send Date" value={date} disabled={campaign.DATE_EXECUTED !== null}
                                  onChange={(date) => this.setState({date})}/>
                    </form>
                </div>
            </div>
        )
    }
}

export default InformationTab;
