import React, {Component} from 'react'
import {COLORS, request} from "../../../util/Util";
import Fancy from "../../../modules/fancy/Fancy";
import CorkDrop from "../../../modules/CorkDrop";
import Customization from "../modules/Customization";
import CustomizationsModal from "../modules/CustomizationsModal";
import CreateCustomizationModal from "../modules/CreateCustomizationModal";
import CorkButton from "../../../modules/CorkButton";
import {FancySelect} from "corky"
import {sendSuccess} from "../../../helpers/NotificationHelper";
import {Products} from "drip-drop"

const FIELDS = {
    ADD_PRESET: "add-preset-preset"
};

class CustomizationsTab extends Component {
    state = {
        name: "",
        preset: -1,
        type: 0,
        required: false
    };

    constructor(props) {
        super(props);

        this.addPresetForm = {};
    }

    componentDidMount() {
        if (this.props.partner.presets.length > 0) {
            this.setState({preset: this.props.partner.presets[0].ID});
        }
    }

    remove(id, rawItem) {
        const {ID} = this.props.match.params;
        if (!window.confirm("Are you sure you want to remove this customization?")) {
            return;
        }

        let {products, rawPresets} = this.props.partner;
        let productIndex = products.findIndex((item) => item.ID === parseInt(ID));
        let customizations = products[productIndex].CUSTOMIZATIONS;
        let index = customizations.findIndex((item) => item.ID === id);

        if (index === -1) {
            request(`preset/${id}/${parseInt(ID)}/remove`, "POST", {}, (err) => {
                if (err) {
                    alert("ERROR DELETING ON SERVER");
                    return;
                }

                let presetIndex = rawPresets.findIndex((item) => item.CUSTOMIZATION_ID === id && item.PRODUCT_ID === parseInt(ID));
                rawPresets.splice(presetIndex, 1);

                this.props.updateRawPresets(rawPresets);

                sendSuccess(rawItem.NAME + " Removed", rawItem.NAME + " was removed as a modifier");
            });
        } else {
            request("customization/" + id, "DELETE", {}, (err) => {
                if (err) {
                    alert("ERROR SAVING ON SERVER");
                    return;
                }

                customizations.splice(index, 1);
                products.splice(productIndex, 1, {
                    ...products[productIndex],
                    CUSTOMIZATIONS: customizations
                });

                this.props.updateProducts(products);

                sendSuccess(rawItem.NAME + " Removed", rawItem.NAME + " was removed as a modifier");
            });
        }
    }

    updateProduct(product) {
        let products = this.props.partner.products;
        let index = products.findIndex((item) => item.ID === product.ID);

        products.splice(index, 1, product);

        this.props.updateProducts(products);
    }

    addPreset() {
        const {ID} = this.props.match.params;
        let form = this.addPresetForm;

        let preset = form[FIELDS.ADD_PRESET].value;
        request("preset/" + preset + "/product/" + ID, "POST", {}, (err, payload) => {
            if (err) {
                alert("Error adding preset");
                return;
            }

            this.presetFancy.close().then(() => {
                this.props.updateRawPresets([...this.props.partner.rawPresets, {
                    ID: payload,
                    PRODUCT_ID: parseInt(ID),
                    CUSTOMIZATION_ID: preset
                }]);
            });
        });
    }

    render() {
        const {ID} = this.props.match.params;
        let {presets} = this.props.partner;
        this.addPresetRefs = [];

        let product = JSON.parse(JSON.stringify(this.props.partner.products.find((item) => parseInt(ID) === item.ID)));
        let rawPresets = this.props.partner.rawPresets.filter((item) => item.PRODUCT_ID === parseInt(ID));

        for (let rawPreset of rawPresets) {
            let customization = this.props.partner.presets.find((item) => item.ID === rawPreset.CUSTOMIZATION_ID);
            customization = JSON.parse(JSON.stringify(customization));
            customization.SEQ = rawPreset.SEQ;
            customization.IS_PRESET = true;

            product.CUSTOMIZATIONS.push(customization);
        }

        product.CUSTOMIZATIONS.sort((a, b) => (a.SEQ === 0 ? 1000000 : a.SEQ) - (b.SEQ === 0 ? 10000 : b.SEQ));
        let Customizations = product.CUSTOMIZATIONS.filter((item) => {
            return item.TYPE !== Products.Constants.CUSTOMIZATION_TYPES.VARIANT && !item.PRESET_ID
        }).map((item) => {
            return <Customization item={item} product={product} id={ID} {...this.props} isPreset={item.IS_PRESET}
                                  updateProduct={this.updateProduct.bind(this)}
                                  remove={() => this.remove(item.ID, item)}/>
        });

        let addPresets = presets.filter((item) => rawPresets.findIndex((_item) => _item.CUSTOMIZATION_ID === item.ID) === -1);
        let addOptions = addPresets.filter((item) => {
            return item.TYPE !== Products.Constants.CUSTOMIZATION_TYPES.VARIANT
        }).map((item) => {
            return {value: item.ID, label: item.INTERNAL_NAME !== null ? item.INTERNAL_NAME : item.NAME}
        });

        return (
            <div className="col-md-9 g-brd-around g-brd-gray-light-v7">
                <CustomizationsModal ref={(e) => this.order = e} product={product} {...this.props}/>
                <CreateCustomizationModal ref={(e) => this.createCustomization = e} product={product} {...this.props} />

                <Fancy name="Add Global Modifier" ref={(e) => this.presetFancy = e} buttonText="Add"
                       onClick={this.addPreset.bind(this)}>
                    <FancySelect options={addOptions} form={this.addPresetForm} label="Name"
                                 ref={(e) => this.addPresetRefs.push(e)} id={FIELDS.ADD_PRESET}
                                 value={addOptions.length > 0 ? addOptions[0] : null}/>
                </Fancy>

                <div className="row" style={{display: "flex", marginRight: 1, marginTop: 20}}>
                    <div style={{flex: 1}}/>

                    <CorkButton onClick={() => this.order.open(product.CUSTOMIZATIONS)} marginRight>
                        Order Modifiers
                    </CorkButton>

                    <CorkButton onClick={() => this.createCustomization.open()} marginRight>
                        Add Modifier
                    </CorkButton>

                    <CorkButton onClick={() => this.presetFancy.open()}>
                        Add Global Modifier
                    </CorkButton>
                </div>

                <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>
                <div style={{marginTop: 10}}>
                    {Customizations}
                </div>
            </div>
        )
    }
}

export default CustomizationsTab;