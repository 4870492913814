import React, {Component} from "react"
import Fancy from "../../../modules/fancy/Fancy";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {request} from "../../../util/Util";
import {Products} from "drip-drop";

const grid = 5;
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "lightgray",

    // styles we need to apply on draggables
    ...draggableStyle
});

class CustomizationsModal extends Component {
    state = {customizations: []};

    open(customizations) {
        this.setState({
            customizations: customizations.filter((item) => {
                return !item.PRESET_ID && item.TYPE !== Products.Constants.CUSTOMIZATION_TYPES.VARIANT;
            })
        }, () => this.modal.open());
    }

    saveOrder() {
        let {customizations} = this.state;
        let {product} = this.props;

        request(`product/${product.ID}/seq`, "POST", {
            ITEMS: customizations.map((item, i) => ({
                CUSTOMIZATION_ID: item.ID,
                SEQ: i + 1
            }))
        }).then(() => {
            this.modal.close().then(() => {
                let {rawPresets, products} = this.props.partner;
                let index = products.findIndex((item) => item.ID === product.ID);
                let actual = products[index];

                customizations.forEach((item, i) => {
                    if (item.PRESET === 1) {
                        rawPresets.find((_item) => _item.CUSTOMIZATION_ID === item.ID).SEQ = i + 1;
                    } else {
                        actual.CUSTOMIZATIONS.find((_item) => item.ID === _item.ID).SEQ = i + 1;
                    }
                });

                this.props.updateProducts(products);
                this.props.updateRawPresets(rawPresets);
            });
        });
    }

    reorderFunc(info) {
        let {customizations} = this.state;

        if (!info.destination) {
            return customizations;
        }

        const [removed] = customizations.splice(info.source.index, 1);
        customizations.splice(info.destination.index, 0, removed);

        this.setState({customizations});
        return customizations;
    };

    render() {
        let {customizations} = this.state;

        customizations = customizations.filter((item) => {
            return !item.PRESET_ID && item.TYPE !== Products.Constants.CUSTOMIZATION_TYPES.VARIANT;
        })

        return (
            <Fancy name="Order Modifiers" ref={(e) => this.modal = e} onClick={this.saveOrder.bind(this)}
                   buttonText="Save">
                <div className="g-mb-20" style={{height: 300, overflowY: "scroll"}}>
                    <DragDropContext onDragEnd={this.reorderFunc.bind(this)}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {
                                        customizations.map((item, index) => (
                                            <Draggable key={item.ID} draggableId={item.ID} index={index}>
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef}
                                                         {...provided.draggableProps}
                                                         {...provided.dragHandleProps}
                                                         style={getItemStyle(
                                                             snapshot.isDragging,
                                                             provided.draggableProps.style
                                                         )}
                                                    >
                                                        {item.NAME}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))
                                    }
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </Fancy>
        )
    }
}

export default CustomizationsModal;