import React, {Component} from "react"
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {bindActionCreators} from "redux";
import Actions from "../../actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

const grid = 8
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});

class CategoryScreen extends Component {
    state = {products: []};

    componentWillReceiveProps(nextProps, nextContext) {
        const {ID} = this.props.match.params;

        let products = nextProps.partner.products.filter((item) => item.CATEGORY_ID === parseInt(ID));
        this.setState({products});
        console.log("HERE");
    }

    reorder(info) {
        let {products} = this.state;

        const [removed] = products.splice(info.source.index, 1);
        products.splice(info.destination.index, 0, removed);

        this.setState({products});
        return products;
    };


    render() {
        let {products} = this.state;

        return (
            <DragDropContext onDragEnd={this.reorder.bind(this)}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {
                                products.map((item, index) => (
                                    <Draggable key={item.ID} draggableId={item.ID} index={index}>
                                        {(provided, snapshot) => (
                                            <div ref={provided.innerRef}
                                                 {...provided.draggableProps}
                                                 {...provided.dragHandleProps}
                                                 style={getItemStyle(
                                                     snapshot.isDragging,
                                                     provided.draggableProps.style
                                                 )}
                                            >
                                                {item.NAME}
                                            </div>
                                        )}
                                    </Draggable>
                                ))
                            }
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryScreen));