import React, {Component} from 'react'
import TutorialContainer from "./../modules/TutorialContainer";
import TutorialCheckbox from "./../modules/TutorialCheckbox";
import Modal from "../../../modules/Modal";

class TipSection extends Component {
    renderModals() {
        return (
            <div>
                <Modal title="Let's get started!" ref={e => this.test = e} buttons={[{
                    text: "Test", onClick: (modal) => {
                    }
                }]}>
                    Welcome to the Drip tutorial. Click on each element that isn't selected to start completing it.
                </Modal>

                <Modal title="Create a Category" ref={e => this.category = e} buttons={[{
                    text: "Bring me there", onClick: (modal) => {
                        modal.close();
                        window.open(window.location.origin + "/categories");
                    }
                }]}>
                    The first thing we need todo is create a category for our items.
                </Modal>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.renderModals()}

                <TutorialContainer title="Doing things faster" subtitle="Let's setup our shop!">
                    <TutorialCheckbox text="Create a Preset" checked={true}
                                      onClick={() => this.test.show()}/>
                </TutorialContainer>
            </div>

        )
    }
}

export default TipSection;