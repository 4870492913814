import React, {Component} from "react"
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

class FormCheckbox extends Component {
    constructor(props) {
        super(props);

        this.lastSwitch = new Date().getTime();
    }

    render() {
        let {label, value} = this.props;
        let Tooltip = <div/>;
        if (this.props.tooltip) {
            Tooltip = (
                <i style={{marginLeft: 5}} className="fa fa-info-circle" data-tip={this.props.tooltip}/>
            );
        }

        return (
            <div className="row g-pl-15 g-pr-15 g-pb-10 show-cursor" style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between"
            }} onClick={(e) => {
                if (this.lastSwitch + 100 > new Date().getTime()) {
                    return;
                }

                this.lastSwitch = new Date().getTime();
                this.props.onChange();
            }}>
                <div>
                    <b>{label}</b>

                    {Tooltip}
                    <ReactTooltip/>
                </div>

                <label className="u-check">
                    <input className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0"
                           type="checkbox" checked={value}
                    />

                    <div
                        className="u-check-icon-radio-v8 u-check-icon--md u-check-icon--lightblue-v3">
                        <i className="fa"/>
                    </div>
                </label>
            </div>
        );
    }
}

FormCheckbox.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
}

export default FormCheckbox;