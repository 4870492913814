import React, {Component} from 'react'
import {request} from "../../util/Util";
import Fancy from "../../modules/fancy/Fancy";
import Customization from "../products/modules/Customization";
import CorkButton from "../../modules/CorkButton";
import {FancyText} from "corky";
import {Products} from "drip-drop";
import {sendSuccess} from "../../helpers/NotificationHelper";
import DescriptionScreen from "../../modules/DescriptionSection";

const FIELDS = {
    NAME: "customization-name",
    INTERNAL_NAME: "customization-internal-name",
    TYPE: "customization-type"
};

class VariantScreen extends Component {
    state = {
        name: "",
        type: 0,
        required: false
    };

    constructor(props) {
        super(props);

        this.form = {};
    }

    remove(id, rawItem) {
        if (!window.confirm("Are you sure you want to remove this customization?")) {
            return;
        }

        request("customization/" + id + "/preset", "DELETE", {}, (err) => {
            if (err) {
                alert("ERROR SAVING ON SERVER");
                return;
            }

            let {presets, rawPresets} = this.props.partner;
            let index = presets.findIndex((item) => item.ID === id);
            presets.splice(index, 1);

            rawPresets = rawPresets.filter((item) => item.CUSTOMIZATION_ID !== id);

            this.props.updateRawPresets(rawPresets);
            this.props.updatePresets(presets);

            sendSuccess(rawItem.NAME + " Removed", `The global modifier ${rawItem.NAME} was removed.`)
        });
    }

    add() {
        let isValid = true;
        for (let field of this.fields) {
            if (field === null) {
                continue;
            }

            if (!field.isValid()) {
                isValid = false;
            }
        }

        if (!isValid) {
            return;
        }
        let name = this.form[FIELDS.NAME], internal = this.form[FIELDS.INTERNAL_NAME];
        request("preset", "POST", {
            NAME: name,
            TYPE: Products.Constants.CUSTOMIZATION_TYPES.VARIANT,
            REQUIRED: 1,
            INTERNAL_NAME: internal ? internal : null
        }, (err, id) => {
            if (err) {
                alert("ERRPR");
                return;
            }

            this.fancy.close().then(() => {
                this.props.updatePresets([
                    ...this.props.partner.presets,
                    {
                        ID: id,
                        NAME: name,
                        TYPE: Products.Constants.CUSTOMIZATION_TYPES.VARIANT,
                        REQUIRED: 1,
                        OPTIONS: [],
                        INTERNAL_NAME: internal ? internal : null
                    }
                ]);

                this.setState({name: "", required: false});
            });
        });
    }

    render() {
        const {ID} = this.props.match.params;
        let {presets} = this.props.partner;
        let form = this.form;

        this.fields = [];
        let Customizations = presets.filter((item) => {
            return item.TYPE === Products.Constants.CUSTOMIZATION_TYPES.VARIANT
        }).map((item) => {
            return <Customization item={item} product={{ID: -1}} id={ID} {...this.props} variant={true}
                                  remove={() => this.remove(item.ID, item)}/>
        });

        return (
            <div className="col-md-12">
                <Fancy name="Create Variant" ref={(e) => this.fancy = e}
                       onClick={this.add.bind(this)}>
                    <div className="row g-mb-20">
                        <FancyText label="Name" form={form} id={FIELDS.NAME} required={true}
                                   ref={(e) => this.fields.push(e)} half={true}/>

                        <FancyText label="Internal Name" form={form} id={FIELDS.INTERNAL_NAME} required={false}
                                   ref={(e) => this.fields.push(e)} half={true}/>
                    </div>
                </Fancy>

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                                Global Variants
                            </h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton onClick={() => {
                                this.form[FIELDS.TYPE] = Products.Constants.CUSTOMIZATION_TYPE_FIELDS[0];
                                this.fancy.open()}}>
                                New Global Variant
                            </CorkButton>
                        </div>
                    </div>
                    <hr />
                    <DescriptionScreen
                        header="Universal Product Types"
                        content="Create global variants that sync across multiple products. Easily create
                        a variant set for things like drink sizes and apply to many products at once."
                        image="globalvariants.png"/>
                    <div style={{marginTop: 10}}>
                        {Customizations}
                    </div>

                    {/* <div className="row" style={{display: "flex", marginRight: 0, marginTop: 10}}>
                    <div style={{flex: 1}}/>

                    <CorkButton onClick={() => {
                        this.form[FIELDS.TYPE] = Products.Constants.CUSTOMIZATION_TYPE_FIELDS[0];
                        this.fancy.open()
                    }}>
                        Create Variant
                    </CorkButton>
                </div>

                <div style={{marginTop: 10}}>
                    {Customizations}
                </div>*/}
                </div>
            </div>
        )
    }
}

export default VariantScreen;