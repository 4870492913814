import React, {Component} from "react"
import DropZone from "../modules/DropZone";
import AdditionalDropZone from "../modules/AdditionalDropZone";
import {uploadImage} from "../../../util/Util";

class FormSection extends Component {
    canContinue() {
        if ((this.w4.getFile() === null || this.i9.getFile() === null) && (this.additional.getFiles().length > 2 || this.additional.getFiles().length === 0)) {
            return this.props.update(false);
        }

        for (let item of this.setupForms) {
            if (item === null) {
                continue;
            }

            if (item.getFile() === null) {
                return this.props.update(false);
            }
        }

        this.props.update(true);
    }

    tryStep() {
        return Promise.resolve();

        return new Promise(async (resolve, reject) => {
            try {
                await uploadImage("employees/form/W4", this.w4.getFile());
                await uploadImage("employees/form/I9", this.i9.getFile());

                for (let file of this.additional.getFiles()) {
                    await uploadImage("employees/form/I9-VER", file);
                }

                for (let file of this.setupForms) {
                    if (file === null) {
                        continue;
                    }

                    await uploadImage("employees/form/CUSTOM/" + file.getCustom(), file.getFile());
                }

                resolve();
            } catch (e) {
                alert("ERROR");
                reject(e);
            }
        });
    }

    renderOther() {
        let {setupForms} = this.props.partner;

        this.setupForms = [];
        if (setupForms.length === 0) {
            return;
        }

        return setupForms.map((item) => (
            <DropZone style={{marginLeft: 34}} type={item.NAME} ref={(e) => this.setupForms.push(e)} custom={item.ID}
                      download={"https://drip-drinks.s3.amazonaws.com/" + item.URL}
                      update={this.canContinue.bind(this)}/>
        ))
    }

    render() {
        return (
            <div style={{margin: "auto", maxWidth: 500}}>
                <div style={{marginTop: 64}}>
                    <div style={{fontSize: 24, fontWeight: "bold"}}>
                        Employment Forms
                    </div>
                </div>

                <div style={{marginTop: 12, display: "flex"}}>
                    <DropZone type="W4" ref={(e) => this.w4 = e} download="https://drip-drinks.s3.amazonaws.com/W4.pdf"
                              update={this.canContinue.bind(this)}/>

                    <div style={{width: 34}}/>

                    <DropZone type="I9" ref={(e) => this.i9 = e} download="https://drip-drinks.s3.amazonaws.com/I9.pdf"
                              update={this.canContinue.bind(this)}/>

                    {this.renderOther()}
                </div>

                <div style={{marginTop: 12, display: "flex"}}>
                    <AdditionalDropZone ref={(e) => this.additional = e} update={this.canContinue.bind(this)}/>
                </div>
            </div>
        );
    }
}

export default FormSection;