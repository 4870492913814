import React, {Component} from 'react'
import {CorkTable} from "corky";
import {parseIdDict, toDollars} from "../../../../util/Util";
import moment from "moment";

class PatronsTab extends Component {
    render() {
        let {patrons} = this.props.partner;

        let Data = patrons.map((item) => {
            return {
                PATRON: item.FULL_NAME,
                ACCOUNT_ID: item.ACCOUNT_ID,
                LAST_DATE: moment(item.DATE_UPDATED).format("MM/DD/YY hh:mma"),
                TIMESTAMP: item.DATE_UPDATED,
                POINTS: item.POINTS,
                ALL_ITEM: item.LIFETIME
            }
        });

        return (
            <div className="col-md-9">
                <CorkTable header={{
                    PATRON: {
                        NAME: "Patron",
                        WIDTH: 25,
                        LINK: "/patron/",
                        LINK_ID: "ACCOUNT_ID"
                    },
                    LAST_DATE: {
                        NAME: "Last Seen",
                        WIDTH: 25,
                        SORT: "TIMESTAMP"
                    },
                    POINTS: {
                        NAME: "Current Points",
                        WIDTH: 25,
                    },
                    ALL_TIME: {
                        NAME: "All Time Points",
                        WIDTH: 25
                    },
                }} sort="LAST_DATE" data={Data} desc={true}/>
            </div>
        );
    }
}


export default PatronsTab;