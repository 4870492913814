import React, {Component} from "react"
import CorkButton from "../../modules/CorkButton";

import FormSection from "./sections/FormSection";
import AccountSection from "./sections/AccountSection";
import TaxSection from "./sections/TaxSection";
import BankSection from "./sections/BankSection";
import {bindActionCreators} from "redux";
import Actions from "../../actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

const STEPS = {
    ACCOUNT: 0,
    TAX: 1,
    FORM: 2,
    PAY: 3
};

class SetupScreen extends Component {
    state = {step: STEPS.ACCOUNT, taxable: {}, account: {}, canContinue: true};

    componentWillMount() {
        // let {employee} = this.props.partner;
        //
        // if (employee === null) {
        //     return this.props.history.push('/');
        // }
        //
        // if (!employee || employee.STRIPE_ID !== null) {
        //     return this.props.history.push('/');
        // }
    }

    step() {
        let {canContinue, step} = this.state;
        // if (!canContinue) {
        //     return;
        // }

        this.sectionRef.tryStep().then(() => {
            this.setState({step: step + 1, canContinue: true});
        });
    }

    renderSteps() {
        let {step} = this.state;


        switch (step) {
            case STEPS.ACCOUNT:
                return (
                    <AccountSection update={(bool) => this.setState({canContinue: bool})}
                                    ref={(e) => this.sectionRef = e} {...this.props}/>
                );
            case STEPS.TAX:
                return <TaxSection update={(bool) => this.setState({canContinue: bool})}
                                   ref={(e) => this.sectionRef = e}/>
            case STEPS.FORM:
                return <FormSection update={(bool) => this.setState({canContinue: bool})}
                                    ref={(e) => this.sectionRef = e} {...this.props}/>
            case STEPS.PAY:
                return <BankSection update={(bool) => this.setState({canContinue: bool})}
                                    ref={(e) => this.sectionRef = e}/>
        }
    }

    render() {
        let {step, canContinue} = this.state;

        return (
            <div>
                <div style={{display: "flex", justifyContent: "center", marginTop: 45, marginBottom: 45}}>
                    <img src={require("./../../assets/drip.png")} height={45} alt="Drip"/>
                </div>

                <div style={{margin: "auto", maxWidth: 1000}}>
                    <div
                        style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <div style={{fontSize: 36, fontWeight: "bold"}}>
                            Team Member Setup
                        </div>

                        <CorkButton type={canContinue ? CorkButton.TYPES.GENERAL : CorkButton.TYPES.DISABLED}
                                    onClick={this.step.bind(this)}>
                            Continue
                        </CorkButton>
                    </div>

                    <div style={{display: "flex", marginTop: 30}}>
                        <div style={{
                            marginRight: 24,
                            flex: "1",
                            borderTop: step >= STEPS.ACCOUNT ? "5px solid #4ACE9C" : "5px solid #C6C6C6",
                            paddingTop: 18
                        }}>
                            1. Personal Information
                        </div>

                        <div style={{
                            marginRight: 24,
                            flex: "1",
                            borderTop: step >= STEPS.TAX ? "5px solid #4ACE9C" : "5px solid #C6C6C6",
                            paddingTop: 18
                        }}>
                            2. Taxing Information
                        </div>

                        <div style={{
                            marginRight: 24,
                            flex: "1",
                            borderTop: step >= STEPS.FORM ? "5px solid #4ACE9C" : "5px solid #C6C6C6",
                            paddingTop: 18
                        }}>
                            3. Employee Form
                        </div>

                        <div style={{
                            flex: "1",
                            borderTop: step >= STEPS.PAY ? "5px solid #4ACE9C" : "5px solid #C6C6C6",
                            paddingTop: 18
                        }}>
                            4. Pay
                        </div>
                    </div>
                </div>

                <div style={{marginBottom: 24}}>
                    {this.renderSteps()}
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SetupScreen));