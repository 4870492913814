import React, {Component} from "react"
import {Fancy, FancySelect, FancyText} from "corky";
import {request} from "../../../util/Util";
import {Team} from "drip-drop";

class OtherPaymentsModal extends Component {
    state = {
        id: -1, name: "", item: null
    };

    open(item) {
        if (item) {
            return this.setState({item, name: item.NAME,}, () => this.modal.open());
        }

        this.modal.open();
    }

    async createRole() {
        let {name} = this.state;

        let isValid = true;
        for (let field of this.fields) {
            if (field === null) {
                continue;
            }

            if (!field.isValid()) {
                isValid = false;
            }
        }

        if (!isValid) {
            return false;
        }

        this.modal.getCreateButton().startLoading();

        let serverRole = await request("other/payments", "POST", {NAME: name});

        await this.modal.getCreateButton().stopLoading();
        await this.modal.close();

        this.props.addOtherPayments(serverRole);
    }

    async saveRole() {
        let {name, item} = this.state;

        let isValid = true;
        for (let field of this.fields) {
            if (field === null) {
                continue;
            }

            if (!field.isValid()) {
                isValid = false;
            }
        }

        if (!isValid) {
            return false;
        }

        this.modal.getCreateButton().startLoading();

        let serverRole = await request("other/payments/" + item.ID, "PATCH", {NAME: name});

        await this.modal.getCreateButton().stopLoading();
        await this.modal.close();

        this.props.editOtherPayments(serverRole);
    }

    render() {
        this.fields = [];
        let {item} = this.state;

        return (
            <Fancy name={item !== null ? "Save Other Payment" : "Create Other Payment"}
                   onClick={item !== null ? this.saveRole.bind(this) : this.createRole.bind(this)}
                   buttonText={item !== null ? "Save" : "Create"} ref={(e) => this.modal = e}
            >
                <div className="g-mb-20">
                    <FancyText name="name" label="Name"
                               ref={(e) => this.fields.push(e)} required={true}
                               parentState={this.state} setParent={(state) => this.setState(state)}
                    />
                </div>
            </Fancy>
        )
    }
}

export default OtherPaymentsModal;