import React, {Component} from 'react';
import Fancy from "../../modules/fancy/Fancy";
import 'bootstrap-daterangepicker/daterangepicker.css';
import {formatDate, request, toDollars} from "../../util/Util";
import {CorkTable} from 'corky'
import moment from "moment";
import Select from "react-select";
import Checklist from "./modules/Checklist";
import CorkButton from "../../modules/CorkButton";
import DescriptionScreen from "../../modules/DescriptionSection";
import {addNotification, ALERT_INFO} from "../../util/NotificationUtil";

const ENABLED = <span
    className="u-tags-v1 text-center g-width-150--md g-brd-around g-bg-darkblue-v2 g-font-size-12 g-font-size-default--md g-color-white g-rounded-50 g-py-4 g-px-15">Enabled</span>
const DISABLED = <span
    className="u-tags-v1 text-center g-width-150--md g-brd-around g-bg-darkred g-font-size-12 g-font-size-default--md g-color-white g-rounded-50 g-py-4 g-px-15">Disabled</span>

const TIME_END = 1000 * 60 * 60 * 24;

class ChecklistsScreen extends Component {
    state = {
        id: -1,
        name: "",
        duration: 30,
        days: [],
        hours: []
    };

    componentWillMount() {
        this.setState({data: this.props.partner.products});
    }

    getSelectOptions() {
        let options = [];
        let start = moment().startOf("day").toDate().getTime();
        let offset = new Date().getTimezoneOffset() * 60 * 1000;

        const startConst = start;
        while (start + (1000 * 60 * 15) <= startConst + TIME_END) {
            options.push({label: moment(start).format("hh:mm A"), value: start - startConst + offset});

            if (options.length > 94) {
                break;
            }

            start += 1000 * 60 * 15;
        }

        return options;
    }

    createChecklist() {
        let {name, days, hours, duration} = this.state;
        let {checklists} = this.props.partner;

        if (name.length === 0 || days.length === 0 || hours.length === 0 || duration.length === 0) {
            return alert("Please fill out all fields");
        }

        hours = hours.map((item) => item.value);
        request("checklists", "POST", {NAME: name, DURATION: duration, DAYS: days, TIMES: hours}, (err, payload) => {
            if (err) {
                alert("Internal error");
                return;
            }

            this.fancy.close().then(() => {
                this.setState({name: ""}, () => {
                    this.props.updateChecklists([...checklists, {
                        ID: payload,
                        NAME: name,
                        DURATION: duration,
                        HOURLY: hours.join("|"),
                        WEEKLY: days.join("|"),
                        DATE_CREATED: new Date().getTime(),
                        DATE_UPDATED: new Date().getTime(),
                        LINES: []
                    }]);

                    this.props.history.push("/checklist/" + payload);
                });
            });
        });
    }

    toggleDay(i) {
        let {days} = this.state;
        let index = days.indexOf(i);

        if (index === -1) {
            days.push(i);
        } else {
            days.splice(index, 1);
        }

        this.setState({days});
    }

    renderInstances() {
        let {checklists, checklistInstances} = this.props.partner;

        let today = moment().format("MM/DD/YY");
        let checklistDict = checklists.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});

        let todaysLists = checklistInstances.filter((item) => moment(item.DATE_START_BY).format("MM/DD/YY") === today);
        if (todaysLists.length === 0) {
            return (
                <div>
                    <DescriptionScreen
                        header="No Active Lists Today"
                        content="Active checklists for each day will show up here. Create a new list by
                                clicking the button above or view/edit current lists below."
                        image="checklists.png"
                    />
                </div>
            );
        }

        return todaysLists.map((item) => {
            let checklist = checklistDict[item.CHECKLIST_ID];

            return (
                <Checklist checklist={checklist} instance={item} {...this.props}/>
            );
        })
    }

    render() {
        let {name, hours, duration} = this.state;
        let {checklists, checklistInstances} = this.props.partner;

        let Data = checklists.map((item) => {
            return {
                ID: item.ID,
                NAME: item.NAME
            }
        });

        checklistInstances.sort((a, b) => a.DATE_START_BY - b.DATE_START_BY);

        return (
            <div>
                <Fancy name="Create Checklist" ref={(e) => this.fancy = e}
                       onClick={this.createChecklist.bind(this)}
                >
                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Name</label>

                        <input name="firstName"
                               className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-primary--focus g-brd-red--error g-rounded-4 g-px-20 g-py-12"
                               type="text" value={name} onChange={(e) => this.setState({name: e.target.value})}
                               aria-invalid="false" placeholder="Ex: Morning Tasks"/>
                    </div>

                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Duration (in minutes)</label>

                        <input name="firstName"
                               className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-primary--focus g-brd-red--error g-rounded-4 g-px-20 g-py-12"
                               type="text" value={duration} onChange={(e) => this.setState({duration: e.target.value})}
                               aria-invalid="false" placeholder="Ex: 30"/>
                    </div>

                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Start Times</label>

                        <div className="form-group mb-0" style={{marginLeft: 0}}>
                            <Select menuPortalTarget={document.body} styles={{
                                menuPortal: base => ({...base, zIndex: 99999}),
                                menuList: () => ({maxHeight: 150, overflowY: "scroll"})
                            }}
                                    isMulti
                                    options={this.getSelectOptions()} value={hours}
                                    onChange={(hours) => this.setState({hours})}
                            />
                        </div>
                    </div>

                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Days</label>

                        <div className="col-md-9 align-self-center">
                            <div className="row g-mx-minus-10">
                                <div className="btn-group justified-content">
                                    <label className="u-check">
                                        <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="currency"
                                               onChange={this.toggleDay.bind(this, 1)} type="checkbox"/>
                                        <span
                                            className="btn btn-md btn-block u-btn-outline-lightgray g-bg-lightblue-v3--checked g-font-size-16 g-color-white--checked rounded-0 g-py-10">
                                            Mon
                                        </span>
                                    </label>
                                    <label className="u-check">
                                        <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="currency"
                                               onChange={this.toggleDay.bind(this, 2)} type="checkbox"/>
                                        <span
                                            className="btn btn-md btn-block u-btn-outline-lightgray g-bg-lightblue-v3--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                            Tue
                                        </span>
                                    </label>
                                    <label className="u-check">
                                        <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="currency"
                                               onChange={this.toggleDay.bind(this, 3)} type="checkbox"/>
                                        <span
                                            className="btn btn-md btn-block u-btn-outline-lightgray g-bg-lightblue-v3--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                            Wed
                                        </span>
                                    </label>
                                    <label className="u-check">
                                        <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="currency"
                                               onChange={this.toggleDay.bind(this, 4)} type="checkbox"/>
                                        <span
                                            className="btn btn-md btn-block u-btn-outline-lightgray g-bg-lightblue-v3--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                            Thu
                                        </span>
                                    </label>
                                    <label className="u-check">
                                        <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="currency"
                                               onChange={this.toggleDay.bind(this, 5)} type="checkbox"/>
                                        <span
                                            className="btn btn-md btn-block u-btn-outline-lightgray g-bg-lightblue-v3--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                            Fri
                                        </span>
                                    </label>
                                    <label className="u-check">
                                        <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="currency"
                                               onChange={this.toggleDay.bind(this, 6)} type="checkbox"/>
                                        <span
                                            className="btn btn-md btn-block u-btn-outline-lightgray g-bg-lightblue-v3--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                            Sat
                                        </span>
                                    </label>
                                    <label className="u-check">
                                        <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="currency"
                                               onChange={this.toggleDay.bind(this, 7)} type="checkbox"/>
                                        <span
                                            className="btn btn-md btn-block u-btn-outline-lightgray g-bg-lightblue-v3--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                            Sun
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </Fancy>

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                                Checklists
                            </h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton onClick={() => {
                                this.setState({
                                    id: -1,
                                    name: "",
                                    phone: ""
                                }, () => {
                                    this.fancy.open();
                                });
                            }}>
                                Create Checklist
                            </CorkButton>
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        {this.renderInstances()}
                    </div>

                    <hr />

                    <div className="row">
                        <CorkTable header={{
                            ID: {
                                NAME: "#",
                                WIDTH: 10,
                                TYPE: "Number"
                            },
                            NAME: {
                                NAME: "Checklist",
                                WIDTH: 45,
                                TYPE: "String",
                                LINK: "/checklist/",
                                LINK_ID: "ID"
                            }
                        }} sort="ID" data={Data} desc={true}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ChecklistsScreen;
