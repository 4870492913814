import {asyncDelay, decimalToDollars, request, toDollars} from "../../../util/Util";
import CorkDrop from "../../../modules/CorkDrop";
import React, {Component} from "react";
import OptionsModal from "./OptionsModal";
import {Link} from "react-router-dom";
import {FancySelect, FancyText} from "corky";
import {Products} from "drip-drop"
import {sendError, sendSuccess} from "../../../helpers/NotificationHelper";

const FIELDS = {
    TYPE: "customization-select",
    NAME: "customization-name",
    INTERNAL_NAME: "customization-internal-name",
};

class Customization extends Component {
    state = {
        custom: null,
        options: null,
        save: 0
    };

    constructor(props) {
        super(props);

        this.lastSwitch = new Date().getTime();

        this.form = {[FIELDS.NAME]: this.props.item.NAME};
    }

    componentWillMount() {
        for (let option of this.props.item.OPTIONS) {
            option.PRICE_DOLLARS = toDollars(option.PRICE);
        }

        this.setState({custom: this.props.item, options: this.props.item.OPTIONS});
    }

    add() {
        let {options} = this.state;

        let seq = options.length === 0 ? 1 : options[options.length - 1].SEQ + 1;

        options.push({
            NAME: "",
            PRICE: "",
            PRICE_DOLLARS: "",
            SEQ: seq,
            ENABLED: 1,
            MODIFIER: null
        });

        this.setState({options: options});
    }

    save() {
        let {item, product, variant} = this.props;
        let {custom, options} = this.state;

        this.setState({save: 1});
        let startTime = new Date().getTime();
        for (let option of options) {
            option.PRICE = decimalToDollars(option.PRICE_DOLLARS);
        }

        if (!variant) {
            custom.TYPE = this.form[FIELDS.TYPE].value;
        }

        custom.NAME = this.form[FIELDS.NAME];
        custom.INTERNAL_NAME = this.form[FIELDS.INTERNAL_NAME];
        request("customization/" + custom.ID, "PATCH", {
            PAYLOAD: custom
        }).then(async (payload) => {
            await asyncDelay(startTime);
            this.setState({save: 2});
            sendSuccess((variant ? "Variant" : "Modifier") + " Saved", `The ${custom.NAME} has been saved`);

            setTimeout(() => {
                this.setState({save: 0});
            }, 3000);

            for (let option of this.state.options) {
                if (typeof option.ID === "undefined") {
                    option.ID = payload.shift();
                }
            }

            if (this.state.custom.PRESET === 1) {
                let presets = this.props.partner.presets;
                let aIndex = presets.findIndex((_item) => _item.ID === item.ID);
                if (aIndex !== -1) {
                    presets.splice(aIndex, 1, {...custom, OPTIONS: options});
                }

                this.props.updatePresets(presets);
                return;
            }

            if (product.ID !== -1) {
                let aCusts = product.CUSTOMIZATIONS;
                let aIndex = aCusts.findIndex((_item) => _item.ID === item.ID);
                if (aIndex !== -1) {
                    aCusts.splice(aIndex, 1, {...custom, OPTIONS: options});
                }

                product.CUSTOMIZATIONS = product.CUSTOMIZATIONS.filter((item) => item.PRESET === 0);

                this.props.updateProduct(product);
            }
        }).catch((err) => {
            console.dir(err);

            sendError("Internal Error", "We couldn't save the customization for some reason. Please try again later.")
        });
    }

    remove(id, i) {
        if (!window.confirm("Are you sure you want to remove this option?")) {
            return;
        }

        if (typeof id === "undefined") {
            let options = this.state.options;
            options.splice(i, 1);

            this.setState({custom: {...this.state.custom, OPTIONS: options}, options: options});
            return;
        }

        let saved = new Date().getTime();
        this.setState({save: 1});

        request("option/" + id, "DELETE", {}, (err, payload) => {
            if (err) {
                alert("ERROR");
                return;
            }

            let options = this.props.item.OPTIONS;
            let index = options.findIndex((item) => item.ID === id);
            options.splice(index, 1);

            let save = () => {
                this.setState({save: 2});

                setTimeout(() => {
                    this.setState({save: 0});
                }, 2500)
            };

            if (saved + 1000 > new Date().getTime()) {
                setTimeout(() => {
                    save();
                }, 1000);
            } else {
                save();
            }

            this.setState({custom: {...this.state.custom, OPTIONS: options}, options: options});
        });
    }

    setDefault(id) {
        if (typeof id === "undefined") {
            alert("Please click save before setting default");
            return;
        }

        let remove = this.state.custom.DEFAULT_OPTION === id;
        request("customization/" + this.state.custom.ID + "/option/" + id, remove ? "DELETE" : "POST", {}, (err, payload) => {
            if (err) {
                alert("error");
                return;
            }

            this.setState({custom: {...this.state.custom, DEFAULT_OPTION: remove ? null : id}});
        });
    }

    async addGlobalDefault(option) {
        let {rawPresets, products} = this.props.partner;
        let {product} = this.props;

        let preset = rawPresets.find((item) => item.CUSTOMIZATION_ID === option.CUSTOMIZATION_ID && item.PRODUCT_ID === product.ID);
        let route = `product/${product.ID}/preset/${preset.ID}/customization/${option.CUSTOMIZATION_ID}/option/${option.ID}`;
        let globalPayload = await request(route, "POST", {});

        let index = products.findIndex((item) => item.ID === product.ID);
        let wholeProduct = products[index];

        wholeProduct.DEFAULTS.push(globalPayload);
        products.splice(index, 1, wholeProduct);

        this.props.updateProducts(products);
        this.forceUpdate();
    }

    async removeGlobal(option) {
        let {products} = this.props.partner;
        let {product} = this.props;

        await request(`customization/defaults/${option.ID}`, "DELETE", {});

        let index = products.findIndex((item) => item.ID === product.ID);
        let wholeProduct = products[index];

        let defaultIndex = wholeProduct.DEFAULTS.findIndex((item) => item.ID === option.ID);
        wholeProduct.DEFAULTS.splice(defaultIndex, 1);

        products.splice(index, 1, wholeProduct);
        this.props.updateProducts(products);
        this.forceUpdate();
    }

    addModifier() {
        let {options} = this.state;
        for (let option of options) {
            option.MODIFIER = 1;
        }

        console.log(options);
        this.setState({options});
    }

    renderNameType() {
        let {isPreset, variant} = this.props;
        let {custom} = this.state;

        if (isPreset && variant) {
            if (isPreset) {
                return (
                    <div className="row g-mb-20">
                        <div className="col-md-6">
                            <label className="g-mb-10">Name</label>
                            <div className="form-group g-pos-rel mb-0">
                                <input disabled={true}
                                       className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                       type="text" placeholder="Modifier Name" value={custom.NAME}
                                       style={{
                                           backgroundImage: 'url("data:image/png',
                                           backgroundRepeat: 'no-repeat',
                                           backgroundAttachment: 'scroll',
                                           backgroundSize: '16px 18px',
                                           backgroundPosition: '98% 50%'
                                       }}/>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label className="g-mb-10">Internal Name</label>
                            <div className="form-group g-pos-rel mb-0">
                                <input disabled={true}
                                       className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                       type="text" value={custom.INTERNAL_NAME}
                                       style={{
                                           backgroundImage: 'url("data:image/png',
                                           backgroundRepeat: 'no-repeat',
                                           backgroundAttachment: 'scroll',
                                           backgroundSize: '16px 18px',
                                           backgroundPosition: '98% 50%'
                                       }}/>
                            </div>
                        </div>
                    </div>
                )
            }
        }

        if (isPreset) {
            return (
                <div className="row g-mb-20">
                    <div className="col-md-4">
                        <label className="g-mb-10">Name</label>
                        <div className="form-group g-pos-rel mb-0">
                            <input disabled={true}
                                   className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                   type="text" placeholder="Modifier Name" value={custom.NAME}
                                   style={{
                                       backgroundImage: 'url("data:image/png',
                                       backgroundRepeat: 'no-repeat',
                                       backgroundAttachment: 'scroll',
                                       backgroundSize: '16px 18px',
                                       backgroundPosition: '98% 50%'
                                   }}/>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <label className="g-mb-10">Internal Name</label>
                        <div className="form-group g-pos-rel mb-0">
                            <input disabled={true}
                                   className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                   type="text" value={custom.INTERNAL_NAME}
                                   style={{
                                       backgroundImage: 'url("data:image/png',
                                       backgroundRepeat: 'no-repeat',
                                       backgroundAttachment: 'scroll',
                                       backgroundSize: '16px 18px',
                                       backgroundPosition: '98% 50%'
                                   }}/>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <label className="g-mb-10">Type</label>
                        <div className="form-group g-pos-rel mb-0">
                            <input disabled={true}
                                   className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                   type="text" placeholder="Customization Name"
                                   value={custom.TYPE === Products.Constants.CUSTOMIZATION_TYPES.VARIANT ? "Variant" : Object.values(Products.Constants.CUSTOMIZATION_TYPE_FIELDS)[custom.TYPE].label}
                                   style={{
                                       backgroundImage: 'url("data:image/png',
                                       backgroundRepeat: 'no-repeat',
                                       backgroundAttachment: 'scroll',
                                       backgroundSize: '16px 18px',
                                       backgroundPosition: '98% 50%'
                                   }}/>
                        </div>
                    </div>
                </div>
            )
        }

        if (variant) {
            return (
                <div className="row g-mb-20">
                    <div className="col-md-6">
                        <FancyText label="Name" form={this.form} id={FIELDS.NAME} value={custom.NAME}/>
                    </div>

                    <div className="col-md-6">
                        <FancyText label="Internal Name" form={this.form} id={FIELDS.INTERNAL_NAME}
                                   value={custom.INTERNAL_NAME}
                                   tooltip="You will only see this name on the dashboard."/>
                    </div>
                </div>
            )
        }

        return (
            <div className="row g-mb-20">
                <div className="col-md-4">
                    <FancyText label="Name" form={this.form} id={FIELDS.NAME}/>
                </div>

                <div className="col-md-4">
                    <FancyText label="Internal Name" form={this.form} id={FIELDS.INTERNAL_NAME}
                               value={custom.INTERNAL_NAME} tooltip="You will only see this name on the dashboard."/>
                </div>

                <div className="col-md-4">
                    <FancySelect label="Type" options={Products.Constants.CUSTOMIZATION_TYPE_FIELDS}
                                 defaultValue={Products.Constants.CUSTOMIZATION_TYPE_FIELDS.find((item) => item.value === custom.TYPE)}
                                 form={this.form} id={FIELDS.TYPE}
                    />
                </div>
            </div>
        )
    }

    renderRequired() {
        let {isPreset, variant} = this.props;
        let {custom} = this.state;

        if (isPreset || variant) {
            return (
                <div/>
            )
        }

        return (
            <div className="form-group d-flex align-items-center justify-content-between g-mb-20 show-cursor"
                 onClick={(e) => {
                     if (this.lastSwitch + 1000 > new Date().getTime()) {
                         return;
                     }

                     this.lastSwitch = new Date().getTime();
                     this.setState({custom: {...custom, REQUIRED: !custom.REQUIRED}})
                 }}
            >
                <span style={{marginLeft: 5}}>Required</span>
                <label className="u-check mb-0 g-ml-10">
                    <input className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0"
                           type="checkbox" checked={custom.REQUIRED}
                    />

                    <div
                        className="u-check-icon-radio-v8 u-check-icon--md u-check-icon--primary">
                        <i className="fa"/>
                    </div>
                </label>
            </div>
        );
    }

    renderButtons() {
        let {isPreset, item, variant} = this.props;
        let {options} = this.state;

        let save, saveIcon;
        switch (this.state.save) {
            case 0:
                save = "Save";
                saveIcon = <i className="fa fa-save g-font-size-16"/>;
                break;
            case 1:
                saveIcon = <i className="fa fa-spinner fa-spin g-font-size-16"/>;
                save = "Saving";
                break;
            case 2:
                saveIcon = <i className="hs-admin-check g-font-size-16"/>;
                save = "Saved";
                break;
        }

        if (isPreset) {
            return (
                <div className="d-flex g-mb-20">
                    <Link className="u-link-v5 d-flex align-items-center g-color-primary show-cursor"
                          to={variant ? "/variants" : "/presets"}>
                        <span
                            className="u-badge-v2--xl g-pos-rel g-transform-origin--top-left g-font-size-10 g-color-white">
                            <i className="hs-admin-pencil g-absolute-centered"/>
                        </span>
                        <span className="g-ml-15">Edit Global {variant ? "Variant" : "Modifier"}</span>
                    </Link>
                    <a className="u-link-v5 d-flex align-items-center g-color-secondary g-ml-30 show-cursor"
                       onClick={this.props.remove}>
                        <i style={{color: "#ff0066"}} className="fa fa-trash g-font-size-16"/>
                        <span className="g-ml-15" style={{color: "#ff0066"}}>
                            Remove {variant ? "Variant" : "Modifier"}
                        </span>
                    </a>
                </div>
            )
        }

        return (
            <div className="d-flex g-mb-20">
                <a className="u-link-v5 d-flex align-items-center g-color-primary show-cursor g-color-gray-dark-v11--hover"
                   onClick={this.save.bind(this)}>
                    {saveIcon}
                    <span className="g-ml-8">{save}</span>
                </a>
                <a className="u-link-v5 d-flex align-items-center g-color-primary g-ml-30 show-cursor g-color-gray-dark-v11--hover"
                   onClick={this.add.bind(this)}>
                    <i className="fa fa-plus-circle g-font-size-16"/>

                    <span className="g-ml-15">Add Option</span>
                </a>
                <a className="u-link-v5 d-flex align-items-center g-color-primary g-ml-30 show-cursor g-color-gray-dark-v11--hover"
                   onClick={() => this.order.open(item.OPTIONS)}>
                    <i className="fa fa-sort g-font-size-16"/>
                    <span className="g-ml-15">Order Options</span>
                </a>
                <a className="u-link-v5 d-flex align-items-center g-color-secondary g-ml-30 show-cursor g-color-gray-dark-v11--hover"
                   onClick={this.props.remove}>
                    <i style={{color: "#ff0066"}} className="fa fa-trash g-font-size-16"/>
                    <span className="g-ml-15" style={{color: "#ff0066"}}>
                        {variant ? "Remove Variant" : "Delete Modifier"}
                    </span>
                </a>
                {!variant || options.length === 0 || options[0].MODIFIER === 0 ? <div/> : (
                    <a className="u-link-v5 d-flex align-items-center g-color-primary g-ml-30 show-cursor g-color-gray-dark-v11--hover"
                       onClick={() => this.addModifier()}>
                        <i className="fa fa-sort g-font-size-16"/>
                        <span className="g-ml-15">Add Modifier</span>
                    </a>
                )}
            </div>
        );
    }

    render() {
        let {item, product, isPreset} = this.props;
        let {location} = this.props.partner;
        let {custom, options} = this.state;

        if (custom === null) {
            return <div/>
        }

        options.sort((a, b) => (a.SEQ === 0 ? 1000000 : a.SEQ) - (b.SEQ === 0 ? 10000 : b.SEQ));
        let Options = options.map((option, i) => {
            if (isPreset) {
                if (options.length === 0 || options[0].MODIFIER === null) {
                    let defaultPayload = product.DEFAULTS.find((item) => item.CUSTOMIZATION_ID === option.CUSTOMIZATION_ID && item.OPTION_ID === option.ID);

                    return (
                        <div className="row g-mb-20" key={option.ID}>
                            <div className="col-md-4">
                                <div className="form-group g-pos-rel mb-0">
                                    <input
                                        className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                        type="text" placeholder="Option Name" disabled={true} value={options[i].NAME}/>

                                    <a className="u-link-v5 g-absolute-centered--y g-right-15 g-font-style-normal g-color-primary g-color-gray-dark-v11--hover show-cursor"
                                       onClick={defaultPayload ? this.removeGlobal.bind(this, defaultPayload) : this.addGlobalDefault.bind(this, options[i])}>
                                        <i className="hs-admin-check" title="Confirmed"/>
                                        <span
                                            className="g-hidden-lg-down g-font-weight-300 g-font-size-default g-ml-8 show-cursor">{
                                            defaultPayload ? "Remove Default" : "Set Default"
                                        }</span>
                                    </a>

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group g-pos-rel mb-0">
                                    <input
                                        className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                        type="text" placeholder="Price" value={"$" + toDollars(options[i].PRICE)}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group g-pos-rel mb-0">
                                    <input
                                        className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                        type="text" placeholder="Price w/Tax"
                                        value={"$" + toDollars(options[i].PRICE + (product.TAX_DISABLED ? 0 : Math.round(options[i].PRICE * (location.TAX_RATE / 100))))}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="row g-mb-20">
                            <div className="col-md-4">
                                <div className="form-group g-pos-rel mb-0">
                                    <input
                                        className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                        type="text" placeholder="Option Name" disabled={true} value={options[i].NAME}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group g-pos-rel mb-0">
                                    <input
                                        className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                        type="text" placeholder="Price" value={"$" + toDollars(options[i].PRICE)}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group g-pos-rel mb-0">
                                    <input
                                        className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                        type="text" placeholder="Price w/Tax"
                                        value={"$" + toDollars(options[i].PRICE + (product.TAX_DISABLED ? 0 : Math.round(options[i].PRICE * (location.TAX_RATE / 100))))}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group g-pos-rel mb-0">
                                    <input
                                        className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                        type="text" placeholder="Modifier" value={options[i].MODIFIER}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                }
            }

            if (options[0].MODIFIER !== null) {
                return (
                    <div className="row g-mb-20">
                        <div className="col-md-4">
                            <div className="form-group g-pos-rel mb-0">
                                <input
                                    className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                    type="text" placeholder="Option Name"
                                    onChange={(e) => {
                                        options.splice(i, 1, {...options[i], NAME: e.target.value});
                                        this.setState({options: options})
                                    }}
                                    value={options[i].NAME}/>
                                <a className="u-link-v5 g-absolute-centered--y g-right-15 g-font-style-normal g-color-primary g-color-gray-dark-v11--hover show-cursor"
                                   onClick={this.setDefault.bind(this, options[i].ID)}>
                                    <i className="hs-admin-check" title="Confirmed"/>
                                    <span
                                        className="g-hidden-lg-down g-font-weight-300 g-font-size-default g-ml-8 show-cursor">{
                                        options[i].ID === custom.DEFAULT_OPTION ? "Default" : "Set Default"
                                    }</span>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group g-pos-rel mb-0">
                                <input
                                    className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                    type="text" placeholder="Price" value={options[i].PRICE_DOLLARS}
                                    onChange={(e) => {
                                        options.splice(i, 1, {...options[i], PRICE_DOLLARS: e.target.value});
                                        this.setState({options: options})
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group g-pos-rel mb-0">
                                <input
                                    className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                    type="text" placeholder="Price w/ Tax"
                                    value={toDollars(decimalToDollars(options[i].PRICE_DOLLARS) + ((!product || product.TAX_DISABLED) ? 0 : Math.round(decimalToDollars(options[i].PRICE_DOLLARS) * (location.TAX_RATE / 100))))}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group g-pos-rel mb-0">
                                <input onChange={(e) => {
                                    options.splice(i, 1, {...options[i], MODIFIER: e.target.value});
                                    this.setState({options: options})
                                }} type="text" placeholder="Modifier" value={options[i].MODIFIER}
                                       className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                />
                            </div>
                        </div>
                        <div className="col-md-1"
                             style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <div onClick={this.remove.bind(this, option.ID, i)}>
                                <i style={{color: "#ff0066", fontSize: 26}} className="fa fa-times show-cursor"/>
                            </div>
                        </div>
                    </div>
                );
            }

            return (
                <div className="row g-mb-20">
                    <div className="col-md-4">
                        <div className="form-group g-pos-rel mb-0">
                            <input
                                className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                type="text" placeholder="Option Name"
                                onChange={(e) => {
                                    options.splice(i, 1, {...options[i], NAME: e.target.value});
                                    this.setState({options: options})
                                }}
                                value={options[i].NAME}/>
                            <a className="u-link-v5 g-absolute-centered--y g-right-15 g-font-style-normal g-color-primary g-color-gray-dark-v11--hover show-cursor"
                               onClick={this.setDefault.bind(this, options[i].ID)}>
                                <i className="hs-admin-check" title="Confirmed"/>
                                <span
                                    className="g-hidden-lg-down g-font-weight-300 g-font-size-default g-ml-8 show-cursor">{
                                    options[i].ID === custom.DEFAULT_OPTION ? "Default" : "Set Default"
                                }</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group g-pos-rel mb-0">
                            <input
                                className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                type="text" placeholder="Price" value={options[i].PRICE_DOLLARS}
                                onChange={(e) => {
                                    options.splice(i, 1, {...options[i], PRICE_DOLLARS: e.target.value});
                                    this.setState({options: options})
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group g-pos-rel mb-0">
                            <input
                                className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                type="text" placeholder="Price w/ Tax"
                                value={toDollars(decimalToDollars(options[i].PRICE_DOLLARS) + ((!product || product.TAX_DISABLED) ? 0 : Math.round(decimalToDollars(options[i].PRICE_DOLLARS) * (location.TAX_RATE / 100))))}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-md-1" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <div onClick={this.remove.bind(this, option.ID, i)}>
                            <i style={{color: "#ff0066", fontSize: 26}} className="fa fa-times show-cursor"/>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div className="g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md"
                 style={{marginBottom: 30}}>
                <OptionsModal ref={(e) => this.order = e} product={product} customization={item} {...this.props}/>

                <form className="js-validate" onSubmit={(e) => e.preventDefault()}>
                    {this.renderNameType()}
                    {this.renderRequired()}

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    {options.length === 0 || options[0].MODIFIER === null ? (
                        <div className="row g-mb-10">
                            <div className="col-md-4">
                                <label>Options</label>
                            </div>
                            <div className="col-md-4">
                                <label>Price</label>
                            </div>
                            <div className="col-md-4">
                                <label>Price w/ Tax</label>
                            </div>
                        </div>
                    ) : (
                        <div className="row g-mb-10">
                            <div className="col-md-4">
                                <label>Options</label>
                            </div>
                            <div className="col-md-3">
                                <label>Price</label>
                            </div>
                            <div className="col-md-2">
                                <label>Price w/ Tax</label>
                            </div>
                            <div className="col-md-2">
                                <label>Modifier</label>
                            </div>
                        </div>
                    )}

                    {Options}
                    {this.renderButtons()}
                </form>
            </div>
        )
    }
}

export default Customization;
