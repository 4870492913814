import React, {Component} from 'react';
import Fancy from "../../modules/fancy/Fancy";
import 'bootstrap-daterangepicker/daterangepicker.css';
import {formatDate, request, toDollars} from "../../util/Util";
import {CorkTable} from 'corky'
import {Link} from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader';
import moment from "moment"

class PayrollScreen extends Component {
    payrun() {
        this.props.updateLoading(true);
        request("team/payrun", "POST", {}).then(() => {
            window.location.reload();
        }).catch((err) => {
            this.props.updateLoading(false);
            alert("Something went wrong")
        });
    }

    getHours(timesheet) {
        return parseFloat(((timesheet.DATE_END - timesheet.DATE_START) / (1000 * 60 * 60)).toFixed(1))
    }

    render() {
        let {timesheets, payrolls} = this.props.partner;
        let employees = timesheets.filter((item) => item.PAYROLL_ID === null).reduce((dict, item) => {
            if (dict[item.EMPLOYEE_ID]) {
                dict[item.EMPLOYEE_ID].push(item);
            } else {
                dict[item.EMPLOYEE_ID] = [item]
            }

            return dict;
        }, {});

        let Payruns = payrolls.map((payrun) => {
            let sheets = timesheets.filter((item) => item.PAYROLL_ID === payrun.ID);
            let employees = new Set();

            for (let sheet of sheets) {
                employees.add(sheet.EMPLOYEE_ID);
            }

            return {
                ID: payrun.ID,
                DATE: moment(payrun.DATE_SENT).format("hh:mmA MM/DD/YY"),
                AMOUNT: "$" + toDollars(payrun.AMOUNT),
                TIMESHEETS: sheets.length,
                EMPLOYEES: employees.size
            };
        });

        let employeeIds = this.props.partner.employees.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});

        let TotalUnpaid = 0;
        let Unpaid = Object.values(employees).map((timeGroups) => {
            let priceNeeded = timeGroups.reduce((accum, item) => accum + (this.getHours(item) * item.RATE), 0);
            TotalUnpaid += priceNeeded;

            return {
                ID: 1,
                EMPLOYEE: employeeIds[timeGroups[0].EMPLOYEE_ID].FULL_NAME,
                HOURS: timeGroups.reduce((accum, item) => accum + this.getHours(item), 0.0),
                AMOUNT: "$" + toDollars(priceNeeded),
            }
        });

        return (
            <div>
                <div>
                    <div className="g-pa-20">
                        <div className="row">
                            <div className="col-sm-6 col-lg-6 col-xl-3 g-mb-30 show-cursor">
                                <div className="card h-100 g-brd-gray-light-v7 rounded">
                                    <div className="card-block g-font-weight-300 g-pa-20">
                                        <div className="media">
                                            <div className="d-flex g-mr-15">
                                                <div className="js-pie-chart g-pos-rel mx-auto"
                                                     style={{width: '60px', height: '60px'}} data-series="[50, 20, 50]"
                                                     data-start-angle={330}
                                                     data-fill-colors="[&quot;#f1f5f5&quot;, &quot;#1d75e5&quot;, &quot;#3dd1e8&quot;]"
                                                     id="pieCharts0">
                                                </div>
                                            </div>
                                            <div className="media-body align-self-center">
                                                <h4 className="g-font-size-16 g-font-weight-300 g-color-gray-dark-v6 mb-0">
                                                    Unpaid Wages
                                                </h4>
                                                <div className="d-flex">
                                                    <span className="d-flex g-font-size-22 g-color-black">
                                                        ${toDollars(TotalUnpaid)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-lg-6 col-xl-3 g-mb-30 show-cursor">
                                <div className="card h-100 g-brd-gray-light-v7 rounded">
                                    <div className="card-block g-font-weight-300 g-pa-20">
                                        <div className="media">
                                            <div className="d-flex g-mr-15">
                                                <div className="js-pie-chart g-pos-rel mx-auto"
                                                     style={{width: '60px', height: '60px'}} data-series="[50, 20, 50]"
                                                     data-start-angle={330}
                                                     data-fill-colors="[&quot;#f1f5f5&quot;, &quot;#1d75e5&quot;, &quot;#3dd1e8&quot;]"
                                                     id="pieCharts0">
                                                </div>
                                            </div>
                                            <div className="media-body align-self-center">
                                                <h4 className="g-font-size-16 g-font-weight-300 g-color-gray-dark-v6 mb-0">
                                                    Unpaid Employees
                                                </h4>
                                                <div className="d-flex">
                                                    <span className="d-flex g-font-size-22 g-color-black">
                                                        {Object.keys(employees).length}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-lg-6 col-xl-3 g-mb-30 show-cursor">
                                <div className="card h-100 g-brd-gray-light-v7 rounded">
                                    <div className="card-block g-font-weight-300 g-pa-20">
                                        <div className="media">
                                            <div className="d-flex g-mr-15">
                                                <div className="js-pie-chart g-pos-rel mx-auto"
                                                     style={{width: '60px', height: '60px'}} data-series="[50, 20, 50]"
                                                     data-start-angle={330}
                                                     data-fill-colors="[&quot;#f1f5f5&quot;, &quot;#1d75e5&quot;, &quot;#3dd1e8&quot;]"
                                                     id="pieCharts0">
                                                </div>
                                            </div>
                                            <div className="media-body align-self-center">
                                                <h4 className="g-font-size-16 g-font-weight-300 g-color-gray-dark-v6 mb-0">
                                                    Last Payout
                                                </h4>
                                                <div className="d-flex">
                                                    <span className="d-flex g-font-size-22 g-color-black">
                                                        5 days ago
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="g-pa-20">
                        <div className="media">
                            <div className="d-flex align-self-center">
                                <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                                    Payroll
                                </h1>
                            </div>

                            <div className="media-body align-self-center text-right">
                                <a style={{backgroundColor: "#1cc9e4", color: "white"}} onClick={this.payrun.bind(this)}
                                   className="js-fancybox btn btn-xl u-btn-secondary g-width-160--md g-font-size-default g-ml-10"
                                   data-src="#new-project-form" data-speed="350"
                                   data-options="{&quot;touch&quot; : false}">Send Payrun
                                </a>
                            </div>
                        </div>

                        <hr />

                        <div className="row">
                            <CorkTable ref={(e) => this.table = e} header={{
                                ID: {
                                    NAME: "#",
                                    WIDTH: 10,
                                    TYPE: "Number"
                                },
                                EMPLOYEE: {
                                    NAME: "Employee",
                                    WIDTH: 45,
                                    TYPE: "String",
                                    LINK: "/employee/",
                                    LINK_ID: "ID"
                                },
                                HOURS: {
                                    NAME: "Hours",
                                    WIDTH: 10
                                },
                                AMOUNT: {
                                    NAME: "Payment Needed",
                                    WIDTH: 10
                                }
                            }} sort="ID" data={Unpaid} desc={true} title="Unpaid Employees" sortItems={["_TITLE"]}/>
                        </div>

                        <div className="row">
                            <CorkTable ref={(e) => this.table = e} header={{
                                ID: {
                                    NAME: "#",
                                    WIDTH: 10,
                                    TYPE: "Number",
                                    LINK: "/payrun/:ID",
                                    LINK_ID: "ID"
                                },
                                EMPLOYEES: {
                                    NAME: "Employees Paid",
                                    WIDTH: 10
                                },
                                TIMESHEETS: {
                                    NAME: "Timesheets Paid",
                                    WIDTH: 10
                                },
                                AMOUNT: {
                                    NAME: "Amount Payed",
                                    WIDTH: 10
                                },
                                DATE: {
                                    NAME: "Date",
                                    WIDTH: 10
                                }
                            }} sort="ID" data={Payruns} desc={true} title="Past Pay Runs" sortItems={["_TITLE"]}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PayrollScreen;
