import React, {Component} from "react"
import Fancy from "../../../modules/fancy/Fancy";

import {FancyText, FancySelect} from "corky"
import {decimalToDollars, request, toDollars} from "../../../util/Util";
import {BANK_TYPE} from "../../../util/Constants";
import Card from "../../dashboard/modules/Card";

const FIELDS = {
    AMOUNT: "bank-holder",
};

class PayoutModal extends Component {
    state = {setup: false};

    constructor(props) {
        super(props);

        this.form = {};
    }

    sendPayout() {
        let form = this.form;
        let {payouts, balance} = this.props.parentState;
        let amount = decimalToDollars(form[FIELDS.AMOUNT]);

        let isValid = true;
        for (let field of this.fields) {
            if (field === null) {
                continue;
            }

            if (!field.isValid()) {
                isValid = false;
            }
        }

        if (!isValid) {
            return;
        }

        if (amount > balance.available[0].amount) {
            return this.amount.setState({error: "Payout amount can't be over your available balance"});
        }

        request("partner/payout", "POST", {
            AMOUNT: amount
        }).then(async (payload) => {
            await this.fancy.close();

            payouts.push(payload);
            balance.available[0].amount -= amount;

            this.props.setParent({payouts, balance});
        })
    }

    open() {
        this.fancy.open()
    }

    render() {
        this.fields = [];
        let {setup} = this.state;
        let {payouts, balance} = this.props.parentState;

        return (
            <Fancy name="Send Payout" onClick={this.sendPayout.bind(this)} ref={(e) => this.fancy = e}
                   buttonText="Send">
                <div className="row">
                    <Card name="Available Balance" value={toDollars(balance.available[0].amount)}/>
                </div>

                <div className="g-mb-20">
                    <FancyText form={this.form} id={FIELDS.AMOUNT} label="Payout Amount" ref={(e) => {
                        this.fields.push(e);
                        this.amount = e;
                    }} required={true}/>
                </div>
            </Fancy>
        )
    }
}

export default PayoutModal;