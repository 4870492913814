const AsYouTypeFormatter = require('google-libphonenumber').AsYouTypeFormatter;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export function getURL() {
    if (window.location.href.indexOf('qe.partner.drip.is') !== -1) {
        return 'https://qe.api.drip.is/'
    }

    if (window.location.href.indexOf('staging.partner.drip.is') !== -1
        || window.location.href.indexOf('beta.partner.drip.is') !== -1
        || window.location.href.indexOf('beta.drip.is') !== -1) {
        return 'https://staging.api.drip.is/'
    }

    if (window.location.href.indexOf('localhost') === -1) {
        return 'https://api.dripdrinks.co/'
    }

    return 'http://localhost:6969/api/'
}

export function getShortURL() {
    if (window.location.href.indexOf('qe.partner.drip.is') !== -1) {
        return 'https://qe.api.drip.is/'
    }

    if (window.location.href.indexOf('staging.partner.drip.is') !== -1
        || window.location.href.indexOf('beta.partner.drip.is') !== -1
        || window.location.href.indexOf('beta.drip.is') !== -1) {
        return 'https://staging.api.drip.is/'
    }

    if (window.location.href.indexOf('localhost') === -1) {
        return 'https://api.dripdrinks.co/'
    }

    return 'http://127.0.0.1:6969/'
}

export function request(endpoint, method, body, callback) {
    return new Promise((resolve, reject) => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        if (typeof window.localStorage['TOKEN'] !== 'undefined') {
            headers['Authentication'] = window.localStorage['TOKEN']
        }

        if (window.localStorage.getItem("CURRENT_LOCATION") !== null) {
            headers.location = window.localStorage.getItem("CURRENT_LOCATION")
        }

        if (body !== null) {
            fetch(getURL() + endpoint, {
                method: method,
                headers: headers,
                body: JSON.stringify(body),
                credentials: 'same-origin'
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['success']) {
                        if (callback) {
                            callback(undefined, responseJson.data);
                        }

                        resolve(responseJson.data);
                    } else {
                        if (callback) {
                            callback(responseJson, responseJson.code);
                        }

                        reject(responseJson, responseJson.code);
                    }
                })
                .catch((error) => {
                    if (callback) {
                        callback(error, endpoint);
                    }

                    reject(error);
                });
        } else {
            fetch(getURL() + endpoint, {
                method: method,
                headers: headers,
                credentials: 'same-origin'
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['success']) {
                        if (callback) {
                            callback(undefined, responseJson.data);
                        }

                        resolve(responseJson.data);
                    } else {
                        if (callback) {
                            callback(responseJson, responseJson.code);
                        }

                        reject(responseJson, responseJson.code);
                    }
                })
                .catch((error) => {
                    if (callback) {
                        callback(JSON.stringify(error), endpoint);
                    }

                    reject(error);
                });
        }
    });
}

export function uploadImage(endpoint, image, callback) {
    if (!callback) {
        callback = () => {

        }
    }

    return new Promise((resolve, reject) => {
        var formData = new FormData();
        formData.append('file', image);

        let headers = {};
        if (typeof window.localStorage['TOKEN'] !== 'undefined') {
            headers['Authentication'] = window.localStorage['TOKEN']
        }

        if (window.localStorage.getItem("CURRENT_LOCATION") !== null) {
            headers.location = window.localStorage.getItem("CURRENT_LOCATION")
        }

        try {
            fetch(getURL() + endpoint, {
                method: 'POST',
                body: formData,
                headers: headers
            }).then((response) => response.json()).then(function (response) {
                if (response.success === true) {
                    callback(undefined, response.data);
                    resolve(response.data);
                } else {
                    callback(response);
                    reject(response);
                }
            });
        } catch (e) {
            callback(undefined, "File too big");
            reject("File too big");
        }
    });
}

export function formatDate(date) {
    if (typeof date.getMonth === "undefined") {
        date = new Date(date);
    }

    let str = date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
    if (date.getHours() >= 12) {
        str = "PM " + str
    } else {
        str = "AM " + str;
    }

    str = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + str;

    if (date.getHours() !== 0) {
        str = (date.getHours() > 12 ? date.getHours() % 12 : date.getHours()) + ":" + str;
    } else {
        str = "12:" + str;
    }

    return str;
}

let DEBUG = false;

export function log(message) {
    if (!DEBUG) {
        return;
    }

    alert(JSON.stringify(message));
}

export function toDollars(cents) {
    let str = "" + Math.ceil(cents);

    switch (str.length) {
        default:
            return str.substring(0, str.length - 2) + "." + str.substring(str.length - 2, str.length);
        case 0:
            return "0.00";
        case 1:
            return "0.0" + str;
        case 2:
            return "0." + str;
    }
}

export const COLORS = {
    DRIP_GOLD: "#b99438",
    DRIP_GREEN: "#4ACE9C",
    DRIP_MAIN: "#4ACE9C",
};

const AWS_S3_URL = "https://drip-drinks.s3.amazonaws.com";

export function getObjectImage(obj, key, backup) {
    if (obj.hasOwnProperty(key) === false || obj[key] === null) {
        return getUrl(backup);
    }

    return getUrl(obj[key]);
}

function getUrl(url) {
    if (url.startsWith("http")) {
        return url;
    }

    return AWS_S3_URL + "/" + url;
}

export function parseIdDict(arr, key = "ID") {
    return arr.reduce((dict, item) => {
        dict[item[key]] = item;
        return dict;
    }, {});
}

export function inverseString(str) {
    if (str === "0") {
        return "1"
    }

    return "0";
}

export function randomString(howMany, chars) {
    chars = chars
        || "abcdefghijklmnopqrstuwxyzABCDEFGHIJKLMNOPQRSTUWXYZ0123456789";

    var result = '';
    for (let i = 0; i < howMany; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return result;
}

export function formatPhone(phoneNumberString) {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }

    return null
}

export function decimalToDollars(str) {
    if (!str) {
        return 0;
    }

    return Math.round(100 * parseFloat(str.replace(/[$,]/g, '')));
}

const DEFAULT_DELAY = 500;

export function asyncDelay(start) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, Math.min(DEFAULT_DELAY - (new Date().getTime() - start), DEFAULT_DELAY));
    })
}

export function validateFields(fields) {
    let isValid = true;
    for (let field of fields) {
        if (field === null) {
            continue;
        }

        if (!field.isValid()) {
            isValid = false;
        }
    }

    return isValid;
}

export function getFormattedPhone(number) {
    const formatter = new AsYouTypeFormatter("US");
    let formatted;

    number.replace(/-/g, '')
        .replace(/ /g, '')
        .split('')
        .forEach(n => formatted = formatter.inputDigit(n));

    return formatted;
}
