import {
    ADD_ERROR,
    REMOVE_CATEGORY,
    REMOVE_ERROR,
    REMOVE_POST,
    UPDATE_ANNOUNCEMENTS,
    UPDATE_BAR_BEVERAGES,
    UPDATE_BEVERAGES,
    UPDATE_CATEGORIES,
    UPDATE_CHECKLIST_INSTANCES,
    UPDATE_CHECKLISTS,
    UPDATE_CLOSING,
    UPDATE_COUPONS,
    UPDATE_DEVICES,
    UPDATE_DISCOUNTS, UPDATE_EMAIL_CAMPAIGNS,
    UPDATE_EMPLOYEES,
    UPDATE_HOURS,
    UPDATE_INFO,
    UPDATE_INVOICES,
    UPDATE_LOADING,
    UPDATE_LOCATION,
    UPDATE_NEWS,
    UPDATE_ORDERS,
    UPDATE_OTHER_PAYMENTS,
    UPDATE_OVERSEER,
    UPDATE_PARTNER,
    UPDATE_PAYOUTS,
    UPDATE_POST,
    UPDATE_PRESETS,
    UPDATE_PRODUCT_AVAILABILITY,
    UPDATE_PRODUCTS,
    UPDATE_PROMOTIONS,
    UPDATE_PUSHES,
    UPDATE_RAW_PRESETS,
    UPDATE_REPORTING,
    UPDATE_ROLES,
    UPDATE_SECTIONS,
    UPDATE_SETTINGS,
    UPDATE_SETUP_FORMS,
    UPDATE_SHIFT, UPDATE_TEXT_CAMPAIGNS,
    UPDATE_TIMESHEETS,
    UPDATE_VENDORS
} from "../actions/GeneralActions";
import moment from "moment";

let cloneObject = function (obj) {
    return JSON.parse(JSON.stringify(obj))
};

const defaultState = {
    location: {},
    drinks: [],
    checklists: [],
    checklistInstances: [],
    payrolls: [],
    members: [],
    beverages: [],
    rates: [],
    info: {},
    barBeverages: [],
    admins: [],
    news: [],
    pushes: [],
    views: [],
    clicks: [],
    share: 0,
    favorites: 0,
    letters: [{
        ID: 1,
        TITLE: "This weeks update 1/12/20",
        CONTENT: "Here is some stuff",
        DATE_SENT: null,
        DATE_TARGET: moment("1/12/20").toDate().getTime()
    }],
    sections: [],
    payouts: [],
    announcements: [],
    products: [],
    categories: [],
    orders: [],
    hours: {},
    tips: [],
    presets: [],
    overseer: [],
    employees: [],
    shifts: [],
    vendors: [],
    invoices: [],
    timesheets: [],
    rawPresets: [],
    roles: [],
    redeems: [],
    patrons: [],
    account: null,
    loading: true,
    settings: {},
    reporting: {
        start: moment().startOf("isoWeek").toDate().getTime(),
        end: moment().endOf("isoWeek").toDate().getTime(),
        frame: 1000 * 60 * 60 * 24 * 7,
        locations: [],
        data: {orders: [], products: [], categories: [], drawers: []},
        day: null,
        last: ""
    },
    setupForms: [],
    forms: [],
    discounts: [],
    closing: [],
    productAvailability: [],
    otherPayments: [],
    employeePreferences: [],
    promotions: [],
    coupons: [],
    devices: [],
    textCampaigns: [],
    emailCampaigns: [],
    website: null
};

let newState = cloneObject(defaultState);

export default function (state = newState, action) {
    switch (action.type) {
        case UPDATE_PARTNER:
            // newState = cloneObject(state);

            // newState.location = action.payload.location;
            // newState.drinks = action.payload.drinks;
            // newState.members = action.payload.members;
            // newState.pushes = action.payload.pushes;
            // newState.news = action.payload.news;
            // newState.views = action.payload.views;
            // newState.clicks = action.payload.clicks;
            // newState.share = action.payload.share;
            // newState.favorites = action.payload.favorites;
            // newState.payouts = action.payload.payouts;
            // newState.products = action.payload.products;
            // newState.categories = action.payload.categories;
            // newState.orders = action.payload.orders;
            // newState.hours = action.payload.hours;
            // newState.tips = action.payload.tips;
            // newState.presets = action.payload.presets;
            // newState.employees = action.payload.employees;
            // newState.shifts = action.payload.shifts;
            // newState.vendors = action.payload.vendors;
            // newState.invoices = action.payload.invoices;
            // newState.account = action.payload.account;
            // newState.payouts = action.payload.payouts;
            // newState.timesheets = action.payload.timesheets;
            // newState.payrolls = action.payload.payrolls;
            // newState.info = action.payload.info;
            // newState.rawPresets = action.payload.rawPresets;
            // newState.checklists = action.payload.checklists;
            // newState.checklistInstances = action.payload.checklistInstances;
            // newState.settings = action.payload.settings;
            // newState.roles = action.payload.roles;
            // newState.setupForms = action.payload.setupForms;
            // newState.forms = action.payload.forms;
            // newState.redeems = action.payload.redeems;
            // newState.patrons = action.payload.patrons;
            // newState.discounts = action.payload.discounts;
            // newState.closings = action.payload.closings;
            // newState.productAvailability = action.payload.productAvailability;
            // newState.reporting = {
            //     ...state.reporting,
            //     locations: [{value: newState.location.ID, label: newState.location.NAME}]
            // };

            return {...state, ...action.payload};
        case UPDATE_OVERSEER:
            return {...state, overseer: action.payload};
        case UPDATE_LOCATION:
            return {...state, location: action.payload};
        case UPDATE_BEVERAGES:
            return {...state, beverages: action.payload};
        case UPDATE_NEWS:
            return {...state, news: action.payload};
        case UPDATE_PUSHES:
            return {...state, pushes: action.payload};
        case UPDATE_BAR_BEVERAGES:
            return {...state, barBeverages: action.payload};
        case UPDATE_SECTIONS:
            return {...state, sections: action.payload};
        case UPDATE_ANNOUNCEMENTS:
            return {...state, announcements: action.payload};
        case UPDATE_PRODUCTS:
            return {...state, products: action.payload};
        case UPDATE_CATEGORIES:
            return {...state, categories: action.payload};
        case UPDATE_ORDERS:
            return {...state, orders: action.payload};
        case UPDATE_HOURS:
            return {...state, hours: action.payload};
        case UPDATE_PRESETS:
            return {...state, presets: action.payload};
        case ADD_ERROR:
            return {...state, announcements: [...state.announcements, action.payload]};
        case REMOVE_ERROR:
            let announcements = state.announcements;
            let index = announcements.findIndex((item) => item.ID === action.payload);

            if (index === -1) {
                return state;
            }

            announcements.splice(index, 1);
            return {...state, announcements: announcements};
        case REMOVE_CATEGORY:
            let categories = state.categories;
            let cIndex = categories.findIndex((item) => item.ID === action.payload);

            if (cIndex === -1) {
                return state;
            }

            categories.splice(cIndex, 1);
            return {...state, categories: categories};
        case UPDATE_POST:
            let news = state.news;
            let nIndex = news.findIndex((item) => item.ID === action.payload.ID);

            if (nIndex === -1) {
                return state;
            }

            news.splice(nIndex, 1, action.payload);
            return {...state, news: news};
        case REMOVE_POST:
            let rNews = state.news;
            let rnIndex = rNews.findIndex((item) => item.ID === action.payload);

            if (rnIndex === -1) {
                return state;
            }

            rNews.splice(rnIndex, 1);
            return {...state, news: rNews};
        case UPDATE_EMPLOYEES:
            return {...state, employees: action.payload};
        case UPDATE_SHIFT:
            return {...state, shifts: action.payload};
        case UPDATE_INVOICES:
            return {...state, invoices: action.payload};
        case UPDATE_VENDORS:
            return {...state, vendors: action.payload};
        case UPDATE_PAYOUTS:
            return {...state, payouts: action.payload};
        case UPDATE_TIMESHEETS:
            return {...state, timesheets: action.payload};
        case UPDATE_LOADING:
            return {...state, loading: action.payload};
        case UPDATE_INFO:
            return {...state, info: action.payload};
        case UPDATE_RAW_PRESETS:
            return {...state, rawPresets: action.payload};
        case UPDATE_CHECKLISTS:
            return {...state, checklists: action.payload};
        case UPDATE_REPORTING:
            return {...state, reporting: action.payload};
        case UPDATE_CHECKLIST_INSTANCES:
            return {...state, checklistInstances: action.payload};
        case UPDATE_SETTINGS:
            return {...state, settings: action.payload};
        case UPDATE_ROLES:
            return {...state, roles: action.payload};
        case UPDATE_SETUP_FORMS:
            return {...state, setupForms: action.payload};
        case UPDATE_DISCOUNTS:
            return {...state, discounts: action.payload};
        case UPDATE_CLOSING:
            return {...state, closing: action.payload};
        case UPDATE_PRODUCT_AVAILABILITY:
            return {...state, productAvailability: action.payload};
        case UPDATE_OTHER_PAYMENTS:
            return {...state, otherPayments: action.payload};
        case UPDATE_PROMOTIONS:
            return {...state, promotions: action.payload};
        case UPDATE_COUPONS:
            return {...state, coupons: action.payload};
        case UPDATE_DEVICES:
            return {...state, devices: action.payload};
        case UPDATE_TEXT_CAMPAIGNS:
            return {...state, textCampaigns: action.payload};
        case UPDATE_EMAIL_CAMPAIGNS:
            return {...state, emailCampaigns: action.payload};
        default:
            return state
    }
}
