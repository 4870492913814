import React, {Component} from 'react'
import Card from "./modules/Card";
import {Chart} from "react-charts";
import {formatDate, toDollars, uploadImage} from "../../util/Util";
import ReactTooltip from "react-tooltip";

const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
const FULL_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
let chrono = require('chrono-node');

function getHotDay(array) {
    let most = array[0], value = [0];
    for (let i = 1; i < array.length; i++) {
        if (array[i] > most) {
            value = [i];
            most = array[i];
        } else if (array[i] === most) {
            value.push(i);
        }
    }

    if (most === 0) {
        return "NaN";
    }

    return value.map((item) => {
        return DAYS[item];
    }).join("/");
}


class DashboardScreen extends Component {
    render() {
        let startOfWeek = chrono.parseDate("last saturday at 11:59:59PM").getTime();
        let weeklyOrders = this.props.partner.orders.filter((item) => item.DATE_SENT > startOfWeek);
        let weeklyMembers = new Set(weeklyOrders.map((item) => item.ACCOUNT_ID));

        let weeklyTotal = 0;
        let weeklyAverage = weeklyOrders.reduce((accum, item) => {
            weeklyTotal += item.SUBTOTAL * .921 + item.TAXES;

            return accum + item.SUBTOTAL;
        }, 0) / weeklyOrders.length;

        this.props.partner.tips.filter((item) => item.DATE_SENT > startOfWeek).forEach((item) => {
            weeklyTotal += item.AMOUNT;
        });

        let orders = this.props.partner.orders.slice().reverse();

        let patrons = this.props.partner.orders.reduce((dict, item) => {
            if (item.ACCOUNT_ID in dict) {
                dict[item.ACCOUNT_ID] += 1;
            } else {
                dict[item.ACCOUNT_ID] = 1;
            }

            return dict;
        }, {});

        let Today = new Date();
        Today.setSeconds(0);
        Today.setMinutes(0);
        Today.setHours(4);
        Today.setMilliseconds(1);

        let last = 0;
        let lists = [];
        let lists2 = [];
        for (let i = 0; i < 7; i++) {
            let list = [];
            let secondList = [];

            for (let j = last; j < orders.length; j++) {
                let drink = orders[j];

                if (drink.DATE_SENT < Today.getTime()) {
                    last = j;
                    break;
                }

                if (patrons[drink.ACCOUNT_ID] === 1) {
                    secondList.push(drink.ACCOUNT_ID);
                }

                patrons[drink.ACCOUNT_ID] -= 1;

                list.push(drink);
                if (j === orders.length - 1) {
                    last = j + 1;
                }
            }

            lists.splice(0, 0, [FULL_DAYS[Today.getDay()], list.length]);
            lists2.splice(0, 0, [FULL_DAYS[Today.getDay()], secondList.length]);
            Today.setTime(Today.getTime() - (1000 * 60 * 60 * 24));
        }

        if (isNaN(weeklyAverage)) {
            weeklyAverage = 0;
        }

        return (
            <div className="col g-ml-45 g-ml-0--lg g-pb-65--md">
                <div className="g-pa-20">
                    <div className="row">
                        <Card name="Weekly Orders" value={weeklyOrders.length} icon="fa fa-shopping-cart"/>
                        <Card name="Weekly Patrons" value={weeklyMembers.size} icon="fa fa-users"/>
                        <Card name="Weekly Average" value={"$" + toDollars(weeklyAverage)} icon="fa fa-dollar-sign"/>
                        <Card name="Weekly Income" value={"$" + toDollars(weeklyTotal)} icon="fa fa-chart-line"/>
                    </div>

                    <div className="row">
                        <div className="col-xl-4">
                            {/* User Card */}
                            {/*<div
                                className="card g-brd-gray-light-v7 text-center g-pt-40 g-pt-60--md g-mb-30">
                                <header className="g-mb-30">
                                    <img
                                        className="img-fluid rounded-circle g-width-125 g-height-125 g-mb-14"
                                        src={location.LOGO} width={200} height={200}
                                        alt=""/>
                                    <h3 className="g-font-weight-300 g-font-size-22 g-color-black g-mb-2">
                                        {location.NAME}</h3>
                                    <em className="g-font-style-normal g-font-weight-300 g-color-gray-dark-v6">{location.CITY},
                                        {location.STATE}</em>
                                </header>
                                <section
                                    className="row no-gutters g-brd-top g-brd-gray-light-v4">
                                    <div className="col-6 g-py-10 g-py-25--md">
                                        <strong
                                            className="d-block g-font-weight-500 g-font-size-18 g-color-black">
                                            {this.props.partner.members.length}
                                        </strong>
                                        <span
                                            className="g-font-weight-300 g-color-gray-dark-v6">Patrons</span>
                                    </div>
                                    <div
                                        className="col-6 g-brd-left--md g-brd-gray-light-v4 g-py-10 g-py-25--md">
                                        <strong
                                            className="d-block g-font-weight-500 g-font-size-18 g-color-black">
                                            {this.props.partner.orders.length}
                                        </strong>
                                        <span
                                            className="g-font-weight-300 g-color-gray-dark-v6">Orders</span>
                                    </div>
                                </section>
                            </div>*/}
                            {/* End User Card */}
                        </div>


                        <div className="col-12">
                            <div className="card g-brd-gray-light-v7 g-pa-15 g-pa-25-30--md g-mb-30 test_breakdog">
                                <header className="media g-mb-30">
                                    <h3 className="d-flex align-self-center text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                        Weekly Stats
                                    </h3>
                                </header>

                                <section>
                                    <Chart
                                        style={{
                                            width: "400px",
                                            height: "200px",
                                            marginBottom: 100
                                        }}
                                        data={[
                                            {
                                                label: "Orders",
                                                data: lists
                                            },
                                            {
                                                label: "New Patrons",
                                                data: lists2
                                            }
                                        ]}
                                        series={{type: 'line'}}
                                        axes={[
                                            {primary: true, type: 'ordinal', position: 'bottom'},
                                            {position: 'left', type: 'linear', stacked: false},
                                        ]}
                                        primaryCursor
                                        secondaryCursor
                                        tooltip
                                    />
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    static toDate(utc) {
        let jsDate = new Date(utc);

        let time = '';
        if (jsDate.getHours() > 12) {
            time = jsDate.getHours() % 12 + ':' + jsDate.getMinutes() + ' PM on ';
        } else {
            if (jsDate.getHours() === 0) {
                time = '12:' + jsDate.getMinutes() + ' AM on ';
            } else {
                time = jsDate.getHours() + ':' + jsDate.getMinutes() + ' AM on ';
            }
        }

        return time + jsDate.toDateString()
    }
}

export default DashboardScreen;
