import React, {Component} from 'react';
import Fancy from "../../modules/fancy/Fancy";
import 'bootstrap-daterangepicker/daterangepicker.css';
import {formatDate, request, toDollars} from "../../util/Util";
import {CorkTable} from 'corky'
import {Link} from 'react-router-dom'
import CorkButton from "../../modules/CorkButton";

class VendorsScreen extends Component {
    state = {
        id: -1,
        name: "",
        address: "",
        city: "",
        state: "",
        phone: "",
        email: ""
    };

    componentWillMount() {
        this.setState({data: this.props.partner.products});
    }

    createEmployee() {
        let {name, phone, email, address, state, city} = this.state;

        let payload = {
            NAME: name,
            EMAIL: email,
            ADDRESS: address,
            STATE: state,
            CITY: city,
            PHONE: phone
        };

        request("accounting/vendor", "POST", payload, (err, id) => {
            if (err) {
                alert("ERROR");
                return;
            }

            this.fancy.close().then(() => {
                this.setState({name: "", phone: "", email: "", address: "", state: "", city: ""}, () => {
                    payload.ID = id;

                    this.props.updateVendors([...this.props.partner.vendors, payload]);

                    // this.props.history.push("/product/" + id);
                });
            });
        });
    }

    render() {
        let {name, phone, email, address, city, state} = this.state;

        let Data = this.props.partner.vendors.map((item) => {
            return {
                onDelete: () => this.deleteProduct(item.ID),
                onEdit: () => this.props.history.push("/product/" + item.ID),
                ID: item.ID,
                NAME: item.NAME
            }
        });

        return (
            <div>
                <Fancy name="Create Vendor" ref={(e) => this.fancy = e}
                       onClick={this.createEmployee.bind(this)}
                >
                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Name</label>

                        <input name="firstName"
                               className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-primary--focus g-brd-red--error g-rounded-4 g-px-20 g-py-12"
                               type="text" value={name} onChange={(e) => this.setState({name: e.target.value})}
                               aria-invalid="false"/>
                    </div>

                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Address</label>

                        <input name="firstName"
                               className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-primary--focus g-brd-red--error g-rounded-4 g-px-20 g-py-12"
                               type="text" value={address} onChange={(e) => this.setState({address: e.target.value})}
                               aria-invalid="false"/>
                    </div>


                    <div className="row g-mb-20">
                        <div className="col-md-6">
                            <label className="g-mb-10" htmlFor="#bio">City</label>

                            <input name="firstName"
                                   className="form-control form-control-md g-brd-gray-light-v7 g-brd-primary--focus g-brd-red--error g-rounded-4 g-px-20 g-py-12"
                                   type="text" value={city} onChange={(e) => this.setState({city: e.target.value})}
                                   aria-invalid="false"/>
                        </div>

                        <div className="col-md-6">
                            <label className="g-mb-10" htmlFor="#bio">State</label>

                            <input name="firstName"
                                   className="form-control form-control-md g-brd-gray-light-v7 g-brd-primary--focus g-brd-red--error g-rounded-4 g-px-20 g-py-12"
                                   type="text" value={state} onChange={(e) => this.setState({state: e.target.value})}
                                   aria-invalid="false"/>
                        </div>
                    </div>


                    <div className="row g-mb-20">
                        <div className="col-md-6">
                            <label className="g-mb-10" htmlFor="#bio">Phone</label>

                            <input name="firstName"
                                   className="form-control form-control-md g-brd-gray-light-v7 g-brd-primary--focus g-brd-red--error g-rounded-4 g-px-20 g-py-12"
                                   type="text" value={phone} onChange={(e) => this.setState({phone: e.target.value})}
                                   aria-invalid="false"/>
                        </div>

                        <div className="col-md-6">
                            <label className="g-mb-10" htmlFor="#bio">Email</label>

                            <input name="firstName"
                                   className="form-control form-control-md g-brd-gray-light-v7 g-brd-primary--focus g-brd-red--error g-rounded-4 g-px-20 g-py-12"
                                   type="text" value={email} onChange={(e) => this.setState({email: e.target.value})}
                                   aria-invalid="false"/>
                        </div>
                    </div>
                </Fancy>

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">Vendors</h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton onClick={() => {
                                this.setState({
                                    id: -1,
                                    name: "",
                                    phone: ""
                                }, () => {
                                    this.fancy.open();
                                });
                            }}>
                                Create Vendor
                            </CorkButton>
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <CorkTable settings={this.state.settings} ref={(e) => this.table = e}
                                   edit={true} delete={true} header={{
                            ID: {
                                NAME: "#",
                                WIDTH: 10,
                                TYPE: "Number"
                            },
                            NAME: {
                                NAME: "Name",
                                WIDTH: 45,
                                TYPE: "String",
                                LINK: "/vendor/",
                                LINK_ID: "ID"
                            }
                        }} sort="ID" data={Data} desc={true} sortItems={["_TITLE"]}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default VendorsScreen;
