import React, {Component} from "react"
import PropTypes from 'prop-types';
import {MoonLoader} from "react-spinners";

class CorkButton extends Component {
    state = {loading: false};

    static TYPES = {
        GENERAL: {
            backgroundColor: "#4ACE9C",
            color: "white"
        },
        RED: {
            backgroundColor: "#D43A57",
            color: "white"
        },
        DISABLED: {
            backgroundColor: "#C6C6C6",
            color: "white"
        },
        OUTLINE: {
            borderColor: "#CCCCCC",
            color: "#808080"
        }
    };

    getType() {
        let {type} = this.props;

        if (type) {
            if (typeof type === "string") {
                return CorkButton.TYPES[this.props.type];
            } else {
                return type;
            }
        }

        return CorkButton.TYPES.GENERAL
    }

    extraStyle() {
        let {marginLeft, marginRight, bold} = this.props;
        let extra = {};

        if (marginLeft) {
            extra.marginLeft = 5;
        }

        if (marginRight) {
            extra.marginRight = 5;
        }

        if (bold) {
            extra.fontWeight = "bold";
        }

        return extra
    }

    startLoading() {
        this.setState({loading: true});
    }

    stopLoading() {
        this.setState({loading: false});
    }

    renderLoading() {
        let {loading} = this.state;

        if (!loading && !this.props.loading) {
            return;
        }
        return (
            <div className="moon-fix" style={{marginLeft: 8}}>
                <MoonLoader size={14} sizeUnit="px" color="#808080" loading={true}/>
            </div>
        )
    }

    render() {
        let {onClick} = this.props;

        return (
            <button style={{
                ...this.getType(), ...this.props.style, ...this.extraStyle(),
                minWidth: 160
            }}
                    onClick={onClick}
                    className="btn btn-md btn-xl--md u-btn-secondary g-font-size-12 g-font-size-default--md cork-button"
                    type="submit">
                <div className="row" style={{alignItems: "center", justifyContent: "center"}}>
                    {this.props.children}

                    {this.renderLoading()}
                </div>
            </button>
        )
    }
}

CorkButton.propTypes = {
    children: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    type: PropTypes.string,

    marginLeft: PropTypes.bool,
    marginRight: PropTypes.bool,
    bold: PropTypes.bool
};

export default CorkButton;
