import React, {Component} from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {
    formatDate,
    formatPhone,
    getFormattedPhone,
    getObjectImage,
    parseIdDict,
    request,
    toDollars
} from "../../util/Util";
import {CorkTable} from 'corky'
import CreateEmployeeModal from "./modals/CreateEmployeeModal";
import CorkButton from "../../modules/CorkButton";

const ACTIVE = <span
    className="u-tags-v1 text-center g-width-150--md g-brd-around g-bg-darkblue-v2 g-font-size-12 g-font-size-default--md g-color-white g-rounded-50 g-py-4 g-px-15">Active</span>
const INACTIVE = <span
    className="u-tags-v1 text-center g-width-150--md g-brd-around g-bg-darkred g-font-size-12 g-font-size-default--md g-color-white g-rounded-50 g-py-4 g-px-15">Inactive</span>

class EmployeesScreen extends Component {
    state = {
        id: -1,
        name: "",
        phone: "",
        category: null,
        settings: null,
        data: null
    };

    componentWillMount() {
        this.setState({data: this.props.partner.products});
    }

    createEmployee() {
        let {name, phone} = this.state;
        request("employees", "POST", {NAME: name, PHONE: phone}, (err, payload) => {
            if (err) {
                alert("ERROR");
                return;
            }

            this.fancy.close().then(() => {
                this.setState({name: "", phone: ""}, () => {
                    this.props.updateEmployees([...this.props.partner.employees, payload]);

                    this.props.history.push("/employee/" + payload.ID);
                });
            });
        });
    }

    render() {
        let {employees, roles} = this.props.partner;

        roles = parseIdDict(roles);
        let Data = employees.map((item) => {
            return {
                onDelete: () => this.deleteProduct(item.ID),
                onEdit: () => this.props.history.push("/product/" + item.ID),
                LOGO: (
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <img
                            className="g-width-40 g-width-50--md g-height-40 g-height-50--md g-brd-around g-brd-2 g-brd-transparent g-brd-lightblue-v3--parent-opened rounded-circle"
                            src={getObjectImage(item, "ACCOUNT_LOGO", "appicon.png")} alt="Product Image"/>
                    </div>
                ),
                ID: item.ID,
                NAME: item.FULL_NAME,
                ROLE: item.ROLE_NAME,
                PHONE: getFormattedPhone(item.PHONE),
                STATUS: item.ACTIVE ?
                    <div>{ACTIVE}</div> :
                    <div>{INACTIVE}</div>,
                _STATUS: item.ACTIVE
            }
        });

        return (
            <div>
                <CreateEmployeeModal ref={(e) => this.fancy = e} {...this.props} />

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">Employees</h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton onClick={() => this.setState({
                                id: -1,
                                name: "",
                                phone: ""
                            }, () => {
                                this.fancy.open();
                            })}>
                                Create Employee
                            </CorkButton>
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <CorkTable header={{
                            LOGO: {
                                NAME: "",
                                WIDTH: 5,
                                SORT: "ID"
                            },
                            NAME: {
                                NAME: "Name",
                                WIDTH: 45,
                                TYPE: "String",
                                LINK: "/employee/",
                                LINK_ID: "ID"
                            },
                            ROLE: {
                                NAME: "Role",
                                WIDTH: 20
                            },
                            PHONE: {
                                NAME: "Phone",
                                WIDTH: 20
                            },
                            STATUS: {
                                NAME: "Status",
                                WIDTH: 10
                            },
                            // DOWNLOAD: {
                            //     NAME: "Download",
                            //     WIDTH: 10
                            // }
                        }} filters={[{
                            label: "Status:",
                            column: "_STATUS",
                            items: [
                                {label: "All", badge: "g-bg-black", value: null},
                                {label: "Active", badge: "g-bg-darkblue-v2", value: 1},
                                {label: "Disabled", badge: "g-bg-darkred", value: 0}
                            ]
                        }]} sort="LOGO" data={Data} headerMode="simple" desc={true} title=""/>
                    </div>
                </div>
            </div>
        )
    }
}

export default EmployeesScreen;
