import React, {Component} from 'react'
import {Switch, Route, Link} from 'react-router-dom'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import Actions from '../../actions/index'

import {
    withRouter
} from 'react-router-dom'
import TicketTab from "./tabs/TicketTab";
import SecondaryNavItem from "../../modules/SecondaryNavItem";
import CheckoutTab from "./tabs/CheckoutTab";
import ReportsTab from "./tabs/ReportsTab";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class ChecklistScreen extends Component {
    render() {
        return (
            <div>
                <div className="g-pa-20">
                    <div className="row">
                        <div className="col-md-3 g-mb-30 g-mb-0--md">
                            <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                                <section className="text-center g-mb-30 g-mb-50--md">
                                    <div className="d-inline-block g-pos-rel g-mb-20">
                                        <img className="img-fluid rounded-circle"
                                             src={"../../assets/img/settings.png"}
                                             alt=""/>
                                    </div>
                                    <h3 className="g-font-weight-300 g-font-size-20 g-color-black mb-0">
                                        Settings
                                    </h3>
                                </section>

                                <section>
                                    <ul className="list-unstyled mb-0">
                                        <SecondaryNavItem icon="hs-admin-shopping-cart" {...this.props}
                                                          url={"/settings/checkout"} name="Checkout"/>
                                        <SecondaryNavItem icon="hs-admin-ticket" {...this.props}
                                                          url={"/settings/ticket"} name="Tickets"/>
                                        <SecondaryNavItem icon="fa fa-chart-bar" {...this.props}
                                                          url={"/settings/reports"} name="Reports"/>
                                    </ul>
                                </section>
                            </div>
                        </div>

                        <Switch>
                            <Route exact path='/settings/checkout'
                                   component={() => <CheckoutTab {...this.props} />}/>
                            <Route exact path='/settings/ticket'
                                   component={() => <TicketTab {...this.props} />}/>
                            <Route exact path='/settings/reports'
                                   component={() => <ReportsTab {...this.props} />}/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistScreen));