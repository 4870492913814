import React, {Component} from "react"
import Fancy from "../../../../modules/fancy/Fancy";

import {FancyText, FancyDate, FancySelect} from "corky"
import {COLORS, randomString, request, uploadImage} from "../../../../util/Util";
import {EMPLOYEE_NEW_HIRE, EMPLOYEE_STATUS, EMPLOYEE_TYPES} from "../../../../util/Constants";
import moment from "moment"

const STEPS = {
    ACCOUNT: 0,
    EMPLOYEE: 1,
    CONFIRM: 2
};

const FIELDS = {
    NAME: "form-name",
};

class CreateFormModal extends Component {
    state = {img: null};

    constructor(props) {
        super(props);

        this.form = {};
    }

    uploadLogo() {
        let {img} = this.state;

        return new Promise((resolve, reject) => {
            uploadImage("shop/pdf", img, (err, payload) => {
                if (err) {
                    return reject();
                }

                resolve(payload);
            });
        });
    }

    open() {
        this.form = {};

        this.setState({img: null}, () => this.fancy.open());
    }

    create() {
        let {setupForms, location} = this.props.partner;
        let {img} = this.state;
        let form = this.form;

        if (img === null) {
            return alert("Please upload a pdf to make custom form");
        }

        if (!this.name.isValid()) {
            return;
        }

        let aLogo = null;
        this.uploadLogo().then((logo) => {
            aLogo = logo;
            return request("forms", "POST", {NAME: form[FIELDS.NAME], LOGO: logo});
        }).then((id) => {
            this.fancy.close().then(() => {
                setupForms.push({
                    ID: id,
                    URL: aLogo,
                    NAME: form[FIELDS.NAME],
                    LOCATION_ID: location.ID,
                    DATE_CREATED: new Date().getTime(),
                    DATE_UPDATED: new Date().getTime()
                });

                this.props.updateSetupForms(setupForms);
            })
        }).catch((err) => {
            alert("Internal error uploading form");
        })
    }

    render() {
        let form = this.form;
        let {img} = this.state;

        console.dir(img);

        return (
            <Fancy name="Add Custom Form" onClick={() => this.create()} cancelText="Cancel" ref={(e) => this.fancy = e}
                   buttonText="Create"
            >
                <div className="g-mb-20">
                    <FancyText form={form} id={FIELDS.NAME} type={FancyText.TYPES.TEXT} required={true}
                               ref={(e) => this.name = e} label="Form Name"/>
                </div>

                <div className="row g-mb-20">
                    <FancyText value={img === null ? "Upload a Image -->" : img.name} disabled={true}
                               ref={(e) => this.imageRef = e} label="Form PDF" half={true}/>

                    <button onClick={() => {
                        document.getElementById("uploadLogo").click();
                    }}>
                        Click to upload form
                    </button>
                </div>

                <input id="uploadLogo" type="file" style={{display: "none", height: 50}} ref={(e) => this.click = e}
                       onChange={() => {
                           let files = this.click.files;
                           if (files.length === 0) {
                               return alert("Please select a valid file");
                           }

                           if (files.length > 1) {
                               return alert("Please only select one file");
                           }

                           if (files[0].name.split(".")[1] !== "pdf") {
                               return alert("Please upload a pdf file type.")
                           }

                           this.setState({img: files[0]}, () => this.imageRef.setState({value: files[0].name}));
                       }}/>
            </Fancy>
        )
    }
}

export default CreateFormModal;