import React, {Component} from "react"
import Fancy from "../../../modules/fancy/Fancy";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {request} from "../../../util/Util";

const grid = 5;
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "lightgray",

    // styles we need to apply on draggables
    ...draggableStyle
});

class ReorderModal extends Component {
    state = {products: []};

    open(category) {
        let products = [...this.props.partner.products.filter((item) => category === item.CATEGORY_ID)];
        products.sort((a, b) => (a.SEQ === 0 ? 1000000 : a.SEQ) - (b.SEQ === 0 ? 10000 : b.SEQ));

        this.setState({products});
        this.modal.open();
    }

    saveOrder() {
        let {products} = this.state;

        request("products/seq", "POST", {
            ITEMS: products.map((item, i) => ({
                PRODUCT_ID: item.ID,
                SEQ: i + 1
            }))
        }).then(() => {
            this.modal.close().then(() => {
                let actual = this.props.partner.products;

                products.forEach((item, i) => {
                    actual.find((_item) => item.ID === _item.ID).SEQ = i + 1;
                });

                this.props.updateProducts(actual);
                alert("Reordering saved");
            });
        });
    }

    reorderFunc(info) {
        let {products} = this.state;

        if (!info.destination) {
            return products;
        }

        const [removed] = products.splice(info.source.index, 1);
        products.splice(info.destination.index, 0, removed);

        this.setState({products});
        return products;
    };

    render() {
        let {products} = this.state;

        return (
            <Fancy name="Order Products" ref={(e) => this.modal = e} onClick={this.saveOrder.bind(this)}
                   buttonText="Save">
                <div className="g-mb-20" style={{height: 300, overflowY: "scroll"}}>
                    <DragDropContext onDragEnd={this.reorderFunc.bind(this)}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {
                                        products.map((item, index) => (
                                            <Draggable key={item.ID} draggableId={item.ID} index={index}>
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef}
                                                         {...provided.draggableProps}
                                                         {...provided.dragHandleProps}
                                                         style={getItemStyle(
                                                             snapshot.isDragging,
                                                             provided.draggableProps.style
                                                         )}
                                                    >
                                                        {item.NAME}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))
                                    }
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </Fancy>
        )
    }
}

export default ReorderModal;