import React, {Component} from 'react'
import {CorkTable} from 'corky'
import CorkButton from "../../modules/CorkButton";

class RetentionScreen extends Component {
    render() {
        return (
            <div className="g-pa-20">
                <div className="media">
                    <div className="d-flex align-self-center">
                        <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                            Retention Campaigns
                        </h1>
                    </div>

                    <div className="media-body align-self-center text-right">
                        <CorkButton onClick={() => {}}>
                            Start Campaign
                        </CorkButton>
                    </div>
                </div>

                <div className="row">
                    <CorkTable header={{
                        ID: {
                            NAME: "Identifier",
                            WIDTH: 10
                        },
                        NAME: {
                            NAME: "Name",
                            WIDTH: 20,
                            LINK: "/campaign/",
                            LINK_ID: "ID"
                        },
                        TOTAL: {
                            NAME: "Patrons Sent",
                            WIDTH: 15
                        },
                        MONTHLY: {
                            NAME: "Patrons Returned",
                            WIDTH: 15
                        },
                        FAVORITE: {
                            NAME: "Success Rate",
                            WIDTH: 15
                        },
                        OFFER: {
                            NAME: "Offer",
                            WIDTH: 10
                        },
                        STATUS: {
                            NAME: "Status",
                            WIDTH: 10
                        }
                    }} sort="ID" edit={true} delete={true} data={[]} desc={true} title="Retention"/>
                </div>
            </div>
        );
    }
}

export default RetentionScreen;
