import React, {Component} from 'react'
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types"

class FormItem extends Component {
    render() {
        let Tooltip = <div/>;
        let {placeholder} = this.props;

        if (this.props.tooltip) {
            Tooltip = (
                <i style={{marginLeft: 5}} className="fa fa-info-circle" data-tip={this.props.tooltip}/>
            );
        }

        return (
            <div className="row g-mb-20">
                <div className="col-md-3 align-self-center g-mb-5 g-mb-0--md">
                    <label className="mb-0">{this.props.name}</label>

                    {Tooltip}
                    <ReactTooltip/>
                </div>

                <div className="col-md-9 align-self-center">
                    <div className="form-group g-pos-rel mb-0 has-success">
                        {/*<span*/}
                        {/*    className="g-pos-abs g-top-0 g-right-0 d-block g-width-40 h-100 opacity-0 g-opacity-1--success">*/}
                        {/*        <i className="hs-admin-check g-absolute-centered g-font-size-default g-color-secondary"/>*/}
                        {/*</span>*/}
                        <input
                            className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-primary--focus g-brd-red--error g-rounded-4 g-px-20 g-py-12"
                            type="text" value={this.props.value} disabled={this.props.disabled ? "disabled" : false}
                            onChange={(e) => {
                                if (typeof this.props.onChange !== "undefined") {
                                    this.props.onChange(e.target.value);
                                }
                            }} aria-invalid="false" placeholder={placeholder}/>
                    </div>
                </div>
            </div>
        )
    }
}

FormItem.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
};

export default FormItem;