import React, {Component} from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {COLORS, formatDate, parseIdDict, request, toDollars} from "../../util/Util";
import {CorkTable, FancySelect} from 'corky'
import CorkButton from "../../modules/CorkButton";
import {Fancy, FancyText} from "corky";
import {Team} from "drip-drop"
import {bindActionCreators} from "redux";
import Actions from "../../actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import RoleModal from "../employees/modals/RoleModal";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class RolesScreen extends Component {
    render() {
        let {roles, employees} = this.props.partner;

        let Data = roles.map((item) => {
            return {
                onDelete: () => this.deleteProduct(item.ID),
                onEdit: () => this.props.history.push("/role/" + item.ID),
                ID: item.ID,
                NAME: item.NAME,
                EMPLOYEES: employees.filter((employee) => employee.ROLE_ID === item.ID).length + " Employees"
            }
        });

        return (
            <div>
                <RoleModal ref={(e) => this.roleModal = e} {...this.props} />

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">Roles</h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton onClick={() => this.roleModal.open()}>
                                Create Role
                            </CorkButton>
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        <CorkTable header={{
                            NAME: {
                                NAME: "Name",
                                WIDTH: 40,
                                TYPE: "String"
                            },
                            EMPLOYEES: {
                                NAME: "Employees",
                                WIDTH: 40,
                                LINK: "/role/:ID/employees",
                                LINK_ID: "ID",
                                TYPE: "String"
                            },
                        }} edit={true} sort="ID" data={Data} desc={true} title=""/>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RolesScreen));
