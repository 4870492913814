import React, {Component} from "react"
import PropTypes from "prop-types"
import ReactTooltip from "react-tooltip";

class FancyComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: null,
            error: null
        };
    }

    componentWillMount() {
        let {value, form, id} = this.props;

        // If we have a form and id field, and it holds content we want to set the content as our value
        if (form && id && form[id]) {
            return this.setState({value: form[id]});
        }

        if (value) {
            if (form && id) {
                form[id] = value;
            }

            this.setState({value});
        }
    }

    getValue() {
        let {parentState, name} = this.props;
        let {value} = this.state;

        if (parentState && name) {
            return parentState[name];
        }

        return value;
    }

    setValue(txt) {
        let {form, id, setParent, name} = this.props;

        if (form && id) {
            form[id] = txt;
        }

        if (setParent && name) {
            setParent({[name]: txt});
        }

        this.setState({value: txt});
    }

    onChange(txt) {
        let {form, id, setParent, name} = this.props;

        if (form && id) {
            form[id] = txt;
        }

        if (setParent && name) {
            setParent({[name]: txt});
        }

        this.setState({value: txt});
    }

    renderIcon() {
        let {customIcon, icon, iconClick} = this.props;

        if (customIcon) {
            return customIcon;
        }

        if (!icon) {
            return
        }

        return (
            <span className="g-pos-abs g-top-0 g-right-0 d-block g-width-50 h-100 show-cursor fancy-icon" onClick={iconClick}>
                  <i className={icon + " g-absolute-centered g-font-size-16 g-color-gray-light-v6 g-color-primary--hover fancy-icon"}/>
            </span>
        )
    }

    renderSubtext() {
        let {error} = this.state;

        if (!error) {
            return <div/>
        }

        return (
            <small className="g-font-weight-300 g-font-size-12 g-color-lightred-v2 g-pt-5">
                {error}
            </small>
        )
    }

    renderTooltip() {
        let {tooltip} = this.props;

        if (!tooltip) {
            return;
        }

        return (
            <i style={{marginLeft: 5}} className="fa fa-info-circle" data-tip={tooltip}/>
        )
    }

    renderLabel() {
        let {label, labelStyle} = this.props;

        if (!label) {
            return;
        }

        return (
            <label style={{...labelStyle}} className="g-mb-10" htmlFor="#bio">
                {label}

                {this.renderTooltip()}
                <ReactTooltip/>
            </label>
        )
    }

    render() {
        let {half, quad, label, placeholder, id} = this.props;
        let {value} = this.state;

        let wrapperClass = "g-mb-20";
        if (half) {
            wrapperClass = "col-md-6";
        } else if (quad) {
            wrapperClass = "col-md-3";
        }

        return (
            <div className={wrapperClass}>
                <label className="g-mb-10" htmlFor="#bio">
                    {label}
                </label>

                {this.renderSubtext()}
            </div>
        )
    }
}

FancyComponent.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    iconClick: PropTypes.func,
    value: PropTypes.string,
    icon: PropTypes.string,
    half: PropTypes.bool,
    quad: PropTypes.bool,
    form: PropTypes.object,
    id: PropTypes.string,
    customIcon: PropTypes.object,
    labelStyle: PropTypes.object,
    tooltip: PropTypes.string
};

export default FancyComponent;