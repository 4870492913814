import React, {Component} from 'react'
import FormItem from "../../../modules/form/FormItem";
import FormTextBox from "../../../modules/form/FormTextBox";
import {request} from "../../../util/Util";

const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];

class EditTab extends Component {
    state = {
        value: ""
    };

    componentDidMount() {
        const {ID} = this.props.match.params;
        let Section = this.props.partner.sections.find((item) => {
            return item.LETTER_ID === parseInt(ID);
        });

        if (typeof Section !== "undefined") {
            this.setState({value: Section.CONTENT});
        }
    }

    componentWillUnmount() {
        const {ID} = this.props.match.params;
        let Section = this.props.partner.sections.find((item) => {
            return item.LETTER_ID === parseInt(ID);
        });

        if (typeof Section === "undefined") {
            return;
        }

        Section.CONTENT = this.state.value;
        this.props.updateSections([...this.props.partner.sections, Section]);
    }

    render() {
        const {ID} = this.props.match.params;

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <form className="js-validate" onSubmit={(e) => e.preventDefault()}>
                        <header>
                            <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                General information
                            </h2>

                            <textarea name="firstName" style={{marginTop: 10}}
                                      className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-primary--focus g-brd-red--error g-rounded-4 g-px-20 g-py-12"
                                      rows={10} value={this.state.value}
                                      onChange={(e) => {
                                          this.setState({value: e.target.value});
                                      }} aria-invalid="false"/>
                        </header>

                        <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                        <div>
                            <button style={{backgroundColor: "#4ACE9C"}}
                                    onClick={() => {
                                        this.props.updateSections([...this.props.partner.sections, {
                                            LETTER_ID: 1,
                                            CONTENT: this.state.value
                                        }]);
                                    }}
                                    className="btn btn-md btn-xl--md u-btn-secondary g-width-160--md g-font-size-12 g-font-size-default--md g-mb-10">
                                Save Changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}


export default EditTab;
