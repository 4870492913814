import React, {Component} from 'react'
import 'react-day-picker/lib/style.css';
import {CorkTable} from "corky";

let moment = require("moment");

class PayoutsTab extends Component {
    render() {
        let {payouts} = this.props.partner;
        let {employee} = this.props;

        let Payouts = payouts.filter((item) => item.EMPLOYEE_ID === employee.ID).map((item) => {
            return {
                ID: item.ID,
                DATE: moment(item.DATE_START).format("MM/DD/YY"),
                AMOUNT: item.AMOUNT,
                TRANSACTIONS: item.TRANSACTION_ID,
                EMPLOYEE_ID: employee.ID
            }
        });

        return (
            <div className="col-md-9">
                <div className="media">
                    <div className="d-flex align-self-center">
                        <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">Payouts</h1>
                    </div>
                </div>

                <hr />

                <CorkTable header={{
                    ID: {
                        NAME: "#",
                        WIDTH: 10,
                        LINK: "/employee/:EMPLOYEE/payout/:TEST",
                        LINKS: {
                            TEST: "ID",
                            EMPLOYEE: "EMPLOYEE_ID"
                        }
                    },
                    AMOUNT: {
                        NAME: "Amount",
                        WIDTH: 10,
                    },
                    DATE: {
                        NAME: "Date",
                        WIDTH: 25
                    },
                    TRANSACTIONS: {
                        NAME: "Transactions",
                        WIDTH: 15
                    }
                }} sort="DATE" data={Payouts} desc={true} title=""/>
            </div>
        );
    }
}


export default PayoutsTab;