import React, {Component} from "react"
import Fancy from "../../../modules/fancy/Fancy";
import {FancySelect, FancyText} from "corky";
import {Products} from "drip-drop";
import {request} from "../../../util/Util";

const FIELDS = {
    NAME: "customization-name",
    INTERNAL_NAME: "customization-internal-name",
    TYPE: "customization-type"
};

class CreateCustomizationModal extends Component {
    state = {required: false, variant: false};

    constructor(props) {
        super(props);

        this.form = {};
    }

    open(vary) {
        this.setState({variant: !!vary}, () => {
            this.modal.open();
        })
    }

    add() {
        let {product} = this.props;
        let {required, variant} = this.state;

        let isValid = true;
        for (let field of this.fields) {
            if (field === null) {
                continue;
            }

            if (!field.isValid()) {
                isValid = false;
            }
        }

        if (!isValid) {
            return;
        }

        let name = this.form[FIELDS.NAME], internal = this.form[FIELDS.INTERNAL_NAME],
            type = variant ? Products.Constants.CUSTOMIZATION_TYPES.VARIANT : this.form[FIELDS.TYPE].value;
        request("product/" + product.ID + "/customization", "POST", {
            NAME: name,
            TYPE: type,
            INTERNAL_NAME: internal ? internal : null,
            REQUIRED: variant ? true : required
        }, (err, id) => {
            if (err) {
                alert("ERROR");
                return;
            }

            let products = this.props.partner.products;
            let index = products.findIndex((item) => item.ID === parseInt(product.ID));

            products.splice(index, 1, {
                ...products[index],
                CUSTOMIZATIONS: [...products[index].CUSTOMIZATIONS, {
                    ID: id,
                    NAME: name,
                    OPTIONS: [],
                    TYPE: type,
                    PRESET: 0,
                    REQUIRED: variant ? true : required,
                    INTERNAL_NAME: internal ? internal : null,
                    ENABLED: 1
                }]
            });

            this.modal.close().then(() => {
                this.props.updateProducts(products);
            });
        });
    }

    render() {
        let {required, variant} = this.state;

        this.fields = [];
        let form = this.form;

        return (
            <Fancy name={variant ? "Create Variant" : "Create Modifier"} ref={(e) => this.modal = e}
                   onClick={this.add.bind(this)}>
                <div className="row g-mb-20">
                    <FancyText label="Name" placeholder="Ex: Sizes, Options, etc." form={form} id={FIELDS.NAME} required={true}
                               ref={(e) => this.fields.push(e)} half={true}/>

                    <FancyText label="Internal Name (Optional)" placeholder="Ex: Sizing options for our drink products" form={form} id={FIELDS.INTERNAL_NAME} required={false}
                               ref={(e) => this.fields.push(e)} half={true}/>
                </div>

                {variant ? undefined : (
                    <div>
                        <div className="g-mb-20">
                            <FancySelect label="Type" options={Products.Constants.CUSTOMIZATION_TYPE_FIELDS} form={form}
                                         id={FIELDS.TYPE} ref={(e) => this.fields.push(e)}
                                         value={Products.Constants.CUSTOMIZATION_TYPE_FIELDS[0]}/>
                        </div>

                        <div className="form-group d-flex align-items-center justify-content-between g-mb-20">
                            <span>Required</span>
                            <label className="u-check mb-0 g-ml-10">
                                <input className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0"
                                       type="checkbox" onClick={(e) => this.setState({required: !required})}
                                       checked={required}
                                />

                                <div
                                    className="u-check-icon-radio-v8 u-check-icon--md u-check-icon--lightblue-v3">
                                    <i className="fa"/>
                                </div>
                            </label>
                        </div>
                    </div>
                )}
            </Fancy>
        )
    }
}

export default CreateCustomizationModal;