import React, {Component} from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {CorkTable} from 'corky'
import CorkButton from "../../../modules/CorkButton";
import 'react-day-picker/lib/style.css';
import TextModal from "./modals/TextModal";
import moment from "moment"

class TextsScreen extends Component {
    render() {
        let {textCampaigns} = this.props.partner;

        return (
            <div>
                <TextModal promotion={false} ref={(e) => this.promotionModal = e} {...this.props} />

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                                Text Campaigns
                            </h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton onClick={() => this.promotionModal.open()}>
                                Create Campaign
                            </CorkButton>
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <CorkTable header={{
                            NAME: {
                                NAME: "Subject",
                                WIDTH: 20,
                                SORT: "ID",
                                LINK: "/marketing/text/",
                                LINK_ID: "ID"
                            },
                            CONTENT: {
                                NAME: "Message",
                                WIDTH: 45
                            },
                            DATE_SCHEDULED: {
                                NAME: "Date",
                                FORMAT: (date) => moment(date).format("h:mma MM/DD/YY"),
                                WIDTH: 20
                            },
                            INSTANCES: {
                                NAME: "Instances",
                                FORMAT: (data) => data.length,
                                WIDTH: 15
                            },
                        }} sort="SUBJECT" data={textCampaigns} desc={true} title=""/>
                    </div>
                </div>
            </div>
        )
    }
}

export default TextsScreen;
