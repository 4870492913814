import React, {Component} from 'react'
import {CorkTable} from "corky"
import {request} from "../../util/Util";

let moment = require("moment");

class DevicesScreen extends Component {
    async remove(item) {
        if (!window.confirm("Are you sure you want to delete this item")) return
        let {devices, location} = this.props.partner;

        await request("device/" + item.ID, "DELETE", {}, undefined, location.ID);
        let index = devices.findIndex((device) => device.ID === item.ID);
        devices.splice(index, 1);

        this.props.updateDevices(devices);
    }

    render() {
        let {devices} = this.props.partner;

        return (
            <div>
                <CorkTable header={{
                    NAME: {
                        NAME: "Name",
                        WIDTH: 10,
                    },
                    DATE_UPDATED: {
                        NAME: "Last Used",
                        WIDTH: 10,
                        FORMAT: (data) => moment(data).format("hh:mma ddd MMMM Do")
                    },
                    STATUS: {
                        NAME: "Status",
                        WIDTH: 10,
                        FORMAT: (data) => data ? "Setup" : "Disconnected"
                    }
                }} sort="DATE_UPDATED" data={devices.map((item) => ({
                    onDelete: this.remove.bind(this, item), ...item
                }))} desc={true} delete={true} title="Devices"/>
            </div>
        );
    }
}

export default DevicesScreen;
