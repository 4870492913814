import React, {Component} from 'react'
import {parseIdDict, request} from "../../../util/Util";
import CorkButton from "../../../modules/CorkButton";
import AvailabilityModal from "../modals/AvailabilityModal";
import {FancySelect} from "corky";
import {
    ISO_WEEK_DAYS,
    PRODUCT_AVAILABILITY_COMPARISONS,
    PRODUCT_AVAILABILITY_TYPES,
    QUARTER_HOURS
} from "../../../util/Constants";
import {Products} from "drip-drop";
import {sendSuccess} from "../../../helpers/NotificationHelper";

class AvailabilityTab extends Component {
    state = {
        product: null,
        fields: null
    };

    componentWillMount() {
        const {ID} = this.props.match.params;
        let {categories} = this.props.partner;
        categories = parseIdDict(categories);

        let oldProduct = this.props.partner.products.find((item) => item.ID === parseInt(ID));
        oldProduct = JSON.parse(JSON.stringify(oldProduct));
        oldProduct.CATEGORY_CHANGE = {label: categories[oldProduct.CATEGORY_ID].NAME, value: oldProduct.CATEGORY_ID};
        this.setState({product: oldProduct});
    }

    edit() {
        const {ID} = this.props.match.params;
        let {product} = this.state;

        if (product.DESCRIPTION !== null && product.DESCRIPTION.length === 0) {
            product.DESCRIPTION = null;
        }

        product.CATEGORY_ID = product.CATEGORY_CHANGE.value;
        request("products/" + ID, "PATCH", product, (err) => {
            if (err) {
                alert("INTERNAL_ERROR");
                return;
            }

            alert("Product Saved");
        })
    }


    render() {
        let {productAvailability} = this.props.partner;
        const {ID} = this.props.match.params;

        productAvailability = productAvailability.filter((item) => item.PRODUCT_ID === parseInt(ID));

        return (
            <div className="col-md-9">
                <AvailabilityModal ref={(e) => this.modal = e} {...this.props} />

                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <form className="js-validate" onSubmit={(e) => e.preventDefault()}>
                        <div className="media">
                            <div className="media-body align-self-center text-right">
                                <CorkButton onClick={() => this.modal.open()}>
                                    Add Unavailability
                                </CorkButton>
                            </div>
                        </div>

                        <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                        {productAvailability.map((item) => <Availability item={item} {...this.props} />)}
                    </form>
                </div>
            </div>
        )
    }
}

const FIELDS = {
    TYPE: "availability-type",
    CONTENT: "availability-content",
    COMPARE: "availability-compare",
    EXTRA: "availability-extra"
};

class Availability extends Component {
    constructor(props) {
        let {item} = props;
        super(props);

        this.form = {
            [FIELDS.TYPE]: PRODUCT_AVAILABILITY_TYPES.find((_item) => _item.value === item.TYPE),
            [FIELDS.EXTRA]: item.EXTRA === null ? null : ISO_WEEK_DAYS.find((_item) => _item.value === item.EXTRA),
            [FIELDS.CONTENT]: QUARTER_HOURS.find((_item) => _item.value === item.CONTENT),
            [FIELDS.COMPARE]: PRODUCT_AVAILABILITY_COMPARISONS.find((_item) => _item.value === item.COMPARE),
        };
    }

    update() {
        let form = this.form;
        let {item} = this.props;
        const {ID} = this.props.match.params;

        let type = form[FIELDS.TYPE].value, content = form[FIELDS.CONTENT].value, compare = form[FIELDS.COMPARE].value;
        let extra = type === Products.Constants.AVAILABILITY_TYPES.DAY_OF_WEEK ? form[FIELDS.EXTRA].value : null;
        request(`products/${ID}/availability/${item.ID}`, "PATCH", {
            TYPE: type,
            CONTENT: content,
            EXTRA: extra,
            COMPARE: compare
        }).then(() => {
            let {productAvailability} = this.props.partner;
            let index = productAvailability.findIndex((_item) => _item.ID === item.ID);

            productAvailability.splice(index, 1, {
                ...this.props.item, TYPE: type, CONTENT: content, COMPARE: compare, EXTRA: extra
            });

            this.props.updateProductAvailability(productAvailability);

            sendSuccess("Unavailability Update", "The unavailability rule has been updated");
        });

    }

    delete() {
        if (!window.confirm("Are you sure you want to remove this unavailability?")) {
            return;
        }

        let {item} = this.props;
        const {ID} = this.props.match.params;
        request(`products/${ID}/availability/${item.ID}`, "DELETE", {}).then(() => {
            let {productAvailability} = this.props.partner;
            let index = productAvailability.findIndex((_item) => _item.ID === item.ID);
            productAvailability.splice(index, 1);

            this.props.updateProductAvailability(productAvailability);

            sendSuccess("Unavailability Removed", "The unavailability rule has been removed");
        });

    }

    render() {
        let form = this.form;
        this.fields = [];

        let removeComparison = this.form[FIELDS.COMPARE].value === 2;
        if (this.form[FIELDS.TYPE].value !== 3) {
            return (
                <div>
                    <div className="row">
                        <div className={removeComparison ? "col-md-6" : "col-md-4"}>
                            <FancySelect form={this.form} id={FIELDS.TYPE} label="Type" ref={(e) => this.fields.push(e)}
                                         required={true} options={PRODUCT_AVAILABILITY_TYPES}
                                         onChange={() => this.forceUpdate()}/>
                        </div>

                        <div className={removeComparison ? "col-md-6" : "col-md-4"}>
                            <FancySelect form={this.form} id={FIELDS.COMPARE} ref={(e) => this.fields.push(e)}
                                         label="Comparison"
                                         options={PRODUCT_AVAILABILITY_COMPARISONS} required={true}/>
                        </div>

                        {removeComparison ? <div/> : (
                            <div className="col-md-4">
                                <FancySelect form={this.form} id={FIELDS.CONTENT} label="Time" options={QUARTER_HOURS}
                                             ref={(e) => this.fields.push(e)} required={true}
                                />
                            </div>
                        )}
                    </div>

                    <div className="col-3"
                         style={{display: "flex", flex: 1, alignItems: "center", justifyContent: "space-evenly"}}>
                        <a className="u-link-v5 d-flex align-items-center g-color-primary g-ml-30 show-cursor g-color-gray-dark-v11--hover"
                           onClick={this.update.bind(this)}>
                            <i className="fa fa-check g-font-size-16"/>
                            <span className="g-ml-15">Save</span>
                        </a>
                        <a className="u-link-v5 d-flex align-items-center g-ml-30 show-cursor g-color-gray-dark-v11--hover"
                           onClick={this.delete.bind(this)}>
                            <i style={{color: "#ff0066"}} className="fa fa-trash g-font-size-16"/>
                            <span style={{color: "#ff0066"}} className="g-ml-15">Delete</span>
                        </a>
                    </div>

                    <hr/>
                </div>
            )
        }

        return (
            <div>
                <div className="row">
                    <div className={removeComparison ? "col-md-4" : "col-md-3"}>
                        <FancySelect form={this.form} id={FIELDS.TYPE} label="Type" ref={(e) => this.fields.push(e)}
                                     required={true} options={PRODUCT_AVAILABILITY_TYPES}
                                     onChange={() => this.forceUpdate()}/>
                    </div>

                    <div className={removeComparison ? "col-md-4" : "col-md-3"}>
                        <FancySelect form={this.form} id={FIELDS.EXTRA} ref={(e) => this.fields.push(e)}
                                     label="Day of Week" options={ISO_WEEK_DAYS} required={true}
                                     disabled={!form[FIELDS.TYPE] || (form[FIELDS.TYPE].value !== Products.Constants.AVAILABILITY_TYPES.DAY_OF_WEEK)}
                        />
                    </div>

                    <div className={removeComparison ? "col-md-4" : "col-md-3"}>
                        <FancySelect form={this.form} id={FIELDS.COMPARE} ref={(e) => this.fields.push(e)}
                                     label="Comparison"
                                     options={PRODUCT_AVAILABILITY_COMPARISONS} required={true}/>
                    </div>

                    {removeComparison ? <div/> : (
                        <div className="col-md-3">
                            <FancySelect form={this.form} id={FIELDS.CONTENT} label="Time" options={QUARTER_HOURS}
                                         ref={(e) => this.fields.push(e)} required={true}
                            />
                        </div>
                    )}
                </div>

                <div className="col-3"
                     style={{display: "flex", flex: 1, alignItems: "center", justifyContent: "space-evenly"}}>
                    <a className="u-link-v5 d-flex align-items-center g-color-primary g-ml-30 show-cursor g-color-gray-dark-v11--hover"
                       onClick={this.update.bind(this)}>
                        <i className="fa fa-check g-font-size-16"/>
                        <span className="g-ml-15">Save</span>
                    </a>
                    <a className="u-link-v5 d-flex align-items-center g-ml-30 show-cursor g-color-gray-dark-v11--hover"
                       onClick={this.delete.bind(this)}>
                        <i style={{color: "#ff0066"}} className="fa fa-trash g-font-size-16"/>
                        <span style={{color: "#ff0066"}} className="g-ml-15">Delete</span>
                    </a>
                </div>

                <hr/>
            </div>
        )
    }
}


export default AvailabilityTab;