import React, {Component} from 'react'
import 'react-day-picker/lib/style.css';
import {CorkTable} from "corky";
import {formatPhone, getFormattedPhone, getObjectImage} from "../../../util/Util";

let moment = require("moment");

class EmployeesTab extends Component {
    render() {
        let {employees} = this.props.partner;
        let {role} = this.props;

        employees = employees.filter((item) => item.ROLE_ID === role.ID);

        return (
            <div className="col-md-9">
                <div className="media">
                    <div className="d-flex align-self-center">
                        <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">Employees</h1>
                    </div>
                </div>

                <hr/>

                <CorkTable header={{
                    LOGO: {
                        WIDTH: 5,
                        FORMAT: (_, item) => (
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <img src={getObjectImage(item, "ACCOUNT_LOGO", "appicon.png")}
                                     alt={item.FULL_NAME + "'s Logo"}
                                     className="g-width-40 g-width-50--md g-height-40 g-height-50--md g-brd-around g-brd-2 g-brd-transparent g-brd-lightblue-v3--parent-opened rounded-circle"/>
                            </div>
                        )
                    },
                    FULL_NAME: {
                        NAME: "Name",
                        WIDTH: 20,
                        LINK: "/employee/",
                        LINK_ID: "ID"
                    },
                    PHONE: {
                        NAME: "Phone",
                        WIDTH: 25,
                        FORMAT: (item) => getFormattedPhone(item)
                    },
                    EMAIL: {
                        NAME: "Email",
                        WIDTH: 50,
                    }
                }} sort="ID" data={employees} desc={true} title=""/>
            </div>
        );
    }
}


export default EmployeesTab;