import React, {Component} from 'react'
import {CorkTable} from "corky";
import {parseIdDict, toDollars} from "../../../../util/Util";
import moment from "moment";
import CorkButton from "../../../../modules/CorkButton";

class TasksTab extends Component {
    render() {
        let Data = [];

        return (
            <div className="col-md-9">
                <div className="media">
                    <div className="d-flex align-self-center">
                        <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                            Task & Challenges
                        </h1>
                    </div>

                    <div className="media-body align-self-center text-right">
                        <CorkButton onClick={() => {}}>
                            Add Task/Challenge
                        </CorkButton>
                    </div>
                </div>

                <hr />

                <CorkTable header={{
                    CHALLENGE: {
                        NAME: "Name",
                        WIDTH: 25,
                        LINK: "/challenge/",
                        LINK_ID: "ACCOUNT_ID"
                    },
                    START: {
                        NAME: "Start",
                        WIDTH: 15,
                        SORT: "TIMESTAMP"
                    },
                    END: {
                        NAME: "End",
                        WIDTH: 15,
                    },
                    GOAL: {
                        NAME: "Goal",
                        WIDTH: 15
                    },
                    REWARD: {
                        NAME: "Reward",
                        WIDTH: 10
                    },
                    COMPLETED: {
                        NAME: "# Completed",
                        WIDTH: 15
                    }
                }} sort="START" data={Data} desc={true} title="Challenges"/>

                <CorkTable header={{
                    CHALLENGE: {
                        NAME: "Name",
                        WIDTH: 25,
                        LINK: "/challenge/",
                        LINK_ID: "ACCOUNT_ID"
                    },
                    START: {
                        NAME: "Start",
                        WIDTH: 15,
                        SORT: "TIMESTAMP"
                    },
                    END: {
                        NAME: "End",
                        WIDTH: 15,
                    },
                    GOAL: {
                        NAME: "Goal",
                        WIDTH: 15
                    },
                    REWARD: {
                        NAME: "Reward",
                        WIDTH: 10
                    },
                    COMPLETED: {
                        NAME: "# Completed",
                        WIDTH: 15
                    }
                }} sort="START" data={Data} desc={true} title="Tasks"/>
            </div>
        );
    }
}


export default TasksTab;