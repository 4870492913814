import React, {Component} from 'react'
import Report from "./module/Report";
import {toDollars} from "../../util/Util";

class TipSummary extends Report {
    constructor(props) {
        super(props);

        this.cards = [
            {
                LABEL: "Tips",
                DESCRIPTION: "Number of Tips",
                CALC: (items) => items.length
            },
            {
                LABEL: "Unique Tips",
                DESCRIPTION: "Number of users who tipped",
                CALC: (items) => new Set(items.map((item) => item.ACCOUNT_ID)).size
            },
            {
                LABEL: "Average Tip",
                DESCRIPTION: "Average amount tipped",
                CALC: (items) => items.length === 0 ? "$" + toDollars(0) : "$" + toDollars(items.reduce((accum, item) => accum + item.AMOUNT, 0) / items.length)
            }
        ];

        this.sort = "DATE_SENT";
        this.name = "Tips";
        this.downloadUrl = "report/summary/tips";
        this.downloadName = "Drip_Tip_Summary.csv";
        this.valCal = (item) => parseFloat(toDollars(item.AMOUNT));
        this.perCalc = (items) => {
            return items.reduce((accum, item) => accum + parseFloat(toDollars(item.AMOUNT)), 0);
        }
    }

    render() {
        return super.render();
    }

    componentWillMount() {
        let data = this.props.partner.tips.sort((a, b) => a.DATE_SENT - b.DATE_SENT);
        this.setState({data});
    }
}

export default TipSummary;