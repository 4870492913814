import React, {Component} from 'react'
import Table from "../../modules/Table";
import moment from "moment"

const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];

class PatronsScreen extends Component {
    render() {
        let {patrons, orders} = this.props.partner;
        let ordersDict = orders.reduce((dict, item) => {
            if (!item.ACCOUNT_ID) {
                return dict;
            }

            if (dict[item.ACCOUNT_ID]) {
                dict[item.ACCOUNT_ID].push(item);
            } else {
                dict[item.ACCOUNT_ID] = [item];
            }

            return dict;
        }, {});


        let startOfMonth = moment().startOf("month").valueOf();
        let Rows = patrons.map((item, i) => {
            let orders = ordersDict[item.ACCOUNT_ID] || [];
            let orderDays = orders.reduce((arr, item) => {
                arr[new Date(item.DATE_SENT).getDay()] += 1
                return arr;
            }, [0, 0, 0, 0, 0, 0, 0]);

            return {
                ID: item.ACCOUNT_ID,
                NAME: item.FULL_NAME,
                TOTAL: orders.length - 1,
                MONTHLY: orders.map((item) => item.DATE_SENT > startOfMonth).length,
                FAVORITE: PatronsScreen.getHotDay(orderDays),
                LINK_ID: item.ID
            };
        });

        return (
            <div>
                <Table header={{
                    NAME: {
                        NAME: "Patron",
                        WIDTH: 25,
                        LINK: "/patron/",
                        LINK_ID: "ID"
                    },
                    TOTAL: {
                        NAME: "Total Orders",
                        WIDTH: 12
                    },
                    MONTHLY: {
                        NAME: "Monthly Orders",
                        WIDTH: 12
                    },
                    FAVORITE: {
                        NAME: "Favorite Day",
                        WIDTH: 20
                    },
                }} sort="TOTAL" data={Rows} desc={true} title="Patrons"/>
                <div style={{marginBottom: 25, height: 25}}>
                    <br/>
                </div>
            </div>
        );
    }

    static toDate(utc) {
        let jsDate = new Date(utc);

        let time = '';
        if (jsDate.getHours() > 12) {
            time = jsDate.getHours() % 12 + ':' + jsDate.getMinutes() + ' PM on ';
        } else {
            if (jsDate.getHours() === 0) {
                time = '12:' + jsDate.getMinutes() + ' AM on ';
            } else {
                time = jsDate.getHours() + ':' + jsDate.getMinutes() + ' AM on ';
            }
        }

        return time + jsDate.toDateString()
    }

    static getHotDay(array) {
        let most = array[0], value = [0];
        for (let i = 1; i < array.length; i++) {
            if (array[i] > most) {
                value = [i];
                most = array[i];
            } else if (array[i] === most) {
                value.push(i);
            }
        }

        if (most === 0) {
            return "NaN";
        }

        return value.map((item) => {
            return DAYS[item];
        }).join("/");
    }
}

export default PatronsScreen;
