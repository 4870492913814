import React, {Component} from "react"
import Fancy from "../../../../modules/fancy/Fancy";

import {FancyText, FancyDate, FancySelect} from "corky"
import {COLORS, randomString, request} from "../../../../util/Util";
import {EMPLOYEE_NEW_HIRE, EMPLOYEE_STATUS, EMPLOYEE_TYPES} from "../../../../util/Constants";
import moment from "moment"

const FIELDS = {
    NUMBER: "card-number",
    MONTH: "card-month",
    YEAR: "card-year",
    CVC: "card-cvc",
};

class CardModal extends Component {
    constructor(props) {
        super(props);

        this.form = {};
    }

    createCard() {
        let form = this.form;

        let isValid = true;
        for (let field of this.fields) {
            if (field === null) {
                continue;
            }

            if (!field.isValid()) {
                isValid = false;
            }
        }

        if (!isValid) {
            return;
        }

        request("partner/billing/card", "POST", {
            NUMBER: form[FIELDS.NUMBER],
            CVC: form[FIELDS.CVC],
            MONTH: form[FIELDS.MONTH],
            YEAR: form[FIELDS.YEAR],
        }).then(async (payload) => {
            await this.fancy.close();

            this.props.add(payload);
        })
    }

    open() {
        this.fancy.open();
    }

    render() {
        this.fields = [];

        return (
            <Fancy name="Add Card" onClick={this.createCard.bind(this)} ref={(e) => this.fancy = e}
                   buttonText={"Add Card"}>
                <div className="g-mb-20">
                    <FancyText form={this.form} id={FIELDS.NUMBER} label="Card Number" required={true}
                               ref={(e) => this.fields.push(e)} max={16}/>
                </div>

                <div className="row g-mb-20">
                    <FancyText form={this.form} id={FIELDS.MONTH} label="Expire Month" ref={(e) => this.fields.push(e)}
                               quad={true} required={true} max={2}/>
                    <FancyText form={this.form} id={FIELDS.YEAR} label="Expire Year" ref={(e) => this.fields.push(e)}
                               quad={true} required={true} max={2}/>
                    <FancyText form={this.form} id={FIELDS.CVC} label="CVC" ref={(e) => this.fields.push(e)}
                               quad={true} required={true} max={4}/>
                </div>
            </Fancy>
        )
    }
}

export default CardModal;