import React, {Component} from 'react'
import PropTypes from 'prop-types'

class TutorialCheckbox extends Component {
    render() {
        return (
            <div className="form-group g-mb-7">
                <label className="media u-check g-brd-around g-brd-gray-light-v7 g-rounded-4"
                       onClick={this.props.onClick}>
                    <input className="g-hidden-xs-up g-pos-abs g-top-10 g-left-10" type="checkbox"
                           checked={this.props.checked} disabled/>
                    <span
                        className="d-flex align-self-center g-pos-rel g-width-30 g-height-30 g-bg-lightblue-v3--checked g-color-secondary g-color-white--checked rounded-circle g-mx-10">
                        <i className="hs-admin-check g-absolute-centered"/>
                    </span>
                    <span
                        className="media-body g-color-black g-color-gray-dark-v6--checked g-brd-left g-brd-gray-light-v7 g-pa-10">
                        {this.props.text}
                    </span>
                </label>
            </div>
        );
    }
}

export default TutorialCheckbox;