import React, {Component} from "react";

class DropZone extends Component {
    state = {over: false, file: null};

    getFile() {
        return this.state.file;
    }

    getCustom() {
        return this.props.custom;
    }

    renderFile() {
        let {file} = this.state;
        let {update} = this.props;

        if (file === null) {
            return (
                <div/>
            )
        }

        return (
            <div style={{
                backgroundColor: "white",
                padding: "8px 12px"
            }}>
                <div
                    style={{
                        backgroundColor: "#F4F4F4",
                        padding: "0px 8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                    <div style={{fontSize: 12, textOverflow: "ellipsis", overflow: "hidden"}}>
                        {file ? file.name : ""}
                    </div>

                    <div style={{marginLeft: 8}} className="show-cursor"
                         onClick={() => {
                             this.setState({file: null}, () => update());
                         }}>
                        <i className="fa fa-times" style={{fontSize: 12}}/>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let {over} = this.state;
        let {type, update, download} = this.props;

        return (
            <div style={{
                flex: 1,
                border: "1px solid #C6C6C6",
                borderRadius: 5,
                paddingTop: 40,
                backgroundColor: over ? "#b5b5b5" : "#F4F4F4",
                alignItems: "center",
                textAlign: "center",
                overflow: "hidden",
                ...this.props.style
            }} onDragLeave={() => {
                this.setState({over: false})
            }} onDragOver={(ev) => {
                if (!over) {
                    this.setState({over: true})
                }

                ev.preventDefault();
            }} onDropCapture={(ev) => {
                ev.preventDefault();

                this.setState({over: false});
                if (ev.dataTransfer.items) {
                    // Use DataTransferItemList interface to access the file(s)
                    let files = [];
                    for (let item of ev.dataTransfer.items) {
                        if (item.kind === 'file') {
                            files.push(item.getAsFile());
                        }
                    }

                    if (files.length === 0) {
                        return alert("Please select a valid file");
                    }

                    if (files.length > 1) {
                        return alert("Please only select one file");
                    }

                    if (files[0].name.split(".")[1] !== "pdf") {
                        return alert("Please upload a pdf file type.")
                    }

                    this.setState({over: false, file: files[0]}, () => update());
                } else {
                    let files = [];
                    for (let file of ev.dataTransfer.files) {
                        files.push(file);
                    }

                    if (files.length === 0) {
                        return alert("Please select a valid file");
                    }

                    if (files.length > 1) {
                        return alert("Please only select one file");
                    }

                    if (files[0].name.split(".")[1] !== "pdf") {
                        return alert("Please upload a pdf file type.")
                    }

                    this.setState({over: false, file: files[0]}, () => update());
                }
            }}>
                <i className="fa fa-file-pdf" style={{fontSize: 60}}/>

                <div style={{marginTop: 14, fontSize: 18, fontWeight: "bold"}}>
                    {type}
                </div>

                <a href={download} style={{fontSize: 12, color: "#808080", textDecorationLine: "underline"}}
                   target="_blank">
                    download
                </a>

                <div style={{marginTop: 16, marginBottom: 20}}>
                    Drop files here or <span className="show-cursor" style={{color: "#4ACE9C", fontWeight: "bold"}}
                                             onClick={() => {
                                                 this.click.click();
                                             }}>
                    Browse
                </span>
                </div>

                <input id="uploadLogo" type="file" style={{display: "none"}} ref={(e) => this.click = e}
                       onChange={() => {
                           let files = this.click.files;
                           if (files.length === 0) {
                               return alert("Please select a valid file");
                           }

                           if (files.length > 1) {
                               return alert("Please only select one file");
                           }

                           if (files[0].name.split(".")[1] !== "pdf") {
                               return alert("Please upload a pdf file type.")
                           }

                           this.setState({file: files[0]}, () => update());
                       }}/>

                {this.renderFile()}
            </div>
        );
    }
}

export default DropZone;