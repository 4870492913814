import React, {Component} from 'react'
import {CorkTable} from "corky";
import {parseIdDict, toDollars} from "../../../../util/Util";
import moment from "moment";

class ActivityTab extends Component {
    render() {
        let {redeems, members, products} = this.props.partner;

        let memberDict = parseIdDict(members);
        let productsDict = parseIdDict(products);

        let Data = redeems.map((item) => {
            return {
                PATRON: memberDict[item.ACCOUNT_ID] ? memberDict[item.ACCOUNT_ID].FULL_NAME : "Unknown",
                ACCOUNT_ID: item.ACCOUNT_ID,
                DATE: moment(item.DATE_REDEEMED).format("MM/DD/YY"),
                TIMESTAMP: item.DATE_REDEEMED,
                TIME: moment(item.DATE_REDEEMED).format("hh:mm a"),
                ITEM: productsDict[item.PRODUCT_ID] ? productsDict[item.PRODUCT_ID].NAME : "Unknown",
                PRODUCT_ID: item.PRODUCT_ID,
                PRICE: "$" + toDollars(item.PRICE)
            }
        });

        return (
            <div className="col-md-9">
                <CorkTable header={{
                    PATRON: {
                        NAME: "Patron",
                        WIDTH: 25,
                        LINK: "/patron/",
                        LINK_ID: "ACCOUNT_ID"
                    },
                    DATE: {
                        NAME: "Date",
                        WIDTH: 15,
                        SORT: "TIMESTAMP"
                    },
                    TIME: {
                        NAME: "Time",
                        WIDTH: 15,
                    },
                    ITEM: {
                        NAME: "Free Item",
                        WIDTH: 20,
                        LINK: "/products/",
                        LINK_ID: "PRODUCT_ID"
                    },
                    PRICE: {
                        NAME: "Value",
                        WIDTH: 10
                    }
                }} sort="DATE" data={Data} desc={true}/>
            </div>
        );
    }
}


export default ActivityTab;