import React, {Component} from 'react'
import FormItem from "../../../modules/form/FormItem";
import {COLORS, request, uploadImage} from "../../../util/Util";
import FormTextBox from "../../../modules/form/FormTextBox";
import Double from "../../../modules/Double";
import {addNotification, ALERT_ERROR, ALERT_INFO, ALERT_WARNING} from "../../../util/NotificationUtil";
import CorkButton from "../../../modules/CorkButton";
import Fancy from "../../../modules/fancy/Fancy";
import {FancySelect, FancyText} from "corky";
import {sendSuccess} from "../../../helpers/NotificationHelper";

class WebsiteTab extends Component {
    state = {website: {LOGO: null, TITLE: null, TAGLINE: null, SUBTITLE: null, FAVICON: null}};

    componentDidMount() {
        let {website} = this.props.partner;

        if (website !== null) {
            let websiteClone = JSON.parse(JSON.stringify(website));
            delete websiteClone.ID;
            delete websiteClone.COMPANY_ID;

            this.setState({website: websiteClone});
        }
    }

    async updateInfo() {
        let {website} = this.state;

        await request("partner/website", "POST", {...website});

        this.props.updateWebsite({...website});

        sendSuccess("Website Updated", "Your website info has been updated!",);
    }

    render() {
        let {website} = this.state;

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <form className="js-validate" onSubmit={(e) => e.preventDefault()}>
                        <div className="g-pa-0">
                            <div className="media">
                                <div className="d-flex align-self-center">
                                    <h1 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                        Information
                                    </h1>
                                </div>

                                <div className="media-body align-self-center text-right">
                                    <CorkButton onClick={this.updateInfo.bind(this)}>
                                        Save Changes
                                    </CorkButton>
                                </div>
                            </div>
                        </div>
                        <hr/>

                        <FormItem name="Title" placeholder="Ex: Avery's Coffee" value={website.TITLE}
                                  onChange={(val) => this.setState({website: {...website, TITLE: val}})}/>

                        <FormItem name="Tagline" placeholder="Ex: The best coffee shop in Boston"
                                  value={website.TAGLINE}
                                  onChange={(val) => this.setState({website: {...website, TAGLINE: val}})}/>

                        <FormItem name="Subtitle" placeholder="Ex: Find your inner coffee lover"
                                  value={website.SUBTITLE}
                                  onChange={(val) => this.setState({website: {...website, SUBTITLE: val}})}/>

                        <div>
                            <input id="uploadFavicon" type="file" style={{display: "none"}} onChange={async () => {
                                let payload = await uploadImage("shop/image", document.getElementById("uploadFavicon").files[0]);

                                this.setState({FAVICON: payload}, () => this.updateInfo())
                                sendSuccess("Favicon Updated", "Your favicon has bene updated");
                            }}/>

                            <input id="uploadLogo" type="file" style={{display: "none"}} onChange={async () => {
                                let payload = await uploadImage("shop/image", document.getElementById("uploadLogo").files[0]);

                                this.setState({LOGO: payload}, () => this.updateInfo())
                                sendSuccess("Landing Background Updated", "The background for you landing page has been updated");
                            }}/>

                            <CorkButton marginLeft onClick={() => document.getElementById("uploadLogo").click()}>
                                Upload Landing Background
                            </CorkButton>

                            <CorkButton marginLeft onClick={() => document.getElementById("uploadFavicon").click()}>
                                Upload Favicon
                            </CorkButton>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default WebsiteTab;
