import React, {Component} from "react"
import Fancy from "../../../modules/fancy/Fancy";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {request} from "../../../util/Util";

const grid = 5;
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "lightgray",

    // styles we need to apply on draggables
    ...draggableStyle
});

class CategoriesModal extends Component {
    state = {categories: []};

    open(category) {
        this.setState({categories: this.props.partner.categories});
        this.modal.open();
    }

    saveOrder() {
        let {categories} = this.state;

        request("categories/seq", "POST", {
            ITEMS: categories.map((item, i) => ({
                ID: item.ID,
                SEQ: i + 1
            }))
        }).then(() => {
            this.modal.close().then(() => {
                let actual = this.props.partner.categories;

                categories.forEach((item, i) => {
                    actual.find((_item) => item.ID === _item.ID).SEQ = i + 1;
                });

                this.props.updateCategories(actual);
                alert("Reordering saved");
            });
        });
    }

    reorderFunc(info) {
        let {categories} = this.state;

        if (!info.destination) {
            return categories;
        }

        const [removed] = categories.splice(info.source.index, 1);
        categories.splice(info.destination.index, 0, removed);

        this.setState({categories});
        return categories;
    };

    render() {
        let {categories} = this.state;

        return (
            <Fancy name="Reorder Categories" ref={(e) => this.modal = e} onClick={this.saveOrder.bind(this)}
                   buttonText="Save">
                <div className="g-mb-20" style={{height: 300, overflowY: "scroll"}}>
                    <DragDropContext onDragEnd={this.reorderFunc.bind(this)}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {
                                        categories.map((item, index) => (
                                            <Draggable key={item.ID} draggableId={item.ID} index={index}>
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef}
                                                         {...provided.draggableProps}
                                                         {...provided.dragHandleProps}
                                                         style={getItemStyle(
                                                             snapshot.isDragging,
                                                             provided.draggableProps.style
                                                         )}
                                                    >
                                                        {item.NAME}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))
                                    }
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </Fancy>
        )
    }
}

export default CategoriesModal;