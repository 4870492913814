import React, {Component} from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {decimalToDollars, formatDate, request, toDollars} from "../../util/Util";
import {CorkTable, FancySelect, FancyText, Fancy, FancyDay} from 'corky'
import 'react-day-picker/lib/style.css';
import moment from "moment";
import CorkButton from "../../modules/CorkButton";

const DAY_LISTS = {};

class TimesheetsScreen extends Component {
    state = {
        date: moment().startOf("day").toDate(), employee: null, start: 0, end: 0, rate: "", employees: [], show: false
    };

    componentDidMount() {
        let {employees} = this.props.partner;

        this.setState({
            employees: employees.map((item) => ({
                label: item.FULL_NAME,
                value: item.ID
            }))
        })
    }

    async addTimesheet() {
        let {employee, start, date, end, rate} = this.state;

        start = moment(date).startOf("day").add(moment(start.value).valueOf() - moment(start.value).startOf("day").valueOf(), "milliseconds");
        end = moment(date).startOf("day").add(moment(end.value).valueOf() - moment(end.value).startOf("day").valueOf(), "milliseconds");
        rate = decimalToDollars(rate);

        let card = await request("team/timesheet", "POST", {
            EMPLOYEE_ID: employee.value, DATE_START: start.valueOf(), DATE_END: end.valueOf(), RATE: rate
        })

        await this.fancy.close();
        this.props.addTimeCard(card)
    }

    _getStartOfDay() {
        return moment(new Date()).startOf("day").toDate().getTime();
    }

    getSelectOptions() {
        let options = [];

        if (DAY_LISTS[moment().format("MM-DD-YY")]) {
            return DAY_LISTS[moment().format("MM-DD-YY")];
        }

        let shiftStart = moment().startOf("day").valueOf();
        let shiftEnd = moment(shiftStart).add(1, "days").valueOf();

        while (shiftStart + (1000 * 60 * 15) < shiftEnd) {
            options.push({
                label: moment(shiftStart).format("hh:mm A"),
                value: shiftStart,
                color: '#FF5630'
            });

            if (options.length > 94) {
                break;
            }

            shiftStart += 1000 * 60 * 15;
        }

        DAY_LISTS[moment().format("MM-DD-YY")] = options;
        return options;
    }

    renderCreateTimesheet() {
        let {employees} = this.state;

        return (
            <Fancy name="Add Time Card" ref={(e) => this.fancy = e} onClick={this.addTimesheet.bind(this)}>
                <div className="row g-mb-20">
                    <FancySelect label="Employee" options={employees} name="employee" parentState={this.state}
                                 onChange={({value}) => {
                                     let employee = this.props.findEmployee(value);

                                     if (employee) {
                                         this.setState({rate: toDollars(employee.RATE)})
                                     }
                                 }} setParent={(state, callback) => this.setState(state, callback)} half={true}/>

                    <FancyText label="Rate" name="rate" parentState={this.state}
                               setParent={(state, callback) => this.setState(state, callback)} half={true}/>
                </div>

                <div className="row g-mb-20">
                    <FancyDay label="Date" tri={true} parentState={this.state} name="date"
                              setParent={(state, callback) => this.setState(state, callback)}/>

                    <FancySelect label="Time Start" options={this.getSelectOptions()} name="start"
                                 parentState={this.state}
                                 setParent={(state, callback) => this.setState(state, callback)} tri={true}/>

                    <FancySelect label="Time End" options={this.getSelectOptions()} name="end" parentState={this.state}
                                 setParent={(state, callback) => this.setState(state, callback)} tri={true}/>
                </div>
            </Fancy>
        );
    }

    render() {
        let {timesheets, employees} = this.props.partner;
        let Timesheets = timesheets.map((timesheet) => {
            let hours = ((timesheet.DATE_END - timesheet.DATE_START) / (1000 * 60 * 60)).toFixed(1);

            return {
                EMPLOYEE: employees.find((item) => item.ID === timesheet.EMPLOYEE_ID).FULL_NAME,
                EMPLOYEE_ID: timesheet.EMPLOYEE_ID,
                DATE: moment(timesheet.DATE_START).format("MM/DD/YY"),
                DATE_START: timesheet.DATE_START,
                HOURS: timesheet.DATE_END === null ? "On shift" : hours,
                RATE: "$" + toDollars(timesheet.RATE),
                TOTAL: timesheet.DATE_END ? ("$" + toDollars(hours * timesheet.RATE)) : "$0.00",
                PAYOUT_ID: timesheet.PAYOUT_ID,
                PAYROLL_ID: timesheet.PAYROLL_ID
            }
        });

        return (
            <div>
                {this.renderCreateTimesheet()}

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">Timesheets</h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton onClick={() => this.setState({
                                id: -1,
                                name: "",
                                phone: ""
                            }, () => {
                                this.fancy.open();
                            })}>
                                Add Timesheet
                            </CorkButton>
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        <CorkTable headerMode="simple" rowFilter={true} filters={[{
                            label: "Payment Status:",
                            column: "PAYOUT_ID",
                            init: null,
                            FILTER: (item, value) => {
                                if (value === 1) {
                                    return item.PAYOUT_ID === null;
                                } else if (value === 2) {
                                    return item.PAYOUT_ID !== null;
                                }
                            },
                            items: [
                                {label: "All", badge: "g-bg-black", value: null},
                                {label: "Unpaid", badge: "g-bg-darkred-v2", value: 1},
                                {label: "Paid", badge: "g-bg-darkblue-v2", value: 2}
                            ]
                        }]} header={{
                            EMPLOYEE: {
                                NAME: "Employee",
                                WIDTH: 20,
                                LINK: "/employee/:ID/timesheet",
                                LINK_ID: "EMPLOYEE_ID",
                                TYPE: "Dropdown",
                                SORT: "EMPLOYEE_ID",
                                VALUES: [
                                    {NAME: "All", VALUE: "*"},
                                    ...this.props.partner.employees.map((item) => ({
                                        NAME: item.FULL_NAME,
                                        VALUE: item.ID
                                    }))
                                ]
                            },
                            DATE: {
                                NAME: "Date",
                                SORT: "DATE_START",
                                WIDTH: 10,
                            },
                            HOURS: {
                                NAME: "Hours",
                                WIDTH: 10,
                            },
                            RATE: {
                                NAME: "Rate",
                                WIDTH: 15
                            },
                            TOTAL: {
                                NAME: "Wage",
                                WIDTH: 15
                            }
                        }} sort="DATE" data={Timesheets} desc={true} title=""/>
                    </div>
                </div>
            </div>
        )
    }
}

export default TimesheetsScreen;