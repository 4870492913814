import React, {Component} from "react"
import {Chart} from "react-charts";
import PropTypes from "prop-types";
import moment from "moment";

const HOURS_OF_DAY = ["12am", "1am", "2am", "3am", "4am", "5am", "6am", "7am", "8am", "9am", "10am", "11am", "12pm", "1pm",
    "2pm", "3pm", "4pm", "5pm", "6pm", "7pm", "8pm", "9pm", "10pm", "11pm"];

class HourChart extends Component {
    render() {
        let {
            data, title = "Unnamed Title", label = "Unnamed", calc = (item) => 1
        } = this.props;

        let futureData = data.reduce((arr, item) => {
            arr[new Date(item.DATE_SENT).getHours()] += calc(item);
            return arr;
        }, [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

        return (
            <div className="col-xl-12">
                <div className="card g-brd-gray-light-v7 g-pa-15 g-pa-25-30--md g-mb-30">
                    <header className="media g-mb-30">
                        <h3 className="d-flex align-self-center text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            {title}
                        </h3>
                    </header>

                    <section>
                        <Chart style={{width: "400px", height: "300px"}} data={[{
                            label, secondaryAxisID: label, data: futureData.map((item, i) => [HOURS_OF_DAY[i], item])
                        }]} axes={[
                            {primary: true, type: "ordinal", position: "bottom"},
                            {type: "linear", hardMin: 0, id: label, position: "left"},
                        ]} primaryCursor tooltip/>
                    </section>
                </div>
            </div>
        )

    }
}

HourChart.propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    calc: PropTypes.func.isRequired
}

export default HourChart;