import React, {Component} from 'react'
import FormItem from "../../../modules/form/FormItem";
import {toDollars} from "../../../util/Util";

let moment = require("moment");

const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
class GeneralTab extends Component {
    render() {
        const {ID} = this.props.match.params;
        let {patron} = this.props;

        let orders = this.props.partner.orders.filter((item) => {
            return item.ACCOUNT_ID === parseInt(ID)
        });

        let tips = this.props.partner.tips.filter((item) => {
            return item.ACCOUNT_ID === parseInt(ID);
        });

        let lifetime = orders.reduce((accum, item) => accum + (item.SUBTOTAL * .921 + item.TAXES), 0);
        let averageTips = tips.reduce((accum, item) => accum + item.AMOUNT, 0) / orders.length;

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <form className="js-validate">
                        <header>
                            <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                General information
                            </h2>

                            <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                            <FormItem disabled={true} name="Name" value={patron.FULL_NAME}/>
                            <FormItem disabled={true} name="First Seen" value={moment(new Date(orders[0].DATE_SENT)).format('h:mm A MM/DD/YYYY')}/>
                            <FormItem disabled={true} name="Latest Seen"
                                      value={moment(new Date(orders[orders.length - 1].DATE_SENT)).format('h:mm A MM/DD/YYYY')}/>

                            <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                Stats
                            </h2>

                            <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>
                            <FormItem tooltip="All tips / number of orders" disabled={true} name="Average Tip" value={"$" + toDollars(isNaN(averageTips) ? 0 : averageTips)}/>
                            <FormItem disabled={true} name="Average Order" value={"$" + toDollars(Math.floor(lifetime / orders.length))}/>
                            <FormItem disabled={true} name="Lifetime Value" value={"$" + toDollars(Math.floor(lifetime))}/>

                        </header>
                    </form>
                </div>
            </div>
        )
    }

    static getHotDay(array) {
        let most = array[0], value = [0];
        for (let i = 1; i < array.length; i++) {
            if (array[i] > most) {
                value = [i];
                most = array[i];
            } else if (array[i] === most) {
                value.push(i);
            }
        }

        if (most === 0) {
            return "NaN";
        }

        return value.map((item) => {
            return DAYS[item];
        }).join("/");
    }
}


export default GeneralTab;
