import React, {Component} from "react"

import {FancyText, FancySelect, Fancy} from "corky";
import FormCheckbox from "../../../modules/form/FormCheckbox";
import {request} from "../../../util/Util";
import moment from "moment";

class PublishModal extends Component {
    state = {
        notifyAll: false, notifyEmail: true, notifySms: false
    };

    open() {
        this.setState({notifyAll: false, notifyEmail: true, notifySms: false}, () => {
            this.fancy.open();
        });
    }

    async publish() {
        let {notifyAll, notifyEmail, notifySms} = this.state;
        let {location} = this.props.partner;
        let {window: publishWindow} = this.props;

        this.fancy.getCreateButton().startLoading();
        await request("scheduling/publish/" + moment(publishWindow).startOf("isoWeek").valueOf(), "POST", {
            SEND_EMAIL: notifyEmail,
            SEND_ALL: notifyAll,
            SEND_SMS: notifySms
        }, undefined, location.ID);

        await this.fancy.close();
        this.props.history.push("/scheduling");
        window.location.reload();

        // TODO, just mark all read lol
    }

    render() {
        let {notifyAll, notifyEmail, notifySms} = this.state;
        this.fancyRefs = [];

        return (
            <Fancy name="Publish Shifts" ref={(e) => this.fancy = e} onClick={this.publish.bind(this)}>
                <div className="g-mb-10">
                    <FormCheckbox label="Notify all employees" value={notifyAll}
                                  onChange={() => this.setState({notifyAll: !notifyAll})}/>
                    <FormCheckbox label="Notify employees by Email" value={notifyEmail}
                                  onChange={() => this.setState({notifyEmail: !notifyEmail})}/>
                    <FormCheckbox label="Notify employees by Text" value={notifySms}
                                  onChange={() => this.setState({notifySms: !notifySms})}/>
                </div>
            </Fancy>
        )
    }
}

export default PublishModal;
