import React, {Component} from "react"
import {Fancy, FancySelect, FancyText} from "corky";
import {request} from "../../../util/Util";
import {Team} from "drip-drop";

class RoleModal extends Component {
    state = {
        id: -1, name: "", permissions: [], item: null
    };

    open(item) {
        if (item) {
            return this.setState({
                item, name: item.NAME,
                permissions: this.getPermissions().filter(({value}) => {
                    return item.PERMISSIONS.indexOf(value) !== -1
                })
            }, () => this.modal.open());
        }

        this.modal.open();
    }

    async createRole() {
        let {name, permissions} = this.state;

        let isValid = true;
        for (let field of this.fields) {
            if (field === null) {
                continue;
            }

            if (!field.isValid()) {
                isValid = false;
            }
        }

        if (!isValid) {
            return false;
        }

        this.modal.getCreateButton().startLoading();

        let serverRole = await request("employees/roles", "POST", {
            NAME: name,
            PERMISSIONS: permissions.map((item) => item.value)
        });

        await this.modal.getCreateButton().stopLoading();
        await this.modal.close();

        this.props.addRole(serverRole);
    }

    async saveRole() {
        let {name, permissions, item} = this.state;

        let isValid = true;
        for (let field of this.fields) {
            if (field === null) {
                continue;
            }

            if (!field.isValid()) {
                isValid = false;
            }
        }

        if (!isValid) {
            return false;
        }

        this.modal.getCreateButton().startLoading();

        let serverRole = await request("employees/roles/" + item.ID, "PATCH", {
            NAME: name, PERMISSIONS: permissions.map((item) => item.value)
        });

        await this.modal.getCreateButton().stopLoading();
        await this.modal.close();

        this.props.editRole(serverRole);
    }

    getPermissions() {
        return [
            {label: "Accounting", value: Team.Constants.ROLE_PERMISSIONS.ACCOUNTING_FULL},
            {label: "Admin", value: Team.Constants.ROLE_PERMISSIONS.ADMIN_FULL},
            {label: "Banking", value: Team.Constants.ROLE_PERMISSIONS.BANKING_FULL},
            {label: "Invoices", value: Team.Constants.ROLE_PERMISSIONS.INVOICES_FULL},
            {label: "Reporting", value: Team.Constants.ROLE_PERMISSIONS.REPORTING_FULL},
            {label: "Marketing", value: Team.Constants.ROLE_PERMISSIONS.MARKETING_FULL},
            {label: "Menu", value: Team.Constants.ROLE_PERMISSIONS.MENU_FULL},
            {label: "Operations", value: Team.Constants.ROLE_PERMISSIONS.OPERATIONS_FULL},
            {label: "Point of Sale", value: Team.Constants.ROLE_PERMISSIONS.POINT_OF_SALE_FULL},
            {label: "Scheduling", value: Team.Constants.ROLE_PERMISSIONS.SCHEDULING_FULL},
            {label: "Team", value: Team.Constants.ROLE_PERMISSIONS.TEAM_FULL},
        ]
    }

    render() {
        this.fields = [];
        let {item} = this.state;

        return (
            <Fancy name={item === null ? "Create Role" : "Edit Role"} ref={(e) => this.modal = e}
                   onClick={item !== null ? this.saveRole.bind(this) : this.createRole.bind(this)}
                   buttonText={item !== null ? "Save" : "Create"}>
                <div className="g-mb-20">
                    <FancyText name="name" label="Name"
                               ref={(e) => this.fields.push(e)} required={true}
                               parentState={this.state} setParent={(state) => this.setState(state)}
                    />
                </div>

                <div className="g-mb-20">
                    <FancySelect name="permissions" label="Permissions" options={this.getPermissions()}
                                 required={true} select={{isMulti: true}}
                                 parentState={this.state} setParent={(state) => this.setState(state)}
                    />
                </div>
            </Fancy>
        )
    }
}

export default RoleModal;