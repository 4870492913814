import React, {Component} from 'react'
import 'react-day-picker/lib/style.css';
import {CorkTable} from "corky";

let moment = require("moment");

class PayoutsTab extends Component {
    render() {
        let {checklistInstances} = this.props.partner;
        let {checklist} = this.props;

        let Instances = checklistInstances.filter((item) => item.CHECKLIST_ID === checklist.ID).map((item) => {
            return {
                ID: item.ID,
                STARTED: item.DATE_STARTED === null ? "Not Started" : moment(item.DATE_STARTED).format("hh:mmA MM/DD/YY"),
                COMPLETED: item.DATE_COMPLETED === null ? "Not Started" : moment(item.DATE_COMPLETED).format("hh:mmA MM/DD/YY"),
                START_BY: moment(item.DATE_START_BY).format("hh:mmA MM/DD/YY"),
                END_BY: moment(item.DATE_END_BY).format("hh:mmA MM/DD/YY")
            }
        });

        return (
            <div className="col-md-9">

                <CorkTable header={{
                    ID: {
                        NAME: "#",
                        WIDTH: 10
                    },
                    START_BY: {
                        NAME: "Start By",
                        WIDTH: 22,
                    },
                    STARTED: {
                        NAME: "Started",
                        WIDTH: 22,
                    },
                    END_BY: {
                        NAME: "End By",
                        WIDTH: 22,
                    },
                    COMPLETED: {
                        NAME: "Completed",
                        WIDTH: 22
                    }
                }} sort="ID" data={Instances} desc={true}/>
            </div>
        );
    }
}


export default PayoutsTab;