import React, {Component} from 'react'
import FormItem from "../../../modules/form/FormItem";
import {request} from "../../../util/Util";
import FormDropdown from "../../../modules/form/FormDropdown";
import {Team} from "drip-drop";
import {sendSuccess} from "../../../helpers/NotificationHelper";
import {CorkButton} from "corky";

class GeneralTab extends Component {
    state = {name: "", permissions: []}

    componentDidMount() {
        let {role} = this.props;

        this.setState({
            name: role.NAME, permissions: this.getPermissions().filter(({value}) => {
                return role.PERMISSIONS.indexOf(value) !== -1
            })
        })
    }

    async save() {
        let {name, permissions} = this.state;
        let {role} = this.props;

        this.saveButton.startLoading();
        let serverRole = await request("employees/roles/" + role.ID, "PATCH", {
            NAME: name, PERMISSIONS: permissions.map((item) => item.value)
        });

        await this.saveButton.stopLoading();
        this.props.editRole(serverRole);

        sendSuccess("Role Saved", name + " has been saved!")
    }

    getPermissions() {
        return [
            {label: "Accounting", value: Team.Constants.ROLE_PERMISSIONS.ACCOUNTING_FULL},
            {label: "Admin", value: Team.Constants.ROLE_PERMISSIONS.ADMIN_FULL},
            {label: "Banking", value: Team.Constants.ROLE_PERMISSIONS.BANKING_FULL},
            {label: "Invoices", value: Team.Constants.ROLE_PERMISSIONS.INVOICES_FULL},
            {label: "Reporting", value: Team.Constants.ROLE_PERMISSIONS.REPORTING_FULL},
            {label: "Marketing", value: Team.Constants.ROLE_PERMISSIONS.MARKETING_FULL},
            {label: "Menu", value: Team.Constants.ROLE_PERMISSIONS.MENU_FULL},
            {label: "Operations", value: Team.Constants.ROLE_PERMISSIONS.OPERATIONS_FULL},
            {label: "Point of Sale", value: Team.Constants.ROLE_PERMISSIONS.POINT_OF_SALE_FULL},
            {label: "Scheduling", value: Team.Constants.ROLE_PERMISSIONS.SCHEDULING_FULL},
            {label: "Team", value: Team.Constants.ROLE_PERMISSIONS.TEAM_FULL},
        ]
    }

    render() {
        let {role} = this.props;
        let {name, permissions} = this.state;

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <form className="js-validate" onSubmit={(e) => e.preventDefault()}>
                        <header>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                    General information
                                </h2>

                                <CorkButton ref={(e) => this.saveButton = e} onClick={this.save.bind(this)}>
                                    Save Changes
                                </CorkButton>
                            </div>

                            <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                            <FormItem name="Name" value={name ? name : role.NAME}
                                      onChange={(txt) => this.setState(({name: txt}))}/>

                            <FormDropdown name="Permissions" multi data={this.getPermissions()} value={permissions}
                                          onChange={(permissions) => {
                                              this.setState({permissions});
                                          }}
                            />
                        </header>
                    </form>
                </div>
            </div>
        )
    }
}

export default GeneralTab;
