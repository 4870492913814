import React, {Component} from 'react'
import {CorkTable} from "corky";
import Card from "../../../dashboard/modules/Card";
import moment from "moment"
import {toDollars} from "../../../../util/Util";

class InstancesTab extends Component {
    render() {
        let {campaign} = this.props;

        return (
            <div className="col-md-9">
                <div className="row">
                    <Card name="Texts Sent" value={campaign.INSTANCES.length}/>
                    <Card name="Return Spend" value={"$" + toDollars(campaign.INSTANCES.reduce((accum, item) => accum + (item.TOTAL || 0), 0))}/>
                </div>

                <CorkTable header={{
                    FULL_NAME: {
                        NAME: "Date",
                        WIDTH: 25,
                        SORT: "ID",
                        LINK: "/patron"
                    },
                    UNSUBSCRIBED: {
                        NAME: "Unsubscribed",
                        FORMAT: (condition) => condition ? "yes" : "-",
                        WIDTH: 25,
                    },
                    DATE_OPENED: {
                        NAME: "Date Opened",
                        FORMAT: (date) => date ? moment(date).format("h:mma MM/DD/YY") : "-",
                        WIDTH: 25,
                    },
                    RETURN_VISIT: {
                        NAME: "Return Visit",
                        FORMAT: (_, data) => data.DATE_SENT ? moment(data.DATE_SENT).format("h:mma MM/DD/YY") : "-",
                        WIDTH: 25,
                    },
                    RETURN_SPEND: {
                        NAME: "Return Spend",
                        FORMAT: (_, data) => data.TOTAL ? toDollars(data.TOTAL) : "-",
                        WIDTH: 25
                    }
                }} sort="PATRON" data={campaign.INSTANCES} noPadding={true} desc={true}/>
            </div>
        );
    }
}


export default InstancesTab;
