import React, {Component} from 'react';
import Fancy from "../../../modules/fancy/Fancy";
import 'bootstrap-daterangepicker/daterangepicker.css';
import {formatDate, request, toDollars} from "../../../util/Util";
import {CorkTable} from 'corky'
import {Link} from 'react-router-dom'
import Select from "react-select";
import moment from "moment";
import CorkButton from "../../../modules/CorkButton";

class InvoicesScreen extends Component {
    state = {
        id: -1,
        name: "",
        number: "",
        vendor: null
    };

    componentWillMount() {
        this.setState({data: this.props.partner.products});
    }

    createInvoice() {
        let {name, number, vendor} = this.state;

        let payload = {
            NAME: name,
            NUMBER: number,
            INVOICE_NUMBER: number,
            VENDOR: vendor.value,
            VENDOR_ID: vendor.value,
            LINES: [],
            NOTES: ""
        };

        request("accounting/invoice", "POST", payload, (err, id) => {
            if (err) {
                alert("ERROR");
                return;
            }

            this.fancy.close().then(() => {
                this.setState({name: "", number: "", vendor: null}, () => {
                    payload.ID = id;

                    this.props.updateInvoices([...this.props.partner.invoices, payload]);

                    this.props.history.push("/invoice/" + id);
                });
            });
        });
    }

    render() {
        let {name, number, vendor} = this.state;

        let Data = this.props.partner.invoices.map((item) => {
            return {
                onEdit: () => this.props.history.push("/invoice/" + item.ID),
                ID: item.ID,
                NAME: item.NAME,
                NUMBER: item.INVOICE_NUMBER,
                VENDOR: this.props.partner.vendors.find((_item) => _item.ID === item.CUSTOMER_ID).NAME,
                VENDOR_ID: item.CUSTOMER_ID,
                PRICE: toDollars(item.LINES.reduce((accum, line) => accum + line.RATE * line.QUANTITY, 0)),
                PAYED: item.DATE_PAYED === null ? "Unpaid" : moment(item.DATE_PAYED).format("mm-dd-yy")
            }
        });

        return (
            <div>
                <Fancy name="Create Invoice" ref={(e) => this.fancy = e}
                       onClick={this.createInvoice.bind(this)}
                >
                    <div className="row g-mb-20">
                        <div className="col-md-6">
                            <label className="g-mb-10" htmlFor="#bio">Name</label>

                            <input name="firstName"
                                   className="form-control form-control-md g-brd-gray-light-v7 g-brd-primary--focus g-brd-red--error g-rounded-4 g-px-20 g-py-12"
                                   type="text" value={name} onChange={(e) => this.setState({name: e.target.value})}
                                   aria-invalid="false"/>
                        </div>

                        <div className="col-md-6">
                            <label className="g-mb-10" htmlFor="#bio">Number</label>

                            <input name="firstName"
                                   className="form-control form-control-md g-brd-gray-light-v7 g-brd-primary--focus g-brd-red--error g-rounded-4 g-px-20 g-py-12"
                                   type="text" value={number} onChange={(e) => this.setState({number: e.target.value})}
                                   aria-invalid="false"/>
                        </div>
                    </div>

                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Bill To</label>

                        <div className="form-group mb-0" style={{marginLeft: 0}}>
                            <Select menuPortalTarget={document.body} styles={{
                                menuPortal: base => ({...base, zIndex: 99999}),
                                menuList: () => ({maxHeight: 150, overflowY: "scroll"})
                            }}
                                    options={this.props.partner.vendors.map((item) => ({
                                        label: item.NAME,
                                        value: item.ID
                                    }))} value={vendor}
                                    onChange={(vendor) => this.setState({vendor})}
                            />
                        </div>
                    </div>
                </Fancy>

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">Invoices</h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton onClick={() => {
                                this.setState({
                                    id: -1,
                                    name: "",
                                    phone: ""
                                }, () => {
                                    this.fancy.open();
                                });
                            }}>
                                Create Invoice
                            </CorkButton>
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <CorkTable ref={(e) => this.table = e} edit={true} header={{
                            NUMBER: {
                                NAME: "#",
                                WIDTH: 10,
                                TYPE: "Number"
                            },
                            NAME: {
                                NAME: "Name",
                                WIDTH: 30,
                                TYPE: "String",
                                LINK: "/invoice/",
                                LINK_ID: "ID"
                            },
                            VENDOR: {
                                NAME: "Vendor",
                                WIDTH: 20,
                                TYPE: "Dropdown",
                                DEFAULT: "*",
                                SORT: "VENDOR_ID",
                                VALUES: [
                                    {NAME: "All", VALUE: "*"},
                                    ...this.props.partner.vendors.map((item) => ({
                                        NAME: item.NAME,
                                        VALUE: item.ID
                                    }))
                                ]
                            },
                            PRICE: {
                                NAME: "Amount",
                                WIDTH: 20,
                                TYPE: "Number"
                            },
                            PAYED: {
                                NAME: "Status",
                                WIDTH: 20,
                                TYPE: "Dropdown",
                                VALUES: [
                                    {NAME: "Paid", VALUE: "*"},
                                    {NAME: "Unpaid", VALUE: "*"},
                                ]
                            }
                        }} sort="NUMBER" data={Data} desc={true} title="" sortItems={["_TITLE"]}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default InvoicesScreen;
