import React, {Component} from 'react'
import {Switch, Route, Link} from 'react-router-dom'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import Actions from '../../actions/index'

import {
    withRouter
} from 'react-router-dom'
import SecondaryNavItem from "../../modules/SecondaryNavItem";
import EditTab from "./tabs/EditTab";
import {request, uploadImage} from "../../util/Util";
import CustomizationsTab from "./tabs/CustomizationsTab";
import AvailabilityTab from "./tabs/AvailabilityTab";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class ProductScreen extends Component {
    render() {
        const {ID} = this.props.match.params;

        let product = this.props.partner.products.find((item) => item.ID === parseInt(ID));
        if (typeof product === "undefined") {
            return (
                <div/>
            )
        }

        return (
            <div>
                <div className="g-hidden-sm-down g-bg-gray-light-v8 g-pa-20">
                    <ul className="u-list-inline g-color-gray-dark-v6">
                        <li className="list-inline-item g-mr-10">
                            <Link className="u-link-v5 g-color-gray-dark-v6 g-color-secondary--hover g-valign-middle"
                                  to="/products">
                                Products
                            </Link>
                            <i className="hs-admin-angle-right g-font-size-12 g-color-gray-light-v6 g-valign-middle g-ml-10"/>
                        </li>
                        <li className="list-inline-item g-mr-10">
                            <Link className="u-link-v5 g-color-gray-dark-v6 g-color-secondary--hover g-valign-middle"
                                  to={`/product/${product.ID}`}>
                                {product.NAME}
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="g-pa-20">
                    <div className="row">
                        <div className="col-md-3 g-mb-30 g-mb-0--md">
                            <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                                <section className="text-center g-mb-30 g-mb-50--md">
                                    <div className="d-inline-block g-pos-rel g-mb-20">
                                        <a className="u-badge-v2--lg u-badge--bottom-right g-width-32 g-height-32 g-color-gray-dark-v11--hover g-transition-0_3 g-mb-20 g-mr-20"
                                           href="#!" onClick={() => {
                                            document.getElementById("uploadLogo").click();
                                        }}>
                                            <i className="hs-admin-pencil g-absolute-centered g-font-size-16 g-color-white"/>
                                        </a>

                                        <input id="uploadLogo" type="file" style={{display: "none"}}
                                               onChange={() => {
                                                   uploadImage("shop/image/" + ID, document.getElementById("uploadLogo").files[0], (err, payload) => {
                                                       if (err) {
                                                           alert("Image is too large. Try reducing the size.");
                                                           return;
                                                       }

                                                       let products = this.props.partner.products;
                                                       let index = products.findIndex((item) => item.ID === parseInt(ID));
                                                       products.splice(index, 1, {
                                                           ...products[index],
                                                           LOGO: payload
                                                       });

                                                       setTimeout(() => {
                                                           this.props.updateProducts(products);
                                                       }, 1000);
                                                   });
                                               }}/>

                                        <img className="img-fluid"
                                             style={{width: 200, height: 200, objectFit: "cover", borderRadius: 20}}
                                             src={product.LOGO === null ?
                                                 "https://s3.amazonaws.com/drip-drinks/appicon.png" :
                                                 "https://s3.amazonaws.com/drip-drinks/" + product.LOGO}
                                             alt=""/>
                                    </div>

                                    <h3 className="g-font-weight-300 g-font-size-20 g-color-black mb-0">
                                        {product.NAME}
                                    </h3>
                                </section>

                                <section>
                                    <ul className="list-unstyled mb-0">
                                        <SecondaryNavItem icon="hs-admin-user" {...this.props}
                                                          url={"/product/" + ID} name="Edit Section"/>
                                        <SecondaryNavItem icon="fa fa-fill" {...this.props}
                                                          url={"/product/" + ID + "/modifiers"} name="Modifiers"/>
                                        <SecondaryNavItem icon="fa fa-eye-slash" {...this.props}
                                                          url={"/product/" + ID + "/unavailability"}
                                                          name="Unavailability"/>
                                    </ul>
                                </section>
                            </div>
                        </div>

                        <Switch>
                            <Route exact path='/product/:ID'
                                   component={() => <EditTab {...this.props} />}/>
                            <Route exact path='/product/:ID/modifiers'
                                   component={() => <CustomizationsTab {...this.props} />}/>
                            <Route exact path='/product/:ID/unavailability'
                                   component={() => <AvailabilityTab {...this.props} />}/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductScreen));