import React, {Component} from "react"
import Fancy from "../../../modules/fancy/Fancy";

import {FancyText, FancySelect} from "corky"
import {decimalToDollars, request, toDollars} from "../../../util/Util";
import {BANK_TYPE, CARDHOLDER_TYPES} from "../../../util/Constants";
import Card from "../../dashboard/modules/Card";

const FIELDS = {
    NAME: "issuing-name",
    TYPE: "issuing-type",
    PHONE: "issuing-phone",
    EMAIL: "issuing-email",
    BILLING_NAME: "issuing-billing-name",
    ADDRESS_LINE_1: "issuing-line-1",
    ADDRESS_LINE_2: "issuing-line-2",
    CITY: "issuing-city",
    STATE: "issuing-state",
    ZIPCODE: "issuing-zipcode",
};

class IssuingHolderModal extends Component {
    state = {setup: false};

    constructor(props) {
        super(props);

        this.form = {};
    }

    addCard() {
        let form = this.form;
        let {holders} = this.props.parentState;

        let isValid = true;
        for (let field of this.fields) {
            if (field === null) {
                continue;
            }

            if (!field.isValid()) {
                isValid = false;
            }
        }

        if (!isValid) {
            return;
        }

        let phone = form[FIELDS.PHONE] ? form[FIELDS.PHONE] : null;
        let email = form[FIELDS.EMAIL] ? form[FIELDS.EMAIL] : null;
        let line2 = form[FIELDS.ADDRESS_LINE_2] ? form[FIELDS.ADDRESS_LINE_2] : null;

        request("partner/holder", "POST", {
            NAME: form[FIELDS.NAME],
            TYPE: form[FIELDS.TYPE].value,
            PHONE: phone === null || phone.length === 0 ? undefined : phone,
            EMAIL: email === null || email.length === 0 ? undefined : email,
            BILLING: {
                NAME: form[FIELDS.BILLING_NAME],
                LINE_ONE: form[FIELDS.ADDRESS_LINE_1],
                LINE_TWO: line2 === null || line2.length === 0 ? undefined : line2,
                CITY: form[FIELDS.CITY],
                STATE: form[FIELDS.STATE],
                ZIPCODE: form[FIELDS.ZIPCODE],
            },
        }).then(async (payload) => {
            await this.fancy.close();

            holders.push(payload);
            this.props.setParent({holders});
        })
    }

    open() {
        this.form = {};
        this.forceUpdate();
        this.fancy.open()
    }

    render() {
        this.fields = [];

        return (
            <Fancy name="Add Card Holder" onClick={this.addCard.bind(this)} ref={(e) => this.fancy = e}
                   buttonText="Send">
                <div className="g-mb-20 row">
                    <FancyText form={this.form} id={FIELDS.NAME} label="Name on Card"
                               ref={(e) => this.fields.push(e)} required={true} half={true}/>

                    <FancySelect form={this.form} id={FIELDS.TYPE} label="Card Type" options={CARDHOLDER_TYPES}
                                 ref={(e) => this.fields.push(e)} required={true} half={true}
                                 defaultValue={CARDHOLDER_TYPES[0]}/>
                </div>

                <div className="g-mb-20 row">
                    <FancyText form={this.form} id={FIELDS.PHONE} label="Phone (optional)" type={FancyText.TYPES.PHONE}
                               ref={(e) => this.fields.push(e)} half={true}/>

                    <FancyText form={this.form} id={FIELDS.EMAIL} label="Email (optional)" type={FancyText.TYPES.EMAIL}
                               ref={(e) => this.fields.push(e)} half={true}/>
                </div>

                <div className="g-mb-20">
                    <FancyText form={this.form} id={FIELDS.BILLING_NAME} label="Billing Name"
                               ref={(e) => this.fields.push(e)} required={true}/>
                </div>

                <div className="g-mb-20">
                    <FancyText form={this.form} id={FIELDS.ADDRESS_LINE_1} label="Address Line 1"
                               ref={(e) => this.fields.push(e)} required={true}/>
                </div>

                <div className="g-mb-20">
                    <FancyText form={this.form} id={FIELDS.ADDRESS_LINE_2} label="Address Line 2"
                               ref={(e) => this.fields.push(e)}/>
                </div>

                <div className="g-mb-20 row">
                    <FancyText form={this.form} id={FIELDS.CITY} label="City"
                               ref={(e) => this.fields.push(e)} required={true} half={true}/>

                    <FancyText form={this.form} id={FIELDS.STATE} label="State" max={2}
                               ref={(e) => this.fields.push(e)} required={true} quad={true}/>

                    <FancyText form={this.form} id={FIELDS.ZIPCODE} label="Postal Code"
                               ref={(e) => this.fields.push(e)} required={true} quad={true}/>
                </div>
            </Fancy>
        )
    }
}

export default IssuingHolderModal;