import React, {Component} from 'react'
import Report from "./module/Report";
import {toDollars} from "../../util/Util";
import {CorkTable} from "corky";
import {PLATFORMS, TRANSACTION_TYPES} from "../../util/Constants";

class PaymentsReport extends Report {
    constructor(props) {
        super(props);

        this.cards = [
            {
                LABEL: "Sales",
                DESCRIPTION: "Total volume",
                CALC: (items) => "$" + toDollars(items.reduce((accum, item) => accum + item.TOTAL + (item.TIP ? item.TIP.AMOUNT : 0), 0))
            },
            {
                LABEL: "Transactions",
                DESCRIPTION: "Number of transactions",
                CALC: (items) => items.length
            },
            {
                LABEL: "Average Ticket",
                DESCRIPTION: "Sales / Transactions",
                CALC: (items) => "$" + toDollars(items.length === 0 ? 0 : Math.floor(items.reduce((accum, item) => accum + item.TOTAL, 0) / items.length))
            }
        ];

        this.sort = "DATE_SENT";
        this.name = "Income";
        this.downloadUrl = "report/summary/sales";
        this.downloadName = "Drip_Sales_Summary.csv";
        this.valCal = (item) => parseFloat(toDollars(item.SUBTOTAL * 0.921 + item.TAXES));
        this.perCalc = (item) => item.reduce((accum, item) => accum + parseFloat(toDollars(item.SUBTOTAL * 0.921 + item.TAXES)), 0);
    }

    getColumns(data) {
        let ids = data.map((item) => item.ID);
        let {tips} = this.getData();

        let refunds = data.filter((item) => item.REFUND_ID !== null);
        tips = tips.filter(this.getFilterFunction()).filter((item) => ids.indexOf(item.ORDER_ID) !== -1);
        let paymentTotal = data.reduce((accum, item) => accum + item.TOTAL, 0);
        let refundTotal = refunds.reduce((accum, item) => accum + item.TOTAL, 0);

        return {
            PAYMENTS: data.length,
            REFUNDS: refunds.length,
            PAYMENT_AMOUNT: "$" + toDollars(paymentTotal),
            REFUND_AMOUNT: "$" + toDollars(refundTotal),
            TIPS: "$" + toDollars(tips.reduce((accum, item) => accum + item.AMOUNT, 0)),
            TOTAL_COLLECTED: "$" + toDollars(paymentTotal - refundTotal),
            FEES: "$" + toDollars(data.reduce((accum, item) => accum + item.PROCESSING, 0))
        };
    }

    render() {
        let data = this.getFilterData();

        let cash = data.filter((item) => item.TRANSACTION_TYPE === TRANSACTION_TYPES.CASH);
        let web = data.filter((item) => item.PLATFORM === PLATFORMS.WEB);
        let terminal = data.filter((item) => item.PLATFORM === PLATFORMS.REGISTER && item.TRANSACTION_TYPE !== TRANSACTION_TYPES.CASH);
        let mobile = data.filter((item) => item.PLATFORM === PLATFORMS.MOBILE);
        let gifts = data.filter((item) => item.TRANSACTION_TYPE === TRANSACTION_TYPES.GIFT_CARD);

        return (
            <div>
                {super.render()}

                <div className="row">
                    <CorkTable key={data} headerMode="download" header={{
                        METHOD: {
                            NAME: "Method",
                            WIDTH: 12.5,
                            SORT: "ID"
                        },
                        PAYMENTS: {
                            NAME: "Payments",
                            WIDTH: 12.5
                        },
                        REFUNDS: {
                            NAME: "Refunds",
                            WIDTH: 12.5
                        },
                        PAYMENT_AMOUNT: {
                            NAME: "Payment Amount",
                            WIDTH: 12.5
                        },
                        REFUND_AMOUNT: {
                            NAME: "Refund Amount",
                            WIDTH: 12.5
                        },
                        TIPS: {
                            NAME: "Tips",
                            WIDTH: 12.5
                        },
                        TOTAL_COLLECTED: {
                            NAME: "Total Collected",
                            WIDTH: 12.5
                        },
                        FEES: {
                            NAME: "Fees",
                            WIDTH: 12.5
                        }
                    }} sort="METHOD" data={[
                        {ID: 1, METHOD: "Cash", ...this.getColumns(cash)},
                        {ID: 2, METHOD: "Card in Store", ...this.getColumns(terminal)},
                        {ID: 3, METHOD: "Card on Web", ...this.getColumns(web)},
                        {ID: 4, METHOD: "Mobile", ...this.getColumns(mobile)},
                        {ID: 5, METHOD: "Gift Card", ...this.getColumns(gifts)},
                        {ID: 6, METHOD: "Total", ...this.getColumns(data)},
                    ]} desc={false} title="Sales Breakdown"/>
                </div>
            </div>
        )
    }

    componentWillMount() {
        let {orders, tips} = this.getData();

        let data = orders.sort((a, b) => a.DATE_SENT - b.DATE_SENT);
        let ids = data.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});

        for (let tip of tips) {
            ids[tip.ORDER_ID].TIP = tip;
        }

        this.setState({data});
    }
}

export default PaymentsReport;