import React, {Component} from 'react'
import {CorkTable} from "corky";
import {parseIdDict, toDollars} from "../../../util/Util";
import Card from "../../dashboard/modules/Card";
import CorkButton from "../../../modules/CorkButton";
import IssuingCardModal from "../modal/IssuingCardModal";
import IssuingHolderModal from "../modal/IssuingHolderModal";
import FundsModal from "../modal/FundsModal";

class CardsTab extends Component {
    render() {
        let {cards, holders, balance} = this.props.parentState;

        let Data = cards.map((item) => {
            return {
                NAME: item.name,
                EMPLOYEE: item.cardholder.name,
                TYPE: "limited",
                BALANCE: 0,
                STATUS: item.status
            };
        });

        let HolderData = holders.map((item) => {
            return {
                NAME: item.name,
                STATUS: item.status
            };
        });

        return (
            <div>
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <IssuingCardModal parentState={this.props.parentState} ref={(e) => this.cardModal = e}
                                      setParent={(state) => this.setState(state)} {...this.props}/>
                    <IssuingHolderModal parentState={this.props.parentState} ref={(e) => this.holderModal = e}
                                        setParent={(state) => this.setState(state)} {...this.props}/>
                    <FundsModal parentState={this.props.parentState} ref={(e) => this.fundsModal = e}
                                setParent={(state) => this.setState(state)} {...this.props}/>

                    <div className="g-pa-0">
                        <div className="media">
                            <div className="d-flex align-self-center">
                                <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                                    Manage Cards
                                </h1>
                            </div>

                            <div className="media-body align-self-center text-right">
                                <CorkButton onClick={() => this.fundsModal.open()}>
                                    Fund Card
                                </CorkButton>

                                <CorkButton marginLeft onClick={() => this.cardModal.open()}>
                                    Add Card
                                </CorkButton>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <Card name="Available Funds"
                              value={"$" + toDollars(balance.issuing.available[0].amount)}/>
                        <Card name="Pending Funds" value={"$" + toDollars(balance.issuing.pending[0].amount)}/>
                    </div>

                    <CorkTable header={{
                        NAME: {
                            NAME: "Card Name",
                            WIDTH: 30,
                        },
                        EMPLOYEE: {
                            NAME: "Card Holder",
                            WIDTH: 30,
                        },
                        TYPE: {
                            NAME: "Type",
                            WIDTH: 10,
                        },
                        BALANCE: {
                            NAME: "Balance",
                            WIDTH: 10
                        },
                        STATUS: {
                            NAME: "Status",
                            WIDTH: 20
                        },
                    }} sort="NAME" data={Data} noPadding={true} edit={true} desc={true}/>
                    <div className="g-pa-0">
                        <div className="media">
                            <div className="d-flex align-self-center">
                                <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                                    Manage Card Holders
                                </h1>
                            </div>

                            <div className="media-body align-self-center text-right">
                                <CorkButton marginLeft onClick={() => this.holderModal.open()}>
                                    Add Cardholder
                                </CorkButton>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <CorkTable header={{
                        NAME: {
                            NAME: "Name",
                            WIDTH: 30,
                        },
                        STATUS: {
                            NAME: "Status",
                            WIDTH: 30
                        }
                    }} sort="NAME" data={HolderData} noPadding={true} edit={true} delete={true} desc={true}/>
                </div>
            </div>
        );
    }
}


export default CardsTab;