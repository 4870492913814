import React, {Component} from 'react';
import Fancy from "../../modules/fancy/Fancy";
import 'bootstrap-daterangepicker/daterangepicker.css';
import {COLORS, formatDate, getURL, parseIdDict, request, toDollars} from "../../util/Util";
import {CorkTable} from 'corky'
import {Link} from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader';
import moment from "moment"
import CorkButton from "../../modules/CorkButton";
import CreateFormModal from "./forms/modals/CreateFormModal";
import {FORM_DISPLAY_NAMES, FORM_TYPES} from "../../util/Constants";

const FILED = <span
    className="u-tags-v1 text-center g-width-150--md g-brd-around g-bg-darkblue-v2 g-font-size-12 g-font-size-default--md g-color-white g-rounded-50 g-py-4 g-px-15">Filed</span>
const NOT_FILED = <span
    className="u-tags-v1 text-center g-width-150--md g-brd-around g-bg-darkred g-font-size-12 g-font-size-default--md g-color-white g-rounded-50 g-py-4 g-px-15">Not Filed</span>

class FormsScreen extends Component {
    payrun() {
        this.props.updateLoading(true);
        request("team/payrun", "POST", {}).then(() => {
            window.location.reload();
        }).catch((err) => {
            this.props.updateLoading(false);
            alert("Something went wrong")
        });
    }

    getHours(timesheet) {
        return parseFloat(((timesheet.DATE_END - timesheet.DATE_START) / (1000 * 60 * 60)).toFixed(1))
    }

    static downloadURI(id, name) {
        request("partner/form/" + id, "GET", null).then((key) => {
            let link = document.createElement("a");
            link.download = name;
            link.target = "_blank";
            link.href = getURL() + `/partner/form/${id}/${key}`;
            link.style.display = "none";

            document.body.appendChild(link);
            if (typeof MouseEvent !== "undefined") {
                link.dispatchEvent(new MouseEvent("click"));
            } else {
                link.click();
            }

            document.body.removeChild(link);
        }).catch(() => {
            alert("Server error. Try again later.");
        })
    }

    render() {
        let {forms, setupForms} = this.props.partner;
        let setupIds = parseIdDict(setupForms);

        let SetupForms = setupForms.map((item) => ({
            ID: item.ID,
            NAME: item.NAME
        }));

        let Forms = forms.map((item) => ({
            ID: item.ID,
            PERIOD: "2020",
            FORM: item.TYPE,
            TYPE: FORM_TYPES[item.TYPE],
            DESCRIPTION: setupIds[item.FORM_ID] ? FORM_DISPLAY_NAMES[item.TYPE] + " - " + setupIds[item.FORM_ID].NAME : FORM_DISPLAY_NAMES[item.TYPE],
            STATUS: NOT_FILED,
            DOWNLOAD: (
                <div style={{fontWeight: "bold", color: COLORS.DRIP_GREEN}} className="show-cursor" onClick={() => {
                    FormsScreen.downloadURI(item.ID, item.TYPE + ".pdf")
                }}>
                    Download
                </div>
            )
        }));

        return (
            <div>
                <CreateFormModal ref={(e) => this.addForm = e} {...this.props} />
                <div>
                    <div className="g-pa-20">
                        <div className="media">
                            <div className="d-flex align-self-center">
                                <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                                    Employment Forms
                                </h1>
                            </div>

                            <div className="media-body align-self-center text-right">
                                {/*<CorkButton onClick={() => {*/}
                                {/*}}>*/}
                                {/*    Upload Form*/}
                                {/*</CorkButton>*/}

                                <CorkButton onClick={() => this.addForm.open()}>
                                    Add Custom Form
                                </CorkButton>
                            </div>
                        </div>

                        <hr />

                        <div className="row">
                            <CorkTable ref={(e) => this.table = e} header={{
                                PERIOD: {
                                    NAME: "Period",
                                    WIDTH: 15,
                                    TYPE: "String",
                                    SORT: "ID"
                                },
                                FORM: {
                                    NAME: "Form",
                                    WIDTH: 15,
                                },
                                TYPE: {
                                    NAME: "Type",
                                    WIDTH: 15,
                                    TYPE: "String",
                                },
                                DESCRIPTION: {
                                    NAME: "Description",
                                    WIDTH: 35
                                },
                                STATUS: {
                                    NAME: "Status",
                                    WIDTH: 10
                                },
                                DOWNLOAD: {
                                    NAME: "",
                                    WIDTH: 10
                                }
                            }} sort="PERIOD" data={Forms} desc={true} title="Filled Forms"/>
                        </div>

                        <div className="row">
                            <CorkTable ref={(e) => this.table = e} settings={setupForms} header={{
                                ID: {
                                    NAME: "#",
                                    WIDTH: 10,
                                    LINK_ID: "ID"
                                },
                                NAME: {
                                    NAME: "Name",
                                    WIDTH: 90
                                },
                            }} sort="ID" data={SetupForms} desc={true} title="Custom Employment Forms"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FormsScreen;
