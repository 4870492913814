import React, {Component} from "react"
import {request} from "../../../util/Util";
import {FancyText, FancyDate} from "corky"

const FIELDS = {
    FIRST_NAME: "account-first-name",
    MIDDLE_INITIAL: "account-middle-initial",
    LAST_NAME: "account-last-name",

    DATE_OF_BIRTH: "account-date-of-birth",
    SSN: "account-ssn"
};

class AccountSection extends Component {
    constructor(props) {
        super(props);

        this.form = {};
    }


    componentDidMount() {
        this.canContinue();
    }

    canContinue() {
        this.props.update(true);

        // TODO: Steve - Do validation here
    }

    tryStep() {
        let form = this.form;

        return Promise.resolve();
    }

    render() {
        let form = this.form;

        return (
            <div style={{margin: "auto", maxWidth: 750}}>
                <div style={{marginTop: 64}}>
                    <div className="row g-mb-20">
                        <FancyText form={form} id={FIELDS.FIRST_NAME} label="Full Name" tri={true}/>
                        <FancyText form={form} id={FIELDS.MIDDLE_INITIAL} label="Email" tri={true}/>
                        <FancyText form={form} id={FIELDS.MIDDLE_INITIAL} label="Phone" tri={true}/>
                    </div>

                    <div className="row g-mb-20">
                        <FancyText form={form} id={FIELDS.LAST_NAME} label="Business Name" half={true}/>

                        <FancyText form={form} id={FIELDS.LAST_NAME} label="Number of Locations" quad={true}/>
                        <FancyText form={form} id={FIELDS.LAST_NAME} label="White Label App" quad={true}
                                   tooltip="We make whitelabled apps for an extra fee"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountSection;