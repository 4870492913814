import React, {Component} from "react"
import Fancy from "../../../modules/fancy/Fancy";

import {FancySelect, FancyText} from "corky"
import {request} from "../../../util/Util";

const FIELDS = {
    HOLDER: "issuing-holder",
    SHIPPING_NAME: "issuing-shipping-name",
    ADDRESS_LINE_1: "issuing-line-1",
    ADDRESS_LINE_2: "issuing-line-2",
    CITY: "issuing-city",
    STATE: "issuing-state",
    ZIPCODE: "issuing-zipcode",
};

class IssuingCardModal extends Component {
    state = {setup: false};

    constructor(props) {
        super(props);

        this.form = {};
    }

    addCard() {
        let form = this.form;
        let {cards} = this.props.parentState;

        let isValid = true;
        for (let field of this.fields) {
            if (field === null) {
                continue;
            }

            if (!field.isValid()) {
                isValid = false;
            }
        }

        if (!isValid) {
            return;
        }

        let line2 = form[FIELDS.ADDRESS_LINE_2] ? form[FIELDS.ADDRESS_LINE_2] : null;
        request("partner/card/" + form[FIELDS.HOLDER].value, "POST", {
            NAME: form[FIELDS.SHIPPING_NAME],
            LINE_ONE: form[FIELDS.ADDRESS_LINE_1],
            LINE_TWO: line2 === null || line2.length === 0 ? undefined : line2,
            CITY: form[FIELDS.CITY],
            STATE: form[FIELDS.STATE],
            ZIPCODE: form[FIELDS.ZIPCODE],
        }).then(async (payload) => {
            await this.fancy.close();

            cards.push(payload);
            this.props.setParent({cards});
        })
    }

    open() {
        this.fancy.open()
    }

    render() {
        this.fields = [];
        let {holders} = this.props.parentState;

        return (
            <Fancy name="Add Card" onClick={this.addCard.bind(this)} ref={(e) => this.fancy = e}
                   buttonText="Send">
                <div className="g-mb-20">
                    <FancySelect form={this.form} id={FIELDS.HOLDER} label="Cardholder" onChange={(val) => {
                        let holder = holders.find((item) => item.id === val.value);

                        this.form = {
                            ...this.form,
                            [FIELDS.SHIPPING_NAME]: holder.billing.name,
                            [FIELDS.CITY]: holder.billing.address.city,
                            [FIELDS.ZIPCODE]: holder.billing.address.postal_code,
                            [FIELDS.STATE]: holder.billing.address.state,
                            [FIELDS.ADDRESS_LINE_1]: holder.billing.address.line1,
                            [FIELDS.ADDRESS_LINE_2]: holder.billing.address.line2,
                        };

                        this.name.setState({value: holder.billing.name});
                        this.line1.setState({value: holder.billing.address.line1});
                        this.line2.setState({value: holder.billing.address.line2 === null ? "" : holder.billing.address.line2});
                        this.city.setState({value: holder.billing.address.city});
                        this.state.setState({value: holder.billing.address.state});
                        this.zip.setState({value: holder.billing.address.postal_code});

                        console.log(this.form);
                    }} options={holders.map((item) => ({label: item.name, value: item.id}))}
                                 ref={(e) => this.fields.push(e)} required={true}/>
                </div>

                <div className="g-mb-20">
                    <FancyText form={this.form} id={FIELDS.SHIPPING_NAME} label="Shipping Name"
                               ref={(e) => {
                                   this.name = e;
                                   this.fields.push(e)
                               }} required={true}/>
                </div>

                <div className="g-mb-20">
                    <FancyText form={this.form} id={FIELDS.ADDRESS_LINE_1} label="Address Line 1"
                               ref={(e) => {
                                   this.line1 = e;
                                   this.fields.push(e)
                               }} required={true}/>
                </div>

                <div className="g-mb-20">
                    <FancyText form={this.form} id={FIELDS.ADDRESS_LINE_2} label="Address Line 2"
                               ref={(e) => {
                                   this.line2 = e;
                                   this.fields.push(e)
                               }}/>
                </div>

                <div className="g-mb-20 row">
                    <FancyText form={this.form} id={FIELDS.CITY} label="City"
                               ref={(e) => {
                                   this.city = e;
                                   this.fields.push(e)
                               }} required={true} half={true}/>

                    <FancyText form={this.form} id={FIELDS.STATE} label="State" max={2}
                               ref={(e) => {
                                   this.state = e;
                                   this.fields.push(e)
                               }} required={true} quad={true}/>

                    <FancyText form={this.form} id={FIELDS.ZIPCODE} label="Postal Code"
                               ref={(e) => {
                                   this.zip = e;
                                   this.fields.push(e)
                               }} required={true} quad={true}/>
                </div>
            </Fancy>
        )
    }
}

export default IssuingCardModal;