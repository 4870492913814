import React, {Component} from 'react'
import EmailEditor from "react-email-editor";
import Template from "../../../../assets/templates/email.json";
import CorkButton from "../../../../modules/CorkButton";
import {request} from "../../../../util/Util";

class EmailTab extends Component {
    async save() {
        let {campaign} = this.props;

        let content = await this.getEmailHtml();
        let jsonContent = await this.getEmailJson();

        let promotionPayload = {
            CONTENT: content,
            JSON_CONTENT: JSON.stringify(jsonContent)
        };

        this.saveButton.startLoading();
        let serverCampaign = await request("marketing/email_campaigns/" + campaign.ID, "PATCH", promotionPayload);

        this.props.updateEmailCampaign(serverCampaign);
        // this.saveButton.stopLoading();
    }

    getEmailHtml() {
        return new Promise((resolve) => {
            this.editor.exportHtml(({html}) => {
                resolve(html);
            });
        })
    }

    getEmailJson() {
        return new Promise((resolve) => {
            this.editor.saveDesign((data) => {
                resolve(data);
            });
        })
    }

    render() {
        let {campaign} = this.props;

        return (
            <div className="col-md-9">
                <header>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            Personal information
                        </h2>

                        <CorkButton ref={(e) => this.saveButton = e} onClick={this.save.bind(this)}>
                            Save Changes
                        </CorkButton>
                    </div>
                </header>

                <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                <EmailEditor style={{minHeight: "100vh"}} ref={(e) => {
                    this.editor = e;

                    setTimeout(() => {
                        if (e && e.editor) {
                            e.loadDesign(campaign.JSON_CONTENT ? JSON.parse(campaign.JSON_CONTENT) : Template);
                        }
                    }, 500);
                }}/>
            </div>
        );
    }
}


export default EmailTab;
