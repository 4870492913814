import React, {Component} from 'react'
import {decimalToDollars, request, toDollars} from "../../../util/Util";
import Fancy from "../../../modules/fancy/Fancy";
import 'react-day-picker/lib/style.css';
import {CorkTable, FancyDay, FancySelect, FancyText} from "corky";
import CorkButton from "../../../modules/CorkButton";

let moment = require("moment");

let DAY_LISTS = {};

class TimesheetsTab extends Component {
    state = {date: moment().startOf("day").toDate(), start: 0, end: 0, rate: ""};

    componentDidMount() {
        let {employee} = this.props;

        this.setState({rate: toDollars(employee.RATE)});
    }

    async addTimesheet() {
        let {start, end, rate, date} = this.state;
        let {employee} = this.props;

        start = moment(date).startOf("day").add(moment(start.value).valueOf() - moment(start.value).startOf("day").valueOf(), "milliseconds");
        end = moment(date).startOf("day").add(moment(end.value).valueOf() - moment(end.value).startOf("day").valueOf(), "milliseconds");
        rate = decimalToDollars(rate);

        let card = await request("team/timesheet", "POST", {
            EMPLOYEE_ID: employee.ID, DATE_START: start.valueOf(), DATE_END: end.valueOf(), RATE: rate
        });

        await this.fancy.close()
        this.props.addTimeCard(card)
    }

    _getStartOfDay() {
        return moment(new Date()).startOf("day").toDate().getTime();
    }

    getSelectOptions() {
        let options = [];

        if (DAY_LISTS[moment().format("MM-DD-YY")]) {
            return DAY_LISTS[moment().format("MM-DD-YY")];
        }

        let shiftStart = moment().startOf("day").valueOf();
        let shiftEnd = moment(shiftStart).add(1, "days").valueOf();

        while (shiftStart + (1000 * 60 * 15) < shiftEnd) {
            options.push({
                label: moment(shiftStart).format("hh:mm A"),
                value: shiftStart,
                color: '#FF5630'
            });

            if (options.length > 94) {
                break;
            }

            shiftStart += 1000 * 60 * 15;
        }

        DAY_LISTS[moment().format("MM-DD-YY")] = options;
        return options;
    }

    renderCreateTimesheet() {
        return (
            <Fancy name="Add Time Card" ref={(e) => this.fancy = e} onClick={this.addTimesheet.bind(this)}>
                <div className="row g-mb-20">
                    <FancyText label="Rate" name="rate" parentState={this.state}
                               setParent={(state, callback) => this.setState(state, callback)} half={true}/>
                </div>

                <div className="row g-mb-20">
                    <FancyDay label="Date" tri={true} parentState={this.state} name="date"
                              setParent={(state, callback) => this.setState(state, callback)}/>

                    <FancySelect label="Time Start" options={this.getSelectOptions()} name="start"
                                 parentState={this.state}
                                 setParent={(state, callback) => this.setState(state, callback)} tri={true}/>

                    <FancySelect label="Time End" options={this.getSelectOptions()} name="end" parentState={this.state}
                                 setParent={(state, callback) => this.setState(state, callback)} tri={true}/>
                </div>
            </Fancy>
        );
    }

    render() {
        let {timesheets} = this.props.partner;
        let {employee} = this.props;

        let Timesheets = timesheets.filter((item) => item.EMPLOYEE_ID === employee.ID).map((item) => {
            return {
                ID: item.ID,
                DATE_START: item.DATE_START,
                DATE: moment(item.DATE_START).format("MM/DD/YY"),
                START: moment(item.DATE_START).format("hh:mm A"),
                END: moment(item.DATE_END).format("hh:mm A"),
                RATE: "$" + toDollars(item.RATE),
                TOTAL: "$" + toDollars(item.RATE * ((item.DATE_END - item.DATE_START) / (1000 * 60 * 60)).toFixed(1)),
                PAYOUT_ID: item.PAYOUT_ID
            }
        });

        return (
            <div className="col-md-9">
                {this.renderCreateTimesheet()}

                <div className="media">
                    <div className="d-flex align-self-center">
                        <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">Timesheets</h1>
                    </div>

                    <div className="media-body align-self-center text-right">
                        <CorkButton onClick={() => this.setState({
                            id: -1,
                            name: "",
                            phone: ""
                        }, () => {
                            this.fancy.open();
                        })}>
                            Add Timesheet
                        </CorkButton>
                    </div>
                </div>

                <hr/>

                <CorkTable headerMode="simple" filters={[{
                    label: "Payment Status:",
                    column: "PAYOUT_ID",
                    init: null,
                    FILTER: (item, value) => {
                        if (value === 1) {
                            return item.PAYOUT_ID === null;
                        } else if (value === 2) {
                            return item.PAYOUT_ID !== null;
                        }
                    },
                    items: [
                        {label: "All", badge: "g-bg-black", value: null},
                        {label: "Unpaid", badge: "g-bg-darkred-v2", value: 1},
                        {label: "Paid", badge: "g-bg-darkblue-v2", value: 2}
                    ]
                }]} header={{
                    DATE: {
                        NAME: "Date",
                        SORT: "DATE_START",
                        WIDTH: 10,
                    },
                    START: {
                        NAME: "Start",
                        WIDTH: 10,
                    },
                    END: {
                        NAME: "End",
                        WIDTH: 10,
                    },
                    RATE: {
                        NAME: "Rate",
                        WIDTH: 15
                    },
                    TOTAL: {
                        NAME: "Wage",
                        WIDTH: 15
                    },
                    // PAYOUT_ID: {
                    //     NAME: "Payrun",
                    //     WIDTH: 15
                    // }
                }} sort="DATE" data={Timesheets} desc={true} title=""/>
            </div>
        );
    }
}


export default TimesheetsTab;
