import React, {Component} from 'react'
import {formatDate, request} from "../../../util/Util";

const Months = ['Jan', 'Fed', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

class Letter extends Component {
    state = {
        show: false
    };

    test(e) {
        if (!this.state.show) {
            return;
        }

        if (e.target && e.target.classList && e.target.classList.contains("no-close")) {
            return;
        }

        this.setState({show: false})
    }

    componentWillMount() {
        window.addEventListener("click", this.test.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.test.bind(this));
    }

    render() {
        let {item} = this.props;

        let Section = this.props.partner.sections.slice().reverse().find((_item) => {
            return _item.LETTER_ID === parseInt(item.ID);
        });

        let Edit = false;
        if (typeof Section !== "undefined") {
            Edit = true;
        }

        return (
            <div className="col-md-6 col-lg-4 g-mb-30">
                <div className="card h-100 g-brd-gray-light-v7 rounded">
                    <header className="card-header g-bg-transparent g-brd-bottom-none g-pa-20 g-pa-30--sm">
                        <div className="media g-mb-15">
                            <div className="d-flex align-self-center"></div>

                            <div className="media-body d-flex justify-content-end">
                                <div className="g-pos-rel g-z-index-2">
                                    <a style={{display: item.DATE_SENT === null ? "block" : "none"}}
                                        className="u-link-v5 g-line-height-0 g-font-size-24 g-color-gray-light-v6 g-color-secondary--hover show-cursor"
                                        onClick={() => this.setState({show: !this.state.show})}
                                    >
                                        <i className="hs-admin-more-alt g-ml-20 no-close"/>
                                    </a>

                                    <div
                                        className="u-shadow-v31 g-pos-abs g-right-0 g-bg-white u-dropdown--jquery-slide slideInDms"
                                        style={{height: this.state.show ? "auto" : "0", overflow: "hidden"}}>
                                        <ul className="list-unstyled g-nowrap mb-0">
                                            <li className="show-cursor">
                                                <a className="d-flex align-items-center u-link-v5 g-bg-gray-light-v8--hover g-font-size-12 g-font-size-default--md g-color-gray-dark-v6 g-px-25 g-py-14"
                                                   onClick={() => {
                                                       this.props.history.push("/letter/" + item.ID);
                                                   }}>
                                                    <i className="hs-admin-pencil g-font-size-18 g-color-gray-light-v6 g-mr-10 g-mr-15--md"></i>
                                                    {Edit ? "Edit" : "Add Section"}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h3 className="g-font-weight-300 g-font-size-20 g-color-black g-mb-15">
                            {item.TITLE}
                        </h3>
                        <span
                            className="u-tags-v1 text-center g-width-130 g-brd-around g-brd-lightblue-v3 g-bg-lightblue-v3 g-color-white g-rounded-50 g-py-4 g-px-15">Scheduled</span>
                    </header>

                    <hr className="d-flex g-brd-gray-light-v7 g-mx-20 g-mx-30--sm my-0"/>

                    <div
                        className="card-block d-flex justify-content-between g-px-20 g-px-30--sm g-py-15 g-py-20--sm">
                        <div>
                            <h4 className="g-line-height-1_2 g-font-weight-300 g-font-size-28 g-color-black">
                                ?
                            </h4>
                            <em className="g-font-style-normal g-font-weight-300 g-font-size-16 g-color-gray-dark-v6">Views</em>
                        </div>

                        <div>
                            <h4 className="g-line-height-1_2 g-font-weight-300 g-font-size-28 g-color-black">
                                ?
                            </h4>
                            <em className="g-font-style-normal g-font-weight-300 g-font-size-16 g-color-gray-dark-v6">Clicks</em>
                        </div>

                        <div>
                            <h4 className="g-line-height-1_2 g-font-weight-300 g-font-size-28 g-color-black">
                                ?
                            </h4>
                            <em className="g-font-style-normal g-font-weight-300 g-font-size-16 g-color-gray-dark-v6">Likes</em>
                        </div>
                    </div>

                    <hr className="d-flex g-brd-gray-light-v7 g-mx-20 g-mx-30--sm my-0"/>

                    <div className="card-block g-px-20 g-px-30--sm g-py-15 g-py-20--sm">
                        <div className="row g-mb-25">
                            <div className="col-md-6 g-mb-25 g-mb-0--md">
                                <h5 className="g-font-size-default g-color-gray-dark-v6 g-mb-5">
                                    Target Release
                                </h5>
                                <p className="g-color-black mb-0">
                                    {formatDate(item.DATE_TARGET)}
                                </p>
                            </div>

                            <div className="col-md-6 g-mb-25 g-mb-0--md">
                                <h5 className="g-font-size-default g-color-gray-dark-v6 g-mb-5">
                                    Date Sent
                                </h5>
                                <p className="g-color-black mb-0">
                                    {item.DATE_SENT === null ? "Unreleased" : formatDate(item.DATE_SENT)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Letter;