import React, {Component} from 'react';
import Fancy from "../../modules/fancy/Fancy";
import 'bootstrap-daterangepicker/daterangepicker.css';
import {formatDate, request, toDollars} from "../../util/Util";
import {CorkTable} from 'corky'
import {Link, withRouter} from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader';
import moment from "moment"
import {bindActionCreators} from "redux";
import Actions from "../../actions";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class PayrunScreen extends Component {
    payrun() {
        this.props.updateLoading(true);
        request("team/payrun", "POST", {}).then(() => {
            window.location.reload();
        }).catch((err) => {
            this.props.updateLoading(false);
            alert("Something went wrong")
        });
    }

    getHours(timesheet) {
        return parseFloat(((timesheet.DATE_END - timesheet.DATE_START) / (1000 * 60 * 60)).toFixed(1))
    }

    render() {
        const {ID} = this.props.match.params;

        let payroll = this.props.partner.payrolls.find((item) => item.ID === parseInt(ID));
        if (!payroll) {
            return <div />
        }

        let employees = this.props.partner.timesheets.filter((item) => item.PAYROLL_ID === parseInt(ID)).reduce((dict, item) => {
            if (dict[item.EMPLOYEE_ID]) {
                dict[item.EMPLOYEE_ID].push(item);
            } else {
                dict[item.EMPLOYEE_ID] = [item]
            }

            return dict;
        }, {});

        let employeeIds = this.props.partner.employees.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});

        let TotalUnpaid = 0;
        let Unpaid = Object.values(employees).map((timeGroups) => {
            let priceNeeded = timeGroups.reduce((accum, item) => accum + (this.getHours(item) * item.RATE), 0);
            TotalUnpaid += priceNeeded;

            return {
                ID: timeGroups[0].EMPLOYEE_ID,
                EMPLOYEE_ID: timeGroups[0].EMPLOYEE_ID,
                PAYOUT_ID: this.props.partner.payouts.find((item) => {
                    console.dir(item);
                    return item.EMPLOYEE_ID === timeGroups[0].EMPLOYEE_ID && item.PAYROLL_ID === parseInt(ID)
                }).ID,
                EMPLOYEE: employeeIds[timeGroups[0].EMPLOYEE_ID].FULL_NAME,
                HOURS: timeGroups.reduce((accum, item) => accum + this.getHours(item), 0.0),
                AMOUNT: "$" + toDollars(priceNeeded),
            }
        });

        return (
            <div>
                <div>
                    <div className="g-pa-20">
                        <div className="row">
                            <div className="col-sm-6 col-lg-6 col-xl-3 g-mb-30 show-cursor">
                                <div className="card h-100 g-brd-gray-light-v7 rounded">
                                    <div className="card-block g-font-weight-300 g-pa-20">
                                        <div className="media">
                                            <div className="d-flex g-mr-15">
                                                <div className="js-pie-chart g-pos-rel mx-auto"
                                                     style={{width: '60px', height: '60px'}} data-series="[50, 20, 50]"
                                                     data-start-angle={330}
                                                     data-fill-colors="[&quot;#f1f5f5&quot;, &quot;#1d75e5&quot;, &quot;#3dd1e8&quot;]"
                                                     id="pieCharts0">
                                                </div>
                                            </div>
                                            <div className="media-body align-self-center">
                                                <h4 className="g-font-size-16 g-font-weight-300 g-color-gray-dark-v6 mb-0">
                                                    Wages Paid
                                                </h4>
                                                <div className="d-flex">
                                                    <span className="d-flex g-font-size-22 g-color-black">
                                                        ${toDollars(TotalUnpaid)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-lg-6 col-xl-3 g-mb-30 show-cursor">
                                <div className="card h-100 g-brd-gray-light-v7 rounded">
                                    <div className="card-block g-font-weight-300 g-pa-20">
                                        <div className="media">
                                            <div className="d-flex g-mr-15">
                                                <div className="js-pie-chart g-pos-rel mx-auto"
                                                     style={{width: '60px', height: '60px'}} data-series="[50, 20, 50]"
                                                     data-start-angle={330}
                                                     data-fill-colors="[&quot;#f1f5f5&quot;, &quot;#1d75e5&quot;, &quot;#3dd1e8&quot;]"
                                                     id="pieCharts0">
                                                </div>
                                            </div>
                                            <div className="media-body align-self-center">
                                                <h4 className="g-font-size-16 g-font-weight-300 g-color-gray-dark-v6 mb-0">
                                                    Employees Paid
                                                </h4>
                                                <div className="d-flex">
                                                    <span className="d-flex g-font-size-22 g-color-black">
                                                        {Object.keys(employees).length}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-lg-6 col-xl-3 g-mb-30 show-cursor">
                                <div className="card h-100 g-brd-gray-light-v7 rounded">
                                    <div className="card-block g-font-weight-300 g-pa-20">
                                        <div className="media">
                                            <div className="d-flex g-mr-15">
                                                <div className="js-pie-chart g-pos-rel mx-auto"
                                                     style={{width: '60px', height: '60px'}} data-series="[50, 20, 50]"
                                                     data-start-angle={330}
                                                     data-fill-colors="[&quot;#f1f5f5&quot;, &quot;#1d75e5&quot;, &quot;#3dd1e8&quot;]"
                                                     id="pieCharts0">
                                                </div>
                                            </div>
                                            <div className="media-body align-self-center">
                                                <h4 className="g-font-size-16 g-font-weight-300 g-color-gray-dark-v6 mb-0">
                                                    Payout Date
                                                </h4>
                                                <div className="d-flex">
                                                    <span className="d-flex g-font-size-22 g-color-black">
                                                        {moment(payroll.DATE_SENT).format("hh:mmA MM/DD/YY")}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="g-pa-20">
                        <div className="media">
                            <div className="d-flex align-self-center">
                                <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                                    Pay Run
                                </h1>
                            </div>
                        </div>

                        <hr />

                        <div className="row">
                            <CorkTable key={Unpaid} header={{
                                ID: {
                                    NAME: "#",
                                    WIDTH: 10
                                },
                                EMPLOYEE: {
                                    NAME: "Employee",
                                    WIDTH: 45,
                                    LINK: "/employee/:ID/payout/:PAYOUT",
                                    LINKS: {
                                        ID: "EMPLOYEE_ID",
                                        PAYOUT: "PAYOUT_ID"
                                    }
                                },
                                HOURS: {
                                    NAME: "Hours",
                                    WIDTH: 10
                                },
                                AMOUNT: {
                                    NAME: "Payment Needed",
                                    WIDTH: 10
                                }
                            }} sort="ID" data={Unpaid} desc={true} title="Paid Employees"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PayrunScreen));
