import React, {Component} from 'react'
import {Switch, Route, Link} from 'react-router-dom'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import Actions from '../../actions/index'

import {
    withRouter
} from 'react-router-dom'
import GeneralTab from "./tabs/GeneralTab";
import SecondaryNavItem from "../../modules/SecondaryNavItem";
import InstanceTab from "./tabs/InstanceTab";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class ChecklistScreen extends Component {
    state = {
        active: 0
    };

    render() {
        const {ID} = this.props.match.params;
        let checklist = this.props.partner.checklists.find((item) => item.ID === parseInt(ID));

        if (!checklist) {
            return (
                <div>
                    Checklist not found
                </div>
            );
        }

        return (
            <div>
                <div className="g-hidden-sm-down g-bg-gray-light-v8 g-pa-20">
                    <ul className="u-list-inline g-color-gray-dark-v6">
                        <li className="list-inline-item g-mr-10">
                            <Link className="u-link-v5 g-color-gray-dark-v6 g-color-secondary--hover g-valign-middle"
                                  to="/checklists">
                                Checklists
                            </Link>
                            <i className="hs-admin-angle-right g-font-size-12 g-color-gray-light-v6 g-valign-middle g-ml-10"/>
                        </li>
                        <li className="list-inline-item g-mr-10">
                            <Link className="u-link-v5 g-color-gray-dark-v6 g-color-secondary--hover g-valign-middle"
                                  to={`/checklist/${checklist.ID}`}>
                                {checklist.NAME}
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="g-pa-20">
                    <div className="row">
                        <div className="col-md-3 g-mb-30 g-mb-0--md">
                            <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                                <section className="text-center g-mb-30 g-mb-50--md">
                                    <h3 className="g-font-weight-300 g-font-size-20 g-color-black mb-0">
                                        {checklist.NAME}
                                    </h3>
                                </section>

                                <section>
                                    <ul className="list-unstyled mb-0">
                                        <SecondaryNavItem icon="far fa-info-square" {...this.props}
                                                          url={"/checklist/" + ID} name="Checklist Info"/>
                                        <SecondaryNavItem icon="far fa-mouse-pointer" {...this.props}
                                                          url={"/checklist/" + ID + "/instances"} name="Checklist Instances"/>
                                    </ul>
                                </section>
                            </div>
                        </div>

                        <Switch>
                            <Route exact path='/checklist/:ID'
                                   component={() => <GeneralTab checklist={checklist} {...this.props} />}/>
                            <Route exact path='/checklist/:ID/instances'
                                   component={() => <InstanceTab checklist={checklist} {...this.props} />}/>

                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistScreen));