import React, {Component} from 'react'
import Double from "../../../modules/Double";
import {COLORS, getURL, request} from "../../../util/Util";
import CorkButton from "../../../modules/CorkButton";

let moment = require("moment");
let chrono = require('chrono-node');

class PayoutTab extends Component {
    state = {requirements: []};

    open() {
        if (this.props.partner.location.STRIPE_ID === null) {
            request("stripe/connect", "GET").then((data) => {
                window.open(data);
            });
        } else {
            request("stripe/dashboard", "GET").then((data) => {
                window.open(data);
            });
        }
    }

    componentDidMount() {
        this.handler = window.Plaid.create({
            clientName: 'Drip',
            // Optional, specify an array of ISO-3166-1 alpha-2 country
            // codes to initialize Link; European countries will have GDPR
            // consent panel
            countryCodes: ['US'],
            env: 'sandbox',
            // Replace with your public_key from the Dashboard
            key: '9dc344e45d5a89ab2408587065e9eb',
            product: ['transactions'],
            // Optional, use webhooks to get transaction and error updates
            webhook: 'https://requestb.in',
            // Optional, specify a language to localize Link
            language: 'en',
            // Optional, specify userLegalName and userEmailAddress to
            // enable all Auth features
            userLegalName: 'John Appleseed',
            userEmailAddress: 'jappleseed@yourapp.com',
            onLoad: function () {
                // Optional, called when Link loads
            },
            onSuccess: function (public_token, metadata) {
                request("/accounting/token", "POST", {TOKEN: public_token}).then(() => {

                }).catch((err) => {
                    console.dir(err);
                })

                // Send the public_token to your app server.
                // The metadata object contains info about the institution the
                // user selected and the account ID or IDs, if the
                // Select Account view is enabled.
                // $.post('/get_access_token', {
                //     public_token: public_token,
                // });
            },
            onExit: function (err, metadata) {
                // The user exited the Link flow.
                if (err != null) {
                    // The user encountered a Plaid API error prior to exiting.
                }
                // metadata contains information about the institution
                // that the user selected and the most recent API request IDs.
                // Storing this information can be helpful for support.
            },
            onEvent: function (eventName, metadata) {
                // Optionally capture Link flow events, streamed through
                // this callback as your users connect an Item to Plaid.
                // For example:
                // eventName = "TRANSITION_VIEW"
                // metadata  = {
                //   link_session_id: "123-abc",
                //   mfa_type:        "questions",
                //   timestamp:       "2017-09-14T14:42:19.350Z",
                //   view_name:       "MFA",
                // }
            }
        });


        let {location} = this.props.partner;
        request("stripe/status", "GET", null, undefined, location.ID).then((requirements) => {
            this.setState({requirements})
        });
    }

    redirect() {
        let {location} = this.props.partner;

        request("stripe/connect/partner", "POST", {}, undefined, location.ID).then((data) => {
            window.open(data);
        });
    }

    redirectGet() {
        let {location} = this.props.partner;

        request("stripe/connect/partner", "GET", null, undefined, location.ID).then((data) => {
            window.open(data);
        });
    }

    renderRequired() {
        let {requirements} = this.state;

        if (!requirements.currently_due) {
            return (
                <div>
                    Loading
                </div>
            );
        }

        if (requirements.currently_due.length === 0) {
            return (
                <div>
                    You are all set
                </div>
            )
        }

        let currentlyRequired = requirements.currently_due[0];

        if (currentlyRequired.startsWith("business_profile") || currentlyRequired.startsWith("company")) {
            return (
                <CorkButton onClick={this.redirectGet.bind(this)}>
                    Update Info
                </CorkButton>
            )
        }

        if (currentlyRequired.startsWith("external_account")) {

        }

        return (
            <div>
                None
            </div>
        )
    }

    renderConnect() {
        let {location} = this.props.partner;

        if (location.CONNECT_ID) {
            return (
                <div>
                    Done
                </div>
            );
        }

        return (
            <CorkButton onClick={this.redirect.bind(this)}>
                Add Connect
            </CorkButton>
        );
    }

    render() {
        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <header>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            Payouts
                        </h2>
                    </header>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    {this.renderConnect()}

                    {this.renderRequired()}

                    <div>
                        <button onClick={() => this.handler.open()}>
                            Plaid
                        </button>

                        {/*<button style={{backgroundColor: COLORS.DRIP_GOLD, color: "white"}}*/}
                        {/*        onClick={this.open.bind(this)}*/}
                        {/*        className="btn btn-md btn-xl--md u-btn-secondary g-width-160--md g-font-size-12 g-font-size-default--md g-mb-10"*/}
                        {/*        type="submit">{this.props.partner.location.STRIPE_ID === null ? "Add Payout Account" : "View Payouts"}*/}
                        {/*</button>*/}


                    </div>
                </div>
            </div>
        )
    }
}

export default PayoutTab;