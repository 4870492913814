// Partner Stuff
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export function addNotification(credentials) {
    return {
        type: ADD_NOTIFICATION,
        payload: credentials
    }
}

export function removeNotification(credentials) {
    return {
        type: REMOVE_NOTIFICATION,
        payload: credentials
    }
}
