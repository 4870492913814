import React, {Component} from "react"

import {FancyText, FancyDate, FancySelect, Fancy} from "corky"
import {COLORS, randomString, request} from "../../../util/Util";
import {EMPLOYEE_NEW_HIRE, EMPLOYEE_STATUS, EMPLOYEE_TYPES} from "../../../util/Constants";
import moment from "moment"

const STEPS = {
    ACCOUNT: 0,
    EMPLOYEE: 1,
    CONFIRM: 2
};

const FIELDS = {
    FULL_NAME: "account-full-name",
    EMAIL: "account-email",
    PHONE: "account-phone",
    ROLE: "account-role",
    PIN: "account-pin",

    TYPE: "employee-type",
    STATUS: "employee-status",
    RATE: "employee-rate",
    START_DATE: "employee-start-date",
    NEW_HIRE: "employee-new-hire"
};

class CreateEmployeeModal extends Component {
    state = {step: STEPS.ACCOUNT, form: {}, payroll: true};

    constructor(props) {
        super(props);

        this.form = {};
    }

    async createEmployee() {
        let form = this.form;
        let {payroll} = this.state;

        let phone = form[FIELDS.PHONE];
        if (phone[0] !== "1") {
            phone = "1" + phone;
        }

        let payload = await request("employees", "POST", {
            FULL_NAME: form[FIELDS.FULL_NAME],
            EMAIL: form[FIELDS.EMAIL],
            PHONE: phone,
            ROLE_ID: form[FIELDS.ROLE].value,
            PIN: form[FIELDS.PIN],
            TYPE: form[FIELDS.TYPE].value,
            STATUS: form[FIELDS.STATUS].value,
            RATE: !payroll ? "0" : form[FIELDS.RATE],
            DATE_STARTED: !payroll ? new Date().getTime() : form[FIELDS.START_DATE].getTime(),
            PAYROLL: payroll
        })

        await this.fancy.close();

        this.props.addEmployee(payload);
        this.props.history.push("/employee/" + payload.ID);
    }

    open() {
        this.setState({step: STEPS.ACCOUNT}, () => this.fancy.open());
    }

    getCurrentRole() {
        let {roles} = this.props.partner;

        if (roles.length === 0) {
            return null;
        }

        return {value: roles[0].ID, label: roles[0].NAME};
    }

    //boolean error invalid set error to true
    step() {
        let {step} = this.state;
        let isValidTracker = true;

        switch (step) {
            default:
                break;
            case STEPS.ACCOUNT:
                isValidTracker = true;
                for (let item of this.accountList) {
                    if (item && !item.isValid()) {
                        isValidTracker = false;
                    }
                }
                if (!isValidTracker) return false;
                return this.setState({step: STEPS.EMPLOYEE});
            case STEPS.EMPLOYEE:
                isValidTracker = true;
                for (let item of this.employeeList) {
                    if (item && !item.isValid()) {
                        isValidTracker = false;
                    }
                }
                if (!isValidTracker) return false;

                return this.setState({step: STEPS.CONFIRM, payroll: false})
                // return this.setState({step: STEPS.CONFIRM, payroll: true});
            case STEPS.CONFIRM:
                return this.createEmployee();
        }
    }

    stepBack(close) {
        let {step} = this.state;

        switch (step) {
            default:
                break;
            case STEPS.ACCOUNT:
                return close();
            case STEPS.EMPLOYEE:
                return this.setState({step: STEPS.ACCOUNT});
            case STEPS.CONFIRM:
                return this.setState({step: STEPS.EMPLOYEE})
        }
    }

    renderAccount() {
        this.accountList = [];
        let {roles} = this.props.partner;
        let form = this.form;

        return (
            <form>
                <div className="g-mb-20">
                    <FancyText form={form} id={FIELDS.FULL_NAME} type={FancyText.TYPES.TEXT} required={true}
                               ref={(e) => this.accountList.push(e)} label="Full Name"/>
                </div>

                <div className="row g-mb-20">
                    <FancyText form={form} id={FIELDS.EMAIL} type={FancyText.TYPES.EMAIL} required={true}
                               ref={(e) => this.accountList.push(e)} label="Email" half={true}/>
                    <FancyText form={form} id={FIELDS.PHONE} type={FancyText.TYPES.PHONE} required={true}
                               ref={(e) => this.accountList.push(e)} label="Phone" half={true}/>
                </div>

                <div className="row g-mb-20">
                    <FancySelect form={form} id={FIELDS.ROLE} label="Role" half={true} value={this.getCurrentRole()}
                                 options={roles.map((item) => ({value: item.ID, label: item.NAME}))}/>

                    <FancyText form={form} id={FIELDS.PIN} type={FancyText.TYPES.TEXT} required={true} length={4}
                               onlyNumbers={true} ref={(e) => {
                        this.pin = e;
                        this.accountList.push(e);
                    }} label="Pin" icon="fa fa-random"
                               half={true} iconClick={() => {
                        this.pin.setValue(randomString(4, "0123456789"));
                    }}/>
                </div>
            </form>
        )
    }

    renderEmployee() {
        this.employeeList = [];
        let form = this.form;

        return (
            <form>
                <div className="row g-mb-20">
                    <FancySelect form={form} id={FIELDS.TYPE} options={EMPLOYEE_TYPES} label="Employee Type"
                                 value={EMPLOYEE_TYPES[0]} half={true}/>
                    <FancySelect form={form} id={FIELDS.STATUS} options={EMPLOYEE_STATUS} label="Status" half={true}
                                 value={EMPLOYEE_STATUS[0]}/>
                </div>

                <div/>

                <div className="row g-mb-20">
                    <FancyText form={form} id={FIELDS.RATE} required={true} type={FancyText.TYPES.MONEY}
                               ref={(e) => this.employeeList.push(e)} label="Rate/Salary" half={true}/>

                    {/*<FancyDate form={form} id={FIELDS.START_DATE} required={true} ref={(e) => this.employeeList.push(e)}*/}
                    {/*           label="Hire Date" quad={true}/>*/}
                    {/*<FancySelect form={form} id={FIELDS.NEW_HIRE} options={EMPLOYEE_NEW_HIRE} label="New Hire"*/}
                    {/*             value={EMPLOYEE_NEW_HIRE[0]} quad={true}/>*/}
                </div>

                {/*<div className="g-mb-20">*/}
                {/*    <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>*/}
                {/*        <div style={{fontSize: 14}}>*/}
                {/*            We’ll securely send employment forms for team members to fill out and access digitally*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div style={{*/}
                {/*        display: "flex",*/}
                {/*        flexDirection: "row",*/}
                {/*        justifyContent: "space-evenly",*/}
                {/*        alignItems: "center"*/}
                {/*    }}>*/}
                {/*        <span style={{fontWeight: "bold", fontSize: 12}}>*/}
                {/*            <i className="fa fa-check" style={{fontSize: 12, marginRight: 6}}/>*/}

                {/*            Tax withholding - Form W4*/}
                {/*        </span>*/}

                {/*        <span style={{fontWeight: "bold", fontSize: 12}}>*/}
                {/*            <i className="fa fa-check" style={{fontSize: 12, marginRight: 6}}/>*/}

                {/*            Employment  eligibility - Form I9*/}
                {/*        </span>*/}

                {/*        <span style={{fontWeight: "bold", fontSize: 12}}>*/}
                {/*            <i className="fa fa-check" style={{fontSize: 12, marginRight: 6}}/>*/}

                {/*            Direct deposit setup*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </form>
        )
    }

    renderConfirmEmployee() {
        let {payroll} = this.state;
        let form = this.form;

        if (!payroll) {
            return
        }

        return (
            <div>
                <div style={{display: "flex", marginTop: 24, alignItems: "center"}}>
                    <div style={{marginBottom: 8, fontWeight: "bold"}}>
                        Payroll
                    </div>

                    <div onClick={() => this.setState({step: STEPS.EMPLOYEE})} className="show-cursor"
                         style={{marginLeft: 10, color: COLORS.DRIP_GREEN, fontSize: 12}}>
                        Edit
                    </div>
                </div>

                <div className="row g-mb-20">
                    <FancyText disabled={true} label="Employee Type" half={true} value={form[FIELDS.TYPE].label}/>
                    <FancyText disabled={true} label="Status" half={true} value={form[FIELDS.STATUS].label}/>
                </div>

                <div className="row g-mb-20">
                    <FancyText disabled={true} label="Rate/Salary" tri={true} value={form[FIELDS.RATE]}/>
                    <FancyText disabled={true} label="Hire Date" tri={true}
                               value={moment(form[FIELDS.START_DATE]).format("M/D/YY")}/>
                    <FancyText disabled={true} label="New Hire" tri={true} value={form[FIELDS.NEW_HIRE].label}/>
                </div>
            </div>
        )
    }

    renderConfirm() {
        let form = this.form;

        return (
            <div>
                <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{marginBottom: 8, fontWeight: "bold"}}>
                        Account
                    </div>

                    <div onClick={() => this.setState({step: STEPS.ACCOUNT})} className="show-cursor"
                         style={{marginLeft: 10, color: COLORS.DRIP_GREEN, fontSize: 12}}>
                        Edit
                    </div>
                </div>

                <div className="row g-mb-20">
                    <FancyText disabled={true} label="Name" tri={true} value={form[FIELDS.FULL_NAME]}/>
                    <FancyText disabled={true} label="Role" tri={true} value={form[FIELDS.ROLE].label}/>
                    <FancyText disabled={true} label="Pin" tri={true} value={form[FIELDS.PIN]}/>
                </div>

                <div className="row g-mb-20">
                    <FancyText disabled={true} label="Phone" half={true} value={form[FIELDS.PHONE]}/>
                    <FancyText disabled={true} label="Email" half={true} value={form[FIELDS.EMAIL]}/>
                </div>

                {this.renderConfirmEmployee()}
            </div>
        )
    }

    renderStep() {
        let {step} = this.state;

        switch (step) {
            default:
                break;
            case STEPS.ACCOUNT:
                return this.renderAccount();
            case STEPS.EMPLOYEE:
                return this.renderEmployee();
            case STEPS.CONFIRM:
                return this.renderConfirm();
        }

        return (
            <div/>
        );
    }

    renderNonPayroll() {
        let {step} = this.state;

        return [];
        if (step !== STEPS.EMPLOYEE) {
            return [];
        }

        return [
            <div style={{display: "flex", flex: 1, alignItems: "center"}}>
                <a style={{textDecorationLine: "underline", marginLeft: -50}} className="show-cursor" onClick={() => {
                    this.setState({step: STEPS.CONFIRM, payroll: false});
                }}>Non-payroll person</a>
            </div>
        ]
    }

    render() {
        let {step} = this.state;

        return (
            <Fancy name="Create Employee" onClick={this.step.bind(this)} cancelClick={this.stepBack.bind(this)}
                   cancelText={step !== STEPS.CONFIRM ? "Cancel" : "Back"} ref={(e) => this.fancy = e}
                   buttonText={step !== STEPS.CONFIRM ? "Next" : "Create"} buttons={this.renderNonPayroll()}
            >
                {this.renderStep()}
            </Fancy>
        )
    }
}

export default CreateEmployeeModal;