import React, {Component} from 'react'
import {Switch, Route, Link} from 'react-router-dom'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import Actions from '../../actions/index'

import {
    withRouter
} from 'react-router-dom'
import SecondaryNavItem from "../../modules/SecondaryNavItem";
import {request, uploadImage} from "../../util/Util";
import NewsTab from "./tabs/NewsTab";
import {addNotification, ALERT_ERROR, ALERT_INFO} from "../../util/NotificationUtil";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class PostScreen extends Component {
    render() {
        const {ID} = this.props.match.params;

        let product = this.props.partner.news.find((item) => item.ID === parseInt(ID));
        if (typeof product === "undefined") {
            return (
                <div/>
            )
        }

        return (
            <div>
                <div className="g-hidden-sm-down g-bg-gray-light-v8 g-pa-20">
                    <ul className="u-list-inline g-color-gray-dark-v6">
                        <li className="list-inline-item g-mr-10">
                            <Link className="u-link-v5 g-color-gray-dark-v6 g-color-secondary--hover g-valign-middle"
                                  to="/news">
                                News Posts
                            </Link>
                            <i className="hs-admin-angle-right g-font-size-12 g-color-gray-light-v6 g-valign-middle g-ml-10"/>
                        </li>
                        <li className="list-inline-item g-mr-10">
                            <Link className="u-link-v5 g-color-gray-dark-v6 g-color-secondary--hover g-valign-middle"
                                  to={`/news/`}>
                                Edit Post
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="col g-ml-45 g-ml-0--lg g-pb-65--md">
                    <div className="g-pa-20">
                        <div className="row">
                            <div className="col-md-3 g-mb-30 g-mb-0--md">
                                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                                    <section className="text-center g-mb-30 g-mb-50--md">
                                        <div className="d-inline-block g-pos-rel g-mb-20">
                                            <a className="u-badge-v2--lg u-badge--bottom-right g-width-32 g-height-32 g-bg-primary--hover g-transition-0_3 g-mb-20 g-mr-20"
                                               style={{backgroundColor: "red"}} href="#!" onClick={() => {
                                                document.getElementById("uploadLogo").click();
                                            }}>
                                                <i className="hs-admin-pencil g-absolute-centered g-font-size-16 g-color-white"/>
                                            </a>

                                            <input id="uploadLogo" type="file" style={{display: "none"}}
                                                   onChange={() => {
                                                       uploadImage("shop/image", document.getElementById("uploadLogo").files[0], (err, payload) => {
                                                           if (err) {
                                                               alert("Image is too large. Try reducing the size.");
                                                               return;
                                                           }

                                                           request("partner/news/" + ID, "PATCH", {...product, LOGO: payload}).then(() => {
                                                               setTimeout(() => {
                                                                   this.props.updatePost({...product, LOGO: payload});
                                                               }, 100);

                                                               addNotification.call(this, "Logo Updated", "Your logo have been updated", ALERT_INFO);
                                                           }).catch(() => {
                                                               addNotification.call(this, "Error Uploading Logo", "Logo is too big. Please downscale it.", ALERT_ERROR);
                                                           });
                                                       });
                                                   }}/>

                                            <img className="img-fluid rounded-circle"
                                                 src={product.LOGO === null ?
                                                     "http://localhost:3000/assets/img-temp/130x130/img1.jpg" :
                                                     "https://s3.amazonaws.com/drip-drinks/" + product.LOGO}
                                                 alt=""/>
                                        </div>

                                        <h3 className="g-font-weight-300 g-font-size-20 g-color-black mb-0">
                                            {product.NAME}
                                        </h3>
                                    </section>

                                    <section>
                                        <ul className="list-unstyled mb-0">
                                            <SecondaryNavItem icon="fa fa-newspaper" {...this.props}
                                                              url={"/post/" + ID} name="Edit Post"/>
                                            {/*<SecondaryNavItem icon="hs-admin-user" {...this.props}*/}
                                            {/*url={"/post/" + ID + "/stats"} name="View Stats"/>*/}
                                        </ul>
                                    </section>
                                </div>
                            </div>

                            <Switch>
                                <Route exact path='/post/:ID'
                                       component={() => <NewsTab {...this.props} />}/>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostScreen));