import React, {Component} from "react"

import {FancyText, FancySelect, Fancy} from "corky";
import chroma from "chroma-js";
import {request} from "../../../util/Util";
import moment from "moment";

const TO_ADD = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6
};

const SHIFT_COLORS = [
    {label: "Blue", value: "DCF0FB", color: "#DCF0FB"},
    {label: "Purple", value: "DDDCFB", color: "#DDDCFB"},
    {label: "Pink", value: "FBDCF6", color: "#FBDCF6"},
    {label: "Green", value: "DDFBDC", color: "#DDFBDC"},
    {label: "Red", value: "FBDCDC", color: "#FBDCDC"},
];

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const colourStyles = {
    control: styles => ({...styles, backgroundColor: 'white'}),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : null,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : color.alpha(1).css(),
            fontWeight: "bold",
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
            },
        };
    },
    input: styles => ({...styles, ...dot()}),
    placeholder: styles => ({...styles, ...dot()}),
    singleValue: (styles, {data}) => ({...styles, ...dot(data.color)}),
};

const FIELDS = {
    DATE_START: "shift-date-start",
    DATE_END: "shift-date-end",
    NOTES: "shift-notes",
    COLOR: "shift-color"
};

let DAY_LISTS = {};

class ShiftModal extends Component {
    state = {
        start: null, end: null, shift: null, employee: null, day: 0, window: null, days: [], notes: "",
        color: SHIFT_COLORS[0], single: false
    };

    constructor() {
        super();

        this.form = {[FIELDS.NOTES]: ""};
    }

    openDay(employee, day, window, startDate) {
        this.form = {};

        let params = {
            start: {value: startDate, label: moment(startDate).format("hh:mm A")},
            end: {
                value: startDate + (1000 * 60 * 60),
                label: moment(startDate + (1000 * 60 * 60)).format("hh:mm A")
            },
            single: true,
            days: [new Date(startDate).getISODay()]
        };

        let options = this.getSelectOptions(window, day, true);
        let index = options.findIndex(({value}) => {
            return value === startDate;
        });

        if (index !== -1) {
            params.start = options[index];
            params.end = options[index + 1];
        }

        this.setState(params, () => {
            this.open(employee, day, window);
        });
    }

    open(employee, day, window, shift, dayView) {
        let newState = {
            employee, day, days: [TO_ADD[day]], window, shift: shift ? shift : null
        };

        if (dayView) {
            newState.single = true;
        }

        if (shift) {
            newState.notes = shift.NOTES;
            newState.color = SHIFT_COLORS.find((item) => item.value === shift.COLOR);

            let startOption = this.getSelectOptions(window, day, dayView).find(({value}) => value === shift.DATE_START);
            newState.start = startOption ? startOption : {
                value: shift.DATE_START,
                label: moment(shift.DATE_START).format("hh:mm A")
            };

            let endOption = this.getSelectOptions(window, day, dayView).find(({value}) => {
                return value === shift.DATE_END
            });

            newState.end = endOption ? endOption : {
                value: shift.DATE_END,
                label: moment(shift.DATE_END).format("hh:mm A")
            };
        }

        this.setState(newState, () => {
            this.fancy.open();
        });
    }

    validate() {
        let isValidTracker = true;
        for (let item of this.fancyRefs) {
            if (item === null) {
                continue;
            }

            if (!item.isValid()) {
                isValidTracker = false;
            }
        }

        return isValidTracker;
    }

    async addShift() {
        let {employee, start, end, days, notes, color} = this.state;
        let form = this.form;

        if (!this.validate()) {
            return;
        }

        this.fancy.getCreateButton().startLoading();
        let shifts = await request("scheduling/shift", "POST", {
            EMPLOYEE_ID: employee,
            DAYS: days,
            DATE_START: start.value,
            DATE_END: end.value,
            NOTES: notes,
            COLOR: color.value
        });

        await this.fancy.close()

        this.props.addShifts(shifts);
    }

    async sendEditShift() {
        let {end, start, employee, color, shift, notes} = this.state;

        let shiftPayload = {
            EMPLOYEE_ID: employee,
            DATE_START: start.value,
            DATE_END: end.value,
            NOTES: notes,
            COLOR: color.value
        };

        if (end < start) {
            return alert("End Time Invalid", "Start time cannot be after end time");
        }

        this.fancy.getCreateButton().startLoading();

        await request("scheduling/shift/" + shift.ID, "PATCH", shiftPayload);

        await this.fancy.close();
        this.props.editShift({...shift, ...shiftPayload});
    }

    deleteShift() {
        let {shifts} = this.props.partner;

        let shiftIndex = shifts.findIndex((item) => item.ID === this.state.shift.ID);
        let shift = shifts[shiftIndex];

        if (!window.confirm("Are you sure you wanted to delete this shift?")) {
            return;
        }

        request("scheduling/shift/" + shift.ID, "DELETE", {}, (err, ID) => {
            if (err) {
                alert("ERROR");
                return;
            }

            this.fancy.close().then(() => {
                this.setState({notes: ""}, () => {
                    shifts.splice(shiftIndex, 1);

                    this.props.updateShifts(shifts);
                });
            });
        });
    }

    toggleDay(i) {
        let {days} = this.state;
        let index = days.indexOf(i);

        if (index === -1) {
            days.push(i);
        } else {
            days.splice(index, 1);
        }

        this.setState({days});
    }

    getSelectOptions(_window, _day, _single) {
        let {day, window, single} = this.state;
        let options = [];

        if (_window && _day) {
            day = _day;
            window = _window;
        }

        if (_single) {
            single = _single;
        }

        if (!day) {
            return [];
        }

        if (DAY_LISTS[day + "" + moment(window).format("MM-DD-YY")]) {
            return DAY_LISTS[day + "" + moment(window).format("MM-DD-YY")];
        }

        let shiftStart = moment(window).add(single ? 0 : TO_ADD[day], "days").toDate().getTime();
        let shiftEnd = moment(shiftStart).add(1, "days").toDate().getTime();

        while (shiftStart + (1000 * 60 * 15) < shiftEnd) {
            options.push({
                label: moment(shiftStart).format("hh:mm A"),
                value: shiftStart,
                color: '#FF5630'
            });

            if (options.length > 94) {
                break;
            }

            shiftStart += 1000 * 60 * 15;
        }

        DAY_LISTS[day + "" + moment(window).format("MM-DD-YY")] = options;
        return options;
    }

    getButtonText() {
        let {shift, start, end, days, save} = this.state;

        if (start === null) {
            return "Select Start";
        }

        if (end === null) {
            return "Select End"
        }

        if (days.length === 0) {
            return "Select Dates"
        }

        if (shift) {
            return "Save"
        }

        return "Create"
    }

    render() {
        let {shift, start, end, days} = this.state;
        this.fancyRefs = [];

        return (
            <Fancy name={shift === null ? "Add shift" : "Edit Shift"} ref={(e) => this.fancy = e}
                   onClick={shift === null ? this.addShift.bind(this) : this.sendEditShift.bind(this)}
                   onDelete={shift === null ? undefined : () => this.deleteShift()}
                   disabled={start === null || end === null || days.length === 0}
                   buttonText={this.getButtonText()}
            >
                <div className="row g-mb-20">
                    <FancySelect label="Shift Start" quad={true} name="start" parentState={this.state}
                                 options={this.getSelectOptions()}
                                 required={true} ref={(e) => this.fancyRefs.push(e)}
                                 setParent={(state) => this.setState(state)}/>

                    <div style={{
                        display: "flex",
                        alignItems: "flex-end",
                        marginBottom: 8,
                        marginHorizontal: 15
                    }}>
                        {" - "}
                    </div>

                    <FancySelect label="Shift End" quad={true} name="end" parentState={this.state}
                                 options={this.getSelectOptions()}
                                 required={true} ref={(e) => this.fancyRefs.push(e)}
                                 setParent={(state) => {
                                     this.setState(state)
                                 }}/>

                    <FancySelect name="color" parentState={this.state} setParent={(state) => this.setState(state)}
                                 label="Color" ref={(e) => this.fancyRefs.push(e)}
                                 options={SHIFT_COLORS} quad={true} required={true} defaultValue={SHIFT_COLORS[0]}
                                 customStyles={{
                                     ...colourStyles,
                                     control: (base) => ({
                                         ...base,
                                         border: 0,
                                         boxShadow: 0,
                                         '&:hover': {
                                             border: 0
                                         }
                                     })
                                 }}

                    />
                </div>

                <div className="g-mb-20">
                    <label className="g-mb-10" htmlFor="#bio">Apply To</label>

                    <div className="col-md-9 align-self-center">
                        <div className="row g-mx-minus-10">
                            <div className="btn-group justified-content">
                                <label className="u-check">
                                    <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="currency"
                                           onChange={this.toggleDay.bind(this, 0)} type="checkbox"
                                           checked={days.indexOf(0) !== -1}/>
                                    <span
                                        className="btn btn-md btn-block u-btn-outline-lightgray g-bg-primary--checked g-font-size-16 g-color-white--checked rounded-0 g-py-10">
                                            Mon
                                        </span>
                                </label>
                                <label className="u-check">
                                    <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="currency"
                                           onChange={this.toggleDay.bind(this, 1)} type="checkbox"
                                           checked={days.indexOf(1) !== -1}/>
                                    <span
                                        className="btn btn-md btn-block u-btn-outline-lightgray g-bg-primary--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                            Tue
                                        </span>
                                </label>
                                <label className="u-check">
                                    <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="currency"
                                           onChange={this.toggleDay.bind(this, 2)} type="checkbox"
                                           checked={days.indexOf(2) !== -1}/>
                                    <span
                                        className="btn btn-md btn-block u-btn-outline-lightgray g-bg-primary--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                            Wed
                                        </span>
                                </label>
                                <label className="u-check">
                                    <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="currency"
                                           onChange={this.toggleDay.bind(this, 3)} type="checkbox"
                                           checked={days.indexOf(3) !== -1}/>
                                    <span
                                        className="btn btn-md btn-block u-btn-outline-lightgray g-bg-primary--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                            Thu
                                        </span>
                                </label>
                                <label className="u-check">
                                    <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="currency"
                                           onChange={this.toggleDay.bind(this, 4)} type="checkbox"
                                           checked={days.indexOf(4) !== -1}/>
                                    <span
                                        className="btn btn-md btn-block u-btn-outline-lightgray g-bg-primary--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                            Fri
                                        </span>
                                </label>
                                <label className="u-check">
                                    <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="currency"
                                           onChange={this.toggleDay.bind(this, 5)} type="checkbox"
                                           checked={days.indexOf(5) !== -1}/>
                                    <span
                                        className="btn btn-md btn-block u-btn-outline-lightgray g-bg-primary--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                            Sat
                                        </span>
                                </label>
                                <label className="u-check">
                                    <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="currency"
                                           onChange={this.toggleDay.bind(this, 6)} type="checkbox"
                                           checked={days.indexOf(6) !== -1}/>
                                    <span
                                        className="btn btn-md btn-block u-btn-outline-lightgray g-bg-primary--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                            Sun
                                        </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <FancyText name="notes" label="Notes"
                           ref={(e) => this.fancyRefs.push(e)} setParent={(state) => this.setState(state)}/>
            </Fancy>
        )
    }
}

export default ShiftModal;